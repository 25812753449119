type GmMidiInstrument = {
  id: number;
  note: string;
  name: string;
};

const gmDrumMap = new Map<number, GmMidiInstrument>();

gmDrumMap.set(35, { id: 35, note: 'B0', name: 'Acoustic Bass Drum' });
gmDrumMap.set(36, { id: 36, note: 'C1', name: 'Kick Drum' });
gmDrumMap.set(37, { id: 37, note: 'C#1', name: 'Side Stick' });
gmDrumMap.set(38, { id: 38, note: 'D1', name: 'Snare' });
gmDrumMap.set(39, { id: 39, note: 'Eb1', name: 'Hand Clap' });
gmDrumMap.set(40, { id: 40, note: 'E1', name: 'Electric Snare' });
gmDrumMap.set(41, { id: 41, note: 'F1', name: 'Low Floor Tom' });
gmDrumMap.set(42, { id: 42, note: 'F#1', name: 'Hi-Hat Closed' });
gmDrumMap.set(43, { id: 43, note: 'G1', name: 'High Floor Tom' });
gmDrumMap.set(44, { id: 44, note: 'Ab1', name: 'Pedal Hi-Hat' });
gmDrumMap.set(45, { id: 45, note: 'A1', name: 'Low Tom' });
gmDrumMap.set(46, { id: 46, note: 'Bb1', name: 'Hi-Hat Open' });
gmDrumMap.set(47, { id: 47, note: 'B1', name: 'Low-Mid Tom' });
gmDrumMap.set(48, { id: 48, note: 'C2', name: 'Hi Mid Tom' });
gmDrumMap.set(49, { id: 49, note: 'C#2', name: 'Crash Cymbal 1' });
gmDrumMap.set(50, { id: 50, note: 'D2', name: 'High Tom' });
gmDrumMap.set(51, { id: 51, note: 'Eb2', name: 'Ride Cymbal 1' });
gmDrumMap.set(52, { id: 52, note: 'E2', name: 'Chinese Cymbal' });
gmDrumMap.set(53, { id: 53, note: 'F2', name: 'Ride Bell' });
gmDrumMap.set(54, { id: 54, note: 'F#2', name: 'Tambourine' });
gmDrumMap.set(55, { id: 55, note: 'G2', name: 'Splash Cymbal' });
gmDrumMap.set(56, { id: 56, note: 'Ab2', name: 'Cowbell' });
gmDrumMap.set(57, { id: 57, note: 'A2', name: 'Crash Cymbal 2' });
gmDrumMap.set(58, { id: 58, note: 'Bb2', name: 'Vibraslap' });
gmDrumMap.set(59, { id: 59, note: 'B2', name: 'Ride Cymbal 2' });
gmDrumMap.set(60, { id: 60, note: 'C3', name: 'Hi Bongo' });
gmDrumMap.set(61, { id: 61, note: 'C#3', name: 'Low Bongo' });
gmDrumMap.set(62, { id: 62, note: 'D3', name: 'Mute Hi Conga' });
gmDrumMap.set(63, { id: 63, note: 'Eb3', name: 'Open Hi Conga' });
gmDrumMap.set(64, { id: 64, note: 'E3', name: 'Low Conga' });
gmDrumMap.set(65, { id: 65, note: 'F3', name: 'High Timbale' });
gmDrumMap.set(66, { id: 66, note: 'F#3', name: 'Low Timbale' });
gmDrumMap.set(67, { id: 67, note: 'G3', name: 'High Agogo' });
gmDrumMap.set(68, { id: 68, note: 'Ab3', name: 'Low Agogo' });
gmDrumMap.set(69, { id: 69, note: 'A3', name: 'Cabasa' });
gmDrumMap.set(70, { id: 70, note: 'Bb3', name: 'Maracas' });
gmDrumMap.set(71, { id: 71, note: 'B3', name: 'Short Whistle' });
gmDrumMap.set(72, { id: 72, note: 'C4', name: 'Long Whistle' });
gmDrumMap.set(73, { id: 73, note: 'C#4', name: 'Short Guiro' });
gmDrumMap.set(74, { id: 74, note: 'D4', name: 'Long Guiro' });
gmDrumMap.set(75, { id: 75, note: 'Eb4', name: 'Claves' });
gmDrumMap.set(76, { id: 76, note: 'E4', name: 'Hi Wood Block' });
gmDrumMap.set(77, { id: 77, note: 'F4', name: 'Low Wood Block' });
gmDrumMap.set(78, { id: 78, note: 'F#4', name: 'Mute Cuica' });
gmDrumMap.set(79, { id: 79, note: 'G4', name: 'Open Cuica' });
gmDrumMap.set(80, { id: 80, note: 'Ab4', name: 'Mute Triangle' });
gmDrumMap.set(81, { id: 81, note: 'A4', name: 'Open Triangle' });

export default gmDrumMap;
