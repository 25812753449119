export default async function wait<T>(
  callback: () => T | Promise<T>,
): Promise<T> {
  try {
    document.body.classList.add('wait');
    const result = await new Promise<T>((resolve) => {
      setTimeout(async function () {
        const result = await callback();
        resolve(result);
      }, 0);
    });
    return result;
  } finally {
    document.body.classList.remove('wait');
  }
}
