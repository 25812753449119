class Node<T> {
  data: T;
  next?: Node<T>;

  constructor(data: T) {
    this.data = data;
  }
}

class List<T> {
  head?: Node<T>;
  tail?: Node<T>;
  length = 0;

  /**
   * adds an element to the end of the list.
   */
  push(data: T) {
    const node = new Node(data);
    if (!this.head) {
      this.head = node;
      this.tail = node;
    } else {
      this.tail!.next = node;
      this.tail = node;
    }
    this.length++;
  }

  /**
   * Removes the element from the font of the list and returns it.
   */
  shift(): T | undefined {
    if (!this.head) {
      return undefined;
    }
    const data = this.head.data;
    this.head = this.head.next;
    if (!this.head) {
      this.tail = undefined;
    }
    this.length--;
    return data;
  }

  /**
   * Removes the element from the end of the list and returns it.
   */
  pop(): T | undefined {
    if (!this.head) {
      return undefined;
    }
    let current = this.head;
    let prev: Node<T> | undefined;
    while (current.next) {
      prev = current;
      current = current.next;
    }
    if (prev) {
      prev.next = undefined;
      this.tail = prev;
    } else {
      this.head = undefined;
      this.tail = undefined;
    }
    this.length--;
    return current.data;
  }

  /**
   * Looks at the first element of the list without removing it.
   */
  get first() {
    return this.head?.data;
  }

  /**
   * Looks at the last element of the list without removing it.
   */
  get last() {
    return this.tail?.data;
  }

  /**
   * Clears the list.
   */
  clear() {
    this.head = undefined;
    this.tail = undefined;
    this.length = 0;
  }

  /**
   * Removes all elements from the front of the list that match the callback.
   * Resets the list head to the first element that does not match the callback.
   */
  dropWhile(callback: (data: T) => boolean) {
    let current = this.head;
    let dropped: T[] = [];
    while (current && callback(current.data)) {
      dropped.push(current.data);
      current = current.next;
      this.length--;
    }
    this.head = current;
    return dropped;
  }

  /**
   * Removes all elements from the end of the list after the callback returns false.
   * Returns all dropped elements
   */
  dropAfter(callback: (data: T) => boolean) {
    if (!this.head) {
      return [];
    }

    if (!callback(this.head.data)) {
      const data = this.toArray();
      this.clear();
      return data;
    }

    let current: Node<T> | undefined = this.head;
    let prev: Node<T> | undefined;
    while (current && callback(current.data)) {
      prev = current;
      current = current.next;
    }
    let dropped: T[] = [];
    if (prev) {
      prev.next = undefined;
      this.tail = prev;
      while (current) {
        dropped.push(current.data);
        current = current.next;
        this.length--;
      }
    }
    return dropped;
  }

  /**
   * Iterates through the list and calls the callback for each element.
   */
  forEach(callback: (data: T) => void) {
    let current = this.head;
    while (current) {
      callback(current.data);
      current = current.next;
    }
  }

  /**
   * Returns an array of all the elements in the list.
   */
  toArray() {
    const result: T[] = [];
    this.forEach((data) => {
      result.push(data);
    });
    return result;
  }
}

export default List;
