import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import ProgressService from 'editor/services/progress';
import { htmlSafe } from '@ember/template';
import gt from 'editor/helpers/gt';
interface ProgressSignature {
    Element: HTMLDivElement;
    Args: {
    };
    Blocks: {
    };
}
export default class ProgressComponent extends Component<ProgressSignature> {
    @service
    progress: ProgressService;
    constructor(owner1: unknown, args1: ProgressSignature['Args']){
        super(owner1, args1);
    }
    get progressStyle() {
        return htmlSafe(`width: ${this.progress.percentage}%;`);
    }
    get progressMessage() {
        return `${this.progress.progress} of ${this.progress.total} - ${this.progress.percentage}%`;
    }
    static{
        template(`
    {{#if this.progress.showProgress}}
      <div class="bg-black opacity-75" ...attributes></div>

      <div class="flex items-center justify-center progress" ...attributes>
        <div class="relative w-full">
          <div
            class="text-center text-white shadow-md"
          >{{this.progress.waitingPhrase}}</div>
          <div
            class="flex items-start w-full text-primary bg-primary-light progress-bar justify-stretch"
            role="progressbar"
            aria-valuenow={{this.progress.percentage}}
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <div
              class="h-full pr-2 mr-2 text-right text-white bg-primary"
              style={{this.progressStyle}}
            >{{if
                (gt this.progress.percentage 14)
                this.progressMessage
                (htmlSafe "&nbsp;")
              }}</div>
            {{if
              (gt 15 this.progress.percentage)
              this.progressMessage
              (htmlSafe "&nbsp;")
            }}</div>
        </div>
      </div>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        Progress: typeof ProgressComponent;
    }
}
