import Modifier, { type ArgsFor } from 'ember-modifier';
import { registerDestructor } from '@ember/destroyable';
import RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type Transition from '@ember/routing/transition';
import { action } from '@ember/object';

type OnUnloadCallback = (ev: {
  preventDefault: () => void;
  returnValue: string;
}) => void;

interface PreventUnloadModifierSignature {
  element: HTMLElement;
  Args: {
    Positional: [OnUnloadCallback];
    Named: { onRouteChange?: true };
  };
}

function cleanup(instance: PreventUnloadModifier, returnFocus = false) {
  if (instance.eventHandlerAdded && window && instance.callback) {
    window.removeEventListener('beforeunload', instance.eventHandler);
    instance.router.off('routeWillChange', instance.eventHandler);
  }
}

export default class PreventUnloadModifier extends Modifier<PreventUnloadModifierSignature> {
  @service declare router: RouterService;
  callback?: OnUnloadCallback;
  onRouteChange = false;
  eventHandlerAdded = false;

  constructor(owner: any, args: ArgsFor<PreventUnloadModifierSignature>) {
    super(owner, args);
    this.callback = args.positional[0];
    this.onRouteChange = args.named.onRouteChange ?? false;
    registerDestructor(this, () => cleanup(this, true));
  }

  @action
  eventHandler(ev: BeforeUnloadEvent | Transition) {
    if (ev instanceof BeforeUnloadEvent) {
      this.callback?.(ev);
    } else {
      const transition = <Transition>ev;
      //@ts-expect-error
      if (!transition.isAborted) {
        this.callback?.({
          preventDefault: () => {
            transition.abort();
          },
          returnValue: '',
        });
      }
    }
  }

  async modify(/*element: HTMLElement*/) {
    cleanup(this);
    if (window && this.callback) {
      window.addEventListener('beforeunload', this.eventHandler);
      if (this.onRouteChange) {
        this.router.on('routeWillChange', this.eventHandler);
      }
      this.eventHandlerAdded = true;
    }
  }
}
