import Route from '@ember/routing/route';
import { service } from '@ember/service';
import Drumset from 'editor/models/drumset';
import Song from 'editor/models/song';
import type ProjectManagerService from 'editor/services/project-manager';
import {
  DrumsetFile,
  EffectFile,
  SongFile,
  SongFolder,
} from 'editor/services/project-manager';
import type SongPlayerService from 'editor/services/song-player';

export default class PlaygroundRoute extends Route {
  @service declare projectManager: ProjectManagerService;
  @service declare songPlayer: SongPlayerService;

  model() {
    const drumsetPromise = new Promise<Drumset>((resolve) => {
      fetch('/drumset.drm').then((response) => {
        response.arrayBuffer().then((buffer) => {
          const drumset = Drumset.parse(buffer);
          resolve(drumset);
        });
      });
    });
    const songPromise = new Promise<Song>((resolve) => {
      fetch('/song.bbs').then((response) => {
        response.arrayBuffer().then((buffer) => {
          const song = Song.parse(buffer, 'Test Song');
          resolve(song);
        });
      });
    });
    const effect1Promise = new Promise<EffectFile>((resolve) => {
      fetch('/2E816613.WAV').then((response) => {
        response.arrayBuffer().then((buffer) => {
          const handle: unknown = {
            getFile: () => ({ arrayBuffer: () => buffer }),
          };
          const effectFile = new EffectFile(
            <FileSystemFileHandle>handle,
            'Bright crash',
            '2E816613.WAV',
          );
          resolve(effectFile);
        });
      });
    });
    const effect2Promise = new Promise<EffectFile>((resolve) => {
      fetch('/34A0F081.WAV').then((response) => {
        response.arrayBuffer().then((buffer) => {
          const handle: unknown = {
            getFile: () => ({ arrayBuffer: () => buffer }),
          };
          const effectFile = new EffectFile(
            <FileSystemFileHandle>handle,
            'Dark crash',
            '34A0F081.WAV',
          );
          resolve(effectFile);
        });
      });
    });
    return Promise.all([
      drumsetPromise,
      songPromise,
      effect1Promise,
      effect2Promise,
    ]).then(([drumset, song, effect1, effect2]) => {
      const df = new DrumsetFile(
        <FileSystemFileHandle>(<unknown>null),
        'Standard',
        'x.drm',
      );
      df.drumset = drumset;
      this.projectManager.drumsets = [df];
      this.projectManager.effects = [effect1, effect2];
      this.songPlayer.song = song;
      const folder = new SongFolder(
        <FileSystemDirectoryHandle>(<unknown>null),
        'Test folder',
        'test',
      );
      const songFile = new SongFile(
        folder,
        <FileSystemFileHandle>(<unknown>null),
        'Test song',
        'test.bbs',
      );
      songFile.song = song;
      return { drumset, songFile };
    });
  }

  afterModel({ songFile }: { drumset: Drumset; songFile: SongFile }) {
    const song = songFile.song!;
    song.addPart(song.parts.at(-1));
  }
}
