import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import { type IconName } from '@fortawesome/fontawesome-svg-core';
import EditableField from './editable-field';
import { action } from '@ember/object';
interface SelectableItemSignature {
    Element: HTMLDivElement;
    Args: {
        icon?: IconName;
        model?: object;
        field?: string;
        filter?: string;
        when?: boolean;
        // eslint-disable-next-line no-unused-vars
        onChange?: (value: string) => void;
    };
    Blocks: {
        default: [];
    };
}
export default class SelectableItem extends Component<SelectableItemSignature> {
    constructor(owner1: unknown, args1: SelectableItemSignature['Args']){
        super(owner1, args1);
    }
    get model() {
        return this.args.model ?? {};
    }
    get field() {
        return this.args.field ?? 'name';
    }
    @action
    onChange(value1: string) {
        if (this.args.onChange) {
            this.args.onChange(value1);
        }
    }
    static{
        template(`
    <div
      class="flex items-center w-full gap-2 px-3 py-1 overflow-hidden text-left bg-white rounded cursor-default text-nowrap btn text-ellipsis selected:bg-primary selected:text-white selected:cursor-text dark:bg-primary-darkComponent dark:text-white dark:selected:text-black dark:selected:bg-primary-darkButtonSelected"
      ...attributes
    >{{#if @icon}}<FaIcon class="cursor-default" @icon={{@icon}} @fixedWidth={{true}} />
      {{/if~}}
      <EditableField
        @model={{this.model}}
        @field={{this.field}}
        @filter={{@filter}}
        @when={{@when}}
        @onChange={{this.onChange}}
        class="w-full px-1 overflow-hidden text-ellipsis"
      >{{yield}}</EditableField></div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        SelectableItem: typeof SelectableItem;
    }
}
