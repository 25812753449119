// How many quarterTicks makes up one beat
export function beatFactor(timeSignatureDenomiator: number) {
  return timeSignatureDenomiator / 4;
}

export function beatTicks(
  tickToQuarter: number,
  timeSignatureDenomiator: number,
) {
  return tickToQuarter / beatFactor(timeSignatureDenomiator);
}

export function beat(
  step: number,
  division: number,
  timeSignatureNumerator: number,
  timeSignatureDenomiator: number,
) {
  const stepsPerBar =
    timeSignatureNumerator / (timeSignatureDenomiator / division);
  return step * (timeSignatureNumerator / stepsPerBar);
}

export function barBeat(
  step: number,
  division: number,
  timeSignatureNumerator: number,
  timeSignatureDenomiator: number,
) {
  return (
    beat(step, division, timeSignatureNumerator, timeSignatureDenomiator) %
    timeSignatureNumerator
  );
}

export function bar(
  step: number,
  division: number,
  timeSignatureNumerator: number,
  timeSignatureDenomiator: number,
) {
  return Math.floor(
    step / (timeSignatureNumerator / (timeSignatureDenomiator / division)),
  );
}

export default {
  beatFactor,
  beatTicks,
  beat,
  barBeat,
  bar,
};
