import { get } from '@ember/object';

export default function pick(path: string, callback: (value: any) => void) {
  return (ev: Event) => {
    const value = get(<InputEvent>ev, path);
    callback(value);
  };
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    pick: typeof pick;
  }
}
