import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type { DrumsetFile, SongFile } from './project-manager';
import type ProjectManagerService from './project-manager';

export default class StatusService extends Service {
  @service declare projectManager: ProjectManagerService;

  @tracked songFile?: SongFile;
  @tracked drumsetFile?: DrumsetFile;
  @tracked message = 'Welcome to BeatBuddy Manager!';

  addSongMidiInfo(songFile: SongFile) {
    this.drumsetFile = undefined;
    this.songFile = songFile;
  }

  addDrumsetInfo(drumsetFile: DrumsetFile) {
    this.songFile = undefined;
    this.drumsetFile = drumsetFile;
  }
}

declare module '@ember/service' {
  interface Registry {
    status: StatusService;
  }
}
