import type ProjectManagerService from 'editor/services/project-manager';
import type {
  DrumsetFile,
  EffectFile,
  SongFile,
  SongFolder,
} from 'editor/services/project-manager';
import { beatTicks } from 'editor/utils/music-utils';
import Command from './command';
import Song, {
  MidiEvent,
  PartType,
  SectionType,
  SongPart,
  SongTrack,
} from './song';

export class AddMidiEventCommand extends Command<MidiEvent> {
  track: SongTrack;
  type: number = 0;
  tick: number = 0;
  note: number = 0;
  velocity: number = 0;
  length: number = 0;
  event: MidiEvent;

  constructor(
    track: SongTrack,
    tick: number,
    note: number,
    velocity: number,
    length: number,
  ) {
    super('Add Midi Event');
    this.track = track;
    this.tick = tick;
    this.note = note;
    this.velocity = velocity;
    this.length = length;
    this.event = new MidiEvent(
      this.tick,
      this.type,
      this.note,
      this.velocity,
      this.length,
    );
  }

  markDirty(): void {
    this.track.markDirty();
  }

  execute() {
    this.track.addEvent(this.event);
    return this.event;
  }

  reverse() {
    this.track.removeEvent(this.event);
    return this.event;
  }
}

export class AddMidiEventsCommand extends Command<MidiEvent[]> {
  track: SongTrack;
  events: MidiEvent[];

  constructor(track: SongTrack, events: MidiEvent[]) {
    super('Add Midi Events');
    this.track = track;
    this.events = events;
  }

  markDirty(): void {
    this.track.markDirty();
  }

  execute() {
    this.track.addEvents(this.events);
    return this.events;
  }

  reverse() {
    this.track.removeEvents(this.events);
    return this.events;
  }
}

export class RemoveMidiEventCommand extends Command<MidiEvent> {
  track: SongTrack;
  event: MidiEvent;

  constructor(track: SongTrack, event: MidiEvent) {
    super('Remove Midi Event');
    this.track = track;
    this.event = event;
  }

  markDirty(): void {
    this.track.markDirty();
  }

  execute() {
    this.track.removeEvent(this.event);
    return this.event;
  }

  reverse() {
    this.track.addEvent(this.event);
    return this.event;
  }
}

export class RemoveMidiEventsCommand extends Command<MidiEvent[]> {
  track: SongTrack;
  events: MidiEvent[];

  constructor(track: SongTrack, events: MidiEvent[]) {
    super('Remove Midi Events');
    this.track = track;
    this.events = events;
  }

  markDirty(): void {
    this.track.markDirty();
  }

  execute() {
    this.track.removeEvents(this.events);
    return this.events;
  }

  reverse() {
    this.track.addEvents(this.events);
    return this.events;
  }
}

export class RenameSongCommand extends Command<Song> {
  song: Song;
  fromName: string;
  toName: string;

  constructor(song: Song, name: string) {
    super('Rename Song');
    this.song = song;
    this.fromName = song.name!;
    this.toName = name;
  }

  markDirty(): void {
    this.song.markDirty();
  }

  execute() {
    this.song.name = this.toName;
    return this.song;
  }

  reverse() {
    this.song.name = this.fromName;
    return this.song;
  }
}

export class UpdateTempoCommand extends Command<Song> {
  song: Song;
  fromTempo: number;
  toTempo: number;

  constructor(song: Song, fromTempo: number, toTempo: number) {
    super('Update tempo');
    this.song = song;
    this.fromTempo = fromTempo;
    this.toTempo = toTempo;
  }

  markDirty(): void {
    this.song.markDirty();
  }

  execute() {
    this.song.bpm = this.toTempo;
    return this.song;
  }

  reverse() {
    this.song.bpm = this.fromTempo;
    return this.song;
  }
}

export class UpdateMidiEventVelocityCommand extends Command<MidiEvent> {
  event: MidiEvent;
  fromVelocity: number;
  toVelocity: number;

  constructor(event: MidiEvent, fromVelocity: number, toVelocity: number) {
    super('Update Velocity');
    this.event = event;
    this.fromVelocity = fromVelocity;
    this.toVelocity = toVelocity;
  }

  markDirty(): void {
    this.event.markDirty();
  }

  execute() {
    this.event.velocity = this.toVelocity;
    return this.event;
  }

  reverse() {
    this.event.velocity = this.fromVelocity;
    return this.event;
  }
}

export class UpdateMidiEventTickCommand extends Command<MidiEvent> {
  track: SongTrack;
  event: MidiEvent;
  fromTick: number;
  toTick: number;

  constructor(
    track: SongTrack,
    event: MidiEvent,
    toTick: number,
    fromTick?: number,
  ) {
    super('Update MIDI Event Tick');
    this.track = track;
    this.event = event;
    this.fromTick = fromTick ?? event.tick;
    this.toTick = toTick;
  }

  markDirty(): void {
    this.track.markDirty();
  }

  execute() {
    this.event.tick = this.toTick;
    return this.event;
  }

  reverse() {
    this.event.tick = this.fromTick;
    return this.event;
  }
}

export class UpdateMidiEventNoteCommand extends Command<MidiEvent> {
  event: MidiEvent;
  fromNote: number;
  toNote: number;

  constructor(event: MidiEvent, toNote: number, fromNote?: number) {
    super('Update MIDI Event Note');
    this.event = event;
    this.fromNote = fromNote ?? event.note;
    this.toNote = toNote;
  }

  markDirty(): void {
    this.event.markDirty();
  }

  execute() {
    this.event.note = this.toNote;
    return this.event;
  }

  reverse() {
    this.event.note = this.fromNote;
    return this.event;
  }
}

export class FilterOverlappingMidiEventsCommand extends Command<SongTrack> {
  track: SongTrack;
  events: MidiEvent[] = [];

  constructor(track: SongTrack) {
    super('Filter Overlapping MIDI Events');
    this.track = track;
    this.events = track.filterEvents();
  }

  markDirty(): void {
    this.track.markDirty();
  }

  execute() {
    this.track.removeEvents(this.events);
    return this.track;
  }

  reverse() {
    this.track.addEvents(this.events);
    return this.track;
  }
}

export class UpdateMidiEventLengthCommand extends Command<MidiEvent> {
  track: SongTrack;
  event: MidiEvent;
  fromLength: number;
  toLength: number;

  constructor(
    track: SongTrack,
    event: MidiEvent,
    toLength: number,
    fromLength?: number,
  ) {
    super('Update MIDI note length');
    this.track = track;
    this.event = event;
    this.fromLength = fromLength ?? event.length;
    this.toLength = toLength;
  }

  markDirty(): void {
    this.track.markDirty();
  }

  execute() {
    this.event.length = this.toLength;
    return this.event;
  }

  reverse() {
    this.event.length = this.fromLength;
    return this.event;
  }
}

export class AddPartCommand extends Command<Song> {
  song: Song;
  part?: SongPart;
  afterPart?: SongPart;
  clonePart?: SongPart;

  constructor(song: Song, afterPart?: SongPart, clonePart?: SongPart) {
    super('Add Part');
    this.song = song;
    this.afterPart = afterPart;
    this.clonePart = clonePart;
  }

  markDirty(): void {
    this.song.markDirty();
  }

  execute() {
    this.part = this.song.addPart(this.afterPart, this.clonePart);
    return this.song;
  }

  reverse() {
    if (!this.part) {
      throw new Error('Part not found');
    }
    this.song.removePart(this.part);
    return this.song;
  }
}

export class RemovePartCommand extends Command<Song> {
  song: Song;
  part: SongPart;
  beforePart?: SongPart;
  index?: number;

  constructor(song: Song, part: SongPart) {
    super('Remove Part');
    this.song = song;
    this.index = song.parts.findIndex((p) => p.info === part.info);
    this.part = part;
  }

  markDirty(): void {
    this.song.markDirty();
  }

  execute() {
    this.song.removePart(this.part);
    return this.song;
  }

  reverse() {
    this.song.insertPart(this.part, this.index);
    return this.song;
  }
}
type AddTrackOpts = {
  timeSignatureNumerator: number;
  timeSignatureDenominator: number;
};

export class AddTrackCommand extends Command<SongTrack> {
  part: SongPart;
  track: SongTrack;
  sectionType: SectionType;
  sectionIndex: number = 0;

  constructor(part: SongPart, sectionType: SectionType, opts: AddTrackOpts) {
    super('Add Track');
    this.part = part;
    let name;
    switch (sectionType) {
      case SectionType.TRANSITION:
        name = 'Transition';
        break;
      case SectionType.FILL:
        name = 'Fill';
        break;
      default: {
        switch (part.type) {
          case PartType.INTRO:
            name = 'Intro';
            break;
          case PartType.OUTRO:
            name = 'Outro';
            break;
          default:
            name = 'Main';
            break;
        }
      }
    }
    const track = new SongTrack(
      name,
      opts.timeSignatureNumerator,
      opts.timeSignatureDenominator,
    );
    this.track = track;
    this.sectionType = sectionType;
  }

  markDirty(): void {
    this.part.markDirty();
  }

  execute() {
    this.sectionIndex =
      this.part.type === PartType.PART && this.sectionType === SectionType.FILL
        ? this.part.fills.length
        : -1;
    this.part.addTrack(this.sectionType, this.sectionIndex, this.track);
    return this.track;
  }

  reverse() {
    this.part.removeTrack(this.sectionType, this.sectionIndex);
    return this.track;
  }
}

export class ReplaceTrackCommand extends Command<SongTrack> {
  part: SongPart;
  prevTrack: SongTrack;
  newTrack: SongTrack;
  sectionType: SectionType;
  sectionIndex: number;

  constructor(
    part: SongPart,
    sectionType: SectionType,
    sectionIndex: number,
    track: SongTrack,
  ) {
    super('Replace Track');
    this.newTrack = track;
    this.part = part;
    this.prevTrack = part.getTrack(sectionType, sectionIndex)!;
    this.sectionType = sectionType;
    this.sectionIndex = sectionIndex;
  }

  markDirty(): void {
    this.part.markDirty();
  }

  execute() {
    this.part.removeTrack(this.sectionType, this.sectionIndex);
    this.part.addTrack(this.sectionType, this.sectionIndex, this.newTrack);
    return this.newTrack;
  }

  reverse() {
    this.part.removeTrack(this.sectionType, this.sectionIndex);
    if (this.prevTrack) {
      this.part.addTrack(this.sectionType, this.sectionIndex, this.prevTrack);
    }
    return this.newTrack;
  }
}

export class UnlinkTrackCommand extends Command<SongTrack> {
  part: SongPart;
  prevTrack: SongTrack;
  newTrack: SongTrack;
  sectionType: SectionType;
  sectionIndex: number;

  constructor(part: SongPart, sectionType: SectionType, sectionIndex: number) {
    super('Replace Track');
    this.part = part;
    this.sectionType = sectionType;
    this.sectionIndex = sectionIndex;
    this.prevTrack = part.getTrack(sectionType, sectionIndex)!;
    this.newTrack = this.prevTrack.copy();
  }

  markDirty(): void {
    this.part.markDirty();
  }

  execute() {
    this.part.removeTrack(this.sectionType, this.sectionIndex);
    this.part.addTrack(this.sectionType, this.sectionIndex, this.newTrack);
    return this.newTrack;
  }

  reverse() {
    this.part.removeTrack(this.sectionType, this.sectionIndex);
    this.part.addTrack(this.sectionType, this.sectionIndex, this.prevTrack);
    return this.newTrack;
  }
}

export class RemoveTrackCommand extends Command<SongPart> {
  part: SongPart;
  track: SongTrack;
  sectionType: SectionType;
  sectionIndex: number;
  fillsBefore: number[];

  constructor(part: SongPart, sectionType: SectionType, index: number) {
    super('Remove Track');
    this.part = part;
    this.fillsBefore = part.info.fillFileIndexes;
    const track = part.getTrack(sectionType, index);
    if (!track) {
      throw new Error('Track not found');
    }
    this.track = track;
    this.sectionType = sectionType;
    this.sectionIndex = index;
  }

  markDirty(): void {
    this.part.markDirty();
  }

  execute() {
    this.part.removeTrack(this.sectionType, this.sectionIndex);
    return this.part;
  }

  reverse() {
    this.part.info.fillFileIndexes = this.fillsBefore;
    this.part.addTrack(this.sectionType, this.sectionIndex, this.track);
    return this.part;
  }
}

export class RenameTrackCommand extends Command<SongPart> {
  part: SongPart;
  track: SongTrack;
  sectionType: SectionType;
  sectionIndex: number;
  fromName: string;
  toName: string;

  constructor(
    part: SongPart,
    sectionType: SectionType,
    index: number,
    name: string,
  ) {
    super('Rename Track');
    this.part = part;
    const track = part.getTrack(sectionType, index);
    if (!track) {
      throw new Error('Track not found');
    }
    this.fromName = track.name;
    this.toName = name;
    this.track = track;
    this.sectionType = sectionType;
    this.sectionIndex = index;
  }

  markDirty(): void {
    this.part.markDirty();
  }

  execute() {
    this.part.renameTrack(this.sectionType, this.sectionIndex, this.toName);
    return this.part;
  }

  reverse() {
    this.part.renameTrack(this.sectionType, this.sectionIndex, this.fromName);
    return this.part;
  }
}

export class UpdateTrackBarCountCommand extends Command<SongTrack> {
  track: SongTrack;
  fromBarCount: number;
  toBarCount: number;
  addEvents: MidiEvent[] = [];
  removeEvents: MidiEvent[] = [];

  constructor(
    track: SongTrack,
    toBarCount: number,
    inFront = false,
    copyEvents = false,
  ) {
    super('Update Track Bar Count');
    this.track = track;
    this.fromBarCount = track.barCount;
    this.toBarCount = toBarCount;

    // What is the change in bar count
    const barDiff = this.toBarCount - this.fromBarCount;
    // How many bars are being added or removed
    const barsChanged = Math.abs(barDiff);
    // How many ticks are being added or removed
    const ticksAffected = barsChanged * this.track.info.barLength;

    if (barDiff > 0) {
      // We are adding bars
      if (inFront) {
        // We are adding bars in front
        // Remove all events (we will add new events shifted by ticksAffected)
        this.removeEvents = this.track.events;
        // Add the events back shifted by ticksAffected
        this.addEvents = this.track.events.map((event) => {
          return new MidiEvent(
            event.tick + ticksAffected,
            event.type,
            event.note,
            event.velocity,
            event.length,
          );
        });
      } else {
        // We are adding bars in the end
        // No need to shift event ticks
      }
      if (copyEvents) {
        // We are copying events to the new bars
        if (inFront) {
          // We are copying events to the new bars in front
          // Make a copy of all events at the beginning within ticksAffected and add them
          this.addEvents = [
            ...this.addEvents,
            ...this.track.events
              .filter((event) => event.tick < ticksAffected)
              .map((event) => {
                return new MidiEvent(
                  event.tick,
                  event.type,
                  event.note,
                  event.velocity,
                  event.length,
                );
              }),
          ];
        } else {
          // We are copying events to the new bars at the end
          // Make a copy of all events at the end within ticksAffected and add them
          this.addEvents = this.track.events
            .filter(
              (event) => event.tick >= this.track.tickCount - ticksAffected,
            )
            .map((event) => {
              return new MidiEvent(
                event.tick + ticksAffected,
                event.type,
                event.note,
                event.velocity,
                event.length,
              );
            });
        }
      }
    } else {
      // We are removing bars
      this.removeEvents = this.track.events;
      if (inFront) {
        // Remove all events (we will add new events shifted by ticksAffected)
        if (copyEvents) {
          // We are removing bars in front offset by one bar (copy is overloaded to mean this)
          // Make a copy of all events at the beginning within ticksAffected and add them
          this.addEvents = this.track.events
            .filter((event) => event.tick < ticksAffected)
            .map((event) => {
              return new MidiEvent(
                event.tick,
                event.type,
                event.note,
                event.velocity,
                event.length,
              );
            });
          this.addEvents = [
            ...this.addEvents,
            ...this.track.events
              .filter((event) => event.tick >= ticksAffected * 2)
              .map((event) => {
                return new MidiEvent(
                  event.tick - ticksAffected,
                  event.type,
                  event.note,
                  event.velocity,
                  event.length,
                );
              }),
          ];
        } else {
          // Add the events back shifted by ticksAffected
          this.addEvents = this.track.events
            .filter((event) => event.tick >= ticksAffected)
            .map((event) => {
              return new MidiEvent(
                event.tick - ticksAffected,
                event.type,
                event.note,
                event.velocity,
                event.length,
              );
            });
        }
      } else {
        // We are removing bars at the end
        // Remove all events at the end within ticksAffected
        this.addEvents = this.track.events.filter(
          (event) => event.tick < this.track.tickCount - ticksAffected,
        );
      }

      const newTickCount = this.track.tickCount - ticksAffected;
      this.addEvents = this.addEvents.map((event) => {
        let tickEnd = event.tick + event.length;
        if (tickEnd > newTickCount) {
          tickEnd = newTickCount;
        }
        return new MidiEvent(
          event.tick,
          event.type,
          event.note,
          event.velocity,
          tickEnd - event.tick,
        );
      });
    }
  }

  markDirty(): void {
    this.track.markDirty();
  }

  execute() {
    this.track.barCount = this.toBarCount;
    this.track.removeEvents(this.removeEvents);
    this.track.addEvents(this.addEvents);
    return this.track;
  }

  reverse() {
    this.track.barCount = this.fromBarCount;
    this.track.removeEvents(this.addEvents);
    this.track.addEvents(this.removeEvents);
    return this.track;
  }
}

export class UpdateTimeSignatureNumeratorCommand extends Command<SongTrack> {
  track: SongTrack;
  fromNumerator: number;
  toNumerator: number;
  fromBarLength: number;
  fromTickCount: number;

  constructor(track: SongTrack, toNumerator: number) {
    super('Update Time Signature');
    this.track = track;
    this.fromNumerator = track.timeSignatureNumerator;
    this.fromBarLength = track.info.barLength;
    this.fromTickCount = track.info.tickCount;
    this.toNumerator = toNumerator;
  }

  markDirty(): void {
    this.track.markDirty();
  }

  execute() {
    const ticksPerBeat = beatTicks(
      this.track.info.tickToQuarter,
      this.track.info.timeSignatureDenominator,
    );
    const beats = this.track.info.tickCount / ticksPerBeat;
    const fullBars = Math.floor(beats / this.toNumerator);
    const extraBeats = beats % this.toNumerator;

    this.track.timeSignatureNumerator = this.toNumerator;
    this.track.info.barLength = this.toNumerator * ticksPerBeat;
    if (extraBeats > 0) {
      this.track.info.tickCount =
        (fullBars + 1) * ticksPerBeat * this.toNumerator;
    }
    return this.track;
  }

  reverse() {
    this.track.timeSignatureNumerator = this.fromNumerator;
    this.track.info.barLength = this.fromBarLength;
    this.track.info.tickCount = this.fromTickCount;
    return this.track;
  }
}

export class UpdateTimeSignatureDenominatorCommand extends Command<SongTrack> {
  track: SongTrack;
  fromDenominator: number;
  toDenominator: number;

  constructor(track: SongTrack, toDenominator: number) {
    super('Update Time Signature');
    this.track = track;
    this.fromDenominator = track.timeSignatureDenominator;
    this.toDenominator = toDenominator;
  }

  markDirty(): void {
    this.track.markDirty();
  }

  execute() {
    this.track.timeSignatureDenominator = this.toDenominator;
    return this.track;
  }

  reverse() {
    this.track.timeSignatureDenominator = this.fromDenominator;
    return this.track;
  }
}

export class ChangeTrigPosCommand extends Command<SongTrack> {
  track: SongTrack;
  fromTrigPos: number;
  fromTickCount: number;
  toTrigPos: number;
  diff: number;
  removedEvents: MidiEvent[] = [];

  constructor(track: SongTrack, toTrigPos: number) {
    super('Change Track Start');
    this.track = track;
    this.fromTrigPos = track.trigPos;
    this.toTrigPos = toTrigPos;
    this.fromTickCount = this.track.tickCount;
    this.diff = toTrigPos - this.fromTrigPos;
    this.removedEvents = this.track.info.events.filter(
      (ev) => ev.tick < this.diff,
    );
  }

  markDirty(): void {
    this.track.markDirty();
  }

  execute() {
    this.track.info.tickCount -= this.diff;
    this.track.info.trigPos = this.toTrigPos;
    this.track.info.events = this.track.info.events.filter(
      (event) => event.tick >= this.diff,
    );
    this.track.info.events.forEach((event) => (event.tick -= this.diff));
    return this.track;
  }

  reverse() {
    this.track.info.tickCount += this.diff;
    this.track.info.trigPos = this.fromTrigPos;
    this.track.info.events.forEach((event) => (event.tick += this.diff));
    this.track.info.events = [...this.removedEvents, ...this.track.info.events];
    return this.track;
  }
}

export class DoubleBeatCommand extends Command<SongTrack> {
  track: SongTrack;
  tickCount: number;
  events: MidiEvent[];

  constructor(track: SongTrack) {
    super('Double Beat');
    this.track = track;
    this.tickCount = track.info.tickCount;
    this.events = track.events;
  }

  markDirty(): void {
    this.track.markDirty();
  }

  execute() {
    this.track.info.tickCount *= 2;
    const newEvents = this.track.events.map(
      (event) =>
        new MidiEvent(
          this.tickCount + event.tick,
          event.type,
          event.note,
          event.velocity,
          event.length,
        ),
    );
    this.track.info.events = [...this.track.info.events, ...newEvents];
    return this.track;
  }

  reverse() {
    this.track.info.tickCount = this.tickCount;
    this.track.info.events = this.events;
    return this.track;
  }
}

export class QuantizeTrackCommand extends Command<SongTrack> {
  track: SongTrack;
  division: number;
  events: MidiEvent[];

  constructor(track: SongTrack, division: number = 16) {
    super('Quantize Track');
    this.track = track;
    this.division = division;
    this.events = track.events;
  }

  markDirty(): void {
    this.track.markDirty();
  }

  execute() {
    const ticksPerBeat = this.track.tickToQuarter * (16 / this.track.timeSignatureDenominator);
    const step = ticksPerBeat / this.division;

    const newEvents: MidiEvent[] = [];
    this.track.events.map((event) => {
      const newEvent = event.copy();
      newEvent.tick = Math.round(event.tick / step) * step;
      newEvents.push(newEvent);
    });

    this.track.instruments.forEach((instrument) => {
      newEvents
        .filter((event) => event.note === instrument)
        .forEach((event, idx, events) => {
          const nextNote = events[idx + 1];
          let end = event.tick + event.length;
          end = Math.round(end / step) * step;
          const length = end - event.tick;
          event.length = Math.max(step, length);
          if (nextNote) {
            const maxLength = nextNote.tick - event.tick;
            event.length = Math.min(event.length, maxLength);
          } else {
            end = this.track.info.tickCount;
            if (event.tick >= this.track.info.tickCount) {
              end = this.track.info.tickCount + this.track.extraTicks;
            }
            event.length = Math.min(event.length, end - event.tick);
          }
        });
    });

    this.track.info.events = newEvents;
    return this.track;
  }

  reverse() {
    this.track.info.events = this.events;
    return this.track;
  }
}

export class ImportAccentCommand extends Command<EffectFile> {
  projectManager: ProjectManagerService;
  file: File;
  effectFile?: EffectFile;

  constructor(projectManager: ProjectManagerService, file: File) {
    super('Import accent');
    this.projectManager = projectManager;
    this.file = file;
  }

  markDirty(): void {
    this.effectFile?.markDirty();
    this.projectManager.effectsDirty = true;
  }

  async execute() {
    this.effectFile = await this.projectManager.importEffect(this.file);
    return this.effectFile;
  }

  async reverse() {
    this.projectManager.removeEffect(this.effectFile!);
    return this.effectFile!;
  }
}

export class AddAccentCommand extends Command<SongPart> {
  songPart: SongPart;
  accentFileName: string;

  constructor(songPart: SongPart, accentFileName: string) {
    super('Add accent');
    this.songPart = songPart;
    this.accentFileName = accentFileName;
  }

  markDirty(): void {
    this.songPart.markDirty();
  }

  execute() {
    this.songPart.info.accentFileName = this.accentFileName;
    this.songPart.info.effectVolume = 100;
    return this.songPart;
  }

  reverse() {
    this.songPart.info.accentFileName = '';
    this.songPart.info.effectVolume = 100;
    return this.songPart;
  }
}

export class RemoveAccentCommand extends Command<SongPart> {
  songPart: SongPart;
  accentFileName: string;

  constructor(songPart: SongPart) {
    super('Remove accent');
    this.songPart = songPart;
    this.accentFileName = songPart.accentFileName;
  }

  markDirty(): void {
    this.songPart.markDirty();
  }

  execute() {
    this.songPart.info.accentFileName = '';
    return this.songPart;
  }

  reverse() {
    this.songPart.info.accentFileName = this.accentFileName;
    return this.songPart;
  }
}

export class UpdateAccentVolumeCommand extends Command<SongPart> {
  songPart: SongPart;
  fromVolume: number;
  toVolume: number;

  constructor(songPart: SongPart, toVolume: number) {
    super('Update Accent Volume');
    this.songPart = songPart;
    this.fromVolume = songPart.info.effectVolume;
    this.toVolume = toVolume;
  }

  markDirty(): void {
    this.songPart.markDirty();
  }

  execute() {
    this.songPart.info.effectVolume = this.toVolume;
    return this.songPart;
  }

  reverse() {
    this.songPart.info.effectVolume = this.fromVolume;
    return this.songPart;
  }
}

export class RandomiseFillsCommand extends Command<SongPart> {
  songPart: SongPart;
  randomise: boolean;

  constructor(songPart: SongPart, randomise: boolean) {
    super(randomise ? 'Randomise fills' : 'Unrandomise fills');
    this.songPart = songPart;
    this.randomise = randomise;
  }

  markDirty(): void {
    this.songPart.markDirty();
  }

  execute() {
    this.songPart.info.random = this.randomise;
    return this.songPart;
  }

  reverse() {
    this.songPart.info.random = !this.randomise;
    return this.songPart;
  }
}

export class SortFillsCommand extends Command<SongPart> {
  songPart: SongPart;
  oldIndex: number;
  newIndex: number;

  constructor(songPart: SongPart, oldIndex: number, newIndex: number) {
    super('Sort Fills');
    this.songPart = songPart;
    this.oldIndex = oldIndex;
    this.newIndex = newIndex;
  }

  markDirty(): void {
    this.songPart.markDirty();
  }

  execute() {
    const oldFill = this.songPart.fills[this.oldIndex];

    if (oldFill) {
      this.songPart.removeTrack(SectionType.FILL, this.oldIndex);
      this.songPart.addTrack(SectionType.FILL, this.newIndex, oldFill);
    }

    return this.songPart;
  }

  reverse() {
    const newFill = this.songPart.fills[this.newIndex];

    if (newFill) {
      this.songPart.removeTrack(SectionType.FILL, this.newIndex);
      this.songPart.addTrack(SectionType.FILL, this.oldIndex, newFill);
    }

    return this.songPart;
  }
}

export class SortPartsCommand extends Command<Song> {
  song: Song;
  oldIndex: number;
  newIndex: number;

  constructor(song: Song, oldIndex: number, newIndex: number) {
    super('Sort Parts');
    this.song = song;
    this.oldIndex = oldIndex;
    this.newIndex = newIndex;
  }

  markDirty(): void {
    this.song.markDirty();
  }

  execute() {
    const part = this.song.info.parts[this.oldIndex];
    const arr = [...this.song.info.parts];
    if (part) {
      // remove part from oldIndex and insert it at newIndex
      arr.splice(this.oldIndex, 1);
      arr.splice(this.newIndex, 0, part);
    }
    this.song.info.parts = arr;
    return this.song;
  }

  reverse() {
    const part = this.song.info.parts[this.newIndex];
    const arr = [...this.song.info.parts];
    if (part) {
      // remove part from newIndex and insert it at oldIndex
      arr.splice(this.newIndex, 1);
      arr.splice(this.oldIndex, 0, part);
    }
    this.song.info.parts = arr;
    return this.song;
  }
}

export class AddEffectCommand extends Command<EffectFile> {
  project: ProjectManagerService;
  effect: EffectFile;

  constructor(project: ProjectManagerService, effectFile: EffectFile) {
    super('Add Effect');
    this.project = project;
    this.effect = effectFile;
  }

  markDirty(): void {
    this.project.effectsDirty = true;
  }

  execute() {
    this.project.effects = [...this.project.effects, this.effect];
    return this.effect;
  }

  reverse() {
    this.project.effects = this.project.effects.filter(
      (e) => e !== this.effect,
    );
    return this.effect;
  }
}

export class AddNewSongCommand extends Command<SongFile> {
  project: ProjectManagerService;
  folder: SongFolder;
  song?: SongFile;

  constructor(project: ProjectManagerService, folder: SongFolder) {
    super('Add New Song');
    this.project = project;
    this.folder = folder;
  }

  markDirty(): void {
    this.folder.markDirty();
    this.song?.markDirty();
  }

  async execute() {
    this.song = await this.project.createSong(this.folder);
    return this.song;
  }

  async reverse() {
    if (!this.song) {
      throw new Error('Song not found');
    }
    this.project.removeSong(this.folder, this.song);
    return this.song;
  }
}

export class AddSongCommand extends Command<SongFile> {
  folder: SongFolder;
  song: SongFile;

  constructor(folder: SongFolder, song: SongFile) {
    super('Add Song');
    this.folder = folder;
    this.song = song;
  }

  markDirty(): void {
    this.folder.markDirty();
  }

  async execute() {
    await this.folder.addSong(this.song);
    return this.song;
  }

  async reverse() {
    this.folder.songs = this.folder.songs.filter((d) => d !== this.song);
    return this.song;
  }
}

export class DeleteSongCommand extends Command<SongFile> {
  folder: SongFolder;
  song: SongFile;
  index: number;

  constructor(folder: SongFolder, song: SongFile) {
    super('Delete Song');
    this.folder = folder;
    this.index = folder.songs.findIndex((s) => s === song);
    this.song = song;
  }

  markDirty(): void {
    this.folder.markDirty();
    this.song.markDirty();
  }

  async execute() {
    this.folder.songs = this.folder.songs.filter((d) => d !== this.song);
    return this.song;
  }

  async reverse() {
    this.folder.addSong(this.song, this.index);
    this.song.hash = "";
    return this.song;
  }
}

export class DuplicateSongCommand extends Command<SongFile> {
  projectManager: ProjectManagerService;
  folder: SongFolder;
  song: SongFile;
  newSong?: SongFile;

  constructor(
    projectManager: ProjectManagerService,
    folder: SongFolder,
    song: SongFile,
  ) {
    super('Duplicate Song');
    this.projectManager = projectManager;
    this.folder = folder;
    this.song = song;
  }

  markDirty(): void {
    this.folder.markDirty();
    this.newSong?.markDirty();
  }

  async execute() {
    this.newSong =
      this.newSong ??
      (await this.projectManager.copySong(this.song, this.folder));
    await this.folder.addSong(this.newSong);
    return this.newSong;
  }

  async reverse() {
    this.folder.songs = this.folder.songs.filter((d) => d !== this.newSong);
    return this.song;
  }
}

export class UpdateDrumsetCommand extends Command<Song> {
  song: Song;
  fromDrumset: DrumsetFile;
  toDrumset: DrumsetFile;

  constructor(song: Song, fromDrumset: DrumsetFile, toDrumset: DrumsetFile) {
    super('Update Drumset');
    this.song = song;
    this.fromDrumset = fromDrumset;
    this.toDrumset = toDrumset;
  }

  markDirty(): void {
    this.song.markDirty();
  }

  execute() {
    this.song.drumset = this.toDrumset.path;
    this.song.drumsetName = this.toDrumset.name;
    return this.song;
  }

  reverse() {
    this.song.drumset = this.fromDrumset.path;
    this.song.drumsetName = this.fromDrumset.name;
    return this.song;
  }
}
