import { action } from '@ember/object';
import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import shuffle from 'editor/utils/shuffle';

const WAITING_PHRASES = [
  'Busy tuning up my air guitar.',
  'Trying to remember the lyrics to “Happy Birthday”.',
  'Figuring out “Stairway to Heaven” on my kazoo.',
  'Wondering if I should wear sunglasses at night.',
  'Wondering if I left my drumsticks at home.',
  'At this rate, I’ll have enough time to learn a new instrument... or two.',
  'Pondering whether it would just be faster to learn to play the drums.',
  'Composing a tune called the “The Waiting Around Blues” in my head.',
  'If only inspiration struck as often as boredom does.',
  'Playing “Name That Tune” with the music in my head.',
  'If patience is a virtue, consider me a virtuoso.',
  'Time slows down for musicians waiting to play.',
  'Feeling like a permanent fixture in the green room.',
  'I’m not waiting to play. I’m waiting to rock!',
  'Waiting for my big break.',
  'If only twiddling my thumbs made music.',
  'Waiting for my moment in the spotlight.',
  'Rearranging my home studio while I wait.',
];

export default class ProgressService extends Service {
  @tracked progress = 0;
  @tracked total = 0;
  @tracked showProgress = false;
  @tracked waitingPhrase: string;
  showProgressTimer?: ReturnType<typeof setTimeout>;
  changePhrasesTimer?: ReturnType<typeof setTimeout>;

  constructor(properties?: object) {
    super(properties);
    this.reset();
    this.waitingPhrase = WAITING_PHRASES[0]!;
  }

  @action
  test() {
    setTimeout(() => {
      this.completeWork(1);
      if (!this.isComplete) {
        this.test();
      }
    }, 100);
  }

  get percentage() {
    return Math.floor((this.progress / this.total) * 100);
  }

  get isComplete() {
    return this.progress === this.total;
  }

  reset() {
    this.progress = 0;
    this.total = 0;
    this.showProgressTimer = undefined;
    shuffle(WAITING_PHRASES);
  }

  addWork(count = 1) {
    this.total += count;
    if (!this.showProgressTimer) {
      this.showProgressTimer = setTimeout(() => {
        this.showProgress = true;
        this.changePhrasesTimer = setInterval(
          () => this.updateFunnyPhrase(),
          8000,
        );
      }, 500);
    }
  }

  // Get the first phrase, and add it to the back of the array
  updateFunnyPhrase() {
    this.waitingPhrase = WAITING_PHRASES.shift()!;
    WAITING_PHRASES.push(this.waitingPhrase);
  }

  completeWork(count = 1) {
    this.progress += count;
    if (this.isComplete) {
      this.showProgress = false;
      if (this.showProgressTimer) {
        clearTimeout(this.showProgressTimer);
        clearInterval(this.changePhrasesTimer);
        this.showProgressTimer = undefined;
        this.changePhrasesTimer = undefined;
      }
    }
  }

  async trackPromises(promises: Promise<any>[]) {
    this.addWork(promises.length);
    promises.forEach((promise) => {
      promise.finally(() => {
        this.completeWork();
      });
    });
    const results = await Promise.allSettled(promises);
    return results.map((result) => {
      if (result.status === 'rejected') {
        throw result.reason;
      } else {
        return result.value;
      }
    });
  }
}

declare module '@ember/service' {
  interface Registry {
    progress: ProgressService;
  }
}
