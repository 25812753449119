import EmberRouter from '@ember/routing/router';
import config from 'editor/config/environment';

export default class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;
}

Router.map(function () {
  this.route('restricted', { path: '/' }, function () {
    this.route('songs', function () {
      this.route('folder', { path: ':folder' }, function () {
        this.route('song', { path: ':song' });
      });
    });
    this.route('playlists', function () {
      this.route('playlist', { path: ':playlist' });
    });
    this.route('drumsets', function () {
      this.route('drumset', { path: ':drumset' });
    });
  });
  this.route('playground');
});
