import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class PasteboardService extends Service {
  pastboards: Map<string, any> = new Map();
  // A hack to force reactivity on the map
  @tracked counter = 0;

  store(key: string, value: any) {
    this.counter += 1;
    this.pastboards.set(key, value);
  }

  retrieve(key: string) {
    if (this.counter > 0) {
      return this.pastboards.get(key);
    }
  }

  hasCopy(key: string) {
    if (this.counter > 0) {
      return this.pastboards.has(key);
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    pasteboard: PasteboardService;
  }
}
