import { template } from "@ember/template-compiler";
import disabled from 'editor/modifiers/disabled';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import type Drumset from 'editor/models/drumset';
import type { Instrument } from 'editor/models/drumset';
import type ProjectManagerService from 'editor/services/project-manager';
import SelectableItem from './selectable-item';
import selected from 'editor/modifiers/selected';
import { tracked } from '@glimmer/tracking';
import type { DrumsetFile } from 'editor/services/project-manager';
import eq from 'editor/helpers/eq';
import { on } from '@ember/modifier';
import { fn } from '@ember/helper';
import { action } from '@ember/object';
import Button from './button';
interface InstrumentImpportSignature {
    Element: HTMLDivElement;
    Args: {
        drumset: Drumset;
        // eslint-disable-next-line no-unused-vars
        addInstruments: (instruments: Instrument[]) => void;
    };
    Blocks: {
    };
}
// Refactor: This shares a lot of code with the Part/BuildAccentWindow component
export default class InstrumentImpport extends Component<InstrumentImpportSignature> {
    @service
    projectManager: ProjectManagerService;
    @tracked
    selectedDrumset?: DrumsetFile;
    @tracked
    instrumentsLoading = false;
    @tracked
    selectedInstruments: Instrument[] = [];
    constructor(owner1: unknown, args1: InstrumentImpportSignature['Args']){
        super(owner1, args1);
    }
    get drumset() {
        return this.args.drumset;
    }
    @action
    async selectDrumset(drumset1: DrumsetFile) {
        this.selectedDrumset = drumset1;
        this.instrumentsLoading = true;
        await drumset1.parse();
        this.instrumentsLoading = false;
    }
    get availableDrumsets() {
        return this.projectManager.drumsets.filter((d1)=>!d1.drumset || d1.drumset != this.args.drumset).sort((a1, b1)=>a1.name.localeCompare(b1.name));
    }
    get availableInstruments() {
        const drumsetPlayableInstruments1 = this.selectedDrumset?.drumset?.playableInstruments || [];
        return drumsetPlayableInstruments1.filter((i1)=>this.selectedInstruments.includes(i1) || !this.selectedInstruments.map((i1)=>i1.id).includes(i1.id)).filter((i1)=>!this.drumset.playableInstruments.map((i1)=>i1.id).includes(i1.id));
    }
    @action
    isSelectedInstrument(instrument1: Instrument) {
        return this.selectedInstruments.includes(instrument1);
    }
    @action
    toggleInstrument(instrument1: Instrument, ev1?: MouseEvent) {
        if (ev1?.shiftKey) {
            const firstSelectedInstrument1 = this.selectedInstruments.at(0);
            const lastSelectedInstrument1 = this.selectedInstruments.at(-1);
            if (firstSelectedInstrument1 && lastSelectedInstrument1) {
                const firstIndex1 = this.availableInstruments.indexOf(firstSelectedInstrument1);
                const lastIndex1 = this.availableInstruments.indexOf(lastSelectedInstrument1);
                const currentIndex1 = this.availableInstruments.indexOf(instrument1);
                if (currentIndex1 < firstIndex1) {
                    this.selectedInstruments = [
                        ...this.selectedInstruments,
                        ...this.availableInstruments.slice(currentIndex1, firstIndex1 + 1)
                    ];
                } else if (currentIndex1 > lastIndex1) {
                    this.selectedInstruments = [
                        ...this.selectedInstruments,
                        ...this.availableInstruments.slice(lastIndex1, currentIndex1 + 1)
                    ];
                } else {
                    this.selectedInstruments = [
                        ...this.selectedInstruments,
                        ...this.availableInstruments.slice(firstIndex1, currentIndex1 + 1)
                    ];
                }
            } else {
                this.selectedInstruments = [
                    instrument1
                ];
            }
        } else {
            if (this.isSelectedInstrument(instrument1)) {
                this.selectedInstruments = this.selectedInstruments.filter((i1)=>i1 != instrument1);
            } else {
                this.selectedInstruments = [
                    ...this.selectedInstruments,
                    instrument1
                ];
            }
        }
        // remove window selection
        window.getSelection()?.removeAllRanges();
    }
    static{
        template(`
    <div
      class="flex flex-col h-full min-h-0 gap-2 overflow-auto dark:bg-primary-darkComponent dark:text-white"
      data-test-instrument-import
      ...attributes
    >
      <h1>Import instruments</h1>
      <div class="flex gap-2 min-h-0 overflow-auto h-full">
        <div class="overflow-auto border-r dark:border-primary-darkBorderColor">
          <div data-test-drumsets class="flex flex-col gap-px h-max">
            {{#each this.availableDrumsets as |drumset|}}
              <SelectableItem
                @icon="drum"
                @model={{drumset}}
                @field="name"
                @when={{false}}
                title={{drumset.name}}
                {{selected when=(eq this.selectedDrumset drumset)}}
                {{on "click" (fn this.selectDrumset drumset)}}
              >{{drumset.name}}</SelectableItem>
            {{/each}}
          </div>
        </div>
        <div class="overflow-auto">
          <div data-test-instruments class="flex flex-col gap-px h-max">
            {{#if this.instrumentsLoading}}
              <div>Loading...</div>
            {{else}}
              {{#each this.availableInstruments as |instrument|}}
                <SelectableItem
                  @model={{instrument}}
                  @field="name"
                  @when={{false}}
                  title={{instrument.name}}
                  {{selected when=(this.isSelectedInstrument instrument)}}
                  {{on "click" (fn this.toggleInstrument instrument)}}
                >{{instrument.id}}. {{instrument.name}}</SelectableItem>

              {{else}}
                No available instruments
              {{/each}}
            {{/if}}
          </div>
        </div>
      </div>
      <div class="text-right">
        <Button
          class="text-white bg-green-600 hover:bg-green-700"
          {{disabled when=(eq this.selectedInstruments.length 0)}}
          {{on "click" (fn @addInstruments this.selectedInstruments)}}
        >Import instruments</Button>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        InstrumentImpport: typeof InstrumentImpport;
    }
}
