import { template } from "@ember/template-compiler";
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import eq from 'editor/helpers/eq';
import Button from './button';
interface ErrorPopupSignature {
    Element: HTMLFormElement;
    Args: {
        errorData: string[] | null;
        onClose: () => void;
    };
    Blocks: {
    };
}
export default class ErrorPopupDialog extends Component<ErrorPopupSignature> {
    @tracked
    errorData: string[] | null = null;
    constructor(owner1: unknown, args1: ErrorPopupSignature['Args']){
        super(owner1, args1);
    }
    @action
    closeErrorDialog(ev1: SubmitEvent) {
        ev1.preventDefault();
    }
    stringContainsError(str1: string) {
        return str1.includes('Errors in songs:') || str1.includes('Errors in drumsets:');
    }
    static{
        template(`
    <div class="flex flex-col gap-2 dark:text-white">
      {{#each this.args.errorData as |error|}}
        {{#if (this.stringContainsError error)}}
          <h2 class="text-lg font-bold">{{error}}</h2>
        {{else}}
          <p class="text-red-500">{{error}}</p>
        {{/if}}
      {{/each}}
      <Button
        @icon="xmark"
        class="text-white bg-green-600 hover:bg-green-700 p-2"
        {{on "click" this.args.onClose}}
      >Close</Button>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        ErrorPopup: typeof ErrorPopupDialog;
    }
}
