export default function queue(...functions: Function[]) {
  return function (...args: any[]) {
    functions.forEach((fn) => fn(...args));
  };
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    queue: typeof queue;
  }
}
