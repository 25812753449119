export default class Reader {
  dv: DataView;
  offset: number;

  constructor(dv: DataView, offset = 0) {
    this.dv = dv;
    this.offset = offset;
  }

  get length() {
    return this.dv.byteLength;
  }

  get eof() {
    return this.offset >= this.dv.byteLength;
  }

  get remaining() {
    return this.dv.byteLength - this.offset;
  }

  seek(offset: number) {
    this.offset = offset;
    return this;
  }

  getUint8() {
    return this.dv.getUint8(this.offset++);
  }

  getByte() {
    return this.getUint8();
  }

  getUint16(littleEndian = true) {
    const value = this.dv.getUint16(this.offset, littleEndian);
    this.offset += 2;
    return value;
  }

  getUShort(littleEndian = true) {
    return this.getUint16(littleEndian);
  }

  getInt16(littleEndian = true) {
    const value = this.dv.getInt16(this.offset, littleEndian);
    this.offset += 2;
    return value;
  }

  getShort(littleEndian = true) {
    return this.getInt16(littleEndian);
  }

  getUint32(littleEndian = true) {
    const value = this.dv.getUint32(this.offset, littleEndian);
    this.offset += 4;
    return value;
  }

  getUint(littleEndian = true) {
    return this.getUint32(littleEndian);
  }

  getInt32(littleEndian = true) {
    const value = this.dv.getInt32(this.offset, littleEndian);
    this.offset += 4;
    return value;
  }

  getInt(littleEndian = true) {
    return this.getInt32(littleEndian);
  }

  // Get a variable length integer
  getVarInt() {
    let value = 0;
    let byte;
    let count = 0;
    do {
      byte = this.getByte();
      value = (value << 7) + (byte & 0x7f);
      count++;
    } while (byte & 0x80 && count < 4);
    return value;
  }

  getBytes(length: number) {
    const value = this.dv.buffer.slice(this.offset, this.offset + length);
    this.offset += length;
    return value;
  }

  getString(length: number, nullTerminated = true) {
    const bytes = this.getBytes(length);
    const view = new Uint8Array(bytes);
    if (!nullTerminated) {
      return new TextDecoder('utf-8').decode(view);
    }

    const arr: number[] = [];
    for (let i = 0, ii = view.length; i < ii; i++) {
      const char: number = view[i] ?? 0;
      if (char === 0) {
        break;
      }
      arr.push(char);
    }
    return new TextDecoder('utf-8').decode(new Uint8Array(arr));
  }

  streamString(littleEndian = true) {
    const length = this.getUint(littleEndian);
    const bytes = [];
    for (let i = 0, ii = length / 2; i < ii; i++) {
      const char = this.getUint16(littleEndian);
      bytes.push(char);
    }
    return new TextDecoder('utf-16').decode(new Uint16Array(bytes));
  }
}
