
import { importSync as i, macroCondition, getGlobalConfig } from '@embroider/macros';
let w = window;
let d = w.define;


i("./-embroider-implicit-modules.js");

d("editor/app", function(){ return i("editor/app.ts");});
d("editor/application/controller", function(){ return i("editor/application/controller.ts");});
d("editor/application/route", function(){ return i("editor/application/route.ts");});
d("editor/application/template", function(){ return i("editor/application/template.hbs");});
d("editor/config/environment", function(){ return i("editor/config/environment.js");});
d("editor/models/command", function(){ return i("editor/models/command.ts");});
d("editor/models/drumset-commands", function(){ return i("editor/models/drumset-commands.ts");});
d("editor/models/drumset", function(){ return i("editor/models/drumset.ts");});
d("editor/models/folder-commands", function(){ return i("editor/models/folder-commands.ts");});
d("editor/models/midi", function(){ return i("editor/models/midi.ts");});
d("editor/models/playlist-commands", function(){ return i("editor/models/playlist-commands.ts");});
d("editor/models/project-commands", function(){ return i("editor/models/project-commands.ts");});
d("editor/models/project", function(){ return i("editor/models/project.ts");});
d("editor/models/song-commands", function(){ return i("editor/models/song-commands.ts");});
d("editor/models/song", function(){ return i("editor/models/song.ts");});
d("editor/playground/controller", function(){ return i("editor/playground/controller.ts");});
d("editor/playground/route", function(){ return i("editor/playground/route.ts");});
d("editor/playground/template", function(){ return i("editor/playground/template.hbs");});
d("editor/restricted/controller", function(){ return i("editor/restricted/controller.ts");});
d("editor/restricted/drumsets/controller", function(){ return i("editor/restricted/drumsets/controller.ts");});
d("editor/restricted/drumsets/drumset/controller", function(){ return i("editor/restricted/drumsets/drumset/controller.ts");});
d("editor/restricted/drumsets/drumset/route", function(){ return i("editor/restricted/drumsets/drumset/route.ts");});
d("editor/restricted/drumsets/drumset/template", function(){ return i("editor/restricted/drumsets/drumset/template.hbs");});
d("editor/restricted/drumsets/index/route", function(){ return i("editor/restricted/drumsets/index/route.ts");});
d("editor/restricted/drumsets/route", function(){ return i("editor/restricted/drumsets/route.ts");});
d("editor/restricted/drumsets/template", function(){ return i("editor/restricted/drumsets/template.hbs");});
d("editor/restricted/index/route", function(){ return i("editor/restricted/index/route.ts");});
d("editor/restricted/index/template", function(){ return i("editor/restricted/index/template.hbs");});
d("editor/restricted/playlists/controller", function(){ return i("editor/restricted/playlists/controller.ts");});
d("editor/restricted/playlists/index/route", function(){ return i("editor/restricted/playlists/index/route.ts");});
d("editor/restricted/playlists/index/template", function(){ return i("editor/restricted/playlists/index/template.hbs");});
d("editor/restricted/playlists/playlist/controller", function(){ return i("editor/restricted/playlists/playlist/controller.ts");});
d("editor/restricted/playlists/playlist/route", function(){ return i("editor/restricted/playlists/playlist/route.ts");});
d("editor/restricted/playlists/playlist/template", function(){ return i("editor/restricted/playlists/playlist/template.hbs");});
d("editor/restricted/playlists/route", function(){ return i("editor/restricted/playlists/route.ts");});
d("editor/restricted/playlists/template", function(){ return i("editor/restricted/playlists/template.hbs");});
d("editor/restricted/route", function(){ return i("editor/restricted/route.ts");});
d("editor/restricted/songs/controller", function(){ return i("editor/restricted/songs/controller.ts");});
d("editor/restricted/songs/folder/index/controller", function(){ return i("editor/restricted/songs/folder/index/controller.ts");});
d("editor/restricted/songs/folder/index/route", function(){ return i("editor/restricted/songs/folder/index/route.ts");});
d("editor/restricted/songs/folder/index/template", function(){ return i("editor/restricted/songs/folder/index/template.hbs");});
d("editor/restricted/songs/folder/route", function(){ return i("editor/restricted/songs/folder/route.ts");});
d("editor/restricted/songs/folder/song/controller", function(){ return i("editor/restricted/songs/folder/song/controller.ts");});
d("editor/restricted/songs/folder/song/route", function(){ return i("editor/restricted/songs/folder/song/route.ts");});
d("editor/restricted/songs/folder/song/template", function(){ return i("editor/restricted/songs/folder/song/template.hbs");});
d("editor/restricted/songs/folder/template", function(){ return i("editor/restricted/songs/folder/template.hbs");});
d("editor/restricted/songs/index/controller", function(){ return i("editor/restricted/songs/index/controller.ts");});
d("editor/restricted/songs/index/route", function(){ return i("editor/restricted/songs/index/route.ts");});
d("editor/restricted/songs/index/template", function(){ return i("editor/restricted/songs/index/template.hbs");});
d("editor/restricted/songs/route", function(){ return i("editor/restricted/songs/route.ts");});
d("editor/restricted/songs/template", function(){ return i("editor/restricted/songs/template.hbs");});
d("editor/restricted/template", function(){ return i("editor/restricted/template.hbs");});
d("editor/router", function(){ return i("editor/router.ts");});
d("editor/services/pasteboard", function(){ return i("editor/services/pasteboard.ts");});
d("editor/services/progress", function(){ return i("editor/services/progress.ts");});
d("editor/services/project-manager", function(){ return i("editor/services/project-manager.ts");});
d("editor/services/song-player", function(){ return i("editor/services/song-player.ts");});
d("editor/services/status", function(){ return i("editor/services/status.ts");});
d("editor/services/undo-manager", function(){ return i("editor/services/undo-manager.ts");});
d("editor/services/virtual-pedal", function(){ return i("editor/services/virtual-pedal.ts");});
d("editor/utils/base64-to-array-buffer", function(){ return i("editor/utils/base64-to-array-buffer.ts");});
d("editor/utils/bbff-filename", function(){ return i("editor/utils/bbff-filename.ts");});
d("editor/utils/build-gain-table", function(){ return i("editor/utils/build-gain-table.ts");});
d("editor/utils/constrain-value", function(){ return i("editor/utils/constrain-value.ts");});
d("editor/utils/crc32", function(){ return i("editor/utils/crc32.ts");});
d("editor/utils/element-exists", function(){ return i("editor/utils/element-exists.ts");});
d("editor/utils/file-size", function(){ return i("editor/utils/file-size.ts");});
d("editor/utils/gm-drum-map", function(){ return i("editor/utils/gm-drum-map.ts");});
d("editor/utils/list", function(){ return i("editor/utils/list.ts");});
d("editor/utils/music-utils", function(){ return i("editor/utils/music-utils.ts");});
d("editor/utils/name-filter", function(){ return i("editor/utils/name-filter.ts");});
d("editor/utils/play-accent", function(){ return i("editor/utils/play-accent.ts");});
d("editor/utils/play-instrument", function(){ return i("editor/utils/play-instrument.ts");});
d("editor/utils/promise-batch", function(){ return i("editor/utils/promise-batch.ts");});
d("editor/utils/reader", function(){ return i("editor/utils/reader.ts");});
d("editor/utils/shuffle", function(){ return i("editor/utils/shuffle.ts");});
d("editor/utils/type-utils", function(){ return i("editor/utils/type-utils.ts");});
d("editor/utils/unique", function(){ return i("editor/utils/unique.ts");});
d("editor/utils/wait", function(){ return i("editor/utils/wait.ts");});
d("editor/utils/writer", function(){ return i("editor/utils/writer.ts");});
d("editor/services/page-title", function(){ return i("editor/services/page-title.js");});
d("editor/component-managers/glimmer", function(){ return i("editor/component-managers/glimmer.js");});
d("editor/initializers/app-version", function(){ return i("editor/initializers/app-version.js");});
d("editor/data-adapter", function(){ return i("editor/data-adapter.js");});
d("editor/initializers/ember-data", function(){ return i("editor/initializers/ember-data.js");});
d("editor/services/store", function(){ return i("editor/services/store.js");});
d("editor/transforms/boolean", function(){ return i("editor/transforms/boolean.js");});
d("editor/transforms/date", function(){ return i("editor/transforms/date.js");});
d("editor/transforms/number", function(){ return i("editor/transforms/number.js");});
d("editor/transforms/string", function(){ return i("editor/transforms/string.js");});
d("editor/container-debug-adapter", function(){ return i("editor/container-debug-adapter.js");});

  if (macroCondition(getGlobalConfig().fastboot?.isRunning)) {
d("editor/instance-initializers/setup-fetch", function(){ return i("editor/instance-initializers/setup-fetch.js");});
  }




if (!runningTests) {
  i("../app").default.create({"name":"editor","version":"1.5.0+90a92c57"});
}

