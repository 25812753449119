export default class Writer {
  buffer: ArrayBuffer;
  dv: DataView;
  offset: number;
  growBy: number;

  constructor(expectedLength = 1024, growBy = 1024) {
    this.buffer = new ArrayBuffer(expectedLength);
    this.dv = new DataView(this.buffer);
    this.offset = 0;
    this.growBy = growBy;
  }

  get eof() {
    return this.offset >= this.dv.byteLength;
  }

  updateOffset(offset: number) {
    this.offset += offset;
    if (this.offset > this.buffer.byteLength) {
      const newBuffer = new Uint8Array(this.offset + this.growBy);
      newBuffer.set(new Uint8Array(this.buffer.slice(0, this.offset)));
      this.buffer = newBuffer.buffer;
      this.dv = new DataView(this.buffer);
    }
  }

  append(buffer: ArrayBuffer) {
    const tmp = new Uint8Array(this.offset + buffer.byteLength);
    tmp.set(new Uint8Array(this.buffer.slice(0, this.offset)), 0);
    tmp.set(new Uint8Array(buffer), this.offset);
    this.buffer = tmp.buffer;
    this.dv = new DataView(this.buffer);
    this.offset = this.buffer.byteLength;
    return this;
  }

  setBytes(array: Uint8Array) {
    array.forEach((el) => {
      this.setByte(el);
    });
  }

  close() {
    return this.buffer.slice(0, this.offset);
  }

  setUint8(value: number) {
    const offset = this.offset;
    this.updateOffset(1);
    this.dv.setUint8(offset, value);
    return this;
  }

  setByte(value: number) {
    return this.setUint8(value);
  }

  setUint16(value: number, littleEndian = true) {
    const offset = this.offset;
    this.updateOffset(2);
    this.dv.setUint16(offset, value, littleEndian);
    return this;
  }

  setUShort(value: number, littleEndian = true) {
    return this.setUint16(value, littleEndian);
  }

  setInt16(value: number, littleEndian = true) {
    const offset = this.offset;
    this.updateOffset(2);
    this.dv.setInt16(offset, value, littleEndian);
    return this;
  }

  setShort(value: number, littleEndian = true) {
    return this.setInt16(value, littleEndian);
  }

  setUint32(value: number, littleEndian = true) {
    const offset = this.offset;
    this.updateOffset(4);
    this.dv.setUint32(offset, value, littleEndian);
    return this;
  }

  setUint(value: number, littleEndian = true) {
    return this.setUint32(value, littleEndian);
  }

  setInt32(value: number, littleEndian = true) {
    const offset = this.offset;
    this.updateOffset(4);
    this.dv.setInt32(offset, value, littleEndian);
    return this;
  }

  setInt(value: number, littleEndian = true) {
    return this.setInt32(value, littleEndian);
  }

  // Writes out a variable length integer with a maximum of 4 bytes
  setVarInt(value: number) {
    let buffer = value & 0x7f;

    while ((value = value >> 7)) {
      buffer <<= 8;
      buffer |= (value & 0x7f) | 0x80;
    }

    this.setByte(buffer & 0xff);
    while (buffer & 0x80) {
      buffer >>= 8;
      this.setByte(buffer & 0xff);
    }
    return this;
  }

  // Writes the string out in bytes.
  // If padTo, then the remaining length is filled with 0s. (null terminated is ignored)
  // If nullTerminated, then a null byte is appended to the end.
  setString(value: string, nullTerminated = true, padTo = 0) {
    for (let i = 0, ii = value.length; i < ii; i++) {
      const char = value.charCodeAt(i);
      this.setUint8(char);
    }
    if (padTo && value.length < padTo) {
      for (let i = value.length; i < padTo; i++) {
        this.setUint8(0);
      }
      return this;
    }
    if (nullTerminated) {
      this.setUint8(0);
    }
    return this;
  }

  streamString(str: string, littleEndian = true) {
    this.setUint(str.length * 2, littleEndian);
    for (var i = 0, strLen = str.length; i < strLen; i++) {
      this.setInt16(str.charCodeAt(i), littleEndian);
    }
  }
}
