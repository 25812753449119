import type ProjectManagerService from 'editor/services/project-manager';
import Command from './command';

export class RenameProjectCommand extends Command<ProjectManagerService> {
  project: ProjectManagerService;
  fromName: string;
  toName: string;

  constructor(project: ProjectManagerService, name: string) {
    super('Rename Project');
    this.project = project;
    this.fromName = project.projectFile!.name;
    this.toName = name;
  }

  markDirty(): void {
    this.project.projectFile!.markDirty();
  }

  execute() {
    this.project.projectFile!.name = this.toName;
    return this.project;
  }

  reverse() {
    this.project.projectFile!.name = this.fromName;
    return this.project;
  }
}
