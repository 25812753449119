import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import { type IconName } from '@fortawesome/fontawesome-svg-core';
interface ButtonSignature {
    Element: HTMLButtonElement;
    Args: {
        icon?: IconName;
    };
    Blocks: {
        default: [];
    };
}
export default class Button extends Component<ButtonSignature> {
    constructor(owner1: unknown, args1: ButtonSignature['Args']){
        super(owner1, args1);
    }
    static{
        template(`
    <button
      class="px-2 py-1 text-sm border text-nowrap rounded hover:bg-gray-100 selected:bg-primary selected:text-white disabled:text-gray-800 disabled:bg-gray-300 disabled:cursor-not-allowed disabled:opacity-50 dark:hover:bg-primary-darkButtonSelected dark:selected:bg-primary-darkButtonSelected dark:bg-primary-darkComponent dark:text-white dark:hover:text-black dark:border-primary-darkBorderColor"
      type="button"
      ...attributes
    >{{#if @icon~}}
        <FaIcon @icon={{@icon}} @fixedWidth={{true}} />
      {{/if}}{{yield}}</button>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        Button: typeof Button;
    }
}
