import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { on } from '@ember/modifier';
import onClickOutside from 'editor/modifiers/on-click-outside';
import onKey from 'editor/modifiers/on-key';
import pick from 'editor/helpers/pick';
import { get } from '@ember/helper';
import { modifier } from 'ember-modifier';
import { next } from '@ember/runloop';
const didInsert = modifier(// eslint-disable-next-line no-unused-vars
(element1: HTMLInputElement, [callback1]: [(el: HTMLInputElement) => void])=>{
    callback1(element1);
});
interface EditableFieldSignature {
    Element: HTMLElement;
    Args: {
        model: any;
        field: string;
        when?: boolean;
        filter?: string;
        // eslint-disable-next-line no-unused-vars
        onChange: (value: string) => void;
    };
    Blocks: {
        default: [];
        editor: [() => void];
    };
}
export default class EditableField extends Component<EditableFieldSignature> {
    @tracked
    isEditing = false;
    constructor(owner1: unknown, args1: EditableFieldSignature['Args']){
        super(owner1, args1);
    }
    @action
    enableEditing(ev1: MouseEvent) {
        if (this.args.when ?? true) {
            ev1.preventDefault();
            ev1.stopPropagation();
            this.isEditing = true;
        }
    }
    @action
    stopPropagation(ev1: MouseEvent) {
        ev1.stopPropagation();
    }
    @action
    disableEditing() {
        if (this.isEditing) {
            // eslint-disable-next-line ember/no-runloop
            next(()=>{
                this.isEditing = false;
            });
        }
    }
    @action
    registerInput(input1: HTMLInputElement) {
        input1.focus();
    }
    @action
    filterInput(ev1: KeyboardEvent) {
        if (this.args.filter) {
            if (ev1.key.match(this.args.filter)) {
                return;
            }
            ev1.preventDefault();
        }
    }
    static{
        template(`
    {{! template-lint-disable no-invalid-interactive}}
    {{~#if this.isEditing}}
      {{~#if (has-block "editor")}}{{yield this.disableEditing to="editor"}}{{else~}}
        <input
          value={{get @model @field}}
          class="w-full text-black border rounded"
          ...attributes
          {{on "blur" this.disableEditing}}
          {{on "click" this.stopPropagation}}
          {{on "keydown" this.filterInput}}
          {{onKey "Escape" this.disableEditing}}
          {{onKey "Enter" this.disableEditing}}
          {{on "change" (pick "target.value" @onChange)}}
          {{didInsert this.registerInput}}
          {{onClickOutside
            this.disableEditing
            wormholeEl="#ember-basic-dropdown-wormhole"
          }}
        />{{/if}}
    {{~else~}}
      <div ...attributes {{on "click" this.enableEditing capture=true}}>{{yield}}</div>
    {{~/if~}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        EditableField: typeof EditableField;
    }
}
