import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type ProjectManagerService from 'editor/services/project-manager';
import type { SongFile, SongFolder } from 'editor/services/project-manager';
import type SongPlayerService from 'editor/services/song-player';
import type StatusService from 'editor/services/status';

export default class RestrictedSongsFolderSongRoute extends Route {
  @service declare router: RouterService;
  @service declare projectManager: ProjectManagerService;
  @service declare songPlayer: SongPlayerService;
  @service declare status: StatusService;

  constructor(properties?: object) {
    super(properties);
    this.projectManager.on('removeSong', (folder, song) => {
      const model = this.modelFor(this.routeName);
      if (model === song) {
        this.router.transitionTo('restricted.songs.folder', folder.id);
      }
    });
  }

  async model({ song }: { song: string }) {
    const folder = <SongFolder>this.modelFor('restricted.songs.folder');
    const songFile = folder?.findSong(song);
    if (songFile) {
      this.projectManager.currentSong = songFile;

      try {
        await songFile.parse();
      } catch (err) {
        alert(`Error reading song: ${songFile.folder.name}/${songFile.name}`);
        this.projectManager.addSongError(songFile);
        this.router.transitionTo('restricted.songs.folder', folder.id);
      }
      const song = songFile.song!;
      const drumset = this.projectManager.findDrumset(song.drumset);
      await drumset.parse();
      this.songPlayer.song = song;
      return songFile;
    }
  }

  afterModel(model: SongFile) {
    if (!model) {
      this.router.transitionTo('restricted.songs');
      return;
    }
    this.status.addSongMidiInfo(model);
  }
}
