import ProjectManagerService, {
  SongFile,
  SongFolder,
} from 'editor/services/project-manager';
import Command from './command';

export class RenameFolderCommand extends Command<SongFolder> {
  folder: SongFolder;
  fromName: string;
  toName: string;

  constructor(folder: SongFolder, name: string) {
    super('Rename Folder');
    this.folder = folder;
    this.fromName = folder.name;
    this.toName = name;
  }

  markDirty(): void {
    this.folder.markDirty();
  }

  execute() {
    this.folder.name = this.toName;
    return this.folder;
  }

  reverse() {
    this.folder.name = this.fromName;
    return this.folder;
  }
}

export class SortFoldersSongsAlphabeticallyCommand extends Command<void> {
  project: ProjectManagerService;
  folders: SongFolder[];

  constructor(project: ProjectManagerService) {
    super('Sort Folders & Songs Alphabetically');
    this.project = project;
    this.folders = project.songFolders.map((f) => {
      const folder = new SongFolder(f.handle, f.name, f.path);
      folder.songs = [...f.songs];
      return folder;
    });
  }

  markDirty(): void {
    this.project.songFoldersDirty = true;
  }

  execute() {
    if (this.project.sortMethod === 'AtoZ') {
      this.project.songFolders = this.project.songFolders.toSorted((d1, d2) =>
        d1.name.localeCompare(d2.name),
      );
      this.project.songFolders.forEach((folder) => {
        folder.songs = folder.songs.toSorted((s1, s2) =>
          s1.name.localeCompare(s2.name),
        );
      });
    } else if (this.project.sortMethod === 'ZtoA') {
      this.project.songFolders = this.project.songFolders.toSorted((d1, d2) =>
        d2.name.localeCompare(d1.name),
      );
      this.project.songFolders.forEach((folder) => {
        folder.songs = folder.songs.toSorted((s1, s2) =>
          s2.name.localeCompare(s1.name),
        );
      });
    }
  }

  reverse() {
    this.project.songFolders = this.folders;
  }
}

export class SortFoldersCommand extends Command<void> {
  project: ProjectManagerService;
  oldIndex: number;
  newIndex: number;

  constructor(
    project: ProjectManagerService,
    oldIndex: number,
    newIndex: number,
  ) {
    super('Sort Folders');
    this.project = project;
    this.oldIndex = oldIndex;
    this.newIndex = newIndex;
  }

  markDirty(): void {
    this.project.songFoldersDirty = true;
  }

  execute() {
    const folder = this.project.songFolders[this.oldIndex];
    const arr = [...this.project.songFolders];
    if (folder) {
      // remove folder from oldIndex and insert it at newIndex
      arr.splice(this.oldIndex, 1);
      arr.splice(this.newIndex, 0, folder);
    }
    this.project.songFolders = arr.map((f) => {
      const folder = new SongFolder(f.handle, f.name, f.path);
      folder.songs = [...f.songs];
      return folder;
    });
  }

  reverse() {
    const folder = this.project.songFolders[this.newIndex];
    const arr = [...this.project.songFolders];
    if (folder) {
      // remove folder from newIndex and insert it at oldIndex
      arr.splice(this.newIndex, 1);
      arr.splice(this.oldIndex, 0, folder);
    }
    this.project.songFolders = arr.map((f) => {
      const folder = new SongFolder(f.handle, f.name, f.path);
      folder.songs = [...f.songs];
      return folder;
    });
  }
}

export class SortSongsCommand extends Command<SongFolder> {
  folder: SongFolder;
  oldIndex: number;
  newIndex: number;

  constructor(folder: SongFolder, oldIndex: number, newIndex: number) {
    super('Sort Songs');
    this.folder = folder;
    this.oldIndex = oldIndex;
    this.newIndex = newIndex;
  }

  markDirty(): void {
    this.folder.markDirty();
  }

  execute() {
    const song = this.folder.songs[this.oldIndex];
    const arr = [...this.folder.songs];
    if (song) {
      // remove song from oldIndex and insert it at newIndex
      arr.splice(this.oldIndex, 1);
      arr.splice(this.newIndex, 0, song);
    }
    console.log(this.folder);
    this.folder.songs = arr.map((s) => {
      const song = new SongFile(this.folder.handle, s.handle, s.name, s.path);
      return song;
    });
    return this.folder;
  }

  reverse() {
    const song = this.folder.songs[this.newIndex];
    const arr = [...this.folder.songs];
    if (song) {
      // remove song from newIndex and insert it at oldIndex
      arr.splice(this.newIndex, 1);
      arr.splice(this.oldIndex, 0, song);
    }
    this.folder.songs = arr.map((s) => {
      const song = new SongFile(this.folder.handle, s.handle, s.name, s.path);
      return song;
    });
    return this.folder;
  }
}

export class MoveSongCommand extends Command<SongFile> {
  fromFolder: SongFolder;
  fromIndex: number;
  toFolder: SongFolder;
  toIndex?: number;

  constructor(
    fromFolder: SongFolder,
    fromIndex: number,
    toFolder: SongFolder,
    toIndex?: number,
  ) {
    super('Move Song');
    this.fromFolder = fromFolder;
    this.fromIndex = fromIndex;
    this.toFolder = toFolder;
    this.toIndex = toIndex;
  }

  markDirty(): void {
    this.fromFolder.markDirty();
    this.toFolder.markDirty();
  }

  async execute() {
    const song = await this.fromFolder.removeSong(this.fromIndex);
    if (!song) {
      throw new Error('Song not found');
    }
    await this.toFolder.addSong(song, this.toIndex);
    if (!this.toIndex) {
      this.toIndex = this.toFolder.songs.indexOf(song);
    }
    return song;
  }

  async reverse() {
    const song = await this.toFolder.removeSong(this.toIndex!);
    if (!song) {
      throw new Error('Song not found');
    }
    await this.fromFolder.addSong(song, this.fromIndex);
    return song;
  }
}

export class AddFolderCommand extends Command<SongFolder> {
  project: ProjectManagerService;
  folder?: SongFolder;

  constructor(project: ProjectManagerService, name: string = 'New Folder') {
    super('Add Folder');
    this.project = project;
    this.name = name;
  }

  markDirty(): void {
    this.project.songFoldersDirty = true;
    this.folder?.markDirty();
  }

  async execute() {
    this.folder = await this.project.createSongFolder(this.name);
    return this.folder;
  }

  async reverse() {
    if (!this.folder) {
      throw new Error('Folder not found');
    }
    this.project.removeFolder(this.folder);
    return this.folder;
  }
}

export class DeleteSongFolderCommand extends Command<SongFolder> {
  projectManager: ProjectManagerService;
  folder: SongFolder;
  index: number;

  constructor(projectManager: ProjectManagerService, folder: SongFolder) {
    super('Delete Song Folder');
    this.projectManager = projectManager;
    this.index = projectManager.songFolders.findIndex((f) => f === folder);
    this.folder = folder;
  }

  markDirty(): void {
    this.projectManager.songFoldersDirty = true;
  }

  async execute() {
    this.projectManager.songFolders = this.projectManager.songFolders.filter(
      (f) => f !== this.folder,
    );
    return this.folder;
  }

  async reverse() {
    this.projectManager.songFolders = this.projectManager.songFolders.toSpliced(
      this.index,
      0,
      this.folder,
    );
    return this.folder;
  }
}
