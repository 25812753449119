import type { EffectFile } from 'editor/services/project-manager';

export default async function playAccent(
  audioContext: AudioContext,
  masterGainNode: GainNode,
  effect: EffectFile,
  volume = 100,
) {
  if (audioContext.state === 'suspended') {
    audioContext.resume();
  }
  const audio = await effect.getAudio(audioContext);
  if (audio) {
    const gainNode = new GainNode(audioContext, { gain: volume });
    gainNode.connect(masterGainNode);

    const source = audioContext.createBufferSource();
    source.connect(gainNode);
    source.buffer = audio;
    gainNode.gain.setValueAtTime(volume / 100, audioContext.currentTime);
    source.start();
  }
}
