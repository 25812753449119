import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import type ProjectManagerService from 'editor/services/project-manager';
import type { SongFile } from 'editor/services/project-manager';

export default class RestrictedSongsFolderSongController extends Controller {
  @service declare projectManager: ProjectManagerService;

  declare model: SongFile;

  get song() {
    return this.model.song!;
  }

  @action
  async saveSong() {
    await this.projectManager.save();
  }
}
