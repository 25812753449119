export default function fileSize(size: number) {
  const kb = 1024;
  const mb = kb * 1024;
  // Return size in bytes, kilobytes, or megabytes
  if (size >= mb) {
    return `${(size / mb).toFixed(2)} MB`;
  }
  if (size >= kb) {
    return `${(size / kb).toFixed(2)} KB`;
  }
  return `${size} B`;
}
