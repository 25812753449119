import { template } from "@ember/template-compiler";
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import Component from '@glimmer/component';
interface OptionButtonSignature {
    Element: HTMLButtonElement;
    Args: {
    };
    Blocks: {
    };
}
export default class OptionButton extends Component<OptionButtonSignature> {
    constructor(owner1: unknown, args1: OptionButtonSignature['Args']){
        super(owner1, args1);
    }
    static{
        template(`
    <button type="button" class="px-2 py-1" ...attributes><FaIcon
        @icon="ellipsis-vertical"
      /></button>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        OptionButton: typeof OptionButton;
    }
}
