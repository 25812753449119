import { template } from "@ember/template-compiler";
import { registerDestructor } from '@ember/destroyable';
import { array, fn } from '@ember/helper';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { scheduleOnce } from '@ember/runloop';
import { service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import eq from 'editor/helpers/eq';
import gte from 'editor/helpers/gte';
import not from 'editor/helpers/not';
import pick from 'editor/helpers/pick';
import type Command from 'editor/models/command';
import { CompositeCommand } from 'editor/models/command';
import Drumset, { Instrument } from 'editor/models/drumset';
import { MidiEvent, PartType, SectionType } from 'editor/models/song';
import { AddMidiEventCommand, AddMidiEventsCommand, ChangeTrigPosCommand, DoubleBeatCommand, FilterOverlappingMidiEventsCommand, QuantizeTrackCommand, RemoveMidiEventCommand, RemoveMidiEventsCommand, UpdateMidiEventLengthCommand, UpdateMidiEventNoteCommand, UpdateMidiEventTickCommand, UpdateMidiEventVelocityCommand, UpdateTimeSignatureDenominatorCommand, UpdateTimeSignatureNumeratorCommand, UpdateTrackBarCountCommand } from 'editor/models/song-commands';
import disabled from 'editor/modifiers/disabled';
import onKey from 'editor/modifiers/on-key';
import selected from 'editor/modifiers/selected';
import PasteboardService from 'editor/services/pasteboard';
import SongPlayer, { SelectedTrack } from 'editor/services/song-player';
import UndoManagerService from 'editor/services/undo-manager';
import { bar, barBeat, beat, beatFactor, beatTicks } from 'editor/utils/music-utils';
import unique from 'editor/utils/unique';
import { modifier } from 'ember-modifier';
import { OverlayScrollbars } from 'overlayscrollbars';
import Button from './button';
import VelocityValues from './velocity-values';
import type ProjectManagerService from 'editor/services/project-manager';
function roundPixels(pixels1: number) {
    return Math.round((pixels1 + Number.EPSILON) * 1000) / 1000;
}
// We need the modifier to re-run every time `ticks` changes
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
const updatePlayHead = modifier((element1: HTMLElement, [ticks1]: [number])=>{
    element1.scrollIntoView({
        inline: 'center',
        block: 'nearest'
    });
});
function intersectRect(r11: DOMRect, r21: DOMRect) {
    return !(r21.left > r11.right || r21.right < r11.left || r21.top > r11.bottom || r21.bottom < r11.top);
}
type scrollDirection = 'x' | 'y' | 'both';
function getScrollParent(element1: HTMLElement, xy1: scrollDirection = 'both') {
    let parent1: HTMLElement | null = element1;
    const scrollHeight1 = xy1 === 'y' || xy1 === 'both';
    const scrollWidth1 = xy1 === 'x' || xy1 === 'both';
    while(parent1){
        if ((scrollHeight1 && parent1.scrollHeight > parent1.clientHeight) || (scrollWidth1 && parent1.scrollWidth > parent1.clientWidth)) {
            return parent1;
        }
        parent1 = parent1.parentElement;
    }
    return element1;
}
function findElementAtPoint(x1: number, y1: number, match1: string): HTMLElement | undefined {
    return <HTMLElement>document.elementsFromPoint(x1, y1).find((el1)=>el1.matches(match1));
}
function buildRect($element1: HTMLElement, $offsetParent1?: HTMLElement) {
    const rect1 = $element1.getBoundingClientRect();
    const top1 = $element1.offsetTop;
    const left1 = $element1.offsetLeft;
    return {
        top: $offsetParent1 ? top1 + $offsetParent1.offsetTop : top1,
        left: $offsetParent1 ? left1 + $offsetParent1.offsetLeft : left1,
        width: rect1.width,
        height: rect1.height
    };
}
function outerRect($elements1: NodeList, $boundary1: HTMLElement) {
    const outerRect1 = $boundary1.getBoundingClientRect();
    let top1 = Number.MAX_VALUE, left1 = Number.MAX_VALUE, bottom1 = 0, right1 = 0;
    $elements1.forEach(($el1)=>{
        const rect1 = (<HTMLElement>$el1).getBoundingClientRect();
        const rtop1 = rect1.top - outerRect1.top + $boundary1.offsetTop, rleft1 = rect1.left - outerRect1.left + $boundary1.offsetLeft, rbottom1 = rect1.bottom - outerRect1.top, rright1 = rect1.right - outerRect1.left;
        if (rtop1 < top1) {
            top1 = rtop1;
        }
        if (rleft1 < left1) {
            left1 = rleft1;
        }
        if (rbottom1 > bottom1) {
            bottom1 = rbottom1;
        }
        if (rright1 > right1) {
            right1 = rright1;
        }
    });
    return {
        top: top1,
        left: left1,
        height: bottom1 - top1,
        width: right1 - left1
    };
}
const verticalOverlayScroll = modifier(($element1: HTMLElement, [isTrackMaximised1]: [boolean])=>{
    const scrollTarget1 = <HTMLElement>document.querySelector('[data-scroll-target]');
    function repositionScrollTarget1() {
        Object.assign(scrollTarget1.style, {
            position: 'absolute',
            top: `${$element1.scrollTop}px`,
            bottom: `${-$element1.scrollTop}px`
        });
    }
    $element1.addEventListener('scroll', repositionScrollTarget1);
    if (isTrackMaximised1) {
        $element1.scrollTop = 0;
        repositionScrollTarget1();
    }
    return ()=>{
        $element1.removeEventListener('scroll', repositionScrollTarget1);
    };
});
const horizontalOverlayScroll = modifier(($element1: HTMLElement)=>{
    const scrollTarget1 = <HTMLElement>document.querySelector('[data-scroll-target]');
    const scrollParent1 = getScrollParent($element1);
    const parentElement1 = scrollParent1.parentElement!;
    const resizeScrollTarget1 = function() {
        const scrollRect1 = scrollParent1.getBoundingClientRect();
        const parentRect1 = parentElement1.getBoundingClientRect()!;
        Object.assign(scrollTarget1.style, {
            left: `${scrollRect1.left - parentRect1.left}px`
        });
    };
    const resizeObserver1 = new ResizeObserver(resizeScrollTarget1);
    resizeObserver1.observe(parentElement1);
    const instance1 = OverlayScrollbars({
        target: $element1,
        scrollbars: {
            slot: scrollTarget1
        }
    }, {
        scrollbars: {
            theme: 'os-theme-dark',
            visibility: 'auto',
            autoHide: 'never',
            autoHideDelay: 1000,
            autoHideSuspend: true
        }
    });
    instance1.on('updated', resizeScrollTarget1);
    return ()=>{
        resizeObserver1.disconnect();
        instance1.destroy();
    };
});
const selectRegion = modifier(($element1: HTMLElement, [match1]: [string])=>{
    let selecting1 = false;
    let $selectRegion1: HTMLDivElement | undefined = undefined;
    let elementRect1: DOMRect | undefined = undefined;
    let regionRect1: DOMRect | undefined = undefined;
    let $scrollYParent1: HTMLElement;
    let $scrollXParent1: HTMLElement;
    let startX1 = 0, startY1 = 0, top1 = 0, left1 = 0;
    let doubleClickTimer1: number | undefined;
    const onMouseMove1 = (ev1: MouseEvent)=>{
        if (!selecting1) {
            return;
        }
        if ($selectRegion1) {
            window.getSelection()?.removeAllRanges();
            let width1 = ev1.clientX + $scrollXParent1.scrollLeft - startX1, height1 = ev1.clientY + $scrollYParent1.scrollTop - startY1;
            let t1 = top1, l1 = left1;
            if (width1 < 0) {
                l1 = left1 + width1;
            }
            if (left1 + width1 > elementRect1!.width) {
                width1 = elementRect1!.width - left1;
            }
            if (l1 < 0) {
                width1 -= l1;
                l1 = 0;
            }
            if (height1 < 0) {
                t1 = top1 + height1;
            }
            if (top1 + height1 > elementRect1!.height) {
                height1 = elementRect1!.height - top1;
            }
            if (t1 < 0) {
                height1 -= t1;
                t1 = 0;
            }
            regionRect1!.x = l1;
            regionRect1!.y = t1;
            regionRect1!.width = width1;
            regionRect1!.height = height1;
            Object.assign($selectRegion1.style, {
                top: `${t1}px`,
                left: `${l1}px`,
                width: `${Math.abs(width1)}px`,
                height: `${Math.abs(height1)}px`
            });
            // find elements of matching class that intersect with the region
            const rect1 = $selectRegion1.getBoundingClientRect();
            Array.from($element1.querySelectorAll(match1)).forEach((el1)=>{
                if (intersectRect(rect1, el1.getBoundingClientRect())) {
                    el1.dispatchEvent(new CustomEvent('bbff.select', {
                        detail: {
                            ctrlKey: true,
                            metaKey: true,
                            shiftKey: true,
                            append: true
                        }
                    }));
                } else {
                    if (!ev1.shiftKey) {
                        el1.dispatchEvent(new CustomEvent('bbff.deselect'));
                    }
                }
            });
        } else {
            if (Math.abs(startX1 - ev1.clientX) > 5 || Math.abs(startY1 - ev1.clientY) > 5) {
                $selectRegion1 = document.createElement('div');
                Object.assign($selectRegion1.style, {
                    position: 'absolute',
                    border: '1px dashed black',
                    top: `${top1}px`,
                    left: `${left1}px`
                });
                $element1.appendChild($selectRegion1);
            }
        }
    };
    const onMouseUp1 = (ev1: MouseEvent)=>{
        document.removeEventListener('mouseup', onMouseUp1);
        document.removeEventListener('mousemove', onMouseMove1);
        // If the mouse has not moved?
        if (Math.abs(startX1 - (ev1.clientX + $scrollXParent1.scrollLeft)) < 5 && Math.abs(startY1 - (ev1.clientY + $scrollYParent1.scrollTop)) < 5) {
            const $el1 = findElementAtPoint(ev1.clientX, ev1.clientY, match1);
            if ($el1) {
                $el1.dispatchEvent(new CustomEvent('bbff.select', {
                    detail: {
                        ctrlKey: ev1.ctrlKey,
                        metaKey: ev1.metaKey,
                        shiftKey: ev1.shiftKey
                    }
                }));
            }
            if (ev1.detail === 2) {
                clearTimeout(doubleClickTimer1);
                document.elementFromPoint(ev1.clientX, ev1.clientY)?.dispatchEvent(new CustomEvent('bbff.add-event', {
                    bubbles: true,
                    detail: {
                        x: regionRect1!.x,
                        y: regionRect1!.y
                    }
                }));
            } else {
                doubleClickTimer1 = setTimeout(()=>{
                    if (!$el1) {
                        document.elementFromPoint(ev1.clientX, ev1.clientY)?.dispatchEvent(new CustomEvent('bbff.set-track-position', {
                            bubbles: true,
                            detail: {
                                x: regionRect1!.x
                            }
                        }));
                        if (!ev1.shiftKey) {
                            $element1.dispatchEvent(new CustomEvent('bbff.deselect-all'));
                        }
                    }
                }, 300);
            }
        }
        if ($selectRegion1) {
            $selectRegion1.remove();
            $selectRegion1 = undefined;
        }
    };
    const onMouseDown1 = (ev1: MouseEvent)=>{
        selecting1 = false;
        elementRect1 = $element1.getBoundingClientRect();
        $scrollYParent1 = getScrollParent($element1, 'y');
        $scrollXParent1 = getScrollParent($element1, 'x');
        const $el1 = findElementAtPoint(ev1.clientX, ev1.clientY, match1);
        if (!$el1) {
            selecting1 = true;
        }
        if (selecting1) {
            window.getSelection()?.removeAllRanges();
        }
        startX1 = ev1.clientX + $scrollXParent1.scrollLeft;
        startY1 = ev1.clientY + $scrollYParent1.scrollTop;
        top1 = ev1.clientY - elementRect1!.top;
        left1 = ev1.clientX - elementRect1!.left;
        regionRect1 = new DOMRect();
        regionRect1.x = left1;
        regionRect1.y = top1;
        regionRect1.width = 0;
        regionRect1.height = 0;
        document.addEventListener('mouseup', onMouseUp1);
        document.addEventListener('mousemove', onMouseMove1);
    };
    $element1.addEventListener('mousedown', onMouseDown1);
    return ()=>{
        $element1.removeEventListener('mousedown', onMouseDown1);
    };
});
const moveEvents = modifier(($boundaryElement1: HTMLElement, [snapTo1]: [number])=>{
    const eventMatch1 = '.midi-event', instrumentMatch1 = '.instrument';
    let $parent1: HTMLElement;
    let $midiEventElement1: HTMLElement;
    let $instrument1: HTMLElement;
    let offsetX1 = 0, offsetY1 = 0, dragOffsetX1 = 0, dragOffsetY1 = 0, elementRect1 = {
        top: 0,
        left: 0,
        width: 0,
        height: 0
    }, selectedRect1 = {
        top: 0,
        left: 0,
        width: 0,
        height: 0
    }, boundaryRect1 = {
        top: 0,
        left: 0,
        width: 0,
        height: 0
    };
    const moveTo1 = (x1: number, y1: number)=>{
        offsetX1 = x1;
        offsetY1 = y1;
        $midiEventElement1.style.transform = `translate3d(${x1}px, ${y1}px, 0)`;
    };
    const onMouseMove1 = (ev1: MouseEvent)=>{
        ev1.preventDefault();
        // has the mouse moved?
        let moved1 = true;
        if (Math.abs(ev1.clientX - dragOffsetX1) < 5 && Math.abs(ev1.clientY - dragOffsetY1) < 5) {
            moved1 = false;
            return;
        }
        let x1 = ev1.clientX - dragOffsetX1;
        let y1 = ev1.clientY - dragOffsetY1;
        const $instr1 = findElementAtPoint(ev1.clientX, ev1.clientY, instrumentMatch1);
        if ($instr1) {
            $instrument1 = $instr1;
            if (elementRect1.top + y1 != $instrument1.offsetTop) {
                y1 = $instrument1.offsetTop - elementRect1.top;
            }
        }
        if (!ev1.metaKey && !ev1.ctrlKey) {
            x1 = Math.round(x1 / snapTo1) * snapTo1;
        }
        let selectionY1 = elementRect1.top - selectedRect1.top, selectionX1 = elementRect1.left - selectedRect1.left;
        // Make sure the combined selection doesn't move outside the boundary
        if (elementRect1.top - selectionY1 + y1 < 0) {
            y1 = -selectionY1;
        }
        if (selectedRect1.top + selectedRect1.height + y1 > boundaryRect1.height) {
            y1 = boundaryRect1.height - selectedRect1.top - selectedRect1.height;
        }
        if (elementRect1.left - selectionX1 + x1 < boundaryRect1.left) {
            x1 = boundaryRect1.left + selectionX1 - elementRect1.left;
        }
        if (selectedRect1.left + selectedRect1.width + x1 > boundaryRect1.left + boundaryRect1.width) {
            x1 = boundaryRect1.left + boundaryRect1.width - selectedRect1.left - selectedRect1.width;
        }
        const instruments1 = y1 / elementRect1.height;
        if (!ev1.metaKey && !ev1.ctrlKey) {
            x1 -= elementRect1.left % snapTo1;
        }
        $midiEventElement1.dispatchEvent(new CustomEvent('bbff.move', {
            bubbles: true,
            detail: {
                moved: moved1,
                x: x1,
                y: y1,
                instruments: instruments1,
                snap: !(ev1.metaKey || ev1.ctrlKey)
            }
        }));
        moveTo1(x1, y1);
    };
    const onMouseUp1 = (ev1: MouseEvent)=>{
        document.removeEventListener('mouseup', onMouseUp1);
        document.removeEventListener('mousemove', onMouseMove1);
        // has the mouse moved?
        let moved1 = true;
        if (Math.abs(ev1.clientX - dragOffsetX1) < 5 && Math.abs(ev1.clientY - dragOffsetY1) < 5) {
            moved1 = false;
            return;
        }
        const instruments1 = offsetY1 / elementRect1.height;
        $midiEventElement1.dispatchEvent(new CustomEvent('bbff.move-end', {
            bubbles: true,
            detail: {
                moved: moved1,
                x: offsetX1,
                instruments: instruments1,
                snap: !(ev1.metaKey || ev1.ctrlKey)
            }
        }));
        offsetX1 = 0;
        offsetY1 = 0;
        dragOffsetX1 = 0;
        dragOffsetY1 = 0;
    };
    const onMouseDown1 = (ev1: MouseEvent)=>{
        boundaryRect1 = buildRect($boundaryElement1);
        const $el1 = findElementAtPoint(ev1.clientX, ev1.clientY, eventMatch1);
        if ($el1 && !ev1.shiftKey) {
            if ($el1 && !$el1.matches('.selected')) {
                $el1.dispatchEvent(new CustomEvent('bbff.select', {
                    bubbles: true,
                    detail: {
                        ctrlKey: ev1.ctrlKey,
                        metaKey: ev1.metaKey,
                        shiftKey: ev1.metaKey
                    }
                }));
            }
            $instrument1 = <HTMLElement>(findElementAtPoint(ev1.clientX, ev1.clientY, instrumentMatch1));
            $midiEventElement1 = <HTMLElement>$el1;
            $parent1 = <HTMLElement>$midiEventElement1.parentElement;
            elementRect1 = buildRect($midiEventElement1, $parent1);
            if ($parent1.offsetParent) {
                const selectedEvents1 = $boundaryElement1.querySelectorAll(`${eventMatch1}.selected`);
                selectedRect1 = outerRect(selectedEvents1, <HTMLElement>$parent1.offsetParent);
                $midiEventElement1.dispatchEvent(new CustomEvent('bbff.move-start', {
                    bubbles: true,
                    detail: {}
                }));
            }
        } else {
            return;
        }
        dragOffsetX1 = ev1.clientX - offsetX1;
        dragOffsetY1 = ev1.clientY - offsetY1;
        document.addEventListener('mousemove', onMouseMove1);
        document.addEventListener('mouseup', onMouseUp1);
    };
    const cancelDragNDrop1 = (e1: DragEvent)=>{
        e1.preventDefault();
    };
    $boundaryElement1.addEventListener('mousedown', onMouseDown1);
    $boundaryElement1.addEventListener('dragstart', cancelDragNDrop1);
    return ()=>{
        $boundaryElement1.removeEventListener('dragstart', cancelDragNDrop1);
        $boundaryElement1.removeEventListener('mousedown', onMouseDown1);
    };
});
const changeVelocity = modifier(($boundaryElement1: HTMLElement)=>{
    const eventMatch1 = '.velocity-event';
    let $velocityElement1: HTMLElement;
    let offsetY1 = 0, dragOffsetY1 = 0, elementRect1 = {
        top: 0,
        left: 0,
        width: 0,
        height: 0
    }, selectedRect1 = {
        top: 0,
        left: 0,
        width: 0,
        height: 0
    }, boundaryRect1 = {
        top: 0,
        left: 0,
        width: 0,
        height: 0
    };
    const moveTo1 = (y1: number)=>{
        offsetY1 = y1;
        $velocityElement1.style.transform = `translate3d(0, ${y1}px, 0)`;
    };
    const onMouseMove1 = (ev1: MouseEvent)=>{
        ev1.preventDefault();
        let y1 = ev1.clientY - dragOffsetY1;
        const velocityChange1 = -Math.round((y1 / boundaryRect1.height) * 127);
        // Make sure the combined selection doesn't move outside the boundary
        let selectionY1 = elementRect1.top - selectedRect1.top;
        if (elementRect1.top - selectionY1 + y1 < boundaryRect1.top) {
            y1 = boundaryRect1.top - elementRect1.top;
        }
        if (selectedRect1.top + selectedRect1.height + y1 > boundaryRect1.top + boundaryRect1.height) {
            y1 = boundaryRect1.top + boundaryRect1.height - selectedRect1.top - selectedRect1.height;
        }
        $velocityElement1.dispatchEvent(new CustomEvent('bbff.velocity-change', {
            bubbles: true,
            detail: {
                y: y1,
                change: velocityChange1,
                command: ev1.metaKey || ev1.ctrlKey
            }
        }));
        moveTo1(y1);
    };
    const onMouseUp1 = ()=>{
        document.removeEventListener('mouseup', onMouseUp1);
        document.removeEventListener('mousemove', onMouseMove1);
        $velocityElement1.dispatchEvent(new CustomEvent('bbff.velocity-change-end', {
            bubbles: true,
            detail: {}
        }));
        offsetY1 = 0;
        dragOffsetY1 = 0;
    };
    const onMouseDown1 = (ev1: MouseEvent)=>{
        const $el1 = findElementAtPoint(ev1.clientX, ev1.clientY, eventMatch1);
        if ($el1 && !$el1.matches('.selected')) {
            $el1.dispatchEvent(new CustomEvent('bbff.select', {
                bubbles: true,
                detail: {
                    ctrlKey: true,
                    metaKey: true,
                    shiftKey: true,
                    append: true
                }
            }));
        }
        if ($el1 && !ev1.shiftKey) {
            $velocityElement1 = <HTMLElement>$el1;
            elementRect1 = buildRect($velocityElement1, $boundaryElement1);
            const selectedEvents1 = $boundaryElement1.querySelectorAll(`${eventMatch1}.selected`);
            selectedRect1 = outerRect(selectedEvents1, <HTMLElement>$boundaryElement1);
            boundaryRect1 = buildRect($boundaryElement1);
            boundaryRect1.top = 0;
            $velocityElement1.dispatchEvent(new CustomEvent('bbff.velocity-change-start', {
                bubbles: true,
                detail: {}
            }));
        } else {
            return;
        }
        dragOffsetY1 = ev1.clientY - offsetY1;
        document.addEventListener('mousemove', onMouseMove1);
        document.addEventListener('mouseup', onMouseUp1);
    };
    const cancelDragNDrop1 = (e1: DragEvent)=>{
        e1.preventDefault();
    };
    $boundaryElement1.addEventListener('mousedown', onMouseDown1);
    $boundaryElement1.addEventListener('dragstart', cancelDragNDrop1);
    return ()=>{
        $boundaryElement1.removeEventListener('dragstart', cancelDragNDrop1);
        $boundaryElement1.removeEventListener('mousedown', onMouseDown1);
    };
});
const adjustLoopMarker = modifier(($loopMarker1: HTMLElement, [snapTo1]: [number])=>{
    let $parentElement1: HTMLElement;
    let $leftHandle1: HTMLElement;
    let offsetX1 = 0, startX1 = 0, dragOffsetX1 = 0, elementRect1 = {
        top: 0,
        left: 0,
        width: 0,
        height: 0
    }, parentRect1 = {
        top: 0,
        left: 0,
        width: 0,
        height: 0
    };
    const moveTo1 = (x1: number)=>{
        x1 = Math.min(Math.max(0, x1), parentRect1.width - snapTo1);
        const newWidth1 = Math.max(snapTo1, elementRect1.width + startX1 - x1);
        offsetX1 = x1;
        $loopMarker1.style.marginLeft = `${x1}px`;
        $loopMarker1.style.width = `${newWidth1}px`;
    };
    const onMouseMove1 = (ev1: MouseEvent)=>{
        ev1.preventDefault();
        let x1 = ev1.clientX - dragOffsetX1;
        x1 = Math.round(x1 / snapTo1) * snapTo1;
        moveTo1(x1);
    };
    const onPointerUp1 = (ev1: PointerEvent)=>{
        document.removeEventListener('pointerup', onPointerUp1);
        $leftHandle1.releasePointerCapture(ev1.pointerId);
    };
    const onMouseUp1 = ()=>{
        document.removeEventListener('mouseup', onMouseUp1);
        document.removeEventListener('mousemove', onMouseMove1);
        $leftHandle1.dispatchEvent(new CustomEvent('bbff.trig-pos-change', {
            bubbles: true,
            detail: {
                x: offsetX1
            }
        }));
        offsetX1 = 0;
        dragOffsetX1 = 0;
    };
    const onPointerDown1 = (ev1: PointerEvent)=>{
        document.addEventListener('pointerup', onPointerUp1);
        $leftHandle1.setPointerCapture(ev1.pointerId);
    };
    const onMouseDown1 = (ev1: MouseEvent)=>{
        if ($loopMarker1.attributes.getNamedItem('aria-disabled')?.value === 'true') {
            return;
        }
        $parentElement1 = <HTMLElement>$loopMarker1.parentElement;
        parentRect1 = buildRect($parentElement1);
        elementRect1 = buildRect($loopMarker1);
        offsetX1 = parseInt($loopMarker1.style.marginLeft);
        startX1 = offsetX1;
        dragOffsetX1 = ev1.clientX - offsetX1;
        document.addEventListener('mousemove', onMouseMove1);
        document.addEventListener('mouseup', onMouseUp1);
    };
    const cancelDragNDrop1 = (e1: DragEvent)=>{
        e1.preventDefault();
    };
    $leftHandle1 = $loopMarker1.querySelector('.left-handle')!;
    if (!$leftHandle1) {
        console.error('no left handle for loop marker');
        return;
    }
    $leftHandle1.addEventListener('mousedown', onMouseDown1);
    $leftHandle1.addEventListener('pointerdown', onPointerDown1);
    $leftHandle1.addEventListener('dragstart', cancelDragNDrop1);
    return ()=>{
        $leftHandle1.removeEventListener('dragstart', cancelDragNDrop1);
        $leftHandle1.addEventListener('pointerdown', onPointerDown1);
        $leftHandle1.removeEventListener('mousedown', onMouseDown1);
    };
});
const adjustMidiLength = modifier(($boundaryElement1: HTMLElement, [snapTo1]: [number])=>{
    const eventMatch1 = '.midi-event .rhandle';
    let $midiEventElement1: HTMLElement;
    let $rightHandle1: HTMLElement;
    let startX1 = 0, startWidth1 = 0, dragOffsetX1 = 0, elementRect1 = {
        top: 0,
        left: 0,
        width: 0,
        height: 0
    };
    const moveTo1 = (x1: number, width1: number)=>{
        $midiEventElement1.style.left = `${x1}px`;
        $midiEventElement1.style.width = `${width1}px`;
    };
    function calculateWidth1(x1: number, startX1: number, startWidth1: number, snap1 = false) {
        x1 = x1 + startX1 + startWidth1;
        let minWidth1 = 1;
        if (snap1) {
            x1 = Math.ceil(x1 / snapTo1) * snapTo1;
            minWidth1 = Math.floor((startX1 + snapTo1) / snapTo1) * snapTo1 - startX1;
        }
        return Math.max(minWidth1, x1 - startX1);
    }
    const onMouseMove1 = (ev1: MouseEvent)=>{
        // disable moving
        ev1.preventDefault();
        const x1 = ev1.clientX - dragOffsetX1;
        const width1 = calculateWidth1(x1, startX1, startWidth1, !(ev1.metaKey || ev1.ctrlKey));
        const adjustment1 = width1 - startWidth1;
        $midiEventElement1.dispatchEvent(new CustomEvent('bbff.resize', {
            bubbles: true,
            detail: {
                width: width1,
                adjustment: adjustment1,
                snap: !(ev1.metaKey || ev1.ctrlKey)
            }
        }));
        moveTo1(startX1, width1);
    };
    const onPointerUp1 = (ev1: PointerEvent)=>{
        document.removeEventListener('pointerup', onPointerUp1);
        $rightHandle1?.releasePointerCapture(ev1.pointerId);
    };
    const onMouseUp1 = (ev1: MouseEvent)=>{
        document.removeEventListener('mouseup', onMouseUp1);
        document.removeEventListener('mousemove', onMouseMove1);
        const x1 = ev1.clientX - dragOffsetX1;
        const width1 = calculateWidth1(x1, startX1, startWidth1, !(ev1.metaKey || ev1.ctrlKey));
        const adjustment1 = width1 - startWidth1;
        $midiEventElement1.dispatchEvent(new CustomEvent('bbff.resize-end', {
            bubbles: true,
            detail: {
                width: width1,
                adjustment: adjustment1,
                snap: !(ev1.metaKey || ev1.ctrlKey)
            }
        }));
        startX1 = 0;
        startWidth1 = 0;
        dragOffsetX1 = 0;
    };
    const onPointerDown1 = (ev1: PointerEvent)=>{
        document.addEventListener('pointerup', onPointerUp1);
        const $el1 = findElementAtPoint(ev1.clientX, ev1.clientY, eventMatch1);
        if ($el1) {
            $el1.setPointerCapture(ev1.pointerId);
        }
    };
    const onMouseDown1 = (ev1: MouseEvent)=>{
        const $el1 = findElementAtPoint(ev1.clientX, ev1.clientY, eventMatch1);
        if ($el1) {
            ev1.stopImmediatePropagation();
            $rightHandle1 = <HTMLElement>$el1;
            $midiEventElement1 = <HTMLElement>$el1.parentElement;
            if ($midiEventElement1.attributes.getNamedItem('aria-disabled')?.value === 'true') {
                return;
            }
            $midiEventElement1.dispatchEvent(new CustomEvent('bbff.resize-start', {
                bubbles: true,
                detail: {}
            }));
        } else {
            return;
        }
        elementRect1 = buildRect($midiEventElement1);
        startX1 = roundPixels(elementRect1.left);
        startWidth1 = roundPixels(elementRect1.width);
        dragOffsetX1 = ev1.clientX;
        document.addEventListener('mousemove', onMouseMove1);
        document.addEventListener('mouseup', onMouseUp1);
    };
    const cancelDragNDrop1 = (e1: DragEvent)=>{
        e1.preventDefault();
    };
    $boundaryElement1.addEventListener('mousedown', onMouseDown1);
    $boundaryElement1.addEventListener('pointerdown', onPointerDown1);
    $boundaryElement1.addEventListener('dragstart', cancelDragNDrop1);
    return ()=>{
        $boundaryElement1.removeEventListener('dragstart', cancelDragNDrop1);
        $boundaryElement1.addEventListener('pointerdown', onPointerDown1);
        $boundaryElement1.removeEventListener('mousedown', onMouseDown1);
    };
});
function findParent($element1: HTMLElement, match1: string) {
    let $parent1 = $element1.parentElement!;
    while($parent1 && !$parent1.matches(match1)){
        $parent1 = $parent1.parentElement!;
    }
    return $parent1;
}
const stickyTop = modifier(($element1: HTMLElement, [parentMatch1]: [string])=>{
    const $parent1 = findParent($element1, parentMatch1);
    const adjuster1 = ()=>{
        $element1.style.top = `${$parent1.scrollTop - 1}px`;
    };
    const resizeObserver1 = new ResizeObserver(()=>{
        adjuster1();
    });
    resizeObserver1.observe($parent1);
    const mutationObserver1 = new MutationObserver(()=>{
        adjuster1();
    });
    mutationObserver1.observe($element1, {
        attributes: true
    });
    $parent1.addEventListener('scroll', adjuster1);
    window.addEventListener('resize', adjuster1);
    return ()=>{
        window.removeEventListener('resize', adjuster1);
        $parent1.removeEventListener('scroll', adjuster1);
        mutationObserver1.disconnect();
        resizeObserver1.disconnect();
    };
});
const stickyBottom = modifier(($element1: HTMLElement, [parentMatch1]: [string])=>{
    const $parent1 = findParent($element1, parentMatch1);
    const bottomScrollBar1 = 1;
    const adjuster1 = ()=>{
        $element1.style.bottom = `${Math.max(0, $parent1.scrollHeight - $parent1.scrollTop - $parent1.clientHeight - bottomScrollBar1)}px`;
    };
    const resizeObserver1 = new ResizeObserver(()=>{
        adjuster1();
    });
    resizeObserver1.observe($parent1);
    const mutationObserver1 = new MutationObserver(()=>{
        adjuster1();
    });
    mutationObserver1.observe($element1, {
        attributes: true
    });
    adjuster1();
    $parent1.addEventListener('scroll', adjuster1);
    window.addEventListener('resize', adjuster1);
    return ()=>{
        window.removeEventListener('resize', adjuster1);
        $parent1.removeEventListener('scroll', adjuster1);
        mutationObserver1.disconnect();
        resizeObserver1.unobserve($parent1);
    };
});
interface TrackEditorSignature {
    Element: HTMLDivElement;
    Args: {
        drumset: Drumset;
        selectedTrack: SelectedTrack;
        trackPosition: number;
        isTrackMaximized: boolean;
        // eslint-disable-next-line no-unused-vars
        setTrackPosition: (position: number) => void;
    };
    Blocks: {
    };
}
export default class TrackEditorComponent extends Component<TrackEditorSignature> {
    @service
    projectManager: ProjectManagerService;
    @service
    songPlayer: SongPlayer;
    @service
    undoManager: UndoManagerService;
    @service
    pasteboard: PasteboardService;
    @tracked
    _isCollapsed = true;
    @tracked
    division = 16;
    @tracked
    defaultVelocity = 100;
    @tracked
    theme: string | null = localStorage.getItem('theme');
    constructor(owner1: unknown, args1: TrackEditorSignature['Args']){
        super(owner1, args1);
        this.isCollapsed = !this.track.events.length;
        this.handleThemeChange = this.handleThemeChange.bind(this);
        window.addEventListener('themeChange', this.handleThemeChange);
        registerDestructor(this, ()=>{
            this.songPlayer.stop();
        });
        // eslint-disable-next-line ember/no-runloop, ember/no-incorrect-calls-with-inline-anonymous-functions
        scheduleOnce('afterRender', this, this.scrollToBottom);
    }
    handleThemeChange(event1: Event) {
        this.theme = event1.detail.theme;
    }
    get track() {
        return this.args.selectedTrack.track;
    }
    get trigPos() {
        return this.args.selectedTrack.trigPos;
    }
    get tickCount() {
        return this.args.selectedTrack.tickCount;
    }
    get trackPosition() {
        return this.args.trackPosition / this.step;
    }
    get isCollapsed() {
        return this.track.events.length > 0 && this._isCollapsed;
    }
    set isCollapsed(collapse1: boolean) {
        this._isCollapsed = collapse1;
    }
    get beatFactor() {
        return beatFactor(this.track.timeSignatureDenominator);
    }
    // Number of ticks per block at resolution (default: 16ths)
    get step() {
        return (beatTicks(this.track.tickToQuarter, this.track.timeSignatureDenominator) / (this.division / this.track.timeSignatureDenominator));
    }
    pixelsToTicks(pixels1: number) {
        return (pixels1 / this.stepSize) * this.step;
    }
    ticksToPixels(ticks1: number) {
        return (ticks1 / this.step) * this.stepSize;
    }
    get steps() {
        const length1 = (this.track.barCount * this.track.barLength) / this.step + this.extraSteps;
        return Array.from({
            length: length1
        }, (_1, i1)=>i1);
    }
    // A fill track is allowed to have extra steps at the end of a fill
    //  and the fill is allowed to start on any step
    get isFillTrack() {
        return (this.args.selectedTrack.part.type != PartType.PART || this.args.selectedTrack.sectionType !== SectionType.MAIN);
    }
    get extraSteps() {
        if (this.isFillTrack) {
            return 2;
        }
        return 0;
    }
    get stepSize() {
        return 40;
    }
    @action
    beatLabel(step1: number) {
        const bar1 = this.bar(step1) + 1;
        const beat1 = this.barBeat(step1);
        const subDivision1 = beat1 % 1;
        let label1 = `${bar1}`;
        if (beat1 > 0) {
            label1 += `.${Math.floor(beat1) + 1}`;
        }
        if (subDivision1 > 0) {
            label1 += `.${Math.ceil((this.division / this.track.timeSignatureDenominator) * subDivision1) + 1}`;
        }
        return label1;
    }
    @action
    bar(step1: number) {
        return bar(step1, this.division, this.track.timeSignatureNumerator, this.track.timeSignatureDenominator);
    }
    @action
    barBeat(step1: number) {
        return barBeat(step1, this.division, this.track.timeSignatureNumerator, this.track.timeSignatureDenominator);
    }
    @action
    beat(step1: number) {
        return beat(step1, this.division, this.track.timeSignatureNumerator, this.track.timeSignatureDenominator);
    }
    @action
    stepColor(step1: number) {
        if (Math.floor(this.beat(step1)) % 2 == 0) {
            return ' bg-gray-100';
        } else {
            return '';
        }
    }
    // This border is not a solid vertical line because of bottom borders
    @action
    stepBorder(step1: number) {
        const b1 = barBeat(step1 + 1, this.division, this.track.timeSignatureNumerator, this.track.timeSignatureDenominator);
        if (b1 === 0) {
            return ' border-gray-600';
        } else {
            return '';
        }
    }
    @action
    trackStyle() {
        return htmlSafe(`width:${((this.track.barCount * this.track.barLength) / this.step + this.extraSteps) * this.stepSize}px`);
    }
    @action
    stepStyle() {
        return htmlSafe(`width:${this.stepSize}px`);
    }
    //this god forsaken function is the cause of the table not regenerating properly when the styles change
    @action
    divisionStyle(step1: number) {
        var bg1;
        var borderColor1;
        bg1 = this.theme == 'dark' ? Math.floor(this.beat(step1)) % 2 === 0 ? 'background-color:#171717' : 'background-color:#1C1C1C' : Math.floor(this.beat(step1)) % 2 === 0 ? 'background-color:#F3F4F6' : '';
        borderColor1 = this.theme == 'dark' ? '#292B2E' : '#E5E7EB';
        return htmlSafe(`position:absolute;top:0;bottom:0;border-right:1px solid ${borderColor1};${bg1};width:${this.stepSize}px;left:${step1 * this.stepSize}px`);
    }
    get lineStyle() {
        return htmlSafe(`height:${this.stepSize}px`);
    }
    get playHeadStyle() {
        if (this.songPlayer.ticks > 0) {
            let maxPos1 = this.ticksToPixels(this.track.totalTicks);
            const position1 = this.ticksToPixels(this.songPlayer.ticks);
            const left1 = Math.min(maxPos1, position1);
            return htmlSafe(`left:${left1}px;width:1px`);
        } else {
            return htmlSafe(`display:none;`);
        }
    }
    get trackPositionStyle() {
        if (this.selectedEvents.length) {
            return htmlSafe(`display:none;`);
        } else {
            // This will reset the track position if the track changes.
            let maxPos1 = this.ticksToPixels(this.track.totalTicks);
            if (this.isFillTrack) {
                maxPos1 += 2 * this.stepSize;
            }
            const left1 = Math.min(maxPos1, Math.floor(this.trackPosition * this.stepSize)) - 1;
            return htmlSafe(`left:${left1}px;width:1px`);
        }
    }
    get loopMarkerStyle() {
        const left1 = this.ticksToPixels(Math.floor(this.trigPos));
        const width1 = this.ticksToPixels(Math.floor(this.tickCount));
        return htmlSafe(`margin-left:${left1}px;width:${width1}px`);
    }
    @action
    eventStyle(event1: MidiEvent) {
        const left1 = this.ticksToPixels(this.trigPos + event1.tick);
        const width1 = this.ticksToPixels(event1.length);
        const saturation1 = event1.velocity / 127 + 0.5;
        return htmlSafe(`left:${left1}px;width:${width1}px;filter:saturate(${saturation1})`);
    }
    @action
    velocityStyle(event1: MidiEvent) {
        const left1 = this.ticksToPixels(this.trigPos + event1.tick);
        const width1 = this.stepSize - 7;
        const bottom1 = (event1.velocity / 127) * 100;
        return htmlSafe(`left:${left1}px;width:${width1}px;bottom:${bottom1}%;`);
    }
    @action
    eventsForInstrument(instrument1: Instrument) {
        return this.track.eventsForInstrument(instrument1.id);
    }
    @action
    toggleCollapsed() {
        this.isCollapsed = !this.isCollapsed;
    }
    @tracked
    selectedEvents: MidiEvent[] = [];
    @action
    toggleSelectEvent(event1: MidiEvent, evt1?: Event) {
        let ev1 = <CustomEvent>evt1;
        if (ev1?.detail?.shiftKey | ev1.detail?.ctrlKey | ev1.detail?.metaKey) {
            if (this.isEventSelected(event1)) {
                if (!ev1?.detail?.append) {
                    this.selectedEvents = this.selectedEvents.filter((e1)=>e1 !== event1);
                }
            } else {
                this.selectedEvents = [
                    ...this.selectedEvents,
                    event1
                ];
            }
        } else {
            this.selectedEvents = [
                event1
            ];
        }
    }
    @action
    clearSelectedEvents() {
        this.selectedEvents = [];
        this.undoManager.appendCommand(new FilterOverlappingMidiEventsCommand(this.track), false);
    }
    @action
    removeSelectedEvent(event1: MidiEvent) {
        if (!this.isEventSelected(event1)) {
            return;
        }
        this.selectedEvents = this.selectedEvents.filter((e1)=>e1 !== event1);
    }
    @action
    isEventSelected(event1: MidiEvent) {
        return this.selectedEvents.includes(event1);
    }
    @action
    async deleteSelectedMidiEvents() {
        const commands1 = this.selectedEvents.map((event1)=>new RemoveMidiEventCommand(this.track, event1));
        await this.undoManager.executeCommand(new CompositeCommand('Remove MIDI events', commands1));
        this.selectedEvents = [];
    }
    @action
    selectEvents(instrument1: Instrument, ev1: MouseEvent) {
        const trackEvents1 = this.track.eventsForInstrument(instrument1.id);
        if (ev1.shiftKey) {
            if (this.selectedEvents.some((event1)=>trackEvents1.includes(event1))) {
                this.selectedEvents = this.selectedEvents.filter((event1)=>!trackEvents1.includes(event1));
            } else {
                this.selectedEvents = [
                    ...this.selectedEvents,
                    ...trackEvents1
                ];
            }
            return;
        }
        this.selectedEvents = this.track.eventsForInstrument(instrument1.id);
    }
    @action
    selectAll(ev1: KeyboardEvent) {
        ev1.preventDefault();
        this.selectedEvents = this.track.events;
    }
    @action
    copyMidi() {
        if (!this.selectedEvents.length) {
            return;
        }
        this.pasteboard.store('midi-events', this.selectedEvents);
        const minTick1 = Math.min(...this.selectedEvents.map((event1)=>event1.tick));
        this.args.setTrackPosition(Math.round(minTick1 / this.step));
    }
    @action
    async cutMidi() {
        this.copyMidi();
        const cmd1 = new RemoveMidiEventsCommand(this.track, this.selectedEvents);
        cmd1.name = 'Cut MIDI events';
        await this.undoManager.executeCommand(cmd1);
        this.selectedEvents = [];
    }
    @action
    async pasteMidi() {
        const events1 = (<MidiEvent[]>this.pasteboard.retrieve('midi-events') ?? []).map((event1)=>event1.copy());
        //set the shortest event tick to zero at the track select position and adjust all the rest by a relative amount
        const minTick1 = Math.min(...events1.map((event1)=>event1.tick));
        events1.forEach((event1)=>{
            event1.tick = event1.tick - minTick1 + this.trackPosition * this.step;
        });
        const cmd1 = new AddMidiEventsCommand(this.track, events1);
        cmd1.name = 'Paste MIDI events';
        await this.undoManager.executeCommand(cmd1);
        this.selectedEvents = events1;
    }
    @action
    setTrackPosition(evt1: Event) {
        const ev1 = <CustomEvent>evt1;
        if (this.selectedEvents.length > 0) {
            return;
        }
        const trackPosition1 = Math.round(ev1.detail.x / this.stepSize);
        const positionInTicks1 = Math.max(this.trigPos, trackPosition1 * this.step);
        this.args.setTrackPosition?.(positionInTicks1);
    }
    @action
    changeTrigPos(evt1: Event) {
        const ev1 = <CustomEvent>evt1;
        const trigPosStep1 = Math.round(ev1.detail.x / this.stepSize);
        // If the track position is now behind the trigger position, update the track position
        if (trigPosStep1 > this.trackPosition) {
            this.setTrackPosition(evt1);
        }
        const trigPos1 = this.pixelsToTicks(ev1.detail.x);
        this.undoManager.executeCommand(new ChangeTrigPosCommand(this.track, trigPos1));
    }
    @tracked
    highlightedInstrument?: Instrument;
    @action
    highlightInstrument(instrument1: Instrument) {
        this.highlightedInstrument = instrument1;
    }
    @action
    async addMidiEvent(instrument1: Instrument, evt1: Event) {
        const ev1 = <CustomEvent>evt1;
        const step1 = Math.floor(ev1.detail.x / this.stepSize);
        const tick1 = step1 * this.step;
        if (tick1 < this.trigPos) {
            return;
        }
        const event1 = await this.undoManager.executeCommand(new AddMidiEventCommand(this.track, tick1 - this.trigPos, instrument1.id, this.defaultVelocity, this.step));
        this.selectedEvents = [
            event1
        ];
    }
    @action
    playInstrument(instrument1: Instrument) {
        this.songPlayer.audioContext.resume();
        this.songPlayer.playInstrument(instrument1, 127);
    }
    @action
    playFrom(step1: number) {
        this.songPlayer.playFrom(step1 * this.step);
    }
    // All playable and required instruments
    get allInstruments() {
        return this.args.drumset.playableInstruments.concat(this.requiredInstruments).filter(unique).sort((a1, b1)=>b1.id - a1.id);
    }
    get visibleInstruments() {
        return this.allInstruments.filter((instrument1)=>!this.isCollapsed || this.track.instruments.includes(instrument1.id));
    }
    // Instruments required by the selected track (may not be playable by the selected drumset)
    get requiredInstruments() {
        return this.track.instruments.map((id1)=>this.args.drumset.instruments[id1]!);
    }
    eventResizes: {
        event: MidiEvent;
        start: number;
        width: number;
        length: number;
    }[] = [];
    @action
    handleResizeStart() {
        this.eventResizes = this.selectedEvents.map((event1)=>({
                event: event1,
                start: this.ticksToPixels(event1.tick),
                length: event1.length,
                width: this.ticksToPixels(event1.length)
            }));
    }
    doResize(evt1: Event, saveToUndo1 = false) {
        const ev1 = <CustomEvent>evt1;
        const adjustment1 = ev1.detail.adjustment;
        const undoCommands1 = this.eventResizes.map(({ event: event1, start: start1, width: width1, length: length1 })=>{
            let newWidth1 = width1 + adjustment1;
            if (ev1.detail.snap) {
                let end1 = start1 + newWidth1;
                end1 = Math.floor(end1 / this.stepSize) * this.stepSize;
                newWidth1 = end1 - start1;
                if (newWidth1 < this.stepSize) {
                    newWidth1 = this.stepSize;
                }
            }
            const newLength1 = this.pixelsToTicks(newWidth1);
            event1.length = newLength1;
            return new UpdateMidiEventLengthCommand(this.track, event1, newLength1, length1);
        });
        if (saveToUndo1) {
            this.undoManager.executeCommand(new CompositeCommand('Update MIDI note length', undoCommands1));
        }
    }
    @action
    handleResize(evt1: Event) {
        this.doResize(evt1);
    }
    @action
    handleResizeEnd(evt1: Event) {
        this.doResize(evt1, true);
        this.selectedEvents = this.eventResizes.map(({ event: event1 })=>event1);
        this.eventResizes = [];
    }
    eventMoves: {
        event: MidiEvent;
        note: number;
        tick: number;
    }[] = [];
    moveInstruments?: Instrument[];
    @action
    handleMoveStart() {
        this.moveInstruments = [
            ...this.visibleInstruments
        ];
        this.eventMoves = this.selectedEvents.map((event1)=>({
                event: event1,
                note: event1.note,
                tick: event1.tick
            }));
    }
    doMove(evt1: Event, saveToUndo1 = false) {
        const ev1 = <CustomEvent>evt1;
        if (ev1.detail.moved) {
            const x1 = ev1.detail.x;
            const undoCommands1: Command<any>[] = [];
            this.eventMoves.forEach(({ event: event1, tick: tick1, note: note1 })=>{
                let newTick1 = tick1 + this.pixelsToTicks(x1);
                if (ev1.detail.snap) {
                    newTick1 = Math.round(newTick1 / this.step) * this.step;
                }
                event1.tick = newTick1;
                undoCommands1.push(new UpdateMidiEventTickCommand(this.track, event1, newTick1, tick1));
                if (saveToUndo1) {
                    let newNote1: number | undefined = note1;
                    if (ev1.detail.instruments) {
                        const instrumentIndex1 = this.moveInstruments!.findIndex((instrument1)=>instrument1.id === event1.note);
                        newNote1 = this.moveInstruments?.[instrumentIndex1 + ev1.detail.instruments]?.id;
                        if (newNote1) {
                            event1.note = newNote1;
                            undoCommands1.push(new UpdateMidiEventNoteCommand(event1, newNote1, note1));
                        }
                    }
                }
            });
            if (saveToUndo1) {
                this.undoManager.executeCommand(new CompositeCommand('Move MIDI events', undoCommands1));
            } else {
                // eslint-disable-next-line ember/no-runloop, ember/no-incorrect-calls-with-inline-anonymous-functions
                scheduleOnce('afterRender', this, function() {
                    document.querySelectorAll('.midi-event.selected').forEach(($el1)=>{
                        (<HTMLElement>$el1).style.transform = `translate3d(0, ${ev1.detail.y}px, 0)`;
                    });
                });
            }
        }
    }
    @action
    handleMove(evt1: Event) {
        this.doMove(evt1);
    }
    @action
    handleMoveEnd(evt1: Event) {
        this.doMove(evt1, true);
        const $el1 = evt1.target as HTMLElement;
        $el1.style.transform = 'none';
        this.moveInstruments = undefined;
        this.selectedEvents = this.eventMoves.map(({ event: event1 })=>event1);
        this.eventMoves = [];
    }
    @action
    moveEventsUp(ev1: KeyboardEvent) {
        ev1.preventDefault();
        if (!this.selectedEvents.length) {
            return;
        }
        const moveBy1 = ev1.altKey ? 12 : 1;
        const minNote1 = Math.min(...this.selectedEvents.map((event1)=>event1.note));
        const minNoteIndex1 = this.allInstruments.findIndex((instrument1)=>instrument1.id === minNote1);
        if (minNoteIndex1 - moveBy1 < 0) {
            return;
        }
        const commands1 = this.selectedEvents.map((event1)=>{
            const instrumentIndex1 = this.allInstruments.findIndex((instrument1)=>instrument1.id === event1.note);
            const instrument1 = this.allInstruments[instrumentIndex1 - moveBy1]!;
            return new UpdateMidiEventNoteCommand(event1, instrument1.id);
        });
        this.undoManager.executeCommand(new CompositeCommand('Change MIDI instrument', commands1));
    }
    @action
    moveEventsDown(ev1: KeyboardEvent) {
        ev1.preventDefault();
        if (!this.selectedEvents.length) {
            return;
        }
        const moveBy1 = ev1.altKey ? 12 : 1;
        const maxNote1 = Math.max(...this.selectedEvents.map((event1)=>event1.note));
        const maxNoteIndex1 = this.allInstruments.findIndex((instrument1)=>instrument1.id === maxNote1);
        if (maxNoteIndex1 + moveBy1 > this.allInstruments.length - 1) {
            return;
        }
        const commands1 = this.selectedEvents.map((event1)=>{
            const instrumentIndex1 = this.allInstruments.findIndex((instrument1)=>instrument1.id === event1.note);
            const instrument1 = this.allInstruments[instrumentIndex1 + moveBy1]!;
            return new UpdateMidiEventNoteCommand(event1, instrument1.id);
        });
        this.undoManager.executeCommand(new CompositeCommand('Change MIDI instrument', commands1));
    }
    @action
    moveEventsLeft(ev1: KeyboardEvent) {
        ev1.preventDefault();
        if (!this.selectedEvents.length) {
            return;
        }
        const minTick1 = Math.min(...this.selectedEvents.map((event1)=>event1.tick));
        if (minTick1 < this.step) {
            return;
        }
        const commands1 = this.selectedEvents.map((event1)=>new UpdateMidiEventTickCommand(this.track, event1, event1.tick - this.step));
        this.undoManager.executeCommand(new CompositeCommand('Move MIDI events left', commands1));
    }
    @action
    moveEventsRight(ev1: KeyboardEvent) {
        ev1.preventDefault();
        if (!this.selectedEvents.length) {
            return;
        }
        const rightMostTick1 = Math.max(...this.selectedEvents.map((event1)=>event1.tick)) + this.step; /*tick width*/ 
        let maxTick1 = this.track.tickCount;
        if (this.isFillTrack) {
            maxTick1 = this.track.tickCount + 2 * this.step;
        }
        if (rightMostTick1 + this.step > maxTick1) {
            return;
        }
        const commands1 = this.selectedEvents.map((event1)=>new UpdateMidiEventTickCommand(this.track, event1, event1.tick + this.step));
        this.undoManager.executeCommand(new CompositeCommand('Move MIDI events right', commands1));
    }
    activeVelocityEvent?: {
        event: MidiEvent;
        velocity: number;
    };
    @action
    toggleSelectVelocity(event1: MidiEvent) {
        this.activeVelocityEvent = {
            event: event1,
            velocity: event1.velocity
        };
        if (!this.isEventSelected(event1)) {
            this.selectedEvents = [
                event1
            ];
        }
    }
    eventVelocities: any[] = [];
    @action
    handleVelocityChangeStart() {
        this.eventVelocities = this.selectedEvents.map((event1)=>({
                event: event1,
                velocity: event1.velocity
            }));
    }
    @action
    handleVelocityChange(ev1: Event) {
        const evt1 = <CustomEvent>ev1;
        const velocityChange1 = evt1.detail.change;
        if (this.activeVelocityEvent) {
            const adjustedVelocity1 = this.activeVelocityEvent.velocity + velocityChange1;
            const percentageChange1 = (adjustedVelocity1 - this.activeVelocityEvent.velocity) / this.activeVelocityEvent.velocity;
            this.eventVelocities.forEach((change1)=>{
                const event1 = <MidiEvent>change1.event;
                // The active velocity event (the one being dragged) is always changed to the exact value
                if (event1 === this.activeVelocityEvent?.event) {
                    event1.velocity = Math.round(change1.velocity + velocityChange1);
                } else {
                    if (evt1.detail.command) {
                        event1.velocity = Math.round(change1.velocity + velocityChange1);
                    } else {
                        event1.velocity = Math.round(change1.velocity * (1 + percentageChange1));
                    }
                }
            });
        }
    }
    @action
    handleVelocityChangeEnd(ev1: Event) {
        const evt1 = <CustomEvent>ev1;
        const undoCommands1: Command<any>[] = [];
        this.eventVelocities.forEach((change1)=>{
            const event1 = <MidiEvent>change1.event;
            undoCommands1.push(new UpdateMidiEventVelocityCommand(event1, change1.velocity, event1.velocity));
        });
        this.undoManager.executeCommand(new CompositeCommand('Change Velocities', undoCommands1));
        const $el1 = evt1.target as HTMLElement;
        $el1.style.transform = 'none';
        this.selectedEvents = this.eventVelocities.map((change1)=>change1.event);
        this.eventVelocities = [];
        this.activeVelocityEvent = undefined;
    }
    barCountShiftKey = false;
    barCountCtrlKey = false;
    @action
    barCountKeydown(ev1: MouseEvent) {
        this.barCountShiftKey = ev1.shiftKey;
        this.barCountCtrlKey = ev1.ctrlKey || ev1.metaKey;
    }
    @action
    changeBarCount(ev1: Event) {
        const barCount1 = parseInt((<HTMLInputElement>ev1.target).value);
        this.undoManager.executeCommand(new UpdateTrackBarCountCommand(this.track, barCount1, this.barCountShiftKey /* inFront */ , this.barCountCtrlKey /* copyEvents */ ));
        this.barCountShiftKey = false;
        this.barCountCtrlKey = false;
    }
    @action
    changeTimeSignatureNumerator(numerator1: string) {
        this.undoManager.executeCommand(new UpdateTimeSignatureNumeratorCommand(this.track, parseInt(numerator1)));
    }
    @action
    changeTimeSignatureDenominator(denominatorValue1: string) {
        const denominator1 = parseInt(denominatorValue1);
        this.undoManager.executeCommand(new UpdateTimeSignatureDenominatorCommand(this.track, denominator1));
        if (this.division < denominator1) {
            this.division = denominator1;
        }
    }
    @action
    scrollToBottom() {
        const scrollParent1 = document.querySelector('.scroll-parent');
        if (scrollParent1) {
            scrollParent1.scrollTop = scrollParent1.scrollHeight; // Scroll to the bottom
        }
    }
    @action
    doubleBeat() {
        this.undoManager.executeCommand(new DoubleBeatCommand(this.track));
    }
    @action
    quantizeTrack() {
        this.undoManager.executeCommand(new QuantizeTrackCommand(this.track, this.division));
    }
    @action
    changeDivision(ev1: Event) {
        this.division = parseInt((<HTMLInputElement>ev1.target).value);
    }
    divisionName(division1: number) {
        switch(division1){
            case 2:
                return 'Half';
            case 4:
                return 'Quarter';
            case 6:
                return '1/4T';
            case 8:
                return 'Eighth';
            case 12:
                return '1/8T';
            case 16:
                return '16th';
            case 24:
                return '1/16T';
            case 32:
                return '32nd';
            case 64:
                return '64th';
        }
    }
    @action
    setDefaultVelocity(velocity1: number) {
        this.defaultVelocity = velocity1;
    }
    static{
        template(`
    {{! template-lint-disable no-invalid-interactive }}
    <div class="flex flex-col w-full h-full overflow-auto relative" ...attributes>
      <div
        data-test-hides-track-and-instrument-overflow
        class="absolute w-full bottom-0 z-10 h-2 bg-white dark:bg-primary-darkComponent"
      ></div>
      <nav aria-label="beat navigation" class="flex flex-row items-center gap-2 shrink-0">
        <label>Bar count:
          <input
            type="number"
            min="1"
            step="1"
            value={{this.track.barCount}}
            class="w-16 px-2 py-1 pr-1 text-right border rounded dark:bg-primary-darkComponent dark:border-primary-darkBorderColor"
            {{! template-lint-disable no-pointer-down-event-binding }}
            {{on "mousedown" this.barCountKeydown}}
            {{on "change" this.changeBarCount}}
          /></label>
        {{! template-lint-disable no-nested-interactive }}
        <label class="flex items-center gap-1">Time signature:
          <div class="flex flex-col px-2 py-1 text-xs border rounded dark:border-primary-darkBorderColor">
            <select
              class="border-b dark:border-primary-darkBorderColor dark:bg-primary-darkComponent"
              {{on "change" (pick "target.value" this.changeTimeSignatureNumerator)}}
            >
              {{#each (array 2 3 4 6 8 9 12) as |numerator|}}
                <option
                  value={{numerator}}
                  selected={{eq this.track.timeSignatureNumerator numerator}}
                >{{numerator}}</option>
              {{/each}}
              <option disabled>─</option>
              {{! prettier-ignore }}
              {{#each (array 1 5 7 10 11 13 14 15 16 17 18 19 20 21 22 23 24 25 26 27 28 29 30 31 32) as |numerator|}}
                <option
                  value={{numerator}}
                  selected={{eq this.track.timeSignatureNumerator numerator}}
                >{{numerator}}</option>
              {{/each}}
            </select>
            <select
              class="dark:bg-primary-darkComponent"
              {{on "change" (pick "target.value" this.changeTimeSignatureDenominator)}}
            >
              {{#each (array 2 4 8 16 32) as |numerator|}}
                <option
                  value={{numerator}}
                  selected={{eq this.track.timeSignatureDenominator numerator}}
                >{{numerator}}</option>
              {{/each}}
            </select>
          </div>
        </label>
        <Button {{on "click" this.doubleBeat}}>Double Beat</Button>
        <Button
          @icon="bullseye"
          title="Quantize track"
          {{on "click" this.quantizeTrack}}
        />

        <label class="flex items-center gap-1 ml-auto">Division:
          <select
            class="flex flex-col px-2 py-1 text-xs border rounded dark:border-primary-darkBorderColor dark:bg-primary-darkComponent"
            {{on "change" this.changeDivision}}
          >
            {{#each (array 2 4 6 8 12 16 24 32 64) as |division|}}
              {{#if (gte division this.track.timeSignatureDenominator)}}
                <option
                  value={{division}}
                  selected={{eq division this.division}}
                >{{this.divisionName division}}</option>
              {{/if}}
            {{/each}}
          </select>
        </label>
      </nav>
      <div
        class="relative flex w-full overflow-auto border-t border-l scroll-parent pb-2 dark:border-primary-darkBorderColor"
        {{on "bbff.set-track-position" this.setTrackPosition}}
        {{on "bbff.trig-pos-change" this.changeTrigPos}}
        {{verticalOverlayScroll @isTrackMaximized}}
      >
        <div data-scroll-target class="absolute inset-0 z-50 pointer-events-none"></div>
        <div data-test-instruments class="flex flex-col w-1/5 max-w-max h-max">
          <div
            class="sticky top-0 left-0 z-20 w-full bg-white border-b border-gray-400 top-bar dark:bg-primary-darkComponent dark:border-primary-darkBorderColor"
          >
            <nav class="flex flex-col justify-end h-8">
              <button
                type="button"
                class="text-[0.6rem] bg-gray-200 selected:bg-primary selected:text-white py-0.5 px-1 h-4 dark:bg-primary-darkBorderColor"
                {{selected when=this.isCollapsed}}
                {{on "click" this.toggleCollapsed}}
              >{{if this.isCollapsed "Unfold" "Fold"}}</button>
            </nav>
          </div>
          {{#each this.visibleInstruments as |instrument|}}
            <div class="flex flex-row w-full h-5 text-sm border-b border-r shrink-0 dark:border-primary-darkBorderColor">
              <button
                type="button"
                class="px-1 overflow-hidden text-left grow cursor-vol whitespace-nowrap text-ellipsis selected:bg-primary/15"
                title={{instrument.name}}
                {{selected when=(eq this.highlightedInstrument instrument)}}
                {{on "mouseover" (fn this.highlightInstrument instrument)}}
                {{on "click" (fn this.playInstrument instrument)}}
              >{{instrument.id}}. {{instrument.name}}</button>
              <button
                type="button"
                class="w-6 bg-gray-300 shrink-0 dark:bg-primary-darkHeader"
                {{on "mouseover" (fn this.highlightInstrument instrument)}}
                {{on "click" (fn this.selectEvents instrument)}}
              >&nbsp;</button>
            </div>
          {{/each}}
          <div
            class="sticky bottom-0 left-0 z-20 flex flex-col justify-between w-full h-16 px-1 text-sm text-left bg-white border-b border-r shrink-0 dark:border-primary-darkBorderColor dark:bg-primary-darkComponent"
          >
            <h2>Velocity</h2>
            <VelocityValues
              @events={{this.selectedEvents}}
              @defaultValue={{this.defaultVelocity}}
              @setDefault={{this.setDefaultVelocity}}
            />
          </div>
        </div>
        <div class="relative w-full overflow-x-scroll h-max" {{horizontalOverlayScroll}}>
          <div
            class="absolute top-0 left-0 z-20 bg-white border-b border-gray-400 w-max top-bar dark:border-primary-darkBorderColor dark:bg-primary-darkComponent"
            {{stickyTop ".scroll-parent"}}
          >
            <div
              data-test-bar-marker
              class="flex items-start h-4 text-[0.6rem] w-max z-10 bg-white dark:bg-primary-darkFill"
            >
              {{#each this.steps as |step|}}
                <div
                  class="h-4 pl-1 border-r shrink-0 grow cursor-vol dark:bg-primary-darkFill dark:border-primary-darkBorderColor
                    {{this.stepColor step}}
                    {{this.stepBorder step}}"
                  style={{(this.stepStyle)}}
                  {{on "click" (fn this.playFrom step)}}
                >{{this.beatLabel step}}</div>
              {{/each}}
            </div>
            <div
              data-test-loop-marker
              class="relative w-max h-4 text-[0.6rem] bg-primary-light disabled:bg-gray-200 dark:bg-primary-darkFill dark:disabled:bg-primary-darkFill"
              {{disabled when=(eq @selectedTrack.sectionType SectionType.MAIN)}}
            >
              <div
                class="h-4 bg-primary disabled:bg-gray-300 loop-marker"
                style={{this.loopMarkerStyle}}
                {{disabled when=(not this.isFillTrack)}}
                {{adjustLoopMarker this.stepSize}}
              >
                <div class="absolute w-4 h-4 left-handle cursor-ew-resize"></div>
              </div>
            </div>
          </div>
          <div
            data-test-tracks
            class="relative flex flex-col items-start w-full mt-8 mb-16"
            style={{(this.trackStyle)}}
            {{selectRegion ".midi-event"}}
            {{adjustMidiLength this.stepSize}}
            {{moveEvents this.stepSize}}
            {{onKey "Backspace" this.deleteSelectedMidiEvents}}
            {{onKey "Delete" this.deleteSelectedMidiEvents}}
            {{onKey "a" this.selectAll cmdKey=true}}
            {{onKey "c" this.copyMidi cmdKey=true}}
            {{onKey "v" this.pasteMidi cmdKey=true}}
            {{onKey "x" this.cutMidi cmdKey=true}}
            {{onKey "ArrowUp" this.moveEventsUp}}
            {{onKey "ArrowUp" this.moveEventsUp altKey=true}}
            {{onKey "ArrowDown" this.moveEventsDown}}
            {{onKey "ArrowDown" this.moveEventsDown altKey=true}}
            {{onKey "ArrowLeft" this.moveEventsLeft}}
            {{onKey "ArrowRight" this.moveEventsRight}}
            {{on "bbff.deselect-all" this.clearSelectedEvents}}
            {{on "bbff.resize-start" this.handleResizeStart}}
            {{on "bbff.resize" this.handleResize}}
            {{on "bbff.resize-end" this.handleResizeEnd}}
            {{on "bbff.move-start" this.handleMoveStart}}
            {{on "bbff.move" this.handleMove}}
            {{on "bbff.move-end" this.handleMoveEnd}}
          >
            {{! Draws the vertical step divisions }}
            {{#each this.steps as |step|}}
              <div class="step" style={{this.divisionStyle step}}></div>
            {{/each}}
            {{! Draws the horizontal instrument dividing lines }}
            {{#each this.visibleInstruments as |instrument|}}
              <div
                class="relative w-full h-5 border-t dark:border-primary-darkBorderColor instrument selected:bg-primary/15"
                {{selected when=(eq this.highlightedInstrument instrument)}}
                {{on "mouseover" (fn this.highlightInstrument instrument)}}
                {{on "bbff.add-event" (fn this.addMidiEvent instrument)}}
              ></div>
            {{/each}}
            {{! Draws the individual notes }}
            <div class="absolute top-0 bottom-0 left-0 right-0 pointer-events-none">
              {{#each this.visibleInstruments as |instrument|}}
                <div class="relative flex h-5 instrument">
                  {{#each (this.eventsForInstrument instrument) as |event|}}
                    <div
                      class="absolute top-0 left-0 h-5 bg-orange-400 pointer-events-auto midi-event"
                      style={{this.eventStyle event}}
                      {{selected when=(this.isEventSelected event)}}
                      {{on "mouseover" (fn this.highlightInstrument instrument)}}
                      {{on "bbff.select" (fn this.toggleSelectEvent event)}}
                      {{on "bbff.deselect" (fn this.removeSelectedEvent event)}}
                    ><div class="rhandle"></div></div>
                  {{/each}}
                </div>
              {{/each}}
            </div>
          </div>
          <div
            data-test-velocity
            class="absolute bottom-0 left-0 z-20 flex flex-col items-start bg-white border-t w-max border-gray-400 dark:bg-primary-darkFill dark:border-primary-darkBorderColor"
            style={{(this.trackStyle)}}
            {{stickyBottom ".scroll-parent"}}
            {{on "bbff.deselect-all" this.clearSelectedEvents}}
          >
            <div class="relative flex h-16 border-b dark:border-primary-darkBorderColor">
              {{! Draws vertical step divisions }}
              {{#each this.steps as |step|}}
                <div class="step" style={{this.divisionStyle step}}></div>
              {{/each}}
              {{! Draws the individual velocity markers }}
              <div
                class="absolute bottom-0 left-0 right-0 top-2"
                {{selectRegion ".velocity-event"}}
                {{changeVelocity}}
                {{on "bbff.velocity-change-start" this.handleVelocityChangeStart}}
                {{on "bbff.velocity-change" this.handleVelocityChange}}
                {{on "bbff.velocity-change-end" this.handleVelocityChangeEnd}}
                {{on "bbff.deselect-all" this.clearSelectedEvents}}
              >
                {{#each this.track.noteOnEvents as |event|}}
                  <div
                    class="absolute left-0 h-px velocity-event"
                    style={{this.velocityStyle event}}
                    data-test-velocity={{event.velocity}}
                    {{selected when=(this.isEventSelected event)}}
                    {{! template-lint-disable no-pointer-down-event-binding }}
                    {{on "pointerdown" (fn this.toggleSelectVelocity event)}}
                  ></div>
                {{/each}}
              </div>
            </div>
          </div>
          <div
            data-test-play-head
            {{updatePlayHead this.songPlayer.ticks}}
            class="absolute top-0 bottom-0 left-0 mt-8 bg-red-500"
            style={{this.playHeadStyle}}
            {{stickyTop ".scroll-parent"}}
            {{stickyBottom ".scroll-parent"}}
          ></div>
          <div
            data-test-track-position
            class="absolute bottom-0 left-0 mt-8 bg-primary -top-px track-pos"
            style={{this.trackPositionStyle}}
            {{stickyTop ".scroll-parent"}}
            {{stickyBottom ".scroll-parent"}}
          ></div>
        </div>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
