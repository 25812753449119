import { template } from "@ember/template-compiler";
import { fn } from '@ember/helper';
import { on } from '@ember/modifier';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import Component from '@glimmer/component';
import eq from 'editor/helpers/eq';
import not from 'editor/helpers/not';
import pick from 'editor/helpers/pick';
import type { IInstrument } from 'editor/models/drumset';
import disabled from 'editor/modifiers/disabled';
import Button from './button';
import { MultiInstrument } from './drumset-editor';
import EditableField from './editable-field';
import { concat } from '@ember/helper';
import or from 'editor/helpers/or';
interface InstrumentSettingsEditorSignature {
    Element: HTMLDivElement;
    Args: {
        instrument: IInstrument;
        addSamples?: () => void;
        autoSort?: () => void;
        // eslint-disable-next-line no-unused-vars
        renameInstrument: (name: string) => void;
        availableMidiIds: {
            id: number;
            name: string;
        }[];
        // eslint-disable-next-line no-unused-vars
        updateNonPercussion: (nonPercussion: number) => void;
        // eslint-disable-next-line no-unused-vars
        updateInstrumentID: (id: string) => void;
        // eslint-disable-next-line no-unused-vars
        updateChokeGroup: (value: string) => void;
        // eslint-disable-next-line no-unused-vars
        updateFillChokeGroup: (value: string) => void;
        // eslint-disable-next-line no-unused-vars
        updateFillChokeDelay: (value: string) => void;
        // eslint-disable-next-line no-unused-vars
        updateVolume: (value: string) => void;
        // eslint-disable-next-line no-unused-vars
        updatePolyphony: (value: string) => void;
    };
    Blocks: {
    };
}
export default class InstrumentSettingsEditor extends Component<InstrumentSettingsEditorSignature> {
    constructor(owner1: unknown, args1: InstrumentSettingsEditorSignature['Args']){
        super(owner1, args1);
    }
    get isMultiInstrument() {
        return this.args.instrument instanceof MultiInstrument;
    }
    isUndefined(value1: number) {
        if (value1 === -1) {
            return true;
        }
        return false;
    }
    definedValue(value1: number): number | undefined {
        if (value1 === -1) {
            return undefined;
        }
        return value1;
    }
    static{
        template(`
    <header class="max-w-4xl">
      <div class="flex items-center justify-between gap-4 pl-2">
        <EditableField
          @model={{@instrument}}
          @field="name"
          @onChange={{@renameInstrument}}
          @when={{not this.isMultiInstrument}}
          class="text-2xl font-bold"
        >{{@instrument.name}}</EditableField>
        {{#unless this.isMultiInstrument}}
          <nav class="flex gap-2 flex-nowrap">
            {{#if @addSamples}}
              <Button @icon="waveform" {{on "click" @addSamples}}>Add samples</Button>
            {{/if}}
            {{#if @autoSort}}
              <Button @icon="arrow-up-wide-short" {{on "click" @autoSort}}>Auto sort</Button>
            {{/if}}
          </nav>
        {{/unless}}
      </div>
      <section class="flex items-center justify-between gap-2 p-2">
        <div class="flex items-center p-1 border rounded cursor-pointer bg-gray-50 dark:bg-primary-darkHeader dark:border-primary-darkBorderColor">
          <label title="Percussion/Non-percussion instrument">
            <input
              type="radio"
              name="nonPercussion"
              value="percussion"
              class="hidden peer"
              checked={{eq @instrument.nonPercussion 0}}
              {{on "change" (fn @updateNonPercussion 0)}}
            />
            <div
              class="px-2 py-1 rounded peer-checked:bg-primary peer-checked:text-white"
            ><FaIcon @icon="drum" @fixedWidth={{true}} /></div>
          </label>
          <label title="Percussion/Non-percussion instrument">
            <input
              type="radio"
              name="nonPercussion"
              value="non-percussion"
              class="hidden peer"
              checked={{eq @instrument.nonPercussion 1}}
              {{on "change" (fn @updateNonPercussion 1)}}
            />
            <div
              class="px-2 py-1 rounded peer-checked:bg-primary peer-checked:text-white"
            ><FaIcon @icon="piano-keyboard" @fixedWidth={{true}} /></div>
          </label>
        </div>
        {{#unless this.isMultiInstrument}}
          <label>Midi Note
            <select
              class="w-20 px-2 py-1 pr-1 text-right border rounded disabled:bg-gray-100 disabled:text-gray-400 dark:bg-primary-darkComponent dark:border-primary-darkBorderColor"
              {{on "change" (pick "target.value" @updateInstrumentID)}}
            >
              <option value={{@instrument.id}} selected>{{@instrument.id}}</option>
              {{#each @availableMidiIds as |inst|}}
                <option value={{inst.id}}>{{inst.id}} {{inst.name}}</option>
              {{/each}}
            </select></label>
        {{/unless}}
        <label>Choke group
          <input
            type="number"
            min="0"
            max="15"
            value={{this.definedValue @instrument.chokeGroup}}
            class="w-12 px-2 py-1 pr-1 text-right border rounded dark:bg-primary-darkComponent dark:border-primary-darkBorderColor"
            {{on "input" (pick "target.value" @updateChokeGroup)}}
          /></label>
        <label>Fill choke group
          <input
            type="number"
            min="0"
            max="15"
            value={{this.definedValue @instrument.fillChokeGroup}}
            class="w-12 px-2 py-1 pr-1 text-right border rounded dark:bg-primary-darkComponent dark:border-primary-darkBorderColor"
            {{on "input" (pick "target.value" @updateFillChokeGroup)}}
          /></label>
        <label>Fill choke delay
          {{@instrument.fillChokeDelay}}
          <select
            class="w-20 px-2 py-1 pr-1 text-right border rounded disabled:bg-gray-100 disabled:text-gray-400 dark:bg-primary-darkComponent dark:border-primary-darkBorderColor dark:disabled:bg-primary-darkHeader"
            {{disabled
              when=(or
                (not (this.definedValue @instrument.fillChokeGroup))
                (eq @instrument.fillChokeGroup 0)
              )
            }}
            {{on "change" (pick "target.value" @updateFillChokeDelay)}}
          >
            <option
              value="0"
              selected={{eq 0 (this.definedValue @instrument.fillChokeDelay)}}
            >1/4th</option>
            <option
              value="1"
              selected={{eq 1 (this.definedValue @instrument.fillChokeDelay)}}
            >1/8th</option>
            <option
              value="2"
              selected={{eq 2 (this.definedValue @instrument.fillChokeDelay)}}
            >1/16th</option>
          </select></label>
      </section>
      <section class="grid items-center justify-between grid-cols-2 gap-8 p-2">
        <label class="flex items-center gap-1 grow text-nowrap">
          <div class="w-52">Volume ({{if
              (this.isUndefined @instrument.volume)
              "multiple"
              (concat @instrument.volumeDb "dB")
            }})</div>
          <input
            type="range"
            min="1"
            max="100"
            value={{this.definedValue @instrument.volume}}
            class="w-full
              {{if
                (this.isUndefined @instrument.volume)
                'accent-gray-600'
                'accent-primary'
              }}"
            {{on "input" (pick "target.value" @updateVolume)}}
            {{on "dblclick" (fn @updateVolume "100")}}
          />
        </label>
        <label class="flex gap-1 grow text-nowrap">
          <div class="w-48">Polyphony ({{if
              (this.isUndefined @instrument.poly)
              "multiple"
              (if (eq @instrument.poly 0) "+inf" @instrument.poly)
            }})</div>
          <input
            type="range"
            min="0"
            max="10"
            value={{this.definedValue @instrument.poly}}
            class="w-full
              {{if
                (this.isUndefined @instrument.poly)
                'accent-gray-600'
                'accent-primary'
              }}"
            {{on "input" (pick "target.value" @updatePolyphony)}}
          />
        </label>
      </section>
    </header>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        InstrumentSettingsEditor: typeof InstrumentSettingsEditor;
    }
}
