import { modifier } from 'ember-modifier';

interface OnKeySignature {
  Element: HTMLElement;
  Args: {
    Positional: [key: string, (event: KeyboardEvent) => void];
    Named?: {
      preventDefault?: boolean;
      includeInputs?: boolean;
      cmdKey?: boolean;
      shiftKey?: boolean;
      altKey?: boolean;
      capture?: boolean;
    };
  };
}

function onKey(
  element: HTMLElement,
  [key, callback]: [string, (event: KeyboardEvent) => void],
  named?: {
    preventDefault?: boolean;
    includeInputs?: boolean;
    cmdKey?: boolean;
    shiftKey?: boolean;
    altKey?: boolean;
    capture?: boolean;
  },
) {
  const opts = Object.assign(
    {
      preventDefault: false,
      includeInputs: false,
      cmdKey: false,
      shiftKey: false,
      altKey: false,
      capture: false,
    },
    named,
  );
  const eventHandler = (event: KeyboardEvent) => {
    if (
      (event.code === key || event.key === key) &&
      ((opts.cmdKey === false && !(event.metaKey || event.ctrlKey)) ||
        (opts.cmdKey === true && (event.metaKey || event.ctrlKey))) &&
      (event.metaKey === opts.cmdKey || event.ctrlKey === opts.cmdKey) &&
      event.shiftKey === opts.shiftKey &&
      event.altKey === opts.altKey
    ) {
      if (
        !opts.includeInputs &&
        (event.target instanceof HTMLInputElement ||
          event.target instanceof HTMLTextAreaElement) &&
        event.target != element
      ) {
        return;
      }
      if (opts.preventDefault) {
        event.preventDefault();
      }
      callback(event);
    }
  };
  document.addEventListener('keydown', eventHandler, { capture: opts.capture });

  return () => {
    document.removeEventListener('keydown', eventHandler, {
      capture: opts.capture,
    });
  };
}

export default modifier<OnKeySignature>(onKey);

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'on-key': typeof onKey;
  }
}
