import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { RenameDrumsetCommand } from 'editor/models/drumset-commands';
import type { DrumsetFile } from 'editor/services/project-manager';
import type UndoManagerService from 'editor/services/undo-manager';

export default class RestrictedDrumsetsDrumsetController extends Controller {
  @service declare undoManager: UndoManagerService;

  declare model: DrumsetFile;

  get drumset() {
    return this.model.drumset!;
  }

  @action
  renameDrumset(name: string) {
    if (name !== this.model.name) {
      this.undoManager.executeCommand(
        new RenameDrumsetCommand(this.model, name),
      );
    }
  }
}
