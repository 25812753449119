import { modifier } from 'ember-modifier';

export interface SelectedSignature {
  Element: HTMLElement;
  Args: {
    Positional: [];
    Named: {
      when?: boolean;
    };
  };
}

const selected = modifier<SelectedSignature>(function selected(
  element: HTMLElement,
  _,
  { when: shouldBeSelected = false },
) {
  if (shouldBeSelected) {
    element.classList.add('selected');
  } else {
    element.classList.remove('selected');
  }
});

export default selected;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    selected: typeof selected;
  }
}
