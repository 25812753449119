import ProjectManagerService, {
  type DrumsetFile,
} from 'editor/services/project-manager';
import Command from './command';
import Drumset, { Instrument, Velocity, type IInstrument } from './drumset';

export class SortDrumsetsCommand extends Command<void> {
  project: ProjectManagerService;
  oldIndex: number;
  newIndex: number;

  constructor(
    project: ProjectManagerService,
    oldIndex: number,
    newIndex: number,
  ) {
    super('Sort Drum Sets');
    this.project = project;
    this.oldIndex = oldIndex;
    this.newIndex = newIndex;
  }

  markDirty(): void {
    this.project.drumsetsDirty = true;
  }

  execute() {
    const drumset = this.project.drumsets[this.oldIndex];
    const arr = [...this.project.drumsets];
    if (drumset) {
      // remove drumset from oldIndex and insert it at newIndex
      arr.splice(this.oldIndex, 1);
      arr.splice(this.newIndex, 0, drumset);
    }
    this.project.drumsets = arr;
  }

  reverse() {
    const drumset = this.project.drumsets[this.newIndex];
    const arr = [...this.project.drumsets];
    if (drumset) {
      // remove drumset from newIndex and insert it at oldIndex
      arr.splice(this.newIndex, 1);
      arr.splice(this.oldIndex, 0, drumset);
    }
    this.project.drumsets = arr;
  }
}

export class SortDrumsetsAlphabeticallyCommand extends Command<void> {
  project: ProjectManagerService;
  drumsets: DrumsetFile[];

  constructor(project: ProjectManagerService) {
    super('Sort Drumsets Alphabetically');
    this.project = project;
    this.drumsets = [...project.drumsets];
  }

  markDirty(): void {
    this.project.drumsetsDirty = true;
  }

  execute() {
    this.project.drumsets = this.project.drumsets.toSorted((d1, d2) =>
      d1.name.localeCompare(d2.name),
    );
  }

  reverse() {
    this.project.drumsets = this.drumsets;
  }
}

export class AddDrumsetCommand extends Command<DrumsetFile> {
  project: ProjectManagerService;
  drumset: DrumsetFile;

  constructor(project: ProjectManagerService, drumsetFile: DrumsetFile) {
    super('Add Drum Set');
    this.project = project;
    this.drumset = drumsetFile;
  }

  markDirty(): void {
    this.project.drumsetsDirty = true;
  }

  execute() {
    this.project.drumsets = [...this.project.drumsets, this.drumset];
    return this.drumset;
  }

  reverse() {
    this.project.drumsets = this.project.drumsets.filter(
      (d) => d !== this.drumset,
    );
    return this.drumset;
  }
}

export class RenameDrumsetCommand extends Command<DrumsetFile> {
  drumset: DrumsetFile;
  fromName: string;
  toName: string;

  constructor(song: DrumsetFile, name: string) {
    super('Rename Drumset');
    this.drumset = song;
    this.fromName = song.name!;
    this.toName = name;
  }

  markDirty(): void {
    this.drumset.markDirty();
  }

  execute() {
    this.drumset.name = this.toName;
    return this.drumset;
  }

  reverse() {
    this.drumset.name = this.fromName;
    return this.drumset;
  }
}

export class RenameInstrumentCommand extends Command<Instrument> {
  instrument: Instrument;
  fromName: string;
  toName: string;

  constructor(instrument: Instrument, name: string) {
    super('Rename Instrument');
    this.instrument = instrument;
    this.fromName = instrument.name!;
    this.toName = name;
  }

  markDirty(): void {
    this.instrument.markDirty();
  }

  execute() {
    this.instrument.name = this.toName;
    return this.instrument;
  }

  reverse() {
    this.instrument.name = this.fromName;
    return this.instrument;
  }
}

export class AddSamplesToInstrumentCommand extends Command<Instrument> {
  instrument: Instrument;
  velocities: Velocity[];

  constructor(instrument: Instrument, velocities: Velocity[]) {
    super('Add samples to instrument');
    this.instrument = instrument;
    this.velocities = velocities;
  }

  markDirty(): void {
    this.instrument.markDirty();
  }

  execute() {
    this.instrument.addVelocities(this.velocities);
    return this.instrument;
  }

  reverse() {
    this.instrument.removeVelocities(this.velocities);
    return this.instrument;
  }
}

export class RemoveSampleFromInstrumentCommand extends Command<Velocity> {
  instrument: Instrument;
  sample: Velocity;
  samples: Velocity[] = [];
  samplesVelocity: number = -1;

  constructor(instrument: Instrument, sample: Velocity) {
    super('Remove sample from instrument');
    this.instrument = instrument;
    this.sample = sample;
  }

  markDirty(): void {
    this.instrument.markDirty();
  }

  execute() {
    this.instrument.removeVelocities([this.sample]);
    if (this.instrument.velocities[0]?.vel ?? 0 !== 0) {
      this.samplesVelocity = this.instrument.velocities[0]!.vel;
      if (this.samplesVelocity > 0) {
        this.samples = this.instrument.velocities.filter(
          (v) => v.vel === this.samplesVelocity,
        );
        this.samples.forEach((sample) => {
          sample.vel = 0;
        });
      }
    }
    return this.sample;
  }

  reverse() {
    this.instrument.addVelocities([this.sample]);
    this.samples.forEach((sample) => {
      sample.vel = this.samplesVelocity;
    });
    return this.sample;
  }
}
export class RenameVelocityCommand extends Command<Velocity> {
  velocity: Velocity;
  instrument: Instrument;
  fromName: string;
  toName: string;

  constructor(velocity: Velocity, instrument: Instrument, name: string) {
    super('Rename Velocity');
    this.velocity = velocity;
    this.instrument = instrument;
    this.fromName = velocity.name!;
    this.toName = name;
  }

  markDirty(): void {
    this.instrument.markDirty();
  }

  execute() {
    this.velocity.fileName = this.toName;
    return this.velocity;
  }

  reverse() {
    this.velocity.fileName = this.fromName;
    return this.velocity;
  }
}
export class UpdateSampleVelocityCommand extends Command<Velocity> {
  instrument: Instrument;
  sample: Velocity;
  samples: Velocity[] = [];
  samplesVelocity: number = -1;
  fromVelocity: number;
  toVelocity: number;

  constructor(
    instrument: Instrument,
    sample: Velocity,
    toVelocity: number,
    fromVelocity?: number,
  ) {
    super('Update sample velocity');
    this.instrument = instrument;
    this.sample = sample;
    this.fromVelocity = fromVelocity ?? sample.vel;
    this.toVelocity = toVelocity;
  }

  markDirty(): void {
    this.instrument.markDirty();
  }

  execute() {
    this.sample.vel = this.toVelocity;
    this.instrument.sortVelocities();
    if (this.fromVelocity === 0) {
      if (this.instrument.velocities[0]!.vel !== 0) {
        this.samplesVelocity = this.instrument.velocities[0]!.vel;
        if (this.samplesVelocity > 0) {
          this.samples = this.instrument.velocities.filter(
            (v) => v.vel === this.samplesVelocity,
          );
          this.samples.forEach((sample) => {
            sample.vel = 0;
          });
        }
      }
    }
    return this.sample;
  }

  reverse() {
    this.samples.forEach((sample) => {
      sample.vel = this.samplesVelocity;
    });
    this.sample.vel = this.fromVelocity;
    this.instrument.sortVelocities();
    return this.sample;
  }
}

export class CreateDrumsetCommand extends Command<DrumsetFile> {
  project: ProjectManagerService;
  drumset?: DrumsetFile;

  constructor(project: ProjectManagerService) {
    super('Add New Drum Set');
    this.project = project;
  }

  markDirty(): void {
    this.project.drumsetsDirty = true;
  }

  async execute() {
    if (this.drumset) {
      this.project.drumsets = [...this.project.drumsets, this.drumset];
    } else {
      this.drumset = await this.project.createDrumset();
    }
    return this.drumset;
  }

  async reverse() {
    if (!this.drumset) {
      throw new Error('Drumset not found');
    }
    this.project.drumsets = this.project.drumsets.filter(
      (d) => d !== this.drumset,
    );
    return this.drumset;
  }
}

export class AddNewInstrumentToDrumsetCommand extends Command<Instrument> {
  drumset: Drumset;
  instrumentId: number;
  instrumentName?: string;
  oldInstrument: Instrument;
  instrument: Instrument;

  constructor(
    drumset: Drumset,
    instrumentName?: string,
    instrumentId?: number,
  ) {
    super('Add New Instrument to Drumset');
    this.drumset = drumset;
    this.instrumentId =
      instrumentId ??
      drumset.instruments.findIndex((i) => i.velocities.length === 0) ??
      0;
    this.instrumentName = instrumentName;
    this.oldInstrument = drumset.instruments[this.instrumentId]!;
    this.instrument = new Instrument(
      this.instrumentId,
      instrumentName ?? 'New Instrument',
    );
    this.instrument.addVelocities([new Velocity(0)]);
  }

  markDirty(): void {
    this.drumset.markDirty();
  }

  execute() {
    this.drumset.instruments = this.drumset.instruments.toSpliced(
      this.instrumentId,
      1,
      this.instrument,
    );
    return this.instrument;
  }

  reverse() {
    this.drumset.instruments = this.drumset.instruments.toSpliced(
      this.instrumentId,
      1,
      this.oldInstrument,
    );
    return this.instrument;
  }
}

export class AddInstrumentToDrumsetCommand extends Command<Instrument> {
  drumset: Drumset;
  oldInstrument: Instrument;
  newInstrument: Instrument;

  constructor(drumset: Drumset, newInstrument: Instrument) {
    super('Add Instrument to Drumset');
    this.drumset = drumset;
    this.newInstrument = newInstrument;
    this.oldInstrument = drumset.instruments[this.newInstrument.id]!;
  }

  markDirty(): void {
    this.drumset.markDirty();
  }

  execute() {
    this.drumset.instruments = this.drumset.instruments.toSpliced(
      this.newInstrument.id,
      1,
      this.newInstrument,
    );
    return this.newInstrument;
  }

  reverse() {
    this.drumset.instruments = this.drumset.instruments.toSpliced(
      this.oldInstrument.id,
      1,
      this.oldInstrument,
    );
    return this.newInstrument;
  }
}

export class UpdateInstrumentIdCommand extends Command<IInstrument> {
  drumset: Drumset;
  instrument: IInstrument;
  fromId: number;
  toId: number;

  constructor(drumset: Drumset, instrument: IInstrument, toId: number) {
    super('Update Instrument Midi ID');
    this.drumset = drumset;
    this.instrument = instrument;
    this.fromId = instrument.id;
    this.toId = toId;
  }

  markDirty(): void {
    this.drumset.markDirty();
  }

  execute() {
    this.swapInstruments(this.fromId, this.toId);
    return this.instrument;
  }

  reverse() {
    this.swapInstruments(this.toId, this.fromId);
    return this.instrument;
  }

  swapInstruments(fromId: number, toId: number) {
    // Swap instruments
    const instruments = this.drumset.instruments;
    const fromInstrument = this.drumset.instruments[fromId]!;
    const toInstrument = this.drumset.instruments[toId]!;
    instruments[fromId] = toInstrument;
    instruments[toId] = fromInstrument;
    toInstrument.id = fromId;
    fromInstrument.id = toId;
    // We need to write it back for tracking purposes
    this.drumset.instruments = instruments;
  }
}

export class DeleteInstrumentCommand extends Command<Instrument> {
  drumset: Drumset;
  instrument: Instrument;

  constructor(drumset: Drumset, instrument: Instrument) {
    super('Delete Instrument');
    this.drumset = drumset;
    this.instrument = instrument;
  }

  markDirty(): void {
    this.drumset.markDirty();
  }

  execute() {
    this.drumset.instruments = this.drumset.instruments.toSpliced(
      this.instrument.id,
      1,
      new Instrument(this.instrument.id, ''),
    );
    return this.instrument;
  }

  reverse() {
    this.drumset.instruments = this.drumset.instruments.toSpliced(
      this.instrument.id,
      1,
      this.instrument,
    );
    return this.instrument;
  }
}

export class UpdateInstrumentVolumeCommand extends Command<IInstrument> {
  instrument: IInstrument;
  fromVolume: number;
  toVolume: number;

  constructor(instrument: IInstrument, toVolume: number, fromVolume?: number) {
    super('Update Instrument Volume');
    this.instrument = instrument;
    this.fromVolume = fromVolume ?? instrument.volume;
    this.toVolume = toVolume;
  }

  markDirty(): void {
    this.instrument.markDirty();
  }

  execute() {
    this.instrument.volume = this.toVolume;
    return this.instrument;
  }

  reverse() {
    this.instrument.volume = this.fromVolume;
    return this.instrument;
  }
}

export class UpdateInstrumentPolyphonyCommand extends Command<IInstrument> {
  instrument: IInstrument;
  fromPoly: number;
  toPoly: number;

  constructor(instrument: IInstrument, toPoly: number, fromPoly?: number) {
    super('Update Instrument Polyphony');
    this.instrument = instrument;
    this.fromPoly = fromPoly ?? instrument.poly;
    this.toPoly = toPoly;
  }

  markDirty(): void {
    this.instrument.markDirty();
  }

  execute() {
    this.instrument.poly = this.toPoly;
    return this.instrument;
  }

  reverse() {
    this.instrument.poly = this.fromPoly;
    return this.instrument;
  }
}

export class UpdateInstrumentChokeGroupCommand extends Command<IInstrument> {
  instrument: IInstrument;
  fromChokeGroup: number;
  toChokeGroup: number;

  constructor(
    instrument: IInstrument,
    toChokeGroup: number,
    fromChokeGroup?: number,
  ) {
    super('Update Instrument Choke Group');
    this.instrument = instrument;
    this.fromChokeGroup = fromChokeGroup ?? instrument.chokeGroup;
    this.toChokeGroup = toChokeGroup;
  }

  markDirty(): void {
    this.instrument.markDirty();
  }

  execute() {
    this.instrument.chokeGroup = this.toChokeGroup;
    return this.instrument;
  }

  reverse() {
    this.instrument.chokeGroup = this.fromChokeGroup;
    return this.instrument;
  }
}

export class UpdateInstrumentFillChokeGroupCommand extends Command<IInstrument> {
  instrument: IInstrument;
  fromFillChokeGroup: number;
  toFillChokeGroup: number;

  constructor(
    instrument: IInstrument,
    toFillChokeGroup: number,
    fromFillChokeGroup?: number,
  ) {
    super('Update Instrument Fill Choke Group');
    this.instrument = instrument;
    this.fromFillChokeGroup = fromFillChokeGroup ?? instrument.fillChokeGroup;
    this.toFillChokeGroup = toFillChokeGroup;
  }

  markDirty(): void {
    this.instrument.markDirty();
  }

  execute() {
    this.instrument.fillChokeGroup = this.toFillChokeGroup;
    return this.instrument;
  }

  reverse() {
    this.instrument.fillChokeGroup = this.fromFillChokeGroup;
    return this.instrument;
  }
}

export class UpdateInstrumentFillChokeDelayCommand extends Command<IInstrument> {
  instrument: IInstrument;
  fromFillChokeDelay: number;
  toFillChokeDelay: number;

  constructor(
    instrument: IInstrument,
    toFillChokeDelay: number,
    fromFillChokeDelay?: number,
  ) {
    super('Update Instrument Fill Choke Delay');
    this.instrument = instrument;
    this.fromFillChokeDelay = fromFillChokeDelay ?? instrument.fillChokeDelay;
    this.toFillChokeDelay = toFillChokeDelay;
  }

  markDirty(): void {
    this.instrument.markDirty();
  }

  execute() {
    this.instrument.fillChokeDelay = this.toFillChokeDelay;
    return this.instrument;
  }

  reverse() {
    this.instrument.fillChokeDelay = this.fromFillChokeDelay;
    return this.instrument;
  }
}

export class UpdateInstrumentNonPercussionCommand extends Command<IInstrument> {
  instrument: IInstrument;
  nonPercussion: number;

  constructor(instrument: IInstrument, nonPercussion: number) {
    super('Update Instrument Non-Percussion');
    this.instrument = instrument;
    this.nonPercussion = nonPercussion;
  }

  markDirty(): void {
    this.instrument.markDirty();
  }

  execute() {
    this.instrument.nonPercussion = this.nonPercussion ? 1 : 0;
    return this.instrument;
  }

  reverse() {
    this.instrument.nonPercussion = this.nonPercussion ? 0 : 1;
    return this.instrument;
  }
}

export class DeleteDrumsetCommand extends Command<DrumsetFile> {
  projectManager: ProjectManagerService;
  drumset: DrumsetFile;
  index: number;

  constructor(projectManager: ProjectManagerService, drumset: DrumsetFile) {
    super('Delete Drumset');
    this.projectManager = projectManager;
    this.index = projectManager.drumsets.findIndex((d) => d === drumset);
    this.drumset = drumset;
  }

  markDirty(): void {
    this.drumset.markDirty();
    this.projectManager.drumsetsDirty = true;
  }

  async execute() {
    this.projectManager.drumsets = this.projectManager.drumsets.filter(
      (d) => d !== this.drumset,
    );
    if (this.projectManager.currentDrumset === this.drumset) {
      this.projectManager.currentDrumset = undefined;
    }
    return this.drumset;
  }

  async reverse() {
    this.projectManager.addDrumset(this.drumset, this.index);
    this.drumset.hash = "";
    return this.drumset;
  }
}

export class DuplicateDrumsetCommand extends Command<DrumsetFile> {
  projectManager: ProjectManagerService;
  drumset: DrumsetFile;
  newDrumset?: DrumsetFile;

  constructor(projectManager: ProjectManagerService, drumset: DrumsetFile) {
    super('Duplicate Drumset');
    this.projectManager = projectManager;
    this.drumset = drumset;
  }

  markDirty(): void {
    this.projectManager.drumsetsDirty = true;
    this.newDrumset?.markDirty();
  }

  async execute() {
    this.newDrumset =
      this.newDrumset ?? (await this.projectManager.copyDrumset(this.drumset));
    this.projectManager.drumsets = [
      ...this.projectManager.drumsets,
      this.newDrumset,
    ];
    return this.newDrumset;
  }

  async reverse() {
    this.projectManager.drumsets = this.projectManager.drumsets.filter(
      (d) => d !== this.newDrumset,
    );
    return this.drumset;
  }
}

export class UpdateDrumsetVolumeCommand extends Command<Drumset> {
  drumset: Drumset;
  fromVolume: number;
  toVolume: number;

  constructor(drumset: Drumset, toVolume: number, fromVolume?: number) {
    super('Update Drumset Volume');
    this.drumset = drumset;
    this.fromVolume = fromVolume ?? drumset.volume;
    this.toVolume = toVolume;
  }

  markDirty(): void {
    this.drumset.markDirty();
  }

  execute() {
    this.drumset.volume = this.toVolume;
    return this.drumset;
  }

  reverse() {
    this.drumset.volume = this.fromVolume;
    return this.drumset;
  }
}
