import { modifier } from 'ember-modifier';

export interface ScrollIntoViewSignature {
  Element: HTMLElement;
  Args: {
    Positional: [];
    Named: {
      when?: boolean;
      opts?: ScrollIntoViewOptions;
    };
  };
}

const scrollIntoView = modifier<ScrollIntoViewSignature>(
  function scrollIntoView(
    element: HTMLElement,
    _,
    { when: shouldScrollIntoView = true, opts },
  ) {
    if (shouldScrollIntoView) {
      element.scrollIntoView(opts);
    }
  },
);

export default scrollIntoView;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'scroll-into-view': typeof scrollIntoView;
    scrollIntoView: typeof scrollIntoView;
  }
}
