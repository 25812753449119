import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type ProjectManagerService from 'editor/services/project-manager';

export default class RestrictedIndexRoute extends Route {
  @service declare projectManager: ProjectManagerService;
  @service declare router: RouterService;

  afterModel() {
    if (this.projectManager.currentProject) {
      this.router.transitionTo('restricted.songs');
    }
  }
}
