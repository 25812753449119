import { template } from "@ember/template-compiler";
import { array, concat, fn } from '@ember/helper';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { debounce } from '@ember/runloop';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import EditableField from 'editor/components/editable-field';
import Part from 'editor/components/song-editor/part';
import TrackEditor from 'editor/components/track-editor';
import add from 'editor/helpers/add';
import eq from 'editor/helpers/eq';
import not from 'editor/helpers/not';
import pick from 'editor/helpers/pick';
import { CompositeCommand } from 'editor/models/command';
import Drumset from 'editor/models/drumset';
import Midi from 'editor/models/midi';
import { SectionType } from 'editor/models/song';
import Song, { MidiEvent, PartType, SongPart, SongTrack, normalizeMidiEvents } from 'editor/models/song';
import { AddPartCommand, AddTrackCommand, RemovePartCommand, RemoveTrackCommand, RenameSongCommand, RenameTrackCommand, ReplaceTrackCommand, SortPartsCommand, UnlinkTrackCommand, UpdateDrumsetCommand, UpdateTempoCommand } from 'editor/models/song-commands';
import disabled from 'editor/modifiers/disabled';
import onKey from 'editor/modifiers/on-key';
import selected from 'editor/modifiers/selected';
import sortable from 'editor/modifiers/sortable';
import type PasteboardService from 'editor/services/pasteboard';
import ProjectManagerService from 'editor/services/project-manager';
import SongPlayerService, { type SelectedTrack } from 'editor/services/song-player';
import UndoManagerService from 'editor/services/undo-manager';
import { ChokeGroup } from 'editor/services/virtual-pedal';
import nameFilter from 'editor/utils/name-filter';
import playInstrument from 'editor/utils/play-instrument';
import wait from 'editor/utils/wait';
import { modifier } from 'ember-modifier';
import Button from './button';
const manualResize = modifier(($element1: HTMLElement, [song1, drumset1, selectedTrack1, isTrackMaximised1]: [Song | undefined, Drumset | undefined, SelectedTrack | undefined, boolean], { resizable: resizable1, handle: handle1 }: {
    resizable: string[];
    handle: string;
})=>{
    let dragOffsetY1 = 0, availableHeight1 = 0, beforeHandle1: HTMLElement[] = [], afterHandle1: HTMLElement[] = [];
    const cancelDragNDrop1 = (e1: DragEvent)=>{
        e1.preventDefault();
    };
    const onMouseMove1 = (ev1: MouseEvent)=>{
        ev1.preventDefault();
        let y1 = ev1.clientY - dragOffsetY1;
        beforeHandle1.forEach((el1)=>{
            el1.style.height = `${parseInt(el1.style.height) + y1}px`;
        });
        afterHandle1.forEach((el1)=>{
            el1.style.height = `${parseInt(el1.style.height) - y1}px`;
        });
        dragOffsetY1 = ev1.clientY;
    };
    const onPointerUp1 = (ev1: PointerEvent)=>{
        document.removeEventListener('pointerup', onPointerUp1);
        $handle1.releasePointerCapture(ev1.pointerId);
    };
    const onMouseUp1 = ()=>{
        document.removeEventListener('mouseup', onMouseUp1);
        document.removeEventListener('mousemove', onMouseMove1);
        dragOffsetY1 = 0;
    };
    const onPointerDown1 = (ev1: PointerEvent)=>{
        document.addEventListener('pointerup', onPointerUp1);
        $handle1.setPointerCapture(ev1.pointerId);
    };
    const onMouseDown1 = (ev1: MouseEvent)=>{
        dragOffsetY1 = ev1.clientY;
        document.addEventListener('mousemove', onMouseMove1);
        document.addEventListener('mouseup', onMouseUp1);
    };
    const children1: HTMLElement[] = <HTMLElement[]>(Array.from($element1.childNodes).filter((n1)=>n1.nodeType === 1));
    const $handle1 = children1.find((n1)=>n1.matches(handle1)) as HTMLElement;
    const resizableElements1 = children1.filter((n1)=>resizable1.some((r1)=>n1.matches(r1)));
    const fixedSizeELements1 = children1.filter((n1)=>!resizableElements1.includes(n1));
    beforeHandle1 = resizableElements1.filter((el1)=>el1.offsetTop < $handle1.offsetTop);
    afterHandle1 = resizableElements1.filter((el1)=>el1.offsetTop > $handle1.offsetTop);
    const setup1 = ()=>{
        if (isTrackMaximised1) {
            resizableElements1.forEach((el1)=>{
                el1.style.height = '';
            });
            return;
        }
        const fixedHeight1 = fixedSizeELements1.reduce((acc1, el1)=>acc1 + el1.clientHeight, 0);
        let flexibleHeight1 = resizableElements1.reduce((acc1, el1)=>{
            return acc1 + el1.clientHeight;
        }, 0);
        availableHeight1 = $element1.clientHeight - fixedHeight1;
        resizableElements1.forEach((el1)=>{
            if (el1.clientHeight <= availableHeight1 / 2) {
                el1.style.height = `${el1.clientHeight}px`;
                availableHeight1 -= el1.clientHeight;
                flexibleHeight1 -= el1.clientHeight;
            }
        });
        resizableElements1.forEach((el1)=>{
            if (el1.style.height === '') {
                el1.style.height = `${(el1.clientHeight / flexibleHeight1) * availableHeight1}px`;
            }
        });
        //Are there now scrollbars?
        if ($element1.clientHeight < $element1.scrollHeight) {
            const scrollbarHeight1 = $element1.scrollHeight - $element1.clientHeight;
            resizableElements1.forEach((el1)=>{
                el1.style.height = `${el1.clientHeight - scrollbarHeight1 / resizableElements1.length}px`;
            });
        }
        // A timeout is necessary to make sure the resizing has settled before scrolling the selected part into view
        setTimeout(()=>{
            $element1.querySelector('.parts .selected')?.scrollIntoView({
                block: 'center'
            });
        }, 10);
    };
    setup1();
    // I'm dispatching this event in order to make sure that the modifier is "using"
    //  the song argument so it will be re-run when the song changes
    $element1.dispatchEvent(new CustomEvent('manualResize', {
        detail: {
            song: song1,
            drumset: drumset1,
            selectedTrack: selectedTrack1
        }
    }));
    $handle1.addEventListener('mousedown', onMouseDown1);
    $handle1.addEventListener('pointerdown', onPointerDown1);
    $handle1.addEventListener('dragstart', cancelDragNDrop1);
    window.addEventListener('resize', setup1);
    return ()=>{
        window.removeEventListener('resize', setup1);
        $handle1.removeEventListener('mousedown', onMouseDown1);
        $handle1.removeEventListener('pointerdown', onPointerDown1);
        $handle1.removeEventListener('dragstart', cancelDragNDrop1);
    };
});
interface SongEditorSignature {
    Element: HTMLDivElement;
    Args: {
        song: Song;
        saveSong: () => void;
    };
    Blocks: {
    };
}
export default class SongEditorComponent extends Component<SongEditorSignature> {
    @service
    projectManager: ProjectManagerService;
    @service
    songPlayer: SongPlayerService;
    @service
    undoManager: UndoManagerService;
    @service
    pasteboard: PasteboardService;
    tapTempoBuffer: number[] = Array(4).fill(0);
    @tracked
    isTrackMaximized = false;
    constructor(owner1: unknown, args1: SongEditorSignature['Args']){
        super(owner1, args1);
    }
    get song() {
        return this.args.song;
    }
    @action
    renameSong(name1: string) {
        this.undoManager.executeCommand(new RenameSongCommand(this.song, name1));
    }
    get drumsets() {
        return this.projectManager.drumsets.toSorted((d11, d21)=>d11.name.localeCompare(d21.name));
    }
    get drumsetFile() {
        return this.projectManager.findDrumset(this.song.drumset);
    }
    get drumset() {
        return this.drumsetFile.drumset!;
    }
    @action
    async setDrumset(ev1: Event) {
        const drumsetPath1 = (<HTMLInputElement>ev1.target).value;
        const drumset1 = this.projectManager.findDrumset(drumsetPath1);
        if (drumset1) {
            await drumset1.parse();
            this.undoManager.executeCommand(new UpdateDrumsetCommand(this.song, this.drumsetFile, drumset1));
        }
    }
    setTempo: (() => void) | undefined;
    @action
    updateTempo(bpm1: number, debounceTimeout1: number = 500) {
        if (!this.setTempo) {
            // create a debouncable function
            this.setTempo = (()=>{
                // capture the original bpm
                const originalBpm1 = this.song.bpm;
                return ()=>{
                    this.undoManager.executeCommand(new UpdateTempoCommand(this.song, originalBpm1, this.song.bpm));
                    this.setTempo = undefined;
                    this.tapTempoBuffer = Array(4).fill(0);
                };
            })();
        }
        // update the bpm live
        this.song.bpm = bpm1;
        // Debounce the undo command to avoid spamming the undo stack
        // eslint-disable-next-line ember/no-runloop
        debounce(this, this.setTempo, debounceTimeout1);
    }
    @action
    tapTempo(ev1: MouseEvent) {
        ev1.preventDefault();
        const now1 = performance.now();
        const last1 = this.tapTempoBuffer.shift()!;
        this.tapTempoBuffer.push(now1);
        if (last1 > 0) {
            const elapsed1 = now1 - last1;
            const bpm1 = Math.round((this.tapTempoBuffer.length / elapsed1) * 60000);
            this.updateTempo(bpm1, 1600);
        }
    }
    get tracks() {
        return this.args.song.tracks;
    }
    get selectedTrack(): SelectedTrack | undefined {
        return this.songPlayer.selectedTrack;
    }
    get loop() {
        return this.songPlayer.loop;
    }
    @action
    toggleLoop() {
        this.songPlayer.loop = !this.loop;
    }
    get trackPosition() {
        return this.songPlayer.trackPosition;
    }
    @action
    setTrackPosition(position1: number) {
        this.songPlayer.trackPosition = position1;
    }
    @action
    play(fromTheBeginning1: boolean = false) {
        if (fromTheBeginning1) {
            this.songPlayer.playFrom(0);
        } else {
            this.songPlayer.playFrom(this.trackPosition - (this.selectedTrack?.trigPos ?? 0));
        }
    }
    @action
    stop() {
        this.songPlayer.stop();
    }
    @action
    togglePlay(fromTheBeginning1: boolean = false) {
        if (this.songPlayer.isPlaying) {
            this.stop();
        } else {
            this.play(fromTheBeginning1);
        }
    }
    get canAddPart() {
        return this.song.parts.length < 32;
    }
    @action
    addPart(part1?: SongPart) {
        if (this.canAddPart) {
            this.undoManager.executeCommand(new AddPartCommand(this.args.song, part1));
        }
    }
    @action
    removePart(part1: SongPart) {
        this.undoManager.executeCommand(new RemovePartCommand(this.args.song, part1));
        if (part1.tracks.includes(this.songPlayer.track!)) {
            this.songPlayer.stop();
            this.songPlayer.findTrack(this.args.song);
        }
    }
    @action
    pastePart(part1: SongPart) {
        const copiedPart1 = this.pasteboard.retrieve('part');
        if (copiedPart1) {
            this.undoManager.executeCommand(new AddPartCommand(this.args.song, part1, copiedPart1));
        }
    }
    @action
    duplicatePart(part1: SongPart) {
        this.undoManager.executeCommand(new AddPartCommand(this.args.song, part1, part1));
    }
    @action
    selectTrack(part1: SongPart, sectionType1: SectionType, index1: number) {
        this.songPlayer.selectTrack(part1, sectionType1, index1);
    }
    @action
    async addTrack(part1: SongPart, sectionType1: SectionType) {
        const opts1 = {
            timeSignatureNumerator: part1.timeSignatureNumerator,
            timeSignatureDenominator: part1.timeSignatureDenominator
        };
        await this.undoManager.executeCommand(new AddTrackCommand(part1, sectionType1, opts1));
        this.songPlayer.stop();
        this.songPlayer.selectTrack(part1, sectionType1, part1.fills.length - 1);
    }
    @action
    copyTrack() {
        if (this.songPlayer.selectedTrack) {
            this.pasteboard.store('track', [
                this.song,
                this.songPlayer.selectedTrack
            ]);
        }
    }
    get hasCopiedTrack() {
        return this.pasteboard.hasCopy('track');
    }
    @action
    pasteTrack() {
        if (this.songPlayer.selectedTrack) {
            const data1 = <[Song, SelectedTrack] | undefined>this.pasteboard.retrieve('track');
            if (data1 && this.selectedTrack) {
                const [song1, copiedTrack1] = data1;
                const { part: part1, sectionType: sectionType1, sectionIndex: sectionIndex1 } = this.songPlayer.selectedTrack;
                // If we’re pasting into the same song, then we use the same track and they remain linked.
                // If we’re pasting into a different song, then we copy the track so that edits aren’t shared across two songs (BUG)
                const pastedTrack1 = song1 === this.song ? copiedTrack1.track : copiedTrack1.track.copy();
                this.undoManager.executeCommand(new CompositeCommand('Paste track', [
                    new ReplaceTrackCommand(part1, sectionType1, sectionIndex1, pastedTrack1)
                ]));
            }
        }
    }
    get isLinkedTrack() {
        if (!this.selectedTrack) {
            return false;
        }
        const { track: track1 } = this.selectedTrack;
        return (this.song.allParts.flatMap((part1)=>part1.tracks).filter((t1)=>t1 === track1).length > 1);
    }
    @action
    unlinkTrack() {
        if (this.songPlayer.selectedTrack) {
            const { part: part1, sectionType: sectionType1, sectionIndex: sectionIndex1 } = this.songPlayer.selectedTrack;
            this.undoManager.executeCommand(new UnlinkTrackCommand(part1, sectionType1, sectionIndex1));
        }
    }
    @action
    async importMidiFile(part1?: SongPart, sectionType1?: SectionType, sectionIndex1?: number, files1?: FileList) {
        if (!files1 && !this.selectedTrack) {
            return;
        }
        let selectPart1 = false;
        if (!(part1 instanceof SongPart)) {
            part1 = this.selectedTrack!.part;
            sectionType1 = this.selectedTrack!.sectionType;
            sectionIndex1 = this.selectedTrack!.sectionIndex;
            selectPart1 = true;
        }
        // eslint-disable-next-line no-undef
        let handle1: FileSystemFileHandle;
        if (!files1) {
            try {
                [handle1] = await window.showOpenFilePicker({
                    id: 'midi',
                    startIn: 'documents',
                    multiple: false,
                    types: [
                        {
                            description: 'MIDI file',
                            accept: {
                                'audio/midi': [
                                    '.mid',
                                    '.midi'
                                ]
                            }
                        }
                    ]
                });
            } catch (err1: any) {
                if (err1.name === 'AbortError' && /user aborted/i.test(err1.message)) {
                    return;
                }
                throw err1;
            }
            try {
                await wait(async ()=>{
                    const file1 = await handle1.getFile();
                    this.importMidiHelper(file1, part1, sectionType1, sectionIndex1, selectPart1);
                });
            } catch (err1) {
                if (err1 instanceof DOMException) {
                    console.error('DOMException', {
                        name: err1.name,
                        message: err1.message
                    });
                    alert('Unable to import MIDI track\n' + err1.message);
                } else {
                    console.error(err1);
                    alert('Unable to import MIDI track');
                }
            }
        } else {
            this.importMidiFiles(part1, sectionType1, sectionIndex1, files1);
        }
    }
    @action
    async importMidiFiles(part1?: SongPart, sectionType1?: SectionType, sectionIndex1?: number, files1?: FileList) {
        let selectPart1 = false;
        if (!(part1 instanceof SongPart)) {
            part1 = this.selectedTrack!.part;
            sectionType1 = this.selectedTrack!.sectionType;
            sectionIndex1 = this.selectedTrack!.sectionIndex;
            selectPart1 = true;
        }
        // eslint-disable-next-line no-undef
        let handles1: FileSystemFileHandle[];
        if (!files1 || files1.length === 0) {
            // If we are opening a dialog to select file(s), we don't have the file(s) yet
            try {
                handles1 = await window.showOpenFilePicker({
                    id: 'midi',
                    startIn: 'documents',
                    multiple: true,
                    types: [
                        {
                            description: 'MIDI file',
                            accept: {
                                'audio/midi': [
                                    '.mid',
                                    '.midi'
                                ]
                            }
                        }
                    ]
                });
            } catch (err1: any) {
                if (err1.name === 'AbortError' && /user aborted/i.test(err1.message)) {
                    return;
                }
                throw err1;
            }
            if (handles1.length + sectionIndex1 > 8) {
                alert('Only 8 fills are supported');
                return;
            }
            await Promise.all(handles1.map(async (handle1)=>{
                const file1 = await handle1.getFile();
                this.importMidiHelper(file1, part1, sectionType1, sectionIndex1, selectPart1);
                sectionIndex1++;
            }));
        } else {
            if (files1.length + sectionIndex1 > 8) {
                alert('Only 8 fills are supported');
                return;
            }
            // If we are dragging and dropping file(s), we already have the file(s)
            try {
                await wait(async ()=>{
                    for(var i1 = 0; i1 < files1.length; i1++){
                        const file1 = files1?.[i1];
                        this.importMidiHelper(file1, part1, sectionType1, sectionIndex1, selectPart1);
                        sectionIndex1++;
                    }
                });
            } catch (err1) {
                if (err1 instanceof DOMException) {
                    console.error('DOMException', {
                        name: err1.name,
                        message: err1.message
                    });
                    alert('Unable to import MIDI track\n' + err1.message);
                } else {
                    console.error(err1);
                    alert('Unable to import MIDI track');
                }
            }
        }
    }
    @action
    async importMidiHelper(file1: File, part1?: SongPart, sectionType1?: SectionType, sectionIndex1?: number, selectPart1?: boolean) {
        if (file1.size === 0) {
            alert('Unable to read MIDI file. No data');
            return;
        }
        const buffer1 = await file1.arrayBuffer();
        let midi1: Midi;
        try {
            midi1 = Midi.parse(buffer1);
        } catch (err1) {
            alert('Unable to read MIDI file');
            return;
        }
        if (midi1.header.type === 2) {
            alert('MIDI type 2 not supported');
            return;
        }
        const midiTrack1 = midi1.tracks.find((track1)=>track1.tickCount > 0);
        if (midiTrack1) {
            const midiEvents1 = midi1.tracks.filter((t1)=>t1.tickCount > 0).flatMap((t1)=>t1.events).filter((e1: any)=>e1.type === 'noteOn' || e1.type === 'noteOff').map((e1: any)=>{
                const { type: type1, note: note1, velocity: velocity1, tick: tick1 } = e1;
                return new MidiEvent(tick1, type1 === 'noteOn' ? 0 : 1, note1, velocity1);
            });
            const newTrack1 = new SongTrack('Imported Track');
            newTrack1.name = midiTrack1.name ?? file1.name.replace(/\.[^.]+$/, '');
            newTrack1.info.bpm = midiTrack1.bpm;
            newTrack1.info.tickCount = midiTrack1.tickCount;
            newTrack1.info.barLength = midiTrack1.barLength;
            newTrack1.info.timeSignatureNumerator = midiTrack1.timeSignatureNumerator;
            newTrack1.info.timeSignatureDenominator = midiTrack1.timeSignatureDenominator;
            newTrack1.info.events = normalizeMidiEvents(midiEvents1, newTrack1.info);
            const command1 = new ReplaceTrackCommand(part1!, sectionType1!, sectionIndex1!, newTrack1);
            if (selectPart1) {
                command1.on('execute', ()=>{
                    this.songPlayer.selectTrack(part1!, sectionType1!, sectionIndex1!);
                });
            }
            this.undoManager.executeCommand(command1);
        }
    }
    @action
    async exportMidiFile() {
        if (!this.selectedTrack) {
            return;
        }
        const { track: track1 } = this.selectedTrack;
        // eslint-disable-next-line no-undef
        let handle1: FileSystemFileHandle;
        try {
            handle1 = await window.showSaveFilePicker({
                id: 'midi',
                startIn: 'documents',
                suggestedName: `${track1.name}.mid`,
                types: [
                    {
                        description: 'MIDI file',
                        accept: {
                            'audio/midi': [
                                '.mid'
                            ]
                        }
                    },
                    {
                        description: 'WAV file',
                        accept: {
                            'audio/wav': [
                                '.wav'
                            ]
                        }
                    }
                ]
            });
            this.renameTrack(handle1.name.replace(/\.mid$/, ''));
        } catch (err1: any) {
            if (err1.name === 'AbortError' && /user aborted/i.test(err1.message)) {
                return;
            }
            throw err1;
        }
        try {
            await wait(async ()=>{
                const fileExtension1 = handle1.name.split('.').pop();
                let fileData1: ArrayBuffer;
                switch(fileExtension1){
                    case 'mid':
                        fileData1 = track1.toMidi();
                        break;
                    case 'wav':
                        {
                            // NOTE: This, virtual pedal, and song player all use similar variations of the same code
                            // While not duplicated, there is definitely opportunity for refactoring
                            const song1 = this.songPlayer.song;
                            const drumset1 = this.songPlayer.drumset!;
                            const ticksPerSecond1 = (track1.tickToQuarter / 60) * song1.bpm;
                            const totalTime1 = track1.info.tickCount / ticksPerSecond1;
                            const chokeGroups1 = new Array(15).fill(0).map(()=>new ChokeGroup());
                            const audioContext1 = new OfflineAudioContext({
                                numberOfChannels: 2,
                                length: Math.ceil(totalTime1 * 44100),
                                sampleRate: 44100
                            });
                            const drumsetVolume1 = new GainNode(audioContext1, {
                                gain: 1
                            });
                            drumsetVolume1.connect(audioContext1.destination);
                            const drumsetGain1 = drumset1.volume / 100;
                            drumsetVolume1.gain.setValueAtTime(drumsetGain1, 0);
                            track1.events.map((event1)=>{
                                const onWhen1 = event1.tick / ticksPerSecond1;
                                const offWhen1 = onWhen1 + event1.length / ticksPerSecond1;
                                const instrument1 = drumset1.instruments[event1.note];
                                if (instrument1) {
                                    if (instrument1.chokeGroup > 0) {
                                        const chokeGroup1 = chokeGroups1[instrument1.chokeGroup];
                                        chokeGroup1?.choke(onWhen1);
                                    }
                                    const note1 = playInstrument(audioContext1, drumsetVolume1, instrument1, onWhen1, offWhen1, event1.velocity);
                                    if (note1) {
                                        if (instrument1.chokeGroup > 0) {
                                            chokeGroups1[instrument1.chokeGroup]?.add(note1);
                                        }
                                    }
                                }
                            });
                            const audioBuffer1 = await audioContext1.startRendering();
                            fileData1 = Drumset.saveWaveFile(audioBuffer1);
                        }
                        break;
                    default:
                        alert('Unsupported file type');
                        return;
                }
                const file1 = await handle1.createWritable();
                await file1.write(fileData1);
                await file1.close();
            });
        } catch (err1) {
            if (err1 instanceof DOMException) {
                console.error('DOMException', {
                    name: err1.name,
                    message: err1.message
                });
                alert('Unable to save MIDI track\n' + err1.message);
            } else {
                console.error(err1);
                alert('Unable to save MIDI track');
            }
        }
    }
    @action
    async removeTrack() {
        if (this.songPlayer.selectedTrack) {
            const { part: part1, sectionType: sectionType1, sectionIndex: sectionIndex1 } = this.songPlayer.selectedTrack;
            await this.undoManager.executeCommand(new RemoveTrackCommand(part1, sectionType1, sectionIndex1));
            this.songPlayer.stop();
            this.songPlayer.findTrack(this.songPlayer.song);
        }
    }
    @action
    renameTrack(name1: string) {
        if (this.songPlayer.selectedTrack) {
            const { part: part1, sectionType: sectionType1, sectionIndex: sectionIndex1 } = this.songPlayer.selectedTrack;
            this.undoManager.executeCommand(new RenameTrackCommand(part1, sectionType1, sectionIndex1, name1));
        }
    }
    @action
    sortParts(oldIndex1: number, newIndex1: number) {
        this.undoManager.executeCommand(new SortPartsCommand(this.song, oldIndex1, newIndex1));
    }
    @action
    toggleTrackMaximized() {
        this.isTrackMaximized = !this.isTrackMaximized;
    }
    static{
        template(`
    <div
      class="h-full"
      {{onKey
        "Space"
        (fn this.togglePlay false)
        cmdKey=false
        shiftKey=false
        preventDefault=true
      }}
      {{onKey "Space" (fn this.togglePlay true) shiftKey=true preventDefault=true}}
      {{manualResize
        this.song
        this.drumset
        this.selectedTrack
        this.isTrackMaximized
        resizable=(array ".parts" ".track")
        handle=".handle"
      }}
    >
      <nav aria-label="song-menu"><div
          class="grid items-center grid-cols-[1fr_minmax(auto,_3fr)_1fr] gap-2 px-2"
        >
          <div class="flex gap-2">
          </div>
          <EditableField
            @model={{this.song}}
            @field="name"
            @onChange={{this.renameSong}}
            @filter={{nameFilter}}
            class="w-full px-2 py-1 font-semibold text-center"
          >{{this.song.name}}</EditableField>
        </div>
        <div class="flex items-center justify-start w-full gap-2 p-2"><label
            class="flex items-center gap-2"
          >Drum Set:
            <select
              class="px-2 py-1 border rounded max-w-64 dark:bg-primary-darkComponent dark:border-primary-darkBorderColor"
              {{on "change" this.setDrumset}}
            >
              {{#each this.drumsets as |drumset|}}
                <option
                  value={{drumset.path}}
                  selected={{eq this.drumsetFile.path drumset.path}}
                >{{drumset.name}}</option>
              {{/each}}
            </select></label>
          {{! template-lint-disable no-pointer-down-event-binding }}
          <label class="flex items-center gap-2 grow">
            {{! template-lint-disable no-invalid-interactive }}
            <div class="whitespace-nowrap" {{on "mousedown" this.tapTempo}}>Tempo:
              <span class="font-semibold">{{this.song.bpm}}bpm</span></div>
            <input
              type="range"
              min="40"
              max="300"
              value={{this.song.bpm}}
              class="w-full accent-primary"
              {{on "input" (pick "target.value" this.updateTempo)}}
            /></label></div>
      </nav>
      <div class="overflow-auto parts bg-gray-50 dark:bg-primary-darkFill">
        <div
          data-test="song-player"
          class="grid items-start gap-1 p-2 grid-cols-song-parts bg-gray-50 dark:bg-primary-darkFill"
          {{sortable
            draggable="[data-part-type=part]"
            handle=".drag-handle"
            group="parts"
            removeItem=true
            onChange=this.sortParts
          }}
        >
          <div class="sticky top-0 z-10 grid col-span-6 grid-cols-subgrid">
            <div class="text-xs text-center bg-gray-200 dark:bg-primary-darkHeader">Name</div>
            <div class="text-xs text-center bg-gray-200 dark:bg-primary-darkHeader">Main Beat</div>
            <div class="text-xs text-center bg-gray-200 dark:bg-primary-darkHeader">Drum Fill(s)</div>
            <div class="text-xs text-center bg-gray-200 dark:bg-primary-darkHeader">Transition</div>
            <div class="text-xs text-center bg-gray-200 dark:bg-primary-darkHeader">Accent Hit</div>
            <div class="text-xs text-center bg-gray-200 dark:bg-primary-darkHeader">Options</div>
          </div>
          <Part
            @type={{PartType.INTRO}}
            @part={{@song.intro}}
            @name="Intro"
            @addPart={{if this.canAddPart (fn this.addPart undefined)}}
            data-test-part="intro"
            @selectTrack={{fn this.selectTrack @song.intro}}
            @addTrack={{fn this.addTrack @song.intro}}
            @importMidi={{fn this.importMidiFile @song.intro}}
            @importMultiMidi={{fn this.importMidiFiles @song.intro}}
          />
          {{~#each @song.parts as |part i|~}}
            <Part
              @type={{PartType.PART}}
              @part={{part}}
              @name={{concat "Part " (add i 1)}}
              @addPart={{if this.canAddPart (fn this.addPart part)}}
              @removePart={{fn this.removePart part}}
              @pastePart={{fn this.pastePart part}}
              @duplicatePart={{fn this.duplicatePart part}}
              data-test-part={{i}}
              @selectTrack={{fn this.selectTrack part}}
              @addTrack={{fn this.addTrack part}}
              @importMidi={{fn this.importMidiFile part}}
              @importMultiMidi={{fn this.importMidiFiles part}}
            />
          {{~/each~}}
          <Part
            @type={{PartType.OUTRO}}
            @part={{@song.outro}}
            @name="Outro"
            data-test-part="outro"
            @selectTrack={{fn this.selectTrack @song.outro}}
            @addTrack={{fn this.addTrack @song.outro}}
            @importMidi={{fn this.importMidiFile @song.outro}}
            @importMultiMidi={{fn this.importMidiFiles @song.outro}}
          />
        </div>
      </div>
      <div
        class="flex justify-center bg-gray-50 dark:bg-primary-darkFill py-1"
      >
      </div>
      <div class="h-2 cursor-ns-resize handle flex justify-center items-center bg-gray-200 dark:bg-gray-700">
        <svg class="w-4 h-4 text-gray-500 dark:text-gray-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-width="2" d="M4 10h16M4 14h16" />
        </svg>
      </div>
      <div
        class="flex flex-col p-2 track
          {{if
            this.isTrackMaximized
            'fixed inset-0 z-50 bg-white dark:bg-primary-darkFill '
          }}"
      >
        {{#if this.selectedTrack}}
          <nav
            aria-label="track-menu"
            class="grid items-center grid-cols-[1fr_minmax(auto,_3fr)_1fr] gap-2"
          >
            <div class="flex gap-2">
              <Button
                @icon="play"
                title="Play"
                {{selected when=this.songPlayer.isPlaying}}
                {{on "click" (fn this.play false)}}
              />
              <Button @icon="stop" title="Stop" {{on "click" this.stop}} />
              <Button
                @icon="arrows-repeat"
                title="Loop track"
                {{selected when=this.loop}}
                {{on "click" this.toggleLoop}}
              />
            </div>
            <EditableField
              @model={{this.selectedTrack.track}}
              @field="name"
              @onChange={{this.renameTrack}}
              class="w-full px-2 py-1 font-semibold text-center"
            >{{this.selectedTrack.track.name}}</EditableField>
            <div class="flex justify-end gap-2">
              {{#if this.isTrackMaximized}}
                <img
                  src="/images/editor/singularsound-purple.png"
                  alt="BeatBuddy Manager Online Logo"
                  class="h-8"
                />
                <Button
                  @icon="arrow-rotate-left"
                  title="Undo"
                  {{disabled when=(not this.undoManager.canUndo)}}
                  {{on "click" this.undoManager.undo}}
                />
                <Button
                  @icon="arrow-rotate-right"
                  title="Redo"
                  {{disabled when=(not this.undoManager.canRedo)}}
                  {{on "click" this.undoManager.redo}}
                />
              {{/if}}
              <Button
                @icon="folder-open"
                title="Import MIDI file"
                {{on "click" this.importMidiFile}}
              />
              <Button
                @icon="floppy-disk"
                title="Save track as MIDI file"
                {{on "click" this.exportMidiFile}}
              />
              {{#if this.songPlayer.selectedTrack}}
                <Button @icon="copy" title="Copy track" {{on "click" this.copyTrack}} />
                <Button
                  @icon="paste"
                  title="Paste track"
                  {{disabled when=(not this.hasCopiedTrack)}}
                  {{on "click" this.pasteTrack}}
                />
                <Button
                  @icon="link-slash"
                  title="Unlink track"
                  {{on "click" this.unlinkTrack}}
                  {{disabled when=(not this.isLinkedTrack)}}
                />
                <Button
                  @icon="trash-can"
                  title="Delete track"
                  {{on "click" this.removeTrack}}
                />
                <Button
                  @icon={{if this.isTrackMaximized "arrows-minimize" "arrows-maximize"}}
                  title="Maximize track editor"
                  {{on "click" this.toggleTrackMaximized}}
                />
              {{/if}}
            </div>
          </nav>
          <TrackEditor
            @selectedTrack={{this.selectedTrack}}
            @drumset={{this.drumset}}
            @trackPosition={{this.trackPosition}}
            @setTrackPosition={{this.setTrackPosition}}
            @isTrackMaximized={{this.isTrackMaximized}}
          />
        {{/if}}
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        SongEditor: typeof SongEditorComponent;
    }
}
