import { set as setValue } from '@ember/object';

export default function set(target: any, path: string, value: any) {
  return () => {
    if (target.isDestroying || target.isDestroyed) {
      return;
    }
    setValue(target, <never>path, <never>value);
  };
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    set: typeof set;
  }
}
