import { tracked } from '@glimmer/tracking';

export default class Project {
  id: string;
  handle: FileSystemDirectoryHandle;
  @tracked name: string;
  @tracked version: number = 0;
  @tracked revision: number = 0;
  @tracked build: number = 0;
  @tracked folder?: string;
  @tracked song?: string;

  constructor(handle: FileSystemDirectoryHandle, name: string, id?: string) {
    this.handle = handle;
    this.name = name;
    this.id = id ?? crypto.randomUUID();
  }

  async validate() {
    const entries: FileSystemHandle[] = [];
    for await (const [, entry] of this.handle.entries()) {
      entries.push(entry);
    }
    const projectHandle = entries.find((entry) => /\.bbp/i.test(entry.name));
    if (projectHandle && projectHandle.kind === 'file') {
      const file = await (<FileSystemFileHandle>projectHandle).getFile();
      const xml = await file.text();
      return this.parseProjectFile(xml);
    }
    return false;
  }

  parseProjectFile(xml: string) {
    const isProjectXML = /<bbproject.*>/i.test(xml);
    if (isProjectXML) {
      let result = /<version.*version="([^"]+)"/i.exec(xml);
      if (result) {
        const [, version] = result;
        this.version = parseInt(version ?? '0');
      }
      result = /<version.*revision="([^"]+)"/i.exec(xml);
      if (result) {
        const [, revision] = result;
        this.revision = parseInt(revision ?? '0');
      }
      result = /<version.*build="([^"]+)"/i.exec(xml);
      if (result) {
        const [, build] = result;
        this.build = parseInt(build ?? '0');
      }
      result = /<project.*name="([^"]+)"/i.exec(xml);
      if (result) {
        const [, name] = result;
        if (name) {
          this.name = name;
        }
      }
      return true;
    }
    return false;
  }

  static fromStore(data: any) {
    const project = new Project(data.handle, data.name, data.id);
    project.folder = data.folder;
    project.song = data.song;
    return project;
  }

  toStore() {
    return {
      id: this.id,
      handle: this.handle,
      name: this.name,
      folder: this.folder,
      song: this.song,
    };
  }
}
