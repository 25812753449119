import { template } from "@ember/template-compiler";
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import { type IconName } from '@fortawesome/fontawesome-svg-core';
import Component from '@glimmer/component';
interface MenuButtonSignature {
    Element: HTMLButtonElement;
    Args: {
        icon?: IconName;
    };
    Blocks: {
        default: [];
    };
}
export default class MenuButton extends Component<MenuButtonSignature> {
    constructor(owner1: unknown, args1: MenuButtonSignature['Args']){
        super(owner1, args1);
    }
    static{
        template(`
    <button
      class="w-full px-2 py-1 text-sm text-left border-b hover:bg-gray-100 dark:border-primary-darkBorderColor dark:text-white dark:bg-primary-darkComponent dark:hover:bg-primary-darkButtonSelected dark:hover:text-black"
      ...attributes
      type="button"
    >{{#if @icon~}}
        <FaIcon @icon={{@icon}} @fixedWidth={{true}} />
      {{/if}}{{yield}}</button>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        MenuButton: typeof MenuButton;
    }
}
