import Controller from '@ember/controller';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import {
  AddPlaylistCommand,
  DeletePlaylistCommand,
  DuplicatePlaylistCommand,
  RenamePlaylistCommand,
  SortPlaylistsCommand,
} from 'editor/models/playlist-commands';
import type ProjectManagerService from 'editor/services/project-manager';
import type { Playlist } from 'editor/services/project-manager';
import type UndoManagerService from 'editor/services/undo-manager';
import nameFilter from 'editor/utils/name-filter';
import wait from 'editor/utils/wait';

export default class RestrictedPlaylistsController extends Controller {
  @service declare router: RouterService;
  @service declare projectManager: ProjectManagerService;
  @service declare undoManager: UndoManagerService;

  @tracked menuPlaylist?: Playlist;

  nameFilter = nameFilter;

  get playlists() {
    return this.projectManager.playlists;
  }

  get currentProject() {
    return this.projectManager.currentProject!;
  }

  @action
  isSelected(playlist: Playlist) {
    return playlist === this.projectManager.currentPlaylist;
  }

  @action
  selectPlaylist(playlist: Playlist) {
    this.router.transitionTo('restricted.playlists.playlist', playlist.id);
  }

  @action
  songsForPlaylist(playlist: Playlist) {
    return this.projectManager.songsForPlaylist(playlist);
  }

  @action
  createPlaylist() {
    this.undoManager.executeCommand(
      new AddPlaylistCommand(this.projectManager),
    );
  }

  @action
  sortPlaylists(oldIndex: number, newIndex: number) {
    this.undoManager.executeCommand(
      new SortPlaylistsCommand(this.projectManager, oldIndex, newIndex),
    );
  }

  @action
  renamePlaylist(playlist: Playlist, name: string) {
    this.undoManager.executeCommand(new RenamePlaylistCommand(playlist, name));
  }

  @action
  showMenuFor(playlist: Playlist | undefined, ev?: MouseEvent) {
    if (this.menuPlaylist != playlist) {
      ev?.preventDefault();
      ev?.stopPropagation();
      this.menuPlaylist = playlist;
    }
  }

  @action
  shouldShowMenuFor(playlist: Playlist) {
    return this.menuPlaylist === playlist;
  }

  @action
  deletePlaylist(playlist: Playlist) {
    const command = new DeletePlaylistCommand(this.projectManager, playlist);
    command.on('execute', () => {
      this.router.transitionTo('restricted.playlists.index');
    });
    command.on('reverse', (playlist) => {
      this.router.transitionTo('restricted.playlists.playlist', playlist.path);
    });
    this.undoManager.executeCommand(command);
  }

  @action
  async duplicatePlaylist(playlist: Playlist, ev?: MouseEvent) {
    ev?.stopPropagation();
    await wait(async () => {
      const command = new DuplicatePlaylistCommand(
        this.projectManager,
        playlist,
      );
      command.on('execute', (playlist) => {
        this.router.transitionTo('restricted.playlists.playlist', playlist.id);
      });
      command.on('reverse', (playlist) => {
        this.router.transitionTo('restricted.playlists.playlist', playlist.id);
      });
      try {
        await this.undoManager.executeCommand(command);
      } catch (err: any) {
        alert(`Error duplicating playlist`);
        throw err;
      }
    });
  }
}
