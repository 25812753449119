import { assert } from '@ember/debug';
import type Transition from '@ember/routing/transition';
import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Command, { CompositeCommand } from 'editor/models/command';
import Drumset, { Instrument } from 'editor/models/drumset';
import { AddDrumsetCommand } from 'editor/models/drumset-commands';
import { AddFolderCommand } from 'editor/models/folder-commands';
import Song from 'editor/models/song';
import {
  AddEffectCommand,
  AddSongCommand,
  DeleteSongCommand,
} from 'editor/models/song-commands';
import Crc32 from 'editor/utils/crc32';
import elementExists from 'editor/utils/element-exists';
import promiseBatch from 'editor/utils/promise-batch';
import Reader from 'editor/utils/reader';
import unique from 'editor/utils/unique';
import wait from 'editor/utils/wait';
import Writer from 'editor/utils/writer';
import { strToU8, unzipSync, zipSync, type Zippable } from 'fflate';
import { createNanoEvents, type Emitter } from 'nanoevents';
import type ProgressService from './progress';
import UndoManagerService from './undo-manager';

const MAX_PLAYLISTS = 17;
const STRIP_NUMBER_REGEX = /\d+\.\s+(.+)/;

export class ProjectEntry {
  handle: FileSystemHandle;
  @tracked _name: string;
  path: string;
  changed = false;

  constructor(handle: FileSystemHandle, name: string, path: string) {
    this.handle = handle;
    this._name = name;
    this.path = path;
  }

  get id() {
    return this.path;
  }

  get name() {
    return this._name;
  }

  set name(name: string) {
    this._name = name;
  }

  get isDirty() {
    return this.changed;
  }

  markDirty() {
    this.changed = true;
  }

  markClean() {
    this.changed = false;
  }
}

export class ProjectFile extends ProjectEntry {
  handle: FileSystemFileHandle;
  version: number = 1;
  revision: number = 0;
  build: number = 0x1641;
  hash?: string;

  constructor(handle: FileSystemFileHandle, name: string, path: string) {
    super(handle, name, path);
    this.handle = handle;
  }

  async parse() {
    const file = await this.handle.getFile();
    const xml = await file.text();
    this.hash = await sha256hash(new TextEncoder().encode(xml));
    return this.parseProjectFile(xml);
  }

  async save(saveTo?: FileSystemFileHandle) {
    const handle = saveTo ?? this.handle;

    if (await saveTo?.isSameEntry(this.handle)) {
      if (!this.changed) {
        return;
      }
    }

    const file = await handle.createWritable();
    file.write(
      `<?xml version="1.0" encoding="UTF-8"?><BBProject><Version Info Version="${this.version}" Revision="${this.revision}" Build="0x${this.build.toString(16)}"/><Project Info Name="${this.name}"/></BBProject>\n`,
    );
    file.close();
  }

  parseProjectFile(xml: string) {
    const isProjectXML = /<bbproject.*>/i.test(xml);
    if (isProjectXML) {
      let result = /<version.*version="([^"]+)"/i.exec(xml);
      if (result) {
        const [, version] = result;
        this.version = parseInt(version ?? '0');
      }
      result = /<version.*revision="([^"]+)"/i.exec(xml);
      if (result) {
        const [, revision] = result;
        this.revision = parseInt(revision ?? '0');
      }
      result = /<version.*build="([^"]+)"/i.exec(xml);
      if (result) {
        const [, build] = result;
        this.build = parseInt(build ?? '0');
      }
      result = /<project.*name="([^"]+)"/i.exec(xml);
      if (result) {
        const [, name] = result;
        if (name) {
          this.name = name;
        }
      }
      return true;
    }
    return false;
  }
}

export class SongFolder extends ProjectEntry {
  handle: FileSystemDirectoryHandle;
  @tracked songs: SongFile[] = [];

  constructor(handle: FileSystemDirectoryHandle, name: string, path: string) {
    super(handle, name, path);
    this.handle = handle;
  }

  get isDirty(): boolean {
    return this.changed || this.songs.some((song) => song.isDirty);
  }

  markClean(): void {
    this.changed = false;
    this.songs.forEach((song) => song.markClean());
  }

  async addSong(song: SongFile, index?: number) {
    if (this.songs.length >= 128) {
      throw new Error('Cannot have more than 128 songs in a folder');
    }
    const existingHandle = song.handle;
    if (await fileExists(this.handle, song.path)) {
      const path = await createNewPathName(this.handle, '.BBS');
      song.path = path;
    }
    song.handle = await this.handle.getFileHandle(song.path, {
      create: true,
    });
    await copyFile(existingHandle, song.handle);
    if (typeof index === 'number') {
      this.songs = this.songs.toSpliced(index, 0, song);
    } else {
      this.songs = [...this.songs, song];
    }
    song.folder = this;
  }

  findSong(path: string) {
    return this.songs.find((song) => song.path === path);
  }

  async removeSong(index: number) {
    const song = this.songs[index];
    if (song) {
      this.songs = this.songs.toSpliced(index, 1);
      return song;
    }
    return undefined;
  }
}

export class SongFile extends ProjectEntry {
  handle: FileSystemFileHandle;
  folder: SongFolder;
  #song?: Song;
  hash?: string;

  constructor(
    folder: SongFolder,
    handle: FileSystemFileHandle,
    name: string,
    path: string,
  ) {
    super(handle, name, path);
    this.folder = folder;
    this.handle = handle;
  }

  get isDirty(): boolean {
    return this.changed || (this.#song?.isDirty ?? false);
  }

  markClean(): void {
    this.changed = false;
    this.#song?.markClean();
  }

  get id() {
    return this.path.split('.')[0]!;
  }

  get song(): Song | undefined {
    return this.#song;
  }

  set song(song: Song) {
    this.#song = song;
    song.on('rename', (name) => {
      this.name = name;
    });
  }

  async parse() {
    if (this.song) {
      return this.song;
    }
    const file = await this.handle?.getFile();
    if (file) {
      const buffer = await file?.arrayBuffer();
      this.hash = await sha256hash(buffer);
      this.song = Song.parse(buffer, this.name);
      return this.song;
    }
  }
}

export class SongReference {
  path: string;
  song?: SongFile;

  constructor(path: string) {
    this.path = path;
  }
}

export class Playlist extends ProjectEntry {
  _id: string;
  handle: FileSystemFileHandle;
  @tracked songs: SongReference[] = [];

  constructor(handle: FileSystemFileHandle, name: string, path: string) {
    super(handle, name, path);
    this._id = path.split('.')[0]!;
    this.handle = handle;
  }

  get id() {
    return this._id;
  }

  addSong(song: SongFile, index?: number) {
    if (this.songs.length === 128) {
      throw new Error('Cannot have more than 128 songs in a playlist');
    }
    const path = `${song.folder.path}/${song.path}`;
    const ref = new SongReference(path);
    ref.song = song;
    if (typeof index === 'number') {
      this.songs = this.songs.toSpliced(index, 0, ref);
    } else {
      this.songs = [...this.songs, ref];
    }
    return ref;
  }

  removeSong(song: SongReference) {
    const index = this.songs.indexOf(song);
    if (index > -1) {
      this.songs = this.songs.toSpliced(index, 1);
    }
    return index;
  }
}

export class DrumsetFile extends ProjectEntry {
  handle: FileSystemFileHandle;
  drumset?: Drumset;
  hash?: string;

  constructor(handle: FileSystemFileHandle, name: string, path: string) {
    super(handle, name, path);
    this.handle = handle;
  }

  get isDirty() {
    return this.changed || (this.drumset?.isDirty ?? false);
  }

  markClean(): void {
    this.changed = false;
    this.drumset?.markClean();
  }

  get id() {
    return this.path.split('.')[0]!;
  }

  get name() {
    return this._name;
  }

  set name(name: string) {
    this._name = name;
    if (!this.drumset) {
      this.parse();
    }
    this.drumset!.name = name;
    this.changed = true;
  }

  _parsingPromise?: Promise<Drumset | undefined>;
  /**
   * This function uses a stored promise to prevent multiple parsing of the same drumset
   * This is needed especially at project load when all drumsets are being parsed in the b
   * background but the current song needs it’s drumset to be parsed immediately
   */
  async parse() {
    if (this.drumset) {
      return this.drumset;
    }
    if (!this._parsingPromise) {
      this._parsingPromise = (async () => {
        const file = await this.handle?.getFile();
        if (file) {
          const buffer = await file?.arrayBuffer();
          this.hash = await sha256hash(buffer);
          this.drumset = await Drumset.parse(buffer);
          return this.drumset;
        }
      })();
    }
    return this._parsingPromise;
  }
}

export class EffectFile extends ProjectEntry {
  handle: FileSystemFileHandle;
  audio?: AudioBuffer;

  constructor(handle: FileSystemFileHandle, name: string, path: string) {
    super(handle, name, path);
    this.handle = handle;
  }

  async getAudio(audioContext: AudioContext) {
    if (this.audio) {
      return this.audio;
    }
    const file = await this.handle?.getFile();
    if (file) {
      const buffer = await file?.arrayBuffer();
      const decoded = await audioContext.decodeAudioData(buffer);
      this.audio = decoded;
      return decoded;
    }
  }

  async read() {
    const file = await this.handle?.getFile();
    if (!file) {
      throw new Error('Unable to read file');
    }
    const buffer = await file?.arrayBuffer();
    return buffer;
  }
}

async function parseConfig(
  contents: string,
  callback: (name: string, path: string) => void,
) {
  // Handle all line boundaries in case users have modified the CSV: https://www.unicode.org/reports/tr18/#Line_Boundaries
  const lines = contents.split(/\r\n|[\n\v\f\r\x85\u2028\u2029]/);
  for await (let line of lines) {
    const [path, fileName] = line.split(/,/);
    if (path) {
      let name = fileName?.replace(STRIP_NUMBER_REGEX, '$1')?.trim();
      await callback(name ?? '', path);
    }
  }
}

function buildConfig(entries: ProjectEntry[]) {
  return entries
    .map((entry, i) => `${entry.path},${i + 1}. ${entry.name}`)
    .join('\n');
}

async function saveConfig(
  handle: FileSystemDirectoryHandle,
  entries: ProjectEntry[],
) {
  const configHandle = await handle.getFileHandle('config.csv', {
    create: true,
  });
  if (!configHandle) {
    throw new Error('Unable to save folders');
  }
  const config = await configHandle.createWritable();
  const configContents = buildConfig(entries);
  await config.write(configContents);
  await config.close();
}

function hexString(buffer: ArrayBuffer) {
  const arr = new Uint8Array(buffer);
  return Array.from(arr, (byte) => byte.toString(16).padStart(2, '0')).join('');
}

async function sha256hash(buffer: ArrayBuffer) {
  const digest = await crypto.subtle.digest('SHA-256', buffer);
  return hexString(digest);
}

async function getDirectoryHandle(
  parentDirectory: FileSystemDirectoryHandle,
  name: string,
) {
  try {
    return await parentDirectory.getDirectoryHandle(name);
  } catch (error) {
    return undefined;
  }
}

async function getFileHandle(
  parentDirectory: FileSystemDirectoryHandle,
  name: string,
) {
  try {
    return await parentDirectory.getFileHandle(name);
  } catch (error) {
    return undefined;
  }
}

async function getConfigFile(
  directory: FileSystemDirectoryHandle,
): Promise<FileSystemFileHandle | undefined> {
  let configHandle = await getFileHandle(directory, 'config.csv');
  if (!configHandle) {
    configHandle = await getFileHandle(directory, 'CONFIG.CSV');
  }
  return configHandle;
}

async function fileExists(
  parentDirectory: FileSystemDirectoryHandle,
  name: string,
) {
  try {
    await parentDirectory.getFileHandle(name);
    return true;
  } catch (error) {
    return false;
  }
}

async function createDirectory(
  parentDirectory: FileSystemDirectoryHandle,
  name: string,
) {
  const directory = await parentDirectory.getDirectoryHandle(name, {
    create: true,
  });
  if (!directory) {
    throw new Error(`Unable to create ${name} directory`);
  }
  return directory;
}

function generateUniqueName(list: ProjectEntry[], name: string) {
  let uniqueName = name;
  let i = 1;
  while (list.some((entry) => entry.name === uniqueName)) {
    uniqueName = `${name} (${i})`;
    i++;
  }
  return uniqueName;
}

async function createNewPathName(
  directory: FileSystemDirectoryHandle,
  suffix = '',
): Promise<string> {
  const uuid = crypto.randomUUID();
  const path =
    new Crc32()
      .update(new TextEncoder().encode(uuid).buffer, uuid.length)
      .getCRC(true)
      .toString(16)
      .toUpperCase()
      .padStart(8, '0') + suffix;
  let matchesEntry = false;
  for await (const [, entry] of directory.entries()) {
    if (entry.name === path) {
      matchesEntry = true;
      break;
    }
  }
  if (matchesEntry) {
    return createNewPathName(directory, suffix);
  }
  return path;
}

async function copyFile(
  sourceHandle: FileSystemFileHandle | File,
  targetHandle: FileSystemFileHandle,
) {
  let sourceFile: File;
  if (sourceHandle instanceof FileSystemFileHandle) {
    if (await sourceHandle.isSameEntry(targetHandle)) {
      return;
    }
    sourceFile = await sourceHandle.getFile();
  } else {
    sourceFile = sourceHandle;
  }
  const sourceContents = await sourceFile.arrayBuffer();
  const file = await targetHandle.createWritable();
  file.write(sourceContents);
  await file.close();
}

interface Events {
  removeFolder: (folder: SongFolder) => void;
  removeSong: (folder: SongFolder, song: SongFile) => void;
  folderChange: (folder: SongFolder) => void;
  songChange: (song: SongFile) => void;
}

export default class ProjectManagerService extends Service {
  @service declare undoManager: UndoManagerService;
  @service declare progress: ProgressService;

  emmitter: Emitter<Events>;
  transition?: Transition;
  @tracked currentProject?: FileSystemDirectoryHandle;
  @tracked projectFile?: ProjectFile;
  @tracked _currentFolder?: SongFolder;
  @tracked _currentSong?: SongFile;
  @tracked _currentPlaylist?: Playlist;
  @tracked _errorSongs: SongFile[] = [];
  @tracked currentDrumset?: DrumsetFile;
  @tracked currentInstrument?: Instrument;
  @tracked songsHandle?: FileSystemDirectoryHandle;
  @tracked playlistsHandle?: FileSystemDirectoryHandle;
  @tracked songFolders: SongFolder[] = [];
  songFoldersDirty = false;
  @tracked playlists: Playlist[] = [];
  playlistsDirty = false;
  @tracked drumsetsHandle?: FileSystemDirectoryHandle;
  @tracked drumsets: DrumsetFile[] = [];
  drumsetsDirty = false;
  @tracked effectsHandle?: FileSystemDirectoryHandle;
  @tracked effects: EffectFile[] = [];
  @tracked sortMethod?: string = 'none';
  effectsDirty = false;

  constructor(properties?: object) {
    super(properties);
    //TODO: this needs to be called AFTER the DOM is loaded,
    // but for some reason, the standard Ember lifecycle hooks are not working
    // and neither are attempts to use document event listeners
    this.toggleTheme(true);
    this.emmitter = createNanoEvents();

    // this is the listener for the export all shortcut
    document.addEventListener('keydown', (event) => {
      // export all
      if (
        event.key.toLowerCase() === 'e' &&
        event.shiftKey &&
        (event.ctrlKey || event.metaKey)
      ) {
        this.exportAll();
        return;
      }
    });
  }

  on<E extends keyof Events>(event: E, callback: Events[E]) {
    return this.emmitter.on(event, callback);
  }

  get currentFolder(): SongFolder | undefined {
    return this._currentFolder;
  }

  set currentFolder(folder: SongFolder | undefined) {
    this._currentFolder = folder;
    this._currentSong = undefined;
    if (folder) {
      localStorage.setItem('currentFolder', folder?.path ?? '');
      this.emmitter.emit('folderChange', folder);
    }
  }

  get currentSong(): SongFile | undefined {
    return this._currentSong;
  }

  set currentSong(song: SongFile | undefined) {
    this._currentSong = song;
    if (song) {
      localStorage.setItem('currentSong', song?.path ?? '');
      this.emmitter.emit('songChange', song);
    }
  }

  get canAddPlaylist() {
    return this.playlists.length < MAX_PLAYLISTS;
  }

  addSongError(song: SongFile) {
    this._errorSongs = [...this._errorSongs, song];
    localStorage.removeItem('currentSong');
    this.currentSong = undefined;
  }

  get currentPlaylist() {
    return this._currentPlaylist;
  }

  set currentPlaylist(playlist: Playlist | undefined) {
    this._currentPlaylist = playlist;
    if (playlist) {
      localStorage.setItem('currentPlaylist', playlist?.path ?? '');
    }
  }

  closeProject() {
    this.undoManager.reset();
    this.currentProject = undefined;
    this.projectFile = undefined;
    this.currentFolder = undefined;
    this.currentSong = undefined;
    this.currentPlaylist = undefined;
    this.songsHandle = undefined;
    this.playlistsHandle = undefined;
    this.songFolders = [];
    this.playlists = [];
    this.drumsetsHandle = undefined;
    this.drumsets = [];
    this.effectsHandle = undefined;
    this.effects = [];
    this.sortMethod = 'none';
  }

  get isDirty() {
    return this.undoManager.isDirty;
  }

  async createProject(
    projectDirectoryHandle: FileSystemDirectoryHandle,
    projectFileHandle: FileSystemFileHandle,
  ) {
    localStorage.setItem('logs', '');
    return wait(async () => {
      try {
        const projectFile = new ProjectFile(
          <FileSystemFileHandle>projectFileHandle,
          projectFileHandle.name.replace(/\.bbp$/, ''),
          projectFileHandle.name,
        );
        projectFile.markDirty();
        this.projectFile = projectFile;
        this.currentProject = projectDirectoryHandle;

        this.songsHandle = await createDirectory(
          projectDirectoryHandle,
          'SONGS',
        );
        this.songFoldersDirty = true;
        this.drumsetsHandle = await createDirectory(
          projectDirectoryHandle,
          'DRUMSETS',
        );
        this.drumsetsDirty = true;
        this.effectsHandle = await createDirectory(
          projectDirectoryHandle,
          'EFFECTS',
        );
        this.effectsDirty = true;
        // Create an empty PARAMS folder because it's required for the BeatBuddy Manager
        await createDirectory(projectDirectoryHandle, 'PARAMS');
        const folder = await this.createSongFolder('New Folder');
        folder.markDirty();
        await this.save();

        return true;
      } catch (err) {
        console.error('Unable to create project', err);
        alert('Unable to create project\n' + err);
        return false;
      }
    });
  }

  async loadProject(projectDirectory: FileSystemDirectoryHandle) {
    const permitted = await this.verifyPermission(projectDirectory);
    if (permitted) {
      this.progress.reset();

      await wait(async () => {
        try {
          if (await this.loadProjectFile(projectDirectory)) {
            const songsPromise = this.loadSongs(projectDirectory);
            const playlistsPromise = this.loadPlaylists(projectDirectory);
            const drumsetsPromise = this.loadDrumsets(projectDirectory);
            const effectsPromise = this.loadEffects(projectDirectory);
            await this.progress.trackPromises([
              songsPromise,
              playlistsPromise,
              drumsetsPromise,
              effectsPromise,
            ]);

            if (this.songFolders.length === 0) {
              alert('No song folders found');
              return false;
            }

            // Link all songs to playlists
            this.playlists.forEach((playlist) => {
              playlist.songs.forEach((songRef) => {
                const [folderPath, songPath] = songRef.path.split('/');
                if (folderPath && songPath) {
                  const folder = this.findFolder(folderPath);
                  if (folder) {
                    const song = folder.findSong(songPath);
                    songRef.song = song;
                  }
                }
              });
            });
            this.currentProject = projectDirectory;

            const storedFolder = localStorage.getItem('currentFolder');
            const folder = this.findFolder(storedFolder ?? '');
            if (folder) {
              this.currentFolder = folder;
              const storedSong = localStorage.getItem('currentSong');
              const song = folder.findSong(storedSong ?? '');
              this.currentSong = song;
            }

            const storedPlaylist = localStorage.getItem('currentPlaylist');
            const playlist = this.playlists.find(
              (playlist) => playlist.path === storedPlaylist,
            );
            this.currentPlaylist = playlist;

            return true;
          } else {
            return false;
          }
        } catch (err) {
          console.error('Unable to load project', err);
          alert('Unable to load project\n' + err);
          return false;
        }
      });
    }

    return permitted;
  }

  async loadProjectFile(directory: FileSystemDirectoryHandle) {
    const entries: FileSystemHandle[] = [];
    for await (const [, entry] of directory.entries()) {
      entries.push(entry);
    }
    const handle = entries.find((entry) => /^(?!\.).+\.bbp$/i.test(entry.name));

    if (handle && handle.kind === 'file') {
      const projectFile = new ProjectFile(
        <FileSystemFileHandle>handle,
        handle.name,
        handle.name,
      );
      if (await projectFile.parse()) {
        this.projectFile = projectFile;
        return true;
      }
    }
    return false;
  }

  async logDirectoryEntries(dir: FileSystemDirectoryHandle) {
    const entries: FileSystemHandle[] = [];
    for await (const [, entry] of dir.entries()) {
      entries.push(entry);
    }
  }

  async loadSongs(directory: FileSystemDirectoryHandle) {
    this.songFolders = [];
    const songsHandle = await getDirectoryHandle(directory, 'SONGS');
    if (songsHandle) {
      await this.logDirectoryEntries(songsHandle);
      this.songsHandle = songsHandle;
      const configHandle = await getConfigFile(this.songsHandle);
      if (configHandle) {
        const file = await configHandle.getFile();
        const contents = await file.text();
        const songFolders: SongFolder[] = [];
        const songLoading: Promise<void>[] = [];
        await parseConfig(contents, async (name, path) => {
          const folderHandle = await getDirectoryHandle(songsHandle, path);
          if (folderHandle) {
            const songFolder = new SongFolder(folderHandle, name, path);
            const promise = this.loadFolderSongs(songFolder);
            songLoading.push(promise);
            songFolders.push(songFolder);
          } else {
          }
        });
        await this.progress.trackPromises(songLoading);
        this.songFolders = songFolders;
      } else {
        throw new Error('No SONGS config file found');
      }
    }
  }

  async loadFolderSongs(folder: SongFolder) {
    await this.logDirectoryEntries(folder.handle);
    const configHandle = await getConfigFile(folder.handle);
    if (configHandle) {
      const file = await configHandle.getFile();
      const contents = await file.text();
      const songs: SongFile[] = [];
      let fixedSongNameBug = false;
      await parseConfig(contents, async (name, path) => {
        let songHandle = await getFileHandle(folder.handle, path);
        if (songHandle) {
          if (path.endsWith('.BBM')) {
            // Incorrect file extension (previous bug)'
            const newPath = path.replace('.BBM', '.BBS');
            const renameHandle = await folder.handle.getFileHandle(newPath, {
              create: true,
            });
            await copyFile(songHandle, renameHandle);

            folder.handle.removeEntry(path);
            path = newPath;

            songHandle = renameHandle;
            fixedSongNameBug = true;
          }
          const song = new SongFile(folder, songHandle, name, path);
          songs.push(song);
        }
      });
      if (fixedSongNameBug) {
        await saveConfig(folder.handle, songs);
      }
      folder.songs = songs;
    } else {
      throw new Error(
        `No config file found for ${folder.name}: ${folder.path}`,
      );
    }
  }

  async loadPlaylists(directory: FileSystemDirectoryHandle) {
    this.playlists = [];
    try {
      const handle = await getDirectoryHandle(directory, 'PLAYLISTS');
      if (handle) {
        await this.logDirectoryEntries(handle);
        this.playlistsHandle = handle;
        const configHandle = await getConfigFile(this.playlistsHandle);
        if (configHandle) {
          const file = await configHandle.getFile();
          const contents = await file.text();
          const playlists: Playlist[] = [];
          const songLoading: Promise<void>[] = [];
          await parseConfig(contents, async (name, path) => {
            const playlistHandle = await getFileHandle(handle, path);
            if (playlistHandle) {
              const playlist = new Playlist(playlistHandle, name, path);
              songLoading.push(this.loadPlaylistSongs(playlist));
              playlists.push(playlist);
            } else {
            }
          });
          await this.progress.trackPromises(songLoading);
          this.playlists = playlists;
        }
      } else {
      }
    } catch (error) { }
  }

  async loadPlaylistSongs(playlist: Playlist) {
    const file = await playlist.handle.getFile();
    const contents = await file.text();
    const songs: SongReference[] = [];
    await parseConfig(contents, async (name, path) => {
      songs.push(new SongReference(path));
    });
    playlist.songs = songs;
  }

  async loadDrumsets(directory: FileSystemDirectoryHandle) {
    this.drumsets = [];
    const handle = await getDirectoryHandle(directory, 'DRUMSETS');
    if (handle) {
      this.logDirectoryEntries(handle);
      this.drumsetsHandle = handle;
      const configHandle = await getConfigFile(this.drumsetsHandle);
      if (configHandle) {
        const file = await configHandle.getFile();
        const contents = await file.text();
        const drumsets: DrumsetFile[] = [];
        await parseConfig(contents, async (name, path) => {
          const drumsetHandle = await getFileHandle(handle, path);
          if (drumsetHandle) {
            const drumset = new DrumsetFile(drumsetHandle, name, path);
            drumsets.push(drumset);
          }
        });
        this.drumsets = drumsets;
      }
    } else {
      throw new Error('No DRUMSETS directory found');
    }
  }

  async loadEffects(directory: FileSystemDirectoryHandle) {
    this.effects = [];
    const handle = await getDirectoryHandle(directory, 'EFFECTS');
    if (handle) {
      this.logDirectoryEntries(handle);
      this.effectsHandle = handle;
      const configHandle = await getConfigFile(this.effectsHandle);
      if (configHandle) {
        const file = await configHandle.getFile();
        const contents = await file.text();
        const effects: EffectFile[] = [];
        await parseConfig(contents, async (name, path) => {
          const effectHandle = await getFileHandle(handle, path);
          if (effectHandle) {
            const effect = new EffectFile(effectHandle, name, path);
            effects.push(effect);
          }
        });
        this.effects = effects.sort((a, b) => a.name.localeCompare(b.name));
      }
    } else {
      throw new Error('No EFFECTS directory found');
    }
  }

  async verifyPermission(fileHandle: FileSystemHandle, readWrite = true) {
    const options: FileSystemHandlePermissionDescriptor = { mode: undefined };
    if (readWrite) {
      options.mode = 'readwrite';
    }
    // Check if permission was already granted. If so, return true.
    if ((await fileHandle.queryPermission(options)) === 'granted') {
      return true;
    }
    // Request permission. If the user grants permission, return true.
    if ((await fileHandle.requestPermission(options)) === 'granted') {
      return true;
    }
    // The user didn't grant permission, so return false.
    return false;
  }

  async createSongFolder(name = 'New Folder'): Promise<SongFolder> {
    // NOTE: This only applies to Aeros mode.
    // if (this.songFolders.length >= 111) {
    //   throw new Error('Cannot have more than 111 song folders');
    // }

    name = generateUniqueName(this.songFolders, name);
    const path = await createNewPathName(this.songsHandle!);
    let handle: FileSystemDirectoryHandle | undefined;
    handle = await getDirectoryHandle(this.songsHandle!, path);
    if (handle) {
      // If we get back a handle, it means the folder already exists
      // try again
      return this.createSongFolder();
    } else {
      // create the new folder
      try {
        handle = await this.songsHandle!.getDirectoryHandle(path, {
          create: true,
        });
        const folder = new SongFolder(handle, name, path);
        this.songFolders = [...this.songFolders, folder];
        return folder;
      } catch (err) {
        alert('Unable to create folder');
        throw err;
      }
    }
  }

  removeFolder(folder: SongFolder) {
    this.songFolders = this.songFolders.filter((d) => d !== folder);
    this.currentFolder = undefined;
    this.emmitter.emit('removeFolder', folder);
  }

  async createSong(folder: SongFolder): Promise<SongFile> {
    if (folder.songs.length >= 128) {
      throw new Error('Cannot have more than 128 songs in a folder');
    }

    const song = new Song('New Song');
    song.addPart();
    const path = await createNewPathName(this.songsHandle!, '.BBS');
    let handle = await getFileHandle(folder.handle, path);
    if (handle) {
      // If we get back a handle, it means the file already exists
      // try again
      return this.createSong(folder);
    } else {
      // create the new song file
      try {
        handle = await folder.handle.getFileHandle(path, {
          create: true,
        });
        const songFile = new SongFile(folder, handle, song.name, path);
        songFile.song = song;
        await folder.addSong(songFile);
        return songFile;
      } catch (err) {
        alert('Unable to create song');
        throw err;
      }
    }
  }

  async copySong(songFile: SongFile, folder: SongFolder) {
    if (!songFile.song) {
      await songFile.parse();
    }
    const newSong = songFile.song!.copy();

    const path = await createNewPathName(this.songsHandle!, '.BBS');
    let handle = await getFileHandle(folder.handle, path);
    if (handle) {
      throw new Error('Unable to save song, file already exists');
    } else {
      handle = await folder.handle.getFileHandle(path, {
        create: true,
      });
      const newSongFile = new SongFile(folder, handle, newSong.name, path);
      newSongFile.song = newSong;
      return newSongFile;
    }
  }

  async copyDrumset(drumsetFile: DrumsetFile) {
    await drumsetFile.parse();
    const newDrumset = drumsetFile.drumset!.copy();

    const path = await createNewPathName(this.songsHandle!, '.DRM');
    let handle = await getFileHandle(this.drumsetsHandle!, path);
    if (handle) {
      throw new Error('Unable to save drumset, file already exists');
    } else {
      handle = await this.drumsetsHandle!.getFileHandle(path, {
        create: true,
      });
      const newDrumsetFile = new DrumsetFile(handle, newDrumset.name, path);
      newDrumsetFile.drumset = newDrumset;
      return newDrumsetFile;
    }
  }

  removeSong(folder: SongFolder, song: SongFile) {
    folder.songs = folder.songs.filter((s) => s !== song);
    this.currentSong = undefined;
    this.emmitter.emit('removeSong', folder, song);
  }

  addPlaylist(name?: string) {
    if (this.playlists.length + 1 > MAX_PLAYLISTS) {
      throw new Error(`Cannot have more than ${MAX_PLAYLISTS} playlists`);
    }
    const playlistPaths = this.playlists.map(p => p.path);
    const playlistNumbers: number[] = playlistPaths
      .map((path) => {
        const match = path.match(/^(\d+)\.csv$/);
        if (match) {
          return parseInt(match[1], 10);
        } else {
          console.warn(`Filename "${path}" does not match the expected pattern.`);
          return null;
        }
      })
      .filter((num): num is number => num !== null);

    let next;
    if (playlistNumbers.length === 0) {
      next = 1;
    } else {
      next = Math.max(...playlistNumbers) + 1;
    }
    const newName = generateUniqueName(
      this.playlists,
      name ?? `Playlist ${next}`,
    );
    const path = `${next}.csv`;
    const playlist = new Playlist(null!, newName, path);
    this.playlists = [...this.playlists, playlist];
    return playlist;
  }

  removePlaylist(playlist: Playlist) {
    const index = this.playlists.indexOf(playlist);
    if (index > -1) {
      this.playlists = this.playlists.toSpliced(index, 1);
    }
    return index;
  }

  findFolder(path: string) {
    return this.songFolders.find((folder) => folder.path === path);
  }

  findDrumset(path: string) {
    let drumsetFile =
      this.drumsets.find((drumset) => drumset.path === path) ??
      this.getDefaultDrumset();
    return drumsetFile!;
  }

  findEffect(path: string) {
    return this.effects.find((effect) => effect.path === path);
  }

  findSong(folder: SongFolder) {
    return folder.songs
      .filter((song) => !this._errorSongs.includes(song))
      .at(0);
  }

  getDefaultDrumset() {
    return (
      this.drumsets.find((ds) => ds.name === 'Standard') ?? this.drumsets[0]
    );
  }

  songsForPlaylist(playlist: Playlist) {
    return playlist.songs
      .map((song) => {
        const [folderPath, songPath] = song.path.split('/');
        if (folderPath && songPath) {
          const folder = this.findFolder(folderPath);
          if (folder) {
            song.song = folder.findSong(songPath);
            return song;
          }
        }
      })
      .filter(elementExists);
  }

  async createDrumset(): Promise<DrumsetFile> {
    const uuid = crypto.randomUUID();
    const name = 'New Drum Set';
    const fileName =
      new Crc32()
        .update(new TextEncoder().encode(uuid).buffer, uuid.length)
        .getCRC(true)
        .toString(16)
        .toUpperCase()
        .padStart(8, '0') + '.DRM';

    this.drumsetsHandle =
      this.drumsetsHandle ??
      (await this.currentProject?.getDirectoryHandle('DRUMSETS', {
        create: true,
      }));

    if (!this.drumsetsHandle) {
      throw new Error('Unable to create DRUMSETS folder');
    }

    let handle: FileSystemFileHandle | undefined;
    handle = await getFileHandle(this.drumsetsHandle!, fileName);
    if (handle) {
      // If we get back a handle, it means the drumset already exists
      // try again
      return this.createDrumset();
    } else {
      // create the new drumset
      try {
        handle = await this.drumsetsHandle!.getFileHandle(fileName, {
          create: true,
        });
        const drumset = new DrumsetFile(handle, name, fileName);
        drumset.drumset = new Drumset();
        drumset.name = name;
        this.drumsets = [...this.drumsets, drumset];
        return drumset;
      } catch (err) {
        alert('Unable to create drumset');
        throw err;
      }
    }
  }

  async importEffect(file: File): Promise<EffectFile> {
    this.effectsHandle =
      this.effectsHandle ??
      (await this.currentProject?.getDirectoryHandle('EFFECTS', {
        create: true,
      }));

    if (!this.effectsHandle) {
      throw new Error('Unable to create EFFECTS folder');
    }

    const path = await createNewPathName(this.effectsHandle, '.WAV');
    const effectHandle = await this.effectsHandle.getFileHandle(path, {
      create: true,
    });

    await copyFile(file, effectHandle);
    const name = file.name.replace(/\.wav$/i, '');

    const effectFile = new EffectFile(effectHandle, name, path);
    this.effects = [...this.effects, effectFile];
    return effectFile;
  }

  async removeEffect(effect: EffectFile) {
    this.effects = this.effects.filter((e) => e !== effect);
    return effect;
  }

  async addDrumset(drumset: DrumsetFile, index?: number) {
    const drumsetsHandle = this.drumsetsHandle;

    if (!drumsetsHandle) {
      throw new Error('Unable to create DRUMSETS folder');
    }

    const existingHandle = drumset.handle;
    if (await fileExists(drumsetsHandle, drumset.path)) {
      const path = await createNewPathName(drumsetsHandle, '.DRM');
      drumset.path = path;
    }

    drumset.handle = await drumsetsHandle.getFileHandle(drumset.path, {
      create: true,
    });

    await copyFile(existingHandle, drumset.handle);

    if (typeof index === 'number') {
      this.drumsets = this.drumsets.toSpliced(index, 0, drumset);
    } else {
      this.drumsets = [...this.drumsets, drumset];
    }

    // return new AddDrumsetCommand(this, drumsetFile);
  }

  async importDrumset(): Promise<CompositeCommand<any> | undefined> {
    let handle: FileSystemFileHandle[];
    try {
      handle = await window.showOpenFilePicker({
        id: 'import-drumset',
        startIn: 'downloads',
        excludeAcceptAllOption: true,
        multiple: true,
        types: [
          {
            description: 'BB Drumset',
            accept: {
              'application/x-beatbuddy-song': ['.drm'],
            },
          },
        ],
      });
    } catch (err: any) {
      if (err.name === 'AbortError' && /user aborted/i.test(err.message)) {
        return;
      }
      throw err;
    }

    const commands: Command<any>[] = [];

    return await wait<CompositeCommand<any> | undefined>(async () => {
      await Promise.all(
        handle.map(async (drum) => {
          const drumsetsHandle =
            this.drumsetsHandle ??
            (await this.currentProject?.getDirectoryHandle('DRUMSETS', {
              create: true,
            }));

          if (!drumsetsHandle) {
            throw new Error('Unable to create DRUMSETS folder');
          }

          const path = await createNewPathName(drumsetsHandle, '.DRM');
          const drumsetHandle = await drumsetsHandle.getFileHandle(path, {
            create: true,
          });

          await copyFile(drum, drumsetHandle);

          const drumsetFile = new DrumsetFile(
            drumsetHandle,
            'Imported drumset',
            drumsetHandle.name,
          );

          await drumsetFile.parse();
          drumsetFile.name = this.generateUniqueDrumsetName(
            drumsetFile.drumset!.name,
          );

          let drumCommand = new AddDrumsetCommand(this, drumsetFile);
          commands.push(drumCommand);
        }),
      );
      return new CompositeCommand('Import Drumset(s)', commands);
    });
  }

  generateUniqueDrumsetName(name: string) {
    let uniqueName = name;
    let i = 1;
    while (this.drumsets.some((drumset) => drumset.name === uniqueName)) {
      uniqueName = `${name} (${i})`;
      i++;
    }
    return uniqueName;
  }

  // Will import a song (.sng) or a folder (.pbf)
  async importSong(
    folder?: SongFolder,
  ): Promise<CompositeCommand<any> | undefined> {
    const currentFolder = folder ?? this.currentFolder ?? this.songFolders[0]!;
    const commands: Command<any>[] = [];
    const accept: Record<`${string}/${string}`, `.${string}` | `.${string}`[]> =
    {
      'application/x-beatbuddy-song': ['.sng'],
    };
    if (!folder) {
      accept['application/x-beatbuddy-folder'] = ['.pbf'];
    }

    let handles: FileSystemFileHandle[];
    try {
      handles = await window.showOpenFilePicker({
        id: 'import-song',
        startIn: 'downloads',
        excludeAcceptAllOption: true,
        multiple: true,
        types: [
          {
            description: 'BB Song',
            accept,
          },
        ],
      });
    } catch (err: any) {
      if (err.name === 'AbortError' && /user aborted/i.test(err.message)) {
        return;
      }
      throw err;
    }

    return await wait<CompositeCommand<any> | undefined>(async () => {
      await Promise.all(
        handles.map(async (handle) => {
          const file = await handle.getFile();
          const data = await file.arrayBuffer();
          const unzipped = unzipSync(new Uint8Array(data), {
            filter: (file) => file.originalSize < 10_000_000,
          });

          const textDecoder = new TextDecoder('UTF-8');

          const paramsInfo = unzipped['PARAMS/info.bcf'];
          if (paramsInfo) {
            const reader = new Reader(new DataView(paramsInfo.buffer));
            const entries = reader.getUint(false);
            const map = new Map<string, string>();
            for (let i = 0; i < entries; i++) {
              const key = reader.streamString(false);
              const type = reader.getUint(false);
              assert(`${type} should be 10`, type === 10);
              const reserved = reader.getByte();
              assert(`${reserved} should be 0`, reserved === 0);
              const value = reader.streamString(false);
              map.set(key, value);
            }
            const addFolderCommand = new AddFolderCommand(
              this,
              map.get('folder_name') ?? 'Imported Folder',
            );
            addFolderCommand.on('execute', async (folder) => {
              const songsConfig = unzipped['SONGS/config.csv'];
              if (songsConfig) {
                await parseConfig(
                  textDecoder.decode(songsConfig),
                  async (name, path) => {
                    const songData = unzipped[`SONGS/${path}`];
                    if (songData) {
                      const songHandle = await folder.handle.getFileHandle(
                        path,
                        {
                          create: true,
                        },
                      );
                      const file = await songHandle.createWritable();
                      await file.write(songData.buffer);
                      await file.close();
                      const command = new AddSongCommand(
                        folder,
                        new SongFile(folder, songHandle, name, path),
                      );
                      command.execute();
                      commands.push(command);
                    }
                  },
                );
              }
            });
            commands.push(addFolderCommand);
          }

          const songConfig = unzipped['SONG/config.csv'];
          if (songConfig) {
            await parseConfig(
              textDecoder.decode(songConfig),
              async (name, path) => {
                const songData = unzipped[`SONG/${path}`];
                let existingSong = currentFolder?.findSong(path);
                if (existingSong) {
                  if (
                    !confirm(
                      `Overwrite song: ${existingSong.name}?\nOK to overwrite\nCancel to import as new song`,
                    )
                  ) {
                    existingSong = undefined;
                    path = await createNewPathName(
                      currentFolder.handle,
                      '.BBS',
                    );
                  }
                }
                if (songData) {
                  const songHandle = await currentFolder.handle.getFileHandle(
                    path,
                    { create: true },
                  );
                  const song = Song.parse(songData.buffer, name);
                  const file = await songHandle.createWritable();
                  await file.write(song.save());
                  await file.close();
                  const songFile = new SongFile(
                    currentFolder,
                    songHandle,
                    name,
                    path,
                  );
                  commands.push(new AddSongCommand(currentFolder, songFile));
                  if (existingSong) {
                    commands.push(
                      new DeleteSongCommand(currentFolder, existingSong),
                    );
                  }
                }
              },
            );
          }

          const effectsConfig = unzipped['EFFECTS/config.csv'];
          if (effectsConfig) {
            await parseConfig(
              textDecoder.decode(effectsConfig),
              async (name, path) => {
                const existingEffect = this.effects.find(
                  (effect) => effect.path === path,
                );
                const effectData = unzipped[`EFFECTS/${path}`];
                if (effectData) {
                  const newEffectHash = await sha256hash(effectData.buffer);
                  if (existingEffect) {
                    const data = await existingEffect.read();
                    const existingEffectHash = await sha256hash(data);
                    if (newEffectHash === existingEffectHash) {
                      return;
                    }
                    if (!confirm(`Overwrite effect: ${existingEffect.name}?`)) {
                      return;
                    }
                  }
                  const effectHandle =
                    existingEffect?.handle ??
                    (await this.effectsHandle!.getFileHandle(path, {
                      create: true,
                    }));
                  const file = await effectHandle.createWritable();
                  await file.write(effectData.buffer);
                  await file.close();
                  if (!existingEffect) {
                    commands.push(
                      new AddEffectCommand(
                        this,
                        new EffectFile(effectHandle, name, path),
                      ),
                    );
                  }
                }
              },
            );
          }
        }),
      );
      return new CompositeCommand('Import Song(s)', commands);
    });
  }

  async exportSong(songFile: SongFile) {
    let handle: FileSystemFileHandle;
    let originalSongName = songFile.name;
    try {
      handle = await window.showSaveFilePicker({
        id: 'export-song',
        startIn: 'downloads',
        suggestedName: songFile.name + '.sng',
        types: [
          {
            description: 'BeatBuddy Song',
            accept: {
              'application/x-beatbuddy-song': ['.sng'],
            },
          },
        ],
      });
      // By replacing the song name here, we are making sure the next time this song is imported, it's using the new name
      // But we need to make sure we change it back at the end to keep the UI consistent
      songFile.name = handle.name.replace(/\.sng$/, '');
    } catch (err: any) {
      if (err.name === 'AbortError' && /user aborted/i.test(err.message)) {
        return;
      }
      throw err;
    }

    try {
      await wait(async () => {
        if (!songFile.song) {
          await songFile.parse();
        }
        const song = songFile.song!;
        const effectFiles: EffectFile[] = song.effects.map(
          (effect) => this.findEffect(effect)!,
        );

        const effectsPath: Zippable = {
          'config.csv': strToU8(buildConfig(effectFiles)),
        };
        const effects = await Promise.all(
          effectFiles.map(
            async (effectFile): Promise<[string, Uint8Array]> => [
              effectFile.path,
              new Uint8Array(await effectFile.read()),
            ],
          ),
        );
        effects.forEach(([path, data]) => {
          effectsPath[path] = data;
        });

        const zipped = zipSync(
          {
            SONG: {
              'config.csv': strToU8(buildConfig([songFile])),
              [songFile.path]: new Uint8Array(song.save()),
            },
            EFFECTS: effectsPath,
            'version.bcf': strToU8(
              atob(
                'AAAAAwAAAA4AdgBlAHIAcwBpAG8AbgAAAAEAAAAQAHIAZQB2AGkAcwBpAG8AbgAAAAAAAAAKAGIAdQBpAGwAZAAAFkE=',
              ),
            ),
          },
          {
            level: 0,
            mtime: new Date(),
          },
        );

        try {
          const file = await handle.createWritable();
          await file.write(zipped);
          await file.close();
        } catch (err) {
          console.error('Unable to save song', err);
          alert('Unable to export song');
        }
      });
    } catch (err) {
      console.error('Unable to get a file handle', err);
    }
    // Setting this back to original name so when this function returns we can change the name on the UI
    songFile.name = originalSongName;
    return handle;
  }

  async exportAllMidiFiles(songFile: SongFile) {
    if (!songFile.song) {
      return;
    }
    // eslint-disable-next-line no-undef
    let directoryHandle: FileSystemDirectoryHandle;
    try {
      directoryHandle = await window.showDirectoryPicker({
        id: 'export-midi-files',
        startIn: 'documents',
        mode: 'readwrite',
      });
    } catch (err: any) {
      if (err.name === 'AbortError' && /user aborted/i.test(err.message)) {
        return;
      }
      throw err;
    }

    const now = new Date();
    const hours = now.getHours().toString().padStart(2, '0'); // 24-hour format
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const seconds = now.getSeconds().toString().padStart(2, '0');
    const day = now.getDate().toString().padStart(2, '0');
    const month = (now.getMonth() + 1).toString().padStart(2, '0'); // January is 0!
    const year = now.getFullYear();

    let directoryName = `${songFile.name}_beatBuddyFolder_${hours}_${minutes}_${seconds}_${day}-${month}-${year}_MIDI_Export`;
    // Replace any slashes with underscores
    directoryName = directoryName.replace(/\//g, '_');

    try {
      directoryHandle = await createDirectory(directoryHandle, directoryName);
    } catch (err) {
      console.error('Unable to create directory', err);
      alert(
        'Unable to export MIDI files, please rename the song and try again',
      );
      return;
    }

    try {
      for (let i = 0; i < songFile.song.tracks.length; i++) {
        let fileData: ArrayBuffer;
        let fileHandle: FileSystemFileHandle;

        fileData = songFile.song.tracks[i].toMidi();
        fileHandle = await directoryHandle.getFileHandle(
          `${songFile.song.tracks[i].name}.mid`,
          {
            create: true,
          },
        );
        const file = await fileHandle.createWritable();
        await file.write(fileData);
        await file.close();
      }
    } catch (err) {
      if (err instanceof DOMException) {
        console.error('DOMException', { name: err.name, message: err.message });
        alert('Unable to save MIDI track\n' + err.message);
      } else {
        console.error(err);
        alert('Unable to save MIDI track');
      }
    }

    return;
  }

  async exportFolder(folder: SongFolder) {
    let handle: FileSystemFileHandle;
    try {
      handle = await window.showSaveFilePicker({
        id: 'export-folder',
        startIn: 'downloads',
        suggestedName: folder.name + '.pbf',
        types: [
          {
            description: 'BeatBuddy Folder',
            accept: {
              'application/x-beatbuddy-folder': ['.pbf'],
            },
          },
        ],
      });
    } catch (err: any) {
      if (err.name === 'AbortError' && /user aborted/i.test(err.message)) {
        return;
      }
      throw err;
    }

    try {
      await wait(async () => {
        await promiseBatch(folder.songs, 2, async (songFile) => {
          await songFile.parse();
        });

        const songEffects = folder.songs.reduce(
          (map: Map<SongFile, EffectFile[]>, songFile) => {
            const effects =
              songFile.song?.effects
                .map((effect) => this.findEffect(effect)!)
                .filter(elementExists)
                .filter(unique) ?? [];
            if (effects.length > 0) {
              map.set(songFile, effects);
            }
            return map;
          },
          new Map<SongFile, EffectFile[]>(),
        );

        // Write out the usage.bcf file which is a map of song uuid to array of effect file names
        const usageWriter = new Writer();
        usageWriter.setUint(songEffects.size, false);
        songEffects.forEach((effects, songFile) => {
          const uuid = `{${songFile.song!.uuid!}}`;
          usageWriter.streamString(uuid, false);
          usageWriter.setUint(effects.length, false);
          effects.forEach((effect) => {
            usageWriter.streamString(effect.path, false);
            usageWriter.setUint(1); // unknown
          });
        });

        const effectFiles: EffectFile[] = Array.from(
          songEffects.values(),
        ).flatMap((effects) => effects);

        const effectsPath: Zippable = {
          'config.csv': strToU8(buildConfig(effectFiles)),
          'usage.bcf': new Uint8Array(usageWriter.close()),
        };
        const effects = await Promise.all(
          effectFiles
            .filter(unique)
            .map(
              async (effectFile): Promise<[string, Uint8Array]> => [
                effectFile.path,
                new Uint8Array(await effectFile.read()),
              ],
            ),
        );
        effects.forEach(([path, data]) => {
          effectsPath[path] = data;
        });

        const songFiles: SongFile[] = folder.songs;
        const songsPath: Zippable = {
          'config.csv': strToU8(buildConfig(songFiles)),
        };
        const songs = await Promise.all(
          songFiles.map(
            async (songFile): Promise<[string, Uint8Array]> => [
              songFile.path,
              new Uint8Array(songFile.song!.save()),
            ],
          ),
        );
        songs.forEach(([path, data]) => {
          songsPath[path] = data;
        });

        // Write out the info.bcf file containing:
        // folder_name (string) - the name of the folder (string)
        const infoWriter = new Writer();
        infoWriter.setUint(1, false);
        infoWriter.streamString('folder_name', false);
        infoWriter.setUint(10, false); // Unknown
        infoWriter.setByte(0); // Unknown
        infoWriter.streamString(folder.name, false);

        const paramsPath: Zippable = {
          'info.bcf': new Uint8Array(infoWriter.close()),
        };

        const zipped = zipSync(
          {
            SONGS: songsPath,
            EFFECTS: effectsPath,
            PARAMS: paramsPath,
            'version.bcf': strToU8(
              atob(
                'AAAAAwAAAA4AdgBlAHIAcwBpAG8AbgAAAAEAAAAQAHIAZQB2AGkAcwBpAG8AbgAAAAAAAAAKAGIAdQBpAGwAZAAAFkE=',
              ),
            ),
          },
          {
            level: 0,
            mtime: new Date(),
          },
        );

        try {
          const file = await handle.createWritable();
          await file.write(zipped);
          await file.close();
        } catch (err) {
          console.error('Unable to save folder', err);
          alert('Unable to export folder');
        }
      });
    } catch (err) {
      console.error('Unable to get a file handle', err);
    }
  }

  async exportDrumset(drumsetFile: DrumsetFile) {
    let handle: FileSystemFileHandle;
    try {
      handle = await window.showSaveFilePicker({
        id: 'export-drumset',
        startIn: 'downloads',
        suggestedName: `${drumsetFile.name}.drm`,
        types: [
          {
            description: 'BB Drumset',
            accept: {
              'application/x-beatbuddy-drumset': ['.drm'],
            },
          },
        ],
      });
    } catch (err: any) {
      if (err.name === 'AbortError' && /user aborted/i.test(err.message)) {
        return;
      }
      throw err;
    }

    await wait(async () => {
      this.progress.reset();
      const savedDrumset = this.createSavedDrumset(drumsetFile, handle);
      await this.progress.trackPromises([savedDrumset]);
    });
  }

  async createSavedDrumset(
    drumsetFile: DrumsetFile,
    handle: FileSystemFileHandle,
  ) {
    try {
      if (!drumsetFile.drumset) {
        await drumsetFile.parse();
      }
      const drumset = drumsetFile.drumset!;
      const file = await handle.createWritable();
      await file.write(drumset.save());
      await file.close();
    } catch (err) {
      console.error('Unable to save drumset', err);
      alert('Unable to export drumset');
    }
  }

  async toggleTheme(fromStart: boolean) {
    let currentTheme: String;
    if (localStorage.getItem('theme') === null) {
      console.warn("theme is null, falling back to dark theme")
      localStorage.setItem('theme', 'dark');
      document.getElementById('baseClass')!.classList.add('dark');
      return;
    }
    if (fromStart) {
      currentTheme = localStorage.getItem('theme')!.toString();
    } else {
      localStorage.setItem('theme', localStorage.getItem('theme')!.toString() === 'dark' ? 'light' : 'dark');
      currentTheme = localStorage.getItem('theme')!.toString();
    }
    if (currentTheme === 'dark') {
      document.getElementById('baseClass')!.classList.add('dark');
    } else {
      document.getElementById('baseClass')!.classList.remove('dark');
    }

    // Dispatch custom event
    const event = new CustomEvent('themeChange', {
      detail: { theme: currentTheme },
    });
    window.dispatchEvent(event);
  }

  areSongsValid() {
    let alertMessage = '';
    this.songFolders.forEach((folder) => {
      folder.songs.forEach((songFile) => {
        let validSong = songFile.song?.isValid;
        if (validSong === true || validSong === undefined) {
          // Do nothing, undefined means the song has not been clicked/initialized, so there is nothing wrong with it and it won't be saved
        } else {
          alertMessage += `Song ${songFile.name}: ${validSong}\n`;
        }
      });
    });
    return alertMessage;
  }

  areDumsetsValid() {
    let alertMessage = '';
    this.drumsets.forEach((drumset) => {
      let validDrumset = drumset.drumset?.isValid;
      if (validDrumset === true || validDrumset === undefined) {
        // Do nothing, undefined means the drumset has not been clicked/initialized, so there is nothing wrong with it and it won't be saved
      } else {
        alertMessage += `Drumset ${drumset.name}: ${validDrumset}\n`;
      }
    });
    return alertMessage;
  }

  async save() {
    let alertMessage = '';
    alertMessage += this.areSongsValid();
    alertMessage += this.areDumsetsValid();
    if (alertMessage !== '') {
      alert(alertMessage);
      return;
    }
    await wait(async () => {
      const projectDirectory = this.currentProject;
      if (projectDirectory) {
        this.progress.reset();

        const projectFile = this.projectFile!;
        if (projectFile.isDirty) {
          this.progress.addWork();
          if (!projectFile.handle.name.startsWith(projectFile.name)) {
            const newHandle = await projectDirectory.getFileHandle(
              `${projectFile.name}.bbp`,
              { create: true },
            );
            await projectDirectory.removeEntry(projectFile.handle.name);
            projectFile.handle = newHandle;
          }
          await projectFile!.save();
          this.progress.completeWork();
          projectFile.markClean();
        }

        try {
          const songsDirectory = await createDirectory(
            projectDirectory,
            'SONGS',
          );
          const saveFoldersPromise = this.saveFolders(songsDirectory, {
            batchLimit: this.songFolders.length,
          });

          const playlistsDirectory = await createDirectory(
            projectDirectory,
            'PLAYLISTS',
          );
          const savePlaylistsPromise = this.savePlaylists(playlistsDirectory);

          const drumsetsDirectory = await createDirectory(
            projectDirectory,
            'DRUMSETS',
          );
          const saveDrumsetsPromise = this.saveDrumsets(drumsetsDirectory);

          const effectsDirectory = await createDirectory(
            projectDirectory,
            'EFFECTS',
          );
          const saveEffectsPromise = this.saveEffects(effectsDirectory);

          await this.progress.trackPromises([
            saveFoldersPromise,
            savePlaylistsPromise,
            saveDrumsetsPromise,
            saveEffectsPromise,
          ]);
        } catch (err) {
          console.error('Unable to save project', err);
          alert('Unable to save project\n' + err);
          return false;
        }

        this.undoManager.resetDirty();
        return true;
      }
    });
  }

  sanitizeFilename(filename: String) {
    // Define a regular expression to match the unsupported characters
    const unsupportedChars = /[\/&]/g;

    // Replace all unsupported characters with an underscore
    const sanitizedFilename = filename.replace(unsupportedChars, '_');

    return sanitizedFilename;
  }

  async exportAllFolders(chosenDir: FileSystemDirectoryHandle) {
    this.songFolders.forEach(async (folder) => {
      this.progress.addWork(folder.songs.length);
      let subDir: FileSystemDirectoryHandle | undefined;
      subDir = await chosenDir.getDirectoryHandle(folder.name, {
        create: true,
      });
      this.exportAllSongs(folder, subDir);
    });
  }

  async exportAllSongs(folder: SongFolder, chosenDir: FileSystemDirectoryHandle) {
    folder.songs.forEach(async (songFile) => {
      let handle: FileSystemFileHandle;
      handle = await chosenDir.getFileHandle(this.sanitizeFilename(songFile.name) + '.sng', {
        create: true,
      });

      try {
        await wait(async () => {
          if (!songFile.song) {
            await songFile.parse();
          }
          const song = songFile.song!;
          const effectFiles: EffectFile[] = song.effects.map(
            (effect) => this.findEffect(effect)!,
          );

          const effectsPath: Zippable = {
            'config.csv': strToU8(buildConfig(effectFiles)),
          };
          const effects = await Promise.all(
            effectFiles.map(
              async (effectFile): Promise<[string, Uint8Array]> => [
                effectFile.path,
                new Uint8Array(await effectFile.read()),
              ],
            ),
          );
          effects.forEach(([path, data]) => {
            effectsPath[path] = data;
          });

          const zipped = zipSync(
            {
              SONG: {
                'config.csv': strToU8(buildConfig([songFile])),
                [songFile.path]: new Uint8Array(song.save()),
              },
              EFFECTS: effectsPath,
              'version.bcf': strToU8(
                atob(
                  'AAAAAwAAAA4AdgBlAHIAcwBpAG8AbgAAAAEAAAAQAHIAZQB2AGkAcwBpAG8AbgAAAAAAAAAKAGIAdQBpAGwAZAAAFkE=',
                ),
              ),
            },
            {
              level: 0,
              mtime: new Date(),
            },
          );

          try {
            const file = await handle.createWritable();
            await file.write(zipped);
            await file.close();
            this.progress.completeWork();
          } catch (err) {
            console.error('Unable to save song', err);
            alert('Unable to export song');
            this.progress.completeWork();
          }
        });
      } catch (err) {
        console.error('Unable to get a file handle', err);
        this.progress.completeWork();
      }
    });
  }

  async exportAllDrumsets(chosenDir: FileSystemDirectoryHandle) {
    this.drumsets.forEach(async (drumsetFile) => {
      let handle: FileSystemFileHandle;
      handle = await chosenDir.getFileHandle(drumsetFile.name + '.DRM', {
        create: true,
      });

      await wait(async () => {
        await this.createSavedDrumset(drumsetFile, handle);
        this.progress.completeWork();
      });
    });
  }

  async exportAll() {
    this.progress.reset();
    let destDirectory: FileSystemDirectoryHandle | undefined;
    try {
      destDirectory = await window.showDirectoryPicker({
        id: 'export-all',
        startIn: 'desktop',
        mode: 'readwrite',
      });
    } catch (err: any) {
      if (err.name === 'AbortError' && /user aborted/i.test(err.message)) {
        return;
      }
      throw err;
    }

    const timestamp = Date.now();
    const date = new Date(timestamp);
    const dateStr = date.toISOString().replace(/:/g, '-');

    let exportDir = await destDirectory.getDirectoryHandle('BBMO_export_' + dateStr, {
      create: true,
    });

    let songDir = await exportDir.getDirectoryHandle('Songs', {
      create: true,
    });
    await this.exportAllFolders(songDir);
    let drumsetDir = await exportDir.getDirectoryHandle('Drumsets', {
      create: true,
    });
    this.progress.addWork(this.drumsets.length);
    await this.exportAllDrumsets(drumsetDir);
  }

  async syncToSDCard() {
    let sdCardDirectory: FileSystemDirectoryHandle | undefined;
    try {
      sdCardDirectory = await window.showDirectoryPicker({
        id: 'sync-project',
        startIn: 'desktop',
        mode: 'readwrite',
      });
    } catch (err: any) {
      if (err.name === 'AbortError' && /user aborted/i.test(err.message)) {
        return;
      }
      throw err;
    }

    await wait(async () => {
      if (sdCardDirectory) {
        this.progress.reset();
        try {
          await this.syncToDirectory(sdCardDirectory);
        } catch (err) {
          console.error('Unable to sync project', err);
          alert('Unable to sync project\n' + err);
          return;
        }
      }
    });
  }

  async syncToDirectory(directory: FileSystemDirectoryHandle) {
    await this.syncProjectFile(directory);
    const syncSongsPromise = this.syncSongs(directory);
    const syncPlaylistsPromise = this.syncPlaylists(directory);
    const syncDrumsetsPromise = this.syncDrumsets(directory);
    const syncEffectsPromise = this.syncEffects(directory);
    await this.progress.trackPromises([
      syncSongsPromise,
      syncPlaylistsPromise,
      syncDrumsetsPromise,
      syncEffectsPromise,
    ]);
    await this.removeTemporaryFiles(directory);
  }

  async syncToOFS() {
    const root = await navigator.storage.getDirectory();
    await this.syncToDirectory(root);
  }

  async syncProjectFile(sdCardDirectory: FileSystemDirectoryHandle) {
    this.progress.addWork();
    // Remove project files
    try {
      const entriesToBeDeleted: FileSystemHandle[] = [];
      for await (const [, entry] of sdCardDirectory.entries()) {
        if (entry.kind === 'file' && /.bbp$/i.test(entry.name)) {
          if (entry.name !== this.projectFile!.path) {
            entriesToBeDeleted.push(entry);
          }
        }
      }
      entriesToBeDeleted.forEach(async (entry) => {
        try {
          await sdCardDirectory.removeEntry(entry.name);
        } catch (err) {
          console.error(`Unable to remove project file ${entry.name}`, err);
        }
      });
    } finally {
      this.progress.completeWork();
    }

    this.progress.addWork();
    try {
      const handle = await sdCardDirectory.getFileHandle(
        this.projectFile!.path,
        {
          create: true,
        },
      );
      if (!handle) {
        throw new Error('Unable to create project file');
      }
      await this.projectFile!.save(handle);
    } finally {
      this.progress.completeWork();
    }
  }

  async syncSongs(sdCardDirectory: FileSystemDirectoryHandle) {
    const songsDirectory = await createDirectory(sdCardDirectory, 'SONGS');
    return this.saveFolders(songsDirectory, {
      batchLimit: 3,
      checkDirty: false,
    });
  }

  async syncPlaylists(sdCardDirectory: FileSystemDirectoryHandle) {
    const playlistsDirectory = await createDirectory(
      sdCardDirectory,
      'PLAYLISTS',
    );
    return this.savePlaylists(playlistsDirectory, { checkDirty: false });
  }

  async syncDrumsets(sdCardDirectory: FileSystemDirectoryHandle) {
    const drumsetsDirectory = await createDirectory(
      sdCardDirectory,
      'DRUMSETS',
    );
    return this.saveDrumsets(drumsetsDirectory, { checkDirty: false });
  }

  async syncEffects(handle: FileSystemDirectoryHandle) {
    const effectsDirectory = await createDirectory(handle, 'EFFECTS');
    return this.saveEffects(effectsDirectory, { checkDirty: false });
  }

  async saveFolders(
    songsDirectory: FileSystemDirectoryHandle,
    opts?: { batchLimit?: number; checkDirty?: boolean },
  ) {
    opts = { batchLimit: 3, checkDirty: true, ...opts };
    const batchLimit = opts.batchLimit ?? 3;
    const checkDirty = opts.checkDirty ?? true;
    const someFoldersDirty = this.songFolders.some((f) => f.isDirty);
    let jobs = this.songFolders.reduce(
      (jobs, folder) => jobs + 1 + folder.songs.length,
      0,
    );
    this.progress.addWork(jobs);

    try {
      await promiseBatch(this.songFolders, batchLimit, async (folder) => {
        try {
          await this.saveFolder(songsDirectory, folder, {
            batchLimit,
            checkDirty,
          });
        } finally {
          this.progress.completeWork();
        }
      });
    } catch (err) {
      console.error('Unable to save folders', err);
      throw err;
    }

    // Save the folder config
    if (!checkDirty || this.songFoldersDirty || someFoldersDirty) {
      this.progress.addWork();
      await saveConfig(songsDirectory, this.songFolders);
      this.progress.completeWork();

      this.progress.addWork();
      // Remove any folders that don't exist in the project
      const foldersToBeDeleted: FileSystemDirectoryHandle[] = [];
      for await (const [, entry] of songsDirectory.entries()) {
        if (entry.kind === 'directory') {
          if (
            !this.songFolders.find(
              (folder) =>
                folder.path.toUpperCase() === entry.name.toUpperCase(),
            )
          ) {
            foldersToBeDeleted.push(entry);
          }
        }
      }
      foldersToBeDeleted.forEach(async (entry, idx) => {
        try {
          await songsDirectory.removeEntry(entry.name, { recursive: true });
        } catch (e) {
          console.error(e);
          console.error(`Unable to remove folder ${entry.name}`, e);
        }
      });
      await this.removeTemporaryFiles(songsDirectory);
      this.progress.completeWork();
      checkDirty && (this.songFoldersDirty = false);
    }
  }

  async removeTemporaryFiles(directory: FileSystemDirectoryHandle) {
    const entriesToBeDeleted: FileSystemHandle[] = [];
    for await (const [, entry] of directory.entries()) {
      //Remove temporary files
      if (entry.kind === 'file' && /^\._|\.crswap$/i.test(entry.name)) {
        entriesToBeDeleted.push(entry);
      }
    }
    entriesToBeDeleted.forEach(async (entry) => {
      try {
        await directory.removeEntry(entry.name);
      } catch (e) {
        console.error(`Unable to remove file ${entry.name}`, e);
      }
    });
  }

  async saveFolder(
    songsDirectory: FileSystemDirectoryHandle,
    folder: SongFolder,
    opts?: { batchLimit?: number; checkDirty?: boolean },
  ) {
    opts = { batchLimit: 3, checkDirty: true, ...opts };
    const batchLimit = opts.batchLimit ?? 3;
    const checkDirty = opts.checkDirty ?? true;
    const someSongsDirty = folder.songs.some((s) => s.isDirty);

    const folderDirectory = await createDirectory(songsDirectory, folder.path);
    // NOTE: progress work was added in saveFolders
    await promiseBatch(folder.songs, batchLimit, async (song) => {
      try {
        await this.saveSong(folderDirectory, song, { checkDirty });
      } finally {
        this.progress.completeWork();
      }
    });

    // Save the folder config
    if (!checkDirty || folder.isDirty || someSongsDirty) {
      this.progress.addWork();
      await saveConfig(folderDirectory, folder.songs);
      this.progress.completeWork();
      await this.cleanFolder(folderDirectory, folder);
      checkDirty && folder.markClean();
    }
  }

  async cleanFolder(
    folderDirectory: FileSystemDirectoryHandle,
    folder: SongFolder,
  ) {
    // Remove any songs that don't exist in the project
    const entriesToBeDeleted: FileSystemHandle[] = [];
    for await (const [, entry] of folderDirectory.entries()) {
      if (entry.kind === 'file' && /.bbs$/i.test(entry.name)) {
        if (!folder.songs.find((song) => song.path === entry.name)) {
          entriesToBeDeleted.push(entry);
        }
      }
    }
    entriesToBeDeleted.forEach(async (entry) => {
      try {
        await folderDirectory.removeEntry(entry.name);
      } catch (e) {
        console.error(`Unable to song file ${entry.name}`, e);
      }
    });
    await this.removeTemporaryFiles(folderDirectory);
  }

  async saveSong(
    folder: FileSystemDirectoryHandle,
    songFile: SongFile,
    opts?: { checkDirty?: boolean },
  ) {
    opts = { checkDirty: true, ...opts };
    const checkDirty = opts.checkDirty ?? true;
    if (!checkDirty || songFile.isDirty) {
      const fullPath = `${songFile.folder.path}/${songFile.path}`;
      const saveTo = await folder.getFileHandle(songFile.path, {
        create: true,
      });
      if (!saveTo) {
        throw new Error(`Unable to create song file ${songFile.path}`);
      }

      try {
        const handle = saveTo ?? songFile.handle;

        if (!(await saveTo?.isSameEntry(songFile.handle))) {
          // Not the same file location so we have to copy
          let sourceCrc = songFile.song?.header?.crc;
          if (!sourceCrc) {
            const file = await songFile.handle.getFile();
            if (file.size > 32) {
              const buffer = await file.slice(0, 32).arrayBuffer();
              sourceCrc = Song.parseHeader(buffer).crc;
            }
          }

          let targetCrc;
          const targetFile = await handle.getFile();
          if (targetFile.size > 32) {
            const buffer = await targetFile.slice(0, 32).arrayBuffer();
            targetCrc = Song.parseHeader(buffer).crc;
          }

          if (sourceCrc !== targetCrc) {
            await copyFile(songFile.handle, handle);
          }
          return;
        }

        if (songFile.song) {
          const songData = songFile.song.save();
          songFile.name = songFile.song.name;
          const hash = await sha256hash(songData);
          if (songFile.hash !== hash) {
            songFile.hash = hash;
            const file = await handle.createWritable();
            await file.write(songData);
            await file.close();
          }
        }
      } catch (err) {
        console.error('Unable to save song', err, this);
        throw err;
      }
      checkDirty && songFile.markClean();
    }
  }

  async savePlaylists(
    playlistsDirectory: FileSystemDirectoryHandle,
    opts?: { checkDirty?: boolean },
  ) {
    opts = { checkDirty: true, ...opts };
    const checkDirty = opts.checkDirty ?? true;
    const somePlaylistsDirty = this.playlists.some((p) => p.isDirty);
    this.progress.addWork(this.playlists.length);
    await this.removeDeletedPlaylists(playlistsDirectory);
    await promiseBatch(this.playlists, 2, async (playlist) => {
      try {
        await this.savePlaylist(playlistsDirectory, playlist, { checkDirty });
      } finally {
        this.progress.completeWork();
      }
    });

    if (!checkDirty || this.playlistsDirty || somePlaylistsDirty) {
      await saveConfig(playlistsDirectory, this.playlists);
      await this.removeTemporaryFiles(playlistsDirectory);
      checkDirty && (this.playlistsDirty = false);
    }
  }
  async removeDeletedPlaylists(directory: FileSystemDirectoryHandle) {
    const entriesToBeDeleted: FileSystemHandle[] = [];
    const playlistPaths = this.playlists.map(p => p.path);
    for await (const [, entry] of directory.entries()) {
      // csvs of playlists that have been deleted
      if (entry.kind === 'file' && /^\d+\.csv$/.test(entry.name)) {
        if (!playlistPaths.includes(entry.name)) {
          entriesToBeDeleted.push(entry);
        }
      }
    }
    entriesToBeDeleted.forEach(async (entry) => {
      try {
        await directory.removeEntry(entry.name);
      } catch (e) {
        console.error(`Unable to remove file ${entry.name}`, e);
      }
    });
  }

  async savePlaylist(
    directory: FileSystemDirectoryHandle,
    playlist: Playlist,
    opts?: { checkDirty?: boolean },
  ) {
    opts = { checkDirty: true, ...opts };
    const checkDirty = opts.checkDirty ?? true;

    if (!checkDirty || playlist.isDirty) {
      const saveTo = await directory.getFileHandle(playlist.path, {
        create: true,
      });

      const handle = saveTo ?? playlist.handle;
      const config = playlist.songs.map((song) => song.path).join('\n');
      const file = await handle.createWritable();
      await file.write(config);
      await file.close();
      checkDirty && playlist.markClean();
    }
  }

  async saveDrumsets(
    drumsetsDirectory: FileSystemDirectoryHandle,
    opts?: { checkDirty?: boolean },
  ) {
    opts = { checkDirty: true, ...opts };
    const checkDirty = opts.checkDirty ?? true;
    const anyDrumsetsDirty = this.drumsets.some((d) => d.isDirty);
    this.progress.addWork(this.drumsets.length);
    await promiseBatch(this.drumsets, 1, async (drumset) => {
      try {
        await this.saveDrumset(drumsetsDirectory, drumset, { checkDirty });
      } finally {
        this.progress.completeWork();
      }
    });

    if (!checkDirty || this.drumsetsDirty || anyDrumsetsDirty) {
      await saveConfig(drumsetsDirectory, this.drumsets);
      // Remove any drumsets that don't exist in the project
      const drumsetsToBeDeleted: FileSystemHandle[] = [];
      for await (const [, entry] of drumsetsDirectory.entries()) {
        if (entry.kind === 'file' && /.drm$/i.test(entry.name)) {
          if (!this.drumsets.find((drumset) => drumset.path === entry.name)) {
            drumsetsToBeDeleted.push(entry);
          }
        }
      }
      drumsetsToBeDeleted.forEach(async (entry) => {
        try {
          await drumsetsDirectory.removeEntry(entry.name);
        } catch (e) {
          console.error(`Unable to remove drumset file ${entry.name}`, e);
        }
      });
      await this.removeTemporaryFiles(drumsetsDirectory);
      checkDirty && (this.drumsetsDirty = false);
    }
  }

  async saveDrumset(
    drumsetsDirectory: FileSystemDirectoryHandle,
    drumsetFile: DrumsetFile,
    opts?: { checkDirty?: boolean },
  ) {
    opts = { checkDirty: true, ...opts };
    const checkDirty = opts.checkDirty ?? true;

    if (!checkDirty || drumsetFile.isDirty) {
      const saveTo = await drumsetsDirectory.getFileHandle(drumsetFile.path, {
        create: true,
      });
      if (!saveTo) {
        throw new Error(`Unable to create drumset file ${drumsetFile.path}`);
      }

      try {
        const handle = saveTo ?? drumsetFile.handle;

        if (!(await saveTo?.isSameEntry(drumsetFile.handle))) {
          // Not the same file location so we have to copy
          let sourceCrc = drumsetFile.drumset?.header?.crc;
          if (!sourceCrc) {
            const file = await drumsetFile.handle.getFile();
            const buffer = await file.slice(0, 20).arrayBuffer();
            sourceCrc = Drumset.parseHeader(buffer).crc;
          }

          let targetCrc;
          const targetFile = await handle.getFile();
          if (targetFile.size > 0) {
            const buffer = await targetFile.slice(0, 20).arrayBuffer();
            targetCrc = Drumset.parseHeader(buffer).crc;
          }

          if (sourceCrc !== targetCrc) {
            await copyFile(drumsetFile.handle, handle);
          }
          return;
        }

        if (drumsetFile.drumset) {
          const drumsetData = drumsetFile.drumset.save();
          drumsetFile.name = drumsetFile.drumset.name;
          const hash = await sha256hash(drumsetData);
          if (drumsetFile.hash !== hash) {
            drumsetFile.hash = hash;
            const file = await handle.createWritable();
            await file.write(drumsetData);
            await file.close();
          }
        }
      } catch (err) {
        console.error('Unable to save drumset', err, this);
        throw err;
      }
      checkDirty && drumsetFile.markClean();
    }
  }

  async saveEffects(
    effectsDirectory: FileSystemDirectoryHandle,
    opts?: { checkDirty?: boolean },
  ) {
    opts = { checkDirty: true, ...opts };
    const checkDirty = opts.checkDirty ?? true;
    this.progress.addWork(this.effects.length);
    await promiseBatch(this.effects, 2, async (effect) => {
      try {
        await this.saveEffect(effectsDirectory, effect, { checkDirty });
      } finally {
        this.progress.completeWork();
      }
    });

    if (!checkDirty || this.effectsDirty) {
      await saveConfig(effectsDirectory, this.effects);
      // Remove any effects that don't exist in the project
      const effectsToBeDeleted: FileSystemHandle[] = [];
      for await (const [, entry] of effectsDirectory.entries()) {
        if (entry.kind === 'file' && /.drm$/i.test(entry.name)) {
          if (!this.effects.find((effect) => effect.path === entry.name)) {
            effectsToBeDeleted.push(entry);
          }
        }
      }
      effectsToBeDeleted.forEach(async (entry) => {
        try {
          await effectsDirectory.removeEntry(entry.name);
        } catch (e) {
          console.error(`Unable to remove effect file ${entry.name}`, e);
        }
      });
      await this.removeTemporaryFiles(effectsDirectory);
      checkDirty && (this.effectsDirty = false);
    }
  }

  async saveEffect(
    effectsDirectory: FileSystemDirectoryHandle,
    effectFile: EffectFile,
    opts?: { checkDirty?: boolean },
  ) {
    opts = { checkDirty: true, ...opts };
    const checkDirty = opts.checkDirty ?? true;

    if (!checkDirty || effectFile.isDirty) {
      const saveTo = await effectsDirectory.getFileHandle(effectFile.path, {
        create: true,
      });
      if (!saveTo) {
        throw new Error(`Unable to create effect file ${effectFile.path}`);
      }

      const handle = saveTo ?? effectFile.handle;
      if (!(await saveTo?.isSameEntry(effectFile.handle))) {
        const sourceFile = await effectFile.handle.getFile();
        const sourceContents = await sourceFile.arrayBuffer();
        const sourceHash = await sha256hash(sourceContents);

        const targetFile = await handle.getFile();
        let targetHash;
        if (targetFile.size > 0) {
          const targetContents = await targetFile.arrayBuffer();
          targetHash = await sha256hash(targetContents);
        }
        if (sourceHash !== targetHash) {
          await copyFile(effectFile.handle, handle);
        }
      }
      checkDirty && effectFile.markClean();
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    project: ProjectManagerService;
  }
}
