import { assert } from '@ember/debug';
import Reader from 'editor/utils/reader';

class Header {
  id: string;
  type: number;
  tracks: number;
  tickToQuarter: number;

  constructor(id: string, type: number, tracks: number, tickToQuarter: number) {
    this.id = id;
    this.type = type;
    this.tracks = tracks;
    this.tickToQuarter = tickToQuarter;
  }
}

class MidiEvent {}

class Track {
  channel: number = 0;
  bpm = 120;
  timeSignatureNumerator = 4;
  timeSignatureDenominator = 4;
  tickCount = 0;
  barLength = 0;
  barCouunt = 0;
  events: MidiEvent[] = [];
  name?: string;

  constructor() {}
}

export default class Midi {
  header: Header;
  tracks: Track[] = [];

  constructor(header: Header, tracks: Track[]) {
    this.header = header;
    this.tracks = tracks;
  }

  static parse(buffer: ArrayBuffer) {
    const reader = new Reader(new DataView(buffer), 0);
    const header = parseHeader(reader);
    let parsedTracks = parseTracks(reader, header);
    // Normalise ticks to 480/quarter note
    const tickAdjustmentFactor = 480 / header.tickToQuarter;
    header.tickToQuarter = 480;
    const tracks = parsedTracks.map((track) => {
      track.barLength = track.barLength * tickAdjustmentFactor;
      track.tickCount = track.tickCount * tickAdjustmentFactor;
      track.events = track.events.map((event: any) => {
        if (event.tick) {
          event.tick = event.tick * tickAdjustmentFactor;
        }
        return event;
      });
      return track;
    });
    return new Midi(header, tracks);
  }
}

function parseHeader(reader: Reader) {
  const id = reader.getString(4, false);
  assert('Invalid header id', id === 'MThd');
  const sectionLength = reader.getUint(false);
  assert('Invalid header length', sectionLength === 6);
  const type = reader.getShort(false);
  const tracks = reader.getShort(false);
  const tickToQuarter = reader.getShort(false);
  assert('Invalid tickToQuarter', tickToQuarter > 0);
  return new Header(id, type, tracks, tickToQuarter);
}

function parseTracks(reader: Reader, header: Header) {
  const tracks = [];
  for (let i = 0; i < header.tracks; i++) {
    const id = reader.getString(4, false);
    assert('Invalid track id', id === 'MTrk');
    const length = reader.getUint(false);
    const track = parseTrack(reader, length, header.tickToQuarter);
    tracks.push(track);
  }
  return tracks;
}

function parseTrack(reader: Reader, length: number, tickToQuarter: number) {
  const track = new Track();
  track.name = 'New MIDI Track';
  const events: any[] = [];
  let lastStatus = 0;
  let startOffset = reader.offset;
  let tick = 0;
  do {
    const delta = reader.getVarInt();
    const status = reader.getByte();
    if (status & 0x80) {
      lastStatus = status;
    } else {
      reader.offset--;
    }
    track.channel = lastStatus & 0x0f;
    let event: any;
    switch (lastStatus & 0xf0) {
      case 0x80: // note off
        {
          const note = reader.getByte();
          const velocity = reader.getByte();
          event = { type: 'noteOff', note, velocity };
        }
        break;
      case 0x90: // note on
        {
          const note = reader.getByte();
          const velocity = reader.getByte();
          event = { type: 'noteOn', note, velocity };
        }
        break;
      case 0xa0: // notePressure
        {
          const note = reader.getByte();
          const pressure = reader.getByte();
          event = { type: 'notePressure', note, pressure };
        }
        break;
      case 0xb0: // control change
        {
          const controller = reader.getByte();
          const value = reader.getByte();
          event = { type: 'controlChange', controller, value };
        }
        break;
      case 0xc0: // program change
        {
          const program = reader.getByte();
          event = { type: 'programChange', program };
        }
        break;
      case 0xd0: // channel pressure
        {
          const pressure = reader.getByte();
          event = { type: 'channelPressure', pressure };
        }
        break;
      case 0xe0: // pitch bend
        {
          const lsb = reader.getByte();
          const msb = reader.getByte();
          event = { type: 'pitchBend', value: (msb << 7) | lsb };
        }
        break;
      default: {
        if (lastStatus === 0xff) {
          assert('Meta message', lastStatus === 0xff);
          event = parseMetaEvent(reader);
        } else {
          assert('Sysex message', (lastStatus & 0xf0) == 0xf0);
          const length = reader.getVarInt();
          const data = reader.getBytes(length);
          event = { type: 'sysex', data };
        }
      }
    }
    tick += delta;
    event.delta = delta;
    event.tick = tick;
    events.push(event);
  } while (reader.offset - startOffset < length);
  track.events = events;
  const tempoMsg = events.findLast((e) => e.type === 'tempo');
  if (tempoMsg) {
    track.bpm = Math.round(
      ((60_000_000 / tempoMsg.tempo) * track.timeSignatureDenominator) / 4,
    );
  }
  const timeSignatureMsg = events.findLast((e) => e.type === 'timeSignature');
  if (timeSignatureMsg) {
    track.timeSignatureNumerator = timeSignatureMsg.numerator;
    track.timeSignatureDenominator = timeSignatureMsg.denominator;
  }
  const sequenceNameMsg = events.findLast((e) => e.type === 'sequenceName');
  if (sequenceNameMsg) {
    track.name = sequenceNameMsg.text;
  }
  const lastNote = events.findLast((e) => e.type === 'noteOn');
  if (lastNote) {
    const barLength =
      ((tickToQuarter * 4) / track.timeSignatureDenominator) *
      track.timeSignatureNumerator;
    track.barLength = barLength;
    const tickCount = Math.ceil(lastNote.tick / barLength) * barLength;
    track.tickCount = tickCount;
    const barCount = Math.ceil(tickCount / barLength);
    track.barCouunt = barCount;
  }
  return track;
}

function parseMetaEvent(reader: Reader) {
  const type = reader.getByte();
  const length = reader.getVarInt();

  switch (type) {
    case 0: // META_SEQUENCE_NUM
      return { type: 'sequenceNum', number: reader.getShort() };
    case 1: // META_TEXT
      return { type: 'text', text: reader.getString(length, false) };
    case 2: // META_COPYRIGHT
      return { type: 'copyright', text: reader.getString(length, false) };
    case 3: // META_SEQUENCE_NAME
      return { type: 'sequenceName', text: reader.getString(length, false) };
    case 4: // META_INSTRUMENT_NAME
      return { type: 'instrumentName', text: reader.getString(length, false) };
    case 5: // META_LYRIC
      return { type: 'lyric', text: reader.getString(length, false) };
    case 6: // META_MARKER
      return { type: 'marker', text: reader.getString(length, false) };
    case 7: // META_CUE_POINT
      return { type: 'cuePoint', text: reader.getString(length, false) };
    case 8: // META_PROGRAM_NAME
      return { type: 'programName', text: reader.getString(length, false) };
    case 9: // META_DEVICE_NAME
      return { type: 'deviceName', text: reader.getString(length, false) };
    case 0x20: // META_MIDI_CHANNEL_PREFIX
      return { type: 'midiChannelPrefix', channel: reader.getByte() };
    case 0x21: // META_MIDI_PORT
      return { type: 'midiPort', port: reader.getByte() };
    case 0x2f: // META_END_OF_TRACK
      return { type: 'endOfTrack' };
    case 0x51: {
      // META_TEMPO
      // Read length bytes
      const bytes = [];
      for (let i = 0; i < length; i++) {
        bytes.push(reader.getByte());
      }
      //build the tempo from the bytes
      let tempo = bytes.reduce((acc, byte) => (acc << 8) | byte, 0);
      if (tempo === 0) {
        tempo = 500_000;
      }
      return { type: 'tempo', tempo };
    }
    case 0x54: {
      // META_SMPTE_OFFSET
      const hour = reader.getByte();
      const minute = reader.getByte();
      const second = reader.getByte();
      const frame = reader.getByte();
      const subFrame = reader.getByte();
      return { type: 'smpteOffset', hour, minute, second, frame, subFrame };
    }
    case 0x58: {
      // META_TIME_SIGNATURE
      const numerator = reader.getByte();
      // 2 to the power of the following byte
      const denominator = Math.pow(2, reader.getByte());
      const midiClocksPerMetronomeClick = reader.getByte();
      const n32ndNotesPerMIDIQuarterNote = reader.getByte();
      return {
        type: 'timeSignature',
        numerator,
        denominator,
        midiClocksPerMetronomeClick,
        n32ndNotesPerMIDIQuarterNote,
      };
    }
    case 0x59: {
      // META_KEY_SIGNATURE
      const key = reader.getByte();
      const scale = reader.getByte();
      return { type: 'keySignature', key, scale };
    }
    case 0x7f: {
      // META_SEQUENCER_EVENT
      const data = reader.getBytes(length);
      return { type: 'sequencerEvent', data };
    }
  }
}
