import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type ProjectManagerService from 'editor/services/project-manager';

export default class RestrictedPlaylistsIndexRoute extends Route {
  @service declare projectManager: ProjectManagerService;
  @service declare router: RouterService;

  beforeModel() {
    if (this.projectManager.currentPlaylist) {
      this.router.transitionTo(
        'restricted.playlists.playlist',
        this.projectManager.currentPlaylist.id,
      );
    } else {
      if (this.projectManager.playlists.length > 0) {
        this.router.transitionTo(
          'restricted.playlists.playlist',
          this.projectManager.playlists[0]!.id,
        );
      }
    }
  }
}
