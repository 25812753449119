import Controller from '@ember/controller';
import { action } from '@ember/object';
import type { SongFile } from 'editor/services/project-manager';

export default class PlaygroundController extends Controller {
  declare model: { songFile: SongFile };

  get song() {
    return this.model.songFile.song!;
  }

  @action
  saveSong() {
    const song = this.song;
    const blob = new Blob([song.save()], {
      type: 'application/octet-stream',
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = song.name + '.bbs';
    a.click();
  }
}
