import Controller from '@ember/controller';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import { AddNewSongCommand } from 'editor/models/song-commands';
import type ProjectManagerService from 'editor/services/project-manager';
import { SongFile, type SongFolder } from 'editor/services/project-manager';
import type UndoManagerService from 'editor/services/undo-manager';

export default class RestrictedSongsFolderIndexController extends Controller {
  @service declare projectManager: ProjectManagerService;
  @service declare router: RouterService;
  @service declare undoManager: UndoManagerService;
  declare model: SongFolder;

  get folder() {
    return this.model;
  }

  @action
  async addSong() {
    const command = new AddNewSongCommand(this.projectManager, this.folder);
    command.on('execute', (song) => {
      this.router.transitionTo('restricted.songs.folder.song', song.path);
    });
    await this.undoManager.executeCommand(command);
  }

  @action
  async importSong() {
    const commands = await this.projectManager.importSong(this.folder);
    if (commands) {
      commands.on('execute', async (results) => {
        const [song] = results;
        if (song instanceof SongFile) {
          this.router.transitionTo(
            'restricted.songs.folder.song',
            song.folder.path,
            song.path,
          );
        }
      });
      commands.on('reverse', () => {
        this.projectManager.currentFolder = undefined;
        this.router.transitionTo('restricted.songs.index');
      });

      await this.undoManager.executeCommand(commands);
    }
  }
}
