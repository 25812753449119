import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type ProjectManagerService from 'editor/services/project-manager';

export default class RestrictedSongsIndexRoute extends Route {
  @service declare projectManager: ProjectManagerService;
  @service declare router: RouterService;

  beforeModel() {
    if (this.projectManager.drumsets.length > 0) {
      const folder =
        this.projectManager.currentFolder ?? this.projectManager.songFolders[0];
      const song = this.projectManager.currentSong ?? folder?.songs[0];
      if (folder && song) {
        this.router.transitionTo(
          'restricted.songs.folder.song',
          folder.path,
          song.path,
        );
      }
    }
  }
}
