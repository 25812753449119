import { assert } from '@ember/debug';
import { modifier } from 'ember-modifier';

interface DisabledSignature {
  Element: HTMLElement;
  Args: {
    Named: {
      when: boolean;
    };
    Positional: [];
  };
}

function disabled(
  element: HTMLElement,
  _: never[],
  { when: isDisabled }: DisabledSignature['Args']['Named'],
) {
  assert(
    'disabled modifier requires boolean `when` argument',
    typeof isDisabled === 'boolean',
  );

  element.setAttribute('aria-disabled', isDisabled ? 'true' : 'false');
  if (isDisabled) {
    element.setAttribute('readonly', 'true');
    if (element instanceof HTMLSelectElement) {
      element.setAttribute('disabled', 'true');
    }
  } else {
    element.removeAttribute('readonly');
    if (element instanceof HTMLSelectElement) {
      element.removeAttribute('disabled');
    }
  }
  element.classList.toggle('disabled', isDisabled);

  if (isDisabled) {
    const stopEvent = (event: MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
      return false;
    };

    element.addEventListener('click', stopEvent, true);
    return () => element.removeEventListener('click', stopEvent, true);
  }
  return () => {};
}

export default modifier<DisabledSignature>(disabled);

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    disabled: typeof disabled;
  }
}
