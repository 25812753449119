import { createNanoEvents, type Emitter } from 'nanoevents';

interface UndoRedoEvents<T> {
  execute: (t: T) => void;
  reverse: (t: T) => void;
}

export default abstract class Command<T> {
  name: string;
  emmitter: Emitter<UndoRedoEvents<T>>;

  constructor(name: string) {
    this.name = name;
    this.emmitter = createNanoEvents<UndoRedoEvents<T>>();
  }

  on<E extends keyof UndoRedoEvents<T>>(
    event: E,
    callback: UndoRedoEvents<T>[E],
  ) {
    return this.emmitter.on(event, callback);
  }

  perform(): T | Promise<T> {
    const result: T | Promise<T> = this.execute();
    return Promise.resolve(result).then((result) => {
      this.markDirty();
      this.emmitter.emit('execute', result);
      return result;
    });
  }

  undo(): T | Promise<T> {
    const result: T | Promise<T> = this.reverse();
    return Promise.resolve(result).then((result) => {
      this.markDirty();
      this.emmitter.emit('reverse', result);
      return result;
    });
  }

  abstract markDirty(): void;

  abstract execute(): T | Promise<T>;

  abstract reverse(): T | Promise<T>;
}

export class CompositeCommand<T> extends Command<T[]> {
  commands: Command<T>[] = [];

  constructor(name: string, commands: Command<T>[]) {
    super(name);
    this.commands = commands;
  }

  markDirty(): void {
    this.commands.forEach((command) => command.markDirty());
  }

  execute() {
    return Promise.all(
      this.commands.map((command: Command<T>) => command.perform()),
    );
  }

  reverse() {
    return Promise.all(
      this.commands.toReversed().map((command: Command<T>) => command.undo()),
    );
  }
}
