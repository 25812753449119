import Controller from '@ember/controller';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type ProjectManagerService from 'editor/services/project-manager';
import type UndoManagerService from 'editor/services/undo-manager';

export default class RestrictedSongsIndexController extends Controller {
  @service declare projectManager: ProjectManagerService;
  @service declare undoManager: UndoManagerService;
  @service declare router: RouterService;

  @action
  async importDrumset() {
    const command = await this.projectManager.importDrumset();
    if (command) {
      command.on('execute', (drumset) => {
        this.router.transitionTo('restricted.drumsets.drumset', drumset.path);
      });
      command.on('reverse', () => {
        this.router.transitionTo('restricted.drumsets');
      });
      this.undoManager.executeCommand(command);
    }
  }
}
