import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type ProjectManagerService from 'editor/services/project-manager';

export default class RestrictedPlaylistsRoute extends Route {
  @service declare projectManager: ProjectManagerService;
  @service declare router: RouterService;

  beforeModel(transition: Transition) {
    if (!this.projectManager.currentProject) {
      this.projectManager.transition = transition;
      this.router.transitionTo('restricted');
    }
  }
}
