import { template } from "@ember/template-compiler";
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { CompositeCommand } from 'editor/models/command';
import type { MidiEvent } from 'editor/models/song';
import { UpdateMidiEventVelocityCommand } from 'editor/models/song-commands';
import type UndoManagerService from 'editor/services/undo-manager';
import constrainValue from 'editor/utils/constrain-value';
interface VelocityValuesSignature {
    Element: HTMLDivElement;
    Args: {
        events: MidiEvent[];
        defaultValue: number;
        // eslint-disable-next-line no-unused-vars
        setDefault: (value: number) => void;
    };
    Blocks: {
    };
}
export default class VelocityValues extends Component<VelocityValuesSignature> {
    @service
    undoManager: UndoManagerService;
    constructor(owner1: unknown, args1: VelocityValuesSignature['Args']){
        super(owner1, args1);
    }
    get events() {
        return this.args.events;
    }
    get hasEvents() {
        return this.events.length > 0;
    }
    get hasMultipleEvents() {
        return this.events.length > 1 && this.minValue !== this.maxValue;
    }
    get minValue() {
        return this.events.reduce((min1, event1)=>Math.min(min1, event1.velocity), 127);
    }
    get maxValue() {
        return this.events.reduce((max1, event1)=>Math.max(max1, event1.velocity), 0);
    }
    @action
    updateMinValue(ev1: Event) {
        const input1 = ev1.target as HTMLInputElement;
        const oldMin1 = this.minValue;
        const oldMax1 = this.maxValue;
        let newMin1 = parseInt(input1.value, 10);
        if (isNaN(newMin1)) {
            newMin1 = 0;
        }
        if (newMin1 == oldMin1) {
            return;
        }
        let commands1: UpdateMidiEventVelocityCommand[] = [];
        if (this.events.length === 1 || this.minValue === this.maxValue) {
            const newVelocity1 = newMin1;
            this.events.forEach((event1)=>{
                commands1.push(new UpdateMidiEventVelocityCommand(event1, event1.velocity, newVelocity1));
            });
        } else {
            this.events.forEach((event1)=>{
                const newVelocity1 = Math.min(127, Math.round(((event1.velocity - oldMin1) / (oldMax1 - oldMin1)) * (oldMax1 - newMin1) + newMin1));
                commands1.push(new UpdateMidiEventVelocityCommand(event1, event1.velocity, newVelocity1));
            });
        }
        this.undoManager.executeCommand(new CompositeCommand(commands1[0]!.name, commands1));
    }
    @action
    updateMaxValue(ev1: Event) {
        const input1 = ev1.target as HTMLInputElement;
        const oldMin1 = this.minValue;
        const oldMax1 = this.maxValue;
        let newMax1 = parseInt(input1.value, 10);
        if (isNaN(newMax1)) {
            newMax1 = 127;
        }
        if (newMax1 == oldMax1) {
            return;
        }
        const commands1 = this.events.map((event1)=>{
            const newVelocity1 = Math.min(127, Math.round(((event1.velocity - oldMin1) / (oldMax1 - oldMin1)) * (newMax1 - oldMin1) + oldMin1));
            return new UpdateMidiEventVelocityCommand(event1, event1.velocity, newVelocity1);
        });
        this.undoManager.executeCommand(new CompositeCommand(commands1[0]!.name, commands1));
    }
    @action
    setDefaultValue(ev1: Event) {
        const input1 = ev1.target as HTMLInputElement;
        const newDefault1 = parseInt(input1.value, 10);
        if (isNaN(newDefault1)) {
            return;
        }
        this.args.setDefault(constrainValue(newDefault1, 0, 127));
    }
    static{
        template(`
    <div class="flex items-center gap-px" ...attributes>
      {{#if this.hasEvents}}
        <input
          class="w-10 p-1 text-right border rounded dark:bg-primary-darkComponent dark:border-primary-darkBorderColor"
          value={{this.minValue}}
          aria-label={{if this.hasMultipleEvents "Minimum velocity" "Velocity"}}
          {{on "change" this.updateMinValue}}
        />
        {{#if this.hasMultipleEvents}}
          <span>-</span><input
            class="w-10 p-1 border rounded dark:bg-primary-darkComponent dark:border-primary-darkBorderColor"
            value={{this.maxValue}}
            aria-label="Maximum velocity"
            {{on "change" this.updateMaxValue}}
          />
        {{/if}}
      {{else}}
        <input
          class="w-10 p-1 text-right border rounded dark:bg-primary-darkComponent dark:border-primary-darkBorderColor"
          value={{@defaultValue}}
          aria-label="Default velocity"
          {{on "change" this.setDefaultValue}}
        />
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        VelocityValues: typeof VelocityValues;
    }
}
