import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import Component from '@glimmer/component';
import eq from 'editor/helpers/eq';
import version from 'editor/helpers/version';
import type ProjectManagerService from 'editor/services/project-manager';
import type { DrumsetFile, SongFile } from 'editor/services/project-manager';
import StatusService from 'editor/services/status';
import fileSize from 'editor/utils/file-size';
import ErrorPopupDialog from './error-popup';
import Button from './button';
import { fn } from '@ember/helper';
import { on } from '@ember/modifier';
import velcro from 'ember-velcro/modifiers/velcro';
import onClickOutside from 'editor/modifiers/on-click-outside';
interface DrumsetStatusSignature {
    Element: HTMLDivElement;
    Args: {
        drumsetFile: DrumsetFile;
    };
    Blocks: {
    };
}
let DrumsetFileStatus = class DrumsetFileStatus extends Component<DrumsetStatusSignature> {
    @service
    projectManager: ProjectManagerService;
    @tracked
    displayErrorPopup = false;
    @tracked
    errorData: string[] = [];
    constructor(owner1: unknown, args1: DrumsetStatusSignature['Args']){
        super(owner1, args1);
    }
    get drumset() {
        return this.args.drumsetFile.drumset!;
    }
    drumsetMessage(drumsetFile1: DrumsetFile) {
        let validity1 = drumsetFile1.drumset!.isValid;
        if (validity1 == true) {
            validity1 = '';
        } else {
            validity1 = ` - ${validity1}`;
        }
        return `${drumsetFile1.name} - ${fileSize(drumsetFile1.drumset!.size)}${validity1}`;
    }
    @action
    areSongsValid() {
        this.errorData = [];
        this.errorData.push('Errors in songs:\n');
        this.projectManager.songFolders.forEach((folder1)=>{
            folder1.songs.forEach((songFile1)=>{
                let validSong1 = songFile1.song?.isValid;
                if (validSong1 === true || validSong1 === undefined) {
                // Do nothing, undefined means the song has not been clicked/initialized, so there is nothing wrong with it and it won't be saved
                } else {
                    this.errorData.push(`Song ${songFile1.name}: ${validSong1}\n`);
                }
            });
        });
    }
    @action
    areDrumsetsValid() {
        this.errorData.push('Errors in drumsets:\n');
        this.projectManager.drumsets.forEach((drumset1)=>{
            let validDrumset1 = drumset1.drumset?.isValid;
            if (validDrumset1 === true || validDrumset1 === undefined) {
            // Do nothing, undefined means the drumset has not been clicked/initialized, so there is nothing wrong with it and it won't be saved
            } else {
                this.errorData.push(`Drumset ${drumset1.name}: ${validDrumset1}\n`);
            }
        });
    }
    @action
    toggleErrorPopup(force1: boolean) {
        this.areSongsValid();
        this.areDrumsetsValid();
        this.displayErrorPopup = force1;
    }
    get drumsetSize() {
        return this.args.drumsetFile.drumset!.size;
    }
    static{
        template(`
    {{@drumsetFile.name}}
    -
    {{fileSize this.drumsetSize}}
    {{#if (eq true this.drumset.isValid)}}
      <span class="text-green-500">✓</span>
    {{else}}
      <Button class="!text-red-500" @icon="triangle-exclamation" id="error-text-Btn-drum" {{on "click" (fn this.toggleErrorPopup true)}}>
        {{this.drumset.isValid}}
      </Button>
    {{/if}}
    {{#if this.displayErrorPopup}}
      <div
        class="fixed bg-white border rounded shadow-md p-4 z-50 max-w-96 dark:bg-primary-darkFill dark:border-primary-darkBorderColor"
        {{velcro "#error-text-Btn-drum" placement="bottom-start"}}
        {{onClickOutside (fn this.toggleErrorPopup false)}}
      ><ErrorPopupDialog @errorData={{this.errorData}} @onClose={{fn this.toggleErrorPopup false}} /></div>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
interface StatusBarSignature {
    Element: HTMLDivElement;
    Args: {
    };
    Blocks: {
    };
}
export default class StatusBarComponent extends Component<StatusBarSignature> {
    @service
    status: StatusService;
    @service
    projectManager: ProjectManagerService;
    @tracked
    displayErrorPopup = false;
    @tracked
    errorData: string[] = [];
    constructor(owner1: unknown, args1: StatusBarSignature['Args']){
        super(owner1, args1);
    }
    get drumsetFile() {
        return this.status.drumsetFile;
    }
    get songFile() {
        return this.status.songFile;
    }
    @action
    songFileMessage(songFile1: SongFile) {
        const folderIndex1 = this.projectManager.songFolders.indexOf(songFile1.folder);
        const channel1 = 0;
        const songIndex1 = songFile1.folder.songs.indexOf(songFile1);
        const folderMSB1 = Math.floor(folderIndex1 / 128);
        const folderLSB1 = folderIndex1 % 128;
        const midiInfo1 = `CC0:${folderMSB1} CC32:${folderLSB1} PC:${songIndex1} {PC${folderMSB1}.${folderLSB1}:${songIndex1}@${channel1}}`;
        return `${songFile1.name} - ${midiInfo1}`;
    }
    @action
    isSongValid(songFile1: SongFile) {
        let validSong1 = songFile1.song!.isValid;
        if (validSong1 === true) {
            validSong1 = '';
        } else {
            validSong1 = ` - ${validSong1}`;
        }
        return `${validSong1}`;
    }
    @action
    areSongsValid() {
        this.errorData = [];
        this.errorData.push('Errors in songs:\n');
        this.projectManager.songFolders.forEach((folder1)=>{
            folder1.songs.forEach((songFile1)=>{
                let validSong1 = songFile1.song?.isValid;
                if (validSong1 === true || validSong1 === undefined) {
                // Do nothing, undefined means the song has not been clicked/initialized, so there is nothing wrong with it and it won't be saved
                } else {
                    this.errorData.push(`Song ${songFile1.name}: ${validSong1}\n`);
                }
            });
        });
    }
    @action
    areDrumsetsValid() {
        this.errorData.push('Errors in drumsets:\n');
        this.projectManager.drumsets.forEach((drumset1)=>{
            let validDrumset1 = drumset1.drumset?.isValid;
            if (validDrumset1 === true || validDrumset1 === undefined) {
            // Do nothing, undefined means the drumset has not been clicked/initialized, so there is nothing wrong with it and it won't be saved
            } else {
                this.errorData.push(`Drumset ${drumset1.name}: ${validDrumset1}\n`);
            }
        });
    }
    @action
    toggleErrorPopup(force1: boolean) {
        this.areSongsValid();
        this.areDrumsetsValid();
        this.displayErrorPopup = force1;
    }
    drumsetMessage(drumsetFile1: DrumsetFile) {
        let validity1 = drumsetFile1.drumset!.isValid;
        if (validity1 == true) {
            validity1 = '';
        } else {
            validity1 = ` - ${validity1}`;
        }
        return `${drumsetFile1.name} - ${fileSize(drumsetFile1.drumset!.size)}${validity1}`;
    }
    static{
        template(`
    <div class="grid grid-cols-[1fr_max-content_1fr] gap-4">
      <div data-test-left>
        {{#if this.drumsetFile}}
          <DrumsetFileStatus @drumsetFile={{this.drumsetFile}} />
        {{else if this.songFile}}
          {{this.songFileMessage this.songFile}}
          {{#if (eq true this.songFile.song.isValid)}}
            <span class="text-green-500">✓</span>
          {{else}}
            <Button class="!text-red-500" @icon="triangle-exclamation" id="error-text-Btn-song" {{on "click" (fn this.toggleErrorPopup true)}}>
              {{this.isSongValid this.songFile}}
            </Button>
          {{/if}}
        {{else}}
          Welcome to BeatBuddy Manager!
        {{/if}}
      </div>
      <div data-test-version class="text-center border-l border-r border-gray-300 px-4 dark:border-primary-darkBorderColor"><a
          href="https://www.singularsound.com/pages/bbmo-changelog"
          target="_blank"
          rel="noopener noreferrer"
        >{{version}}</a></div>
      <div data-test-right>{{! right }}</div>
    </div>
    {{#if this.displayErrorPopup}}
      <div
        class="fixed bg-white border rounded shadow-md p-4 z-50 max-w-96 dark:bg-primary-darkFill dark:border-primary-darkBorderColor"
        {{velcro "#error-text-Btn-song" placement="bottom-start"}}
        {{onClickOutside (fn this.toggleErrorPopup false)}}
      ><ErrorPopupDialog @errorData={{this.errorData}} @onClose={{fn this.toggleErrorPopup false}} /></div>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        StatusBar: typeof StatusBarComponent;
    }
}
