import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type ProjectManagerService from 'editor/services/project-manager';

export default class RestrictedDrumsetsIndexRoute extends Route {
  @service declare router: RouterService;
  @service declare projectManager: ProjectManagerService;

  beforeModel() {
    const drumsetFile =
      this.projectManager.currentDrumset ?? this.projectManager.drumsets.at(0);
    if (drumsetFile) {
      this.router.transitionTo('restricted.drumsets.drumset', drumsetFile.path);
    }
  }
}
