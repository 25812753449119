export default function buildGainTable() {
  const gainTable: number[][] = Array.from(
    { length: 128 },
    () => new Array(128),
  );
  let top_db;
  let req_db;
  let dif_db;

  for (let i = 1; i < 128; i++) {
    top_db = -20.0 * Math.log10(127.0 / i);
    for (let j = 1; j <= i; j++) {
      req_db = -20.0 * Math.log10(127.0 / j);
      dif_db = req_db - top_db;

      // Convert dB into fractional gain
      gainTable[i]![j] = Math.pow(10.0, dif_db / 20.0);
    }
  }
  return gainTable;
}
