import { template } from "@ember/template-compiler";
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import markDroppable from 'editor/modifiers/mark-droppable';
interface DragDropContainerSignature {
    Element: HTMLDivElement;
    Args: {
        // eslint-disable-next-line no-unused-vars
        onDrop: (files: FileList) => void;
        isActive?: boolean;
    };
    Blocks: {
        default: [];
    };
}
export default class DragDropContainer extends Component<DragDropContainerSignature> {
    constructor(owner1: unknown, args1: DragDropContainerSignature['Args']){
        super(owner1, args1);
    }
    @tracked
    isDragging = false;
    dragTarget?: EventTarget | null;
    isActive() {
        return this.args.isActive ?? true;
    }
    @action
    handleDragEnter(ev1: DragEvent) {
        this.#cancelEvents(ev1);
        if (!this.isActive()) {
            return;
        }
        this.isDragging = true;
        this.dragTarget = ev1.target;
    }
    @action
    handleDragOver(ev1: DragEvent) {
        this.#cancelEvents(ev1);
        if (ev1.dataTransfer) {
            ev1.dataTransfer.dropEffect = 'copy';
        }
    }
    @action
    handleDragLeave(ev1: DragEvent) {
        if (ev1.target == this.dragTarget) {
            this.#cancelEvents(ev1);
            this.isDragging = false;
            this.dragTarget = undefined;
        }
    }
    @action
    async handleDrop(ev1: DragEvent) {
        if (!this.isActive()) {
            return;
        }
        let files1 = ev1.dataTransfer?.files;
        this.isDragging = false;
        this.dragTarget = undefined;
        if (files1?.length) {
            this.#cancelEvents(ev1);
            this.args.onDrop(files1);
        }
    }
    #cancelEvents(ev1: DragEvent) {
        ev1.stopPropagation();
        ev1.preventDefault();
    }
    static{
        template(`
    <div
      ...attributes
      {{markDroppable this.isDragging}}
      {{on "dragenter" this.handleDragEnter}}
      {{on "dragover" this.handleDragOver}}
      {{on "dragleave" this.handleDragLeave}}
      {{on "drop" this.handleDrop}}
    >
      {{yield}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        DragDropContainer: typeof DragDropContainer;
    }
}
