import { template } from "@ember/template-compiler";
import { fn, hash } from '@ember/helper';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import eq from 'editor/helpers/eq';
import gt from 'editor/helpers/gt';
import notEq from 'editor/helpers/not-eq';
import round from 'editor/helpers/round';
import Command, { CompositeCommand } from 'editor/models/command';
import Drumset, { Velocity, type Instrument } from 'editor/models/drumset';
import { AddSamplesToInstrumentCommand, RemoveSampleFromInstrumentCommand, UpdateSampleVelocityCommand, RenameVelocityCommand } from 'editor/models/drumset-commands';
import disabled from 'editor/modifiers/disabled';
import markDroppable from 'editor/modifiers/mark-droppable';
import onKey from 'editor/modifiers/on-key';
import scrollIntoView from 'editor/modifiers/scroll-into-view';
import selected from 'editor/modifiers/selected';
import sortable from 'editor/modifiers/sortable';
import type SongPlayerService from 'editor/services/song-player';
import type UndoManagerService from 'editor/services/undo-manager';
import bbffFilename from 'editor/utils/bbff-filename';
import constrainValue from 'editor/utils/constrain-value';
import { getGainNodeForVelocity } from 'editor/utils/play-instrument';
import { modifier } from 'ember-modifier';
import Button from './button';
import SelectableItem from './selectable-item';
import nameFilter from 'editor/utils/name-filter';
let VelocityGroup = class VelocityGroup {
    vel: number = 0;
    velocities: Velocity[] = [];
    constructor(velocity1: Velocity){
        this.vel = velocity1.vel;
        this.velocities.push(velocity1);
    }
};
function autoSort(samples1: Velocity[]): [Velocity, number][] {
    if (samples1.length === 0) return [];
    const sorted1 = samples1.toSorted((v11, v21)=>v11.loudness - v21.loudness);
    if (sorted1.length < 2) {
        return samples1.map((v3)=>[
                v3,
                v3.vel ?? 0
            ]);
    }
    // Take the sorted array and group it into sub arrays where the difference between the loudness of two consecutive elements is less than 1 dB
    const groups1: Velocity[][] = [];
    let currentGroup1: Velocity[] = [];
    for(let i1 = 0; i1 < sorted1.length; i1++){
        const velocity1 = sorted1[i1]!;
        const nextVelocity1 = sorted1[i1 + 1];
        currentGroup1.push(velocity1);
        if (nextVelocity1 && nextVelocity1.loudness - currentGroup1[0]!.loudness > 1.5) {
            groups1.push(currentGroup1);
            currentGroup1 = [];
        }
    }
    if (currentGroup1.length) {
        groups1.push(currentGroup1);
    }
    const loudest1 = 0;
    const quietest1 = sorted1[0]!.loudness;
    const velocities1: [Velocity, number][] = [];
    const firstGroup1 = groups1[0]!;
    firstGroup1.forEach((v3)=>{
        velocities1.push([
            v3,
            0
        ]);
    });
    for(var i1 = 1, ii1 = groups1.length; i1 < ii1; i1++){
        const group1 = groups1[i1]!;
        const avg1 = group1.reduce((acc1, v3)=>acc1 + v3.loudness, 0) / group1.length;
        // Calculate where avg sit between the quietest and the loudest?
        const position1 = (avg1 - quietest1) / (loudest1 - quietest1);
        const minVelocity1 = 8; // minimum velocity value
        const maxVelocity1 = 111; // maximum velocity value
        const velocity1 = Math.round(minVelocity1 + position1 * (maxVelocity1 - minVelocity1));
        group1.forEach((v3)=>{
            velocities1.push([
                v3,
                velocity1
            ]);
        });
    }
    return velocities1;
}
const changeVelocity = modifier((element1: HTMLElement, [group1, minVelocity1, maxVelocity1]: [VelocityGroup, number, number])=>{
    let parentElement1: HTMLElement, dragOffsetY1 = 0;
    const moveTo1 = (y1: number)=>{
        element1.style.transform = `translate3d(0, ${y1}px, 0)`;
    };
    const onMouseMove1 = (ev1: MouseEvent)=>{
        ev1.preventDefault();
        ev1.stopImmediatePropagation();
        const rect1 = parentElement1.getBoundingClientRect();
        const maxY1 = rect1.bottom - (maxVelocity1 / 127) * rect1.height;
        const minY1 = rect1.bottom - (minVelocity1 / 127) * rect1.height;
        let y1 = constrainValue(ev1.clientY, maxY1, minY1);
        const posVel1 = Math.round((1 - (y1 - rect1.top) / rect1.height) * 127);
        const velocity1 = constrainValue(posVel1, minVelocity1, maxVelocity1);
        document.querySelectorAll('input.vel-group').forEach((el1)=>el1.dispatchEvent(new CustomEvent('bbff.group-vel-change', {
                detail: {
                    group: group1,
                    velocity: velocity1
                }
            })));
        moveTo1(y1 - dragOffsetY1);
    };
    const onPointerUp1 = (ev1: PointerEvent)=>{
        document.removeEventListener('pointerup', onPointerUp1);
        element1.releasePointerCapture(ev1.pointerId);
    };
    const onMouseUp1 = (ev1: MouseEvent)=>{
        document.removeEventListener('mouseup', onMouseUp1);
        document.removeEventListener('mousemove', onMouseMove1);
        const rect1 = parentElement1.getBoundingClientRect();
        let y1 = constrainValue(ev1.clientY, rect1.top, rect1.bottom);
        const posVel1 = Math.round((1 - (y1 - rect1.top) / rect1.height) * 127);
        const velocity1 = constrainValue(posVel1, minVelocity1, maxVelocity1);
        parentElement1.dispatchEvent(new CustomEvent('bbff.group-vel-change', {
            detail: {
                group: group1,
                velocity: velocity1
            }
        }));
        dragOffsetY1 = 0;
    };
    const onPointerDown1 = (ev1: PointerEvent)=>{
        document.addEventListener('pointerup', onPointerUp1);
        element1.setPointerCapture(ev1.pointerId);
    };
    const onMouseDown1 = (ev1: MouseEvent)=>{
        ev1.preventDefault();
        dragOffsetY1 = ev1.clientY;
        document.addEventListener('mousemove', onMouseMove1);
        document.addEventListener('mouseup', onMouseUp1);
    };
    const cancelDragNDrop1 = (e1: DragEvent)=>{
        e1.preventDefault();
    };
    parentElement1 = element1.parentElement!;
    element1.addEventListener('mousedown', onMouseDown1, {
        capture: true
    });
    element1.addEventListener('pointerdown', onPointerDown1);
    element1.addEventListener('dragstart', cancelDragNDrop1);
    return ()=>{
        element1.removeEventListener('dragstart', cancelDragNDrop1);
        element1.removeEventListener('pointerdown', onPointerDown1);
        element1.removeEventListener('mousedown', onMouseDown1);
    };
});
interface SampleEditorSignature {
    Element: HTMLDivElement;
    Args: {
        drumset: Drumset;
        instrument: Instrument;
    };
    Blocks: {
        default: [addSamples: () => void, autoSort: () => void];
    };
}
export default class SampleEditor extends Component<SampleEditorSignature> {
    @service
    songPlayer: SongPlayerService;
    @service
    undoManager: UndoManagerService;
    @service
    router: RouterService;
    undoUpdateTimer: number = 0;
    constructor(owner1: unknown, args1: SampleEditorSignature['Args']){
        super(owner1, args1);
    }
    get audioContext() {
        return this.songPlayer.audioContext;
    }
    get drumset() {
        return this.args.drumset;
    }
    get instrument() {
        return this.args.instrument;
    }
    get velocityGroups() {
        const groups1: VelocityGroup[] = [];
        this.instrument.velocities.forEach((velocity1)=>{
            const group1 = groups1.find((g1)=>g1.vel === velocity1.vel);
            if (group1) {
                group1.velocities.push(velocity1);
            } else {
                groups1.push(new VelocityGroup(velocity1));
            }
        });
        return groups1.reverse();
    }
    @tracked
    isDragging = false;
    dragTarget?: EventTarget | null;
    @action
    handleDragEnter(ev1: DragEvent) {
        this._cancelEvents(ev1);
        this.isDragging = true;
        this.dragTarget = ev1.target;
    }
    @action
    handleDragOver(ev1: DragEvent) {
        this._cancelEvents(ev1);
        if (ev1.dataTransfer) {
            ev1.dataTransfer.dropEffect = 'copy';
        }
    }
    @action
    handleDragLeave(ev1: DragEvent) {
        if (ev1.target == this.dragTarget) {
            this._cancelEvents(ev1);
            this.isDragging = false;
            this.dragTarget = undefined;
        }
    }
    @action
    async handleDrop(group1: VelocityGroup, ev1: DragEvent) {
        let files1 = ev1.dataTransfer?.files;
        this.isDragging = false;
        this.dragTarget = undefined;
        if (files1?.length) {
            this._cancelEvents(ev1);
            this.#addSampleFiles(files1, group1);
        }
    }
    @action
    async addSamples() {
        // eslint-disable-next-line no-undef
        let handles1: FileSystemFileHandle[];
        try {
            handles1 = await window.showOpenFilePicker({
                multiple: true,
                excludeAcceptAllOption: true,
                id: 'samples',
                startIn: 'music',
                types: [
                    {
                        description: 'Wave files',
                        accept: {
                            'audio/wav': [
                                '.wav'
                            ]
                        }
                    }
                ]
            });
        } catch (err1: any) {
            if (err1.name === 'AbortError' && /user aborted/i.test(err1.message)) {
                return;
            }
            throw err1;
        }
        const files1 = await Promise.all(handles1.map((handle1)=>handle1.getFile()));
        this.#addSampleFiles(files1);
    }
    async #addSampleFiles(files1: FileList | File[], group1?: VelocityGroup) {
        group1 = group1 ?? this.velocityGroups[0]!;
        const velocityValue1 = group1.vel;
        let velocities1: Velocity[] = [];
        for(let i1 = 0, ii1 = files1.length; i1 < ii1; i1++){
            let file1 = files1[i1]!;
            const buffer1 = await file1.arrayBuffer();
            const velocity1 = await Drumset.parseWaveFile(buffer1);
            velocity1.vel = velocityValue1;
            velocity1.fileName = bbffFilename(file1.name);
            if (!this.instrument.velocities.find((v3)=>v3.crc === velocity1.crc)) {
                velocities1.push(velocity1);
            }
        }
        if (velocities1.length) {
            // If this is the first velocity group and it has no samples.
            if (this.velocityGroups.length === 1 && group1.velocities.length === 1 && !group1.velocities[0]!.data) {
                velocities1 = autoSort(velocities1).map(([sample1, velocity1])=>{
                    sample1.vel = velocity1;
                    return sample1;
                });
            }
            const command1 = new AddSamplesToInstrumentCommand(this.instrument, velocities1);
            this.undoManager.executeCommand(this.removeBlankSample(group1, command1));
        }
    }
    removeBlankSample(group1: VelocityGroup, command1: Command<any>) {
        const blankVelocity1 = group1.velocities.find((v3)=>!v3.sample);
        if (blankVelocity1) {
            return new CompositeCommand(command1.name, [
                new RemoveSampleFromInstrumentCommand(this.instrument, blankVelocity1),
                command1
            ]);
        } else {
            return command1;
        }
    }
    _cancelEvents(ev1: DragEvent) {
        ev1.stopPropagation();
        ev1.preventDefault();
    }
    @action
    deleteSelectedSample(ev1: KeyboardEvent) {
        if (this.triggeredVelocity) {
            ev1?.stopImmediatePropagation();
            let command1: Command<any> = new RemoveSampleFromInstrumentCommand(this.instrument, this.triggeredVelocity);
            command1.on('execute', ()=>{
                this.triggeredVelocity = undefined;
            });
            command1.on('reverse', (velocity1)=>{
                this.triggeredVelocity = velocity1;
            });
            if (this.instrument.velocities.length === 1) {
                command1 = new CompositeCommand(command1.name, [
                    command1,
                    new AddSamplesToInstrumentCommand(this.instrument, [
                        new Velocity(0)
                    ])
                ]);
            }
            this.undoManager.executeCommand(command1);
        }
    }
    @action
    autoSort() {
        const commands1 = autoSort(this.instrument.velocities).map(([sample1, velocity1])=>{
            return new UpdateSampleVelocityCommand(this.instrument, sample1, velocity1);
        });
        this.undoManager.executeCommand(new CompositeCommand('Auto sort samples', commands1));
    }
    get velocitiesByLevel() {
        return this.instrument.velocities.toSorted((v11, v21)=>v11.loudness - v21.loudness);
    }
    @action
    updateVelocity(group1: VelocityGroup, ev1: Event) {
        const velocity1 = parseInt((<HTMLInputElement>ev1.target).value);
        const sample1 = group1.velocities[0]!;
        if (!sample1.data) {
            sample1.vel = velocity1;
        } else {
            clearTimeout(this.undoUpdateTimer);
            group1.vel = velocity1;
            this.undoUpdateTimer = window.setTimeout(()=>{
                this.performVelocityUpdate(group1, velocity1);
            }, 800);
        }
    }
    @action
    handleUpdateVelocity(evt1: Event) {
        const ev1 = <CustomEvent>evt1;
        const { group: group1, velocity: velocity1 }: {
            group: VelocityGroup;
            velocity: number;
        } = ev1.detail;
        this.performVelocityUpdate(group1, velocity1);
    }
    performVelocityUpdate(group1: VelocityGroup, velocity1: number) {
        const commands1 = group1.velocities.map((v3)=>new UpdateSampleVelocityCommand(this.instrument, v3, velocity1));
        this.undoManager.executeCommand(new CompositeCommand('Update velocities', commands1));
    }
    @action
    renameVelocity(velocity1: Velocity, instrument1: Instrument, name1: string) {
        if (name1 != "") {
            this.undoManager.executeCommand(new RenameVelocityCommand(velocity1, instrument1, name1));
        }
    }
    @action
    async playVelocity(velocity1: Velocity, group1: VelocityGroup, ev1?: MouseEvent) {
        ev1?.stopPropagation();
        this.selectedVelocityGroup = group1;
        this.triggeredVelocity = velocity1;
        if (!velocity1.audio && velocity1.sample) {
            await this.audioContext.decodeAudioData(velocity1.sample).then((audio1)=>{
                velocity1.audio = audio1;
            });
        }
        this.audioContext.resume();
        const source1 = this.audioContext.createBufferSource();
        source1.connect(this.audioContext.destination);
        source1.buffer = velocity1.audio!;
        source1.start(0);
    }
    @action
    clearSelection() {
        this.triggeredVelocity = undefined;
    }
    @action
    calculateVelocityIndex(idx1: number) {
        return Math.abs(idx1 - this.velocityGroups.length);
    }
    @action
    calculateVelocityRange(idx1: number) {
        let velocityVal1 = this.velocityGroups.at(idx1)!.vel;
        if (idx1 === 0) {
            return velocityVal1 + "-" + 127;
        } else {
            return velocityVal1 + "-" + this.velocityGroups.at(idx1 - 1)!.vel;
        }
    }
    @action
    velocityInRangeIsGreater(velocity1: number, idx1: number) {
        let velocityVal1 = this.velocityGroups.at(idx1)!.vel;
        return velocityVal1 == velocity1;
    }
    @tracked
    triggeredVelocity?: Velocity;
    @tracked
    selectedVelocityGroup?: VelocityGroup;
    playSamples = modifier((element1: HTMLDivElement)=>{
        let elementRect1: DOMRect;
        let volume1 = 0;
        let intervalId1: number = 0;
        let triggerTimeout1 = 1000;
        const playSample1 = ()=>{
            const velocityValue1 = constrainValue(Math.round(volume1 * 127), 0, 127);
            const [gainNode1, velocity1] = getGainNodeForVelocity(this.songPlayer.audioContext, this.songPlayer.drumsetVolume, this.instrument, velocityValue1);
            if (velocity1 && gainNode1) {
                if (velocity1.audio) {
                    this.triggeredVelocity = velocity1;
                    gainNode1.connect(this.audioContext.destination);
                    const source1 = this.audioContext.createBufferSource();
                    source1.connect(gainNode1);
                    source1.buffer = velocity1.audio;
                    source1.start(0);
                    const off1 = this.audioContext.currentTime + triggerTimeout1 / 1000;
                    if (this.instrument.nonPercussion) {
                        gainNode1.gain.setValueAtTime(volume1, off1 - 0.05);
                        gainNode1.gain.exponentialRampToValueAtTime(0.05, off1);
                        source1.stop(off1);
                    }
                    return source1;
                } else {
                    console.error('No audio for instrument');
                }
            }
        };
        const setVolume1 = (ev1: MouseEvent)=>{
            volume1 = (elementRect1.height - (ev1.clientY - elementRect1.top)) / elementRect1.height;
        };
        const onMouseUp1 = (ev1: MouseEvent)=>{
            document.removeEventListener('mousemove', onMouseMove1);
            document.removeEventListener('mouseup', onMouseUp1);
            clearInterval(intervalId1);
            volume1 = 0;
            this.triggeredVelocity = undefined;
            ev1.preventDefault();
            ev1.stopPropagation();
        };
        const onMouseMove1 = (ev1: MouseEvent)=>{
            ev1.preventDefault();
            ev1.stopPropagation();
            setVolume1(ev1);
        };
        const onMouseDown1 = (ev1: MouseEvent)=>{
            if (ev1.eventPhase === Event.CAPTURING_PHASE) {
                return;
            }
            ev1.preventDefault();
            ev1.stopPropagation();
            this.audioContext.resume();
            elementRect1 = element1.getBoundingClientRect();
            setVolume1(ev1);
            clearInterval(intervalId1);
            playSample1();
            intervalId1 = setInterval(playSample1, triggerTimeout1);
            document.addEventListener('mousemove', onMouseMove1);
            document.addEventListener('mouseup', onMouseUp1);
        };
        element1.addEventListener('mousedown', onMouseDown1, {
            capture: true
        });
        return ()=>{
            element1.removeEventListener('mousedown', onMouseDown1);
        };
    });
    @action
    velocityStyle(velocity1: number) {
        return htmlSafe(`bottom: ${(velocity1 / 127) * 100}%;`);
    }
    @action
    addSampleGroup(velocity1: number) {
        const nextVel1 = this.velocityGroups.toReversed().find((g1)=>g1.vel > velocity1)?.vel ?? 127;
        const newVelocity1 = Math.floor((velocity1 + nextVel1) / 2);
        const newSample1 = new Velocity(newVelocity1);
        this.undoManager.executeCommand(new AddSamplesToInstrumentCommand(this.instrument, [
            newSample1
        ]));
    }
    @action
    removeSampleGroup(group1: VelocityGroup) {
        const commands1: Command<any>[] = [];
        group1.velocities.forEach((velocity1)=>{
            commands1.push(new RemoveSampleFromInstrumentCommand(this.instrument, velocity1));
        });
        this.undoManager.executeCommand(new CompositeCommand('Remove velocity group', commands1));
    }
    @action
    moveSample(group1: VelocityGroup, fromElement1: HTMLElement, fromIndex1: number, toElement1: HTMLElement) {
        const sample1 = group1.velocities[fromIndex1];
        if (sample1) {
            const targetVelocity1 = parseInt(toElement1.dataset['velocityGroup']!);
            const toGroup1 = this.velocityGroups.find((g1)=>g1.vel === targetVelocity1)!;
            const command1 = new UpdateSampleVelocityCommand(this.instrument, sample1, targetVelocity1);
            this.undoManager.executeCommand(this.removeBlankSample(toGroup1, command1));
        }
    }
    minVelocity(groups1: VelocityGroup[], idx1: number) {
        return idx1 === groups1.length - 1 ? 0 : groups1[idx1 + 1]!.vel + 1;
    }
    maxVelocity(groups1: VelocityGroup[], idx1: number) {
        return idx1 === 0 ? 126 : groups1[idx1 - 1]!.vel - 1;
    }
    @action
    updateVelocityValue(group1: VelocityGroup, evt1: Event) {
        const ev1 = <CustomEvent>evt1;
        if (ev1.detail.group == group1) {
            const velocity1 = ev1.detail.velocity;
            (<HTMLInputElement>ev1.currentTarget).value = velocity1;
        }
    }
    static{
        template(`
    {{yield this.addSamples this.autoSort}}
    {{! template-lint-disable no-invalid-interactive }}
    <div
      class="flex flex-col m-2 overflow-auto"
      ...attributes
      {{onKey "Backspace" this.deleteSelectedSample capture=true}}
      {{onKey "Delete" this.deleteSelectedSample capture=true}}
      {{on "click" this.clearSelection}}
    >
      <div class="flex h-full gap-2 mt-4 overflow-auto min-h-32">
        <div
          class="relative w-16 h-full shrink-0 velocity-bar bg-gradient-to-b from-red-800 to-red-300 cursor-vol dark:from-primary-darkGradientStart dark:to-primary-darkGradientEnd"
          {{this.playSamples}}
          {{on "bbff.group-vel-change" this.handleUpdateVelocity}}
        >
          {{#each this.velocityGroups as |group idx|}}
            {{#if (notEq group.vel 0)}}
              <div
                class="absolute left-0 w-full h-1 bg-white bg-opacity-80 cursor-ns-resize dark:bg-primary-darkComponent"
                style={{this.velocityStyle group.vel}}
                {{changeVelocity
                  group
                  (this.minVelocity this.velocityGroups idx)
                  (this.maxVelocity this.velocityGroups idx)
                }}
              />
            {{/if}}
          {{/each}}
        </div>
        <div class="w-full overflow-auto">
          <div class="flex flex-col w-full gap-2 overflow-auto h-max">
            <div class="relative flex min-w-full gap-4 overflow-y-auto">
              <div class="flex items-center">
                <input
                  type="number"
                  aria-label="Velocity"
                  value="127"
                  class="w-16 p-2 font-bold text-center border disabled:bg-gray-200 disabled:cursor-not-allowed disabled:opacity-50 vel-group dark:disabled:bg-primary-darkHeader dark:border-primary-darkBorderColor"
                  {{disabled when=true}}
                />
              </div>
            </div>
            {{#each this.velocityGroups as |group idx|}}
              <div
                {{scrollIntoView
                  when=(this.velocityInRangeIsGreater this.triggeredVelocity.vel idx)
                  opts=(hash block="nearest")
                }}
              >
                <div class="flex flex-col px-24 overflow-y-auto">
                  <h1 class="self-start text-xl font-bold text-primary dark:text-primary-darkButtonSelected border-b border-primary dark:border-primary-darkButtonSelected">
                    Dynamic Group {{this.calculateVelocityIndex idx}} ({{this.calculateVelocityRange idx}})
                  </h1>
                </div>
                <div class="relative flex min-w-full gap-4 overflow-y-auto items-end">
                  {{#unless (eq group.vel 126)}}
                    <div class="absolute top-16 -translate-y-1/2">
                      <Button
                        @icon="plus"
                        {{on "click" (fn this.addSampleGroup group.vel)}}
                      />
                      {{#if (gt this.velocityGroups.length 1)}}
                        <Button
                          @icon="xmark"
                          title={{group.vel}}
                          {{on "click" (fn this.removeSampleGroup group)}}
                        />
                      {{/if}}
                    </div>
                  {{/unless}}
                  <div class="flex items-center">
                    <input
                      type="number"
                      aria-label="Velocity"
                      min={{this.minVelocity this.velocityGroups idx}}
                      max={{this.maxVelocity this.velocityGroups idx}}
                      value={{group.vel}}
                      class="w-16 p-2 font-bold text-center border disabled:bg-gray-200 disabled:cursor-not-allowed disabled:opacity-50 vel-group dark:bg-primary-darkComponent dark:disabled:bg-primary-darkHeader dark:border-primary-darkBorderColor"
                      {{disabled when=(eq group.vel 0)}}
                      {{on "change" (fn this.updateVelocity group)}}
                      {{on "bbff.group-vel-change" (fn this.updateVelocityValue group)}}
                    />
                  </div>
                  <div
                    class="flex w-full mb-10 h-32 gap-2 p-2 overflow-x-auto border-2 border-transparent border-dashed min-h-20 grow droppable:border-green-500"
                    data-velocity-group={{group.vel}}
                    {{markDroppable this.isDragging}}
                    {{on "dragenter" this.handleDragEnter}}
                    {{on "dragover" this.handleDragOver}}
                    {{on "dragleave" this.handleDragLeave}}
                    {{on "drop" (fn this.handleDrop group)}}
                    {{sortable
                      draggable=".sample"
                      group="samples"
                      sort=false
                      onMove=(fn this.moveSample group)
                      swapThreshold=0.65
                    }}
                  >
                    {{#each group.velocities as |velocity|}}
                      {{#if (notEq null velocity.data)}}
                      <div
                          role="button"
                          class="p-2 border rounded bg-primary-light selected:bg-primary sample dark:bg-primary-darkSample dark:selected:text-black dark:selected:bg-primary-darkButtonSelected dark:border-primary-darkBorderColor"
                          {{selected when=(eq this.triggeredVelocity velocity)}}
                          {{on "click" (fn this.playVelocity velocity group)}}
                        >
                          <div class="flex flex-col h-24">
                            <div class="flex-grow flex items-center justify-center">
                              <SelectableItem
                                @model={{velocity}}
                                @field="name"
                                @filter={{nameFilter}}
                                class="!bg-transparent !hover:bg-transparent !selected:bg-transparent"
                                @onChange={{fn this.renameVelocity velocity this.instrument}}
                                title={{velocity.name}}
                              >
                                <div class="flex items-center justify-center w-auto h-auto max-w-24 max-h-16 whitespace-normal">
                                  {{velocity.name}}
                                </div>
                              </SelectableItem>
                            </div>
                            <div class="flex items-center justify-between gap-4 text-sm">
                              <div>{{round velocity.duration 2}} sec</div>
                              <div>{{round velocity.loudness 2}} dB</div>
                            </div>
                          </div>
                        </div>
                      {{/if}}
                    {{/each}}
                  </div>
                </div>
              </div>
            {{/each}}
          </div>
        </div>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        SampleEditor: typeof SampleEditor;
    }
}
