import { template } from "@ember/template-compiler";
import { registerDestructor } from '@ember/destroyable';
import { fn } from '@ember/helper';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import onKey from 'editor/modifiers/on-key';
import onKeyUp from 'editor/modifiers/on-key-up';
import type ProjectManagerService from 'editor/services/project-manager';
import type { SongFile } from 'editor/services/project-manager';
import type VirtualPedalService from 'editor/services/virtual-pedal';
import { TrackType } from 'editor/services/virtual-pedal';
import type { ArgsFor } from 'ember-modifier';
import Modifier, { modifier } from 'ember-modifier';
const pedalClickHandler = modifier((element1: Element, // eslint-disable-next-line no-unused-vars
[down1, up1]: [(ev: MouseEvent) => void, (ev: MouseEvent) => void])=>{
    const mouseup1 = (ev1: MouseEvent)=>{
        document.removeEventListener('mouseup', mouseup1);
        up1(ev1);
    };
    const mousedown1 = (ev1: MouseEvent)=>{
        document.addEventListener('mouseup', mouseup1);
        down1(ev1);
    };
    //@ts-expect-error
    element1.addEventListener('mousedown', mousedown1);
    return ()=>{
        document.removeEventListener('mousedown', mousedown1);
    };
});
interface VirtualPedalModifierSignature {
    Args: {
        Named: {
        };
        Positional: [];
    };
}
function virtualPedalModifierCleanup(instance1: VirtualPedalModifier) {
    if (instance1.screenBars) {
        instance1.screenBars.innerHTML = '';
        instance1.virtualPedalEl = undefined;
        instance1.screenBars = undefined;
        instance1.screenBackground = undefined;
        instance1.beatIndicator = undefined;
        instance1.barIndicator = undefined;
    }
}
/**
 *  This modifier sets up and updates the virtual pedal screen
 */ let VirtualPedalModifier = class VirtualPedalModifier extends Modifier<VirtualPedalModifierSignature> {
    @service('virtual-pedal')
    pedalService: VirtualPedalService;
    virtualPedalEl?: SVGElement;
    screenBox?: DOMRect;
    screenBars?: SVGRectElement;
    screenBackground?: SVGRectElement;
    label?: SVGTextElement;
    subLabel?: SVGTextElement;
    beatIndicator?: SVGRectElement;
    barIndicator?: SVGRectElement;
    constructor(owner1: unknown, args1: ArgsFor<VirtualPedalModifierSignature>){
        // @ts-expect-error
        super(owner1, args1);
        registerDestructor(this, virtualPedalModifierCleanup);
    }
    setupScreen(virtualPedalEl1: SVGElement) {
        this.virtualPedalEl = virtualPedalEl1;
        const screen1 = <SVGRectElement>virtualPedalEl1.querySelector('#screen');
        this.screenBars = <SVGRectElement>virtualPedalEl1.querySelector('#screen-bars');
        this.screenBox = screen1?.getBBox();
        let { x: x1, y: y1, width: width1, height: height1 } = this.screenBox;
        this.screenBackground = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
        this.screenBackground.setAttribute('x', `${x1}`);
        this.screenBackground.setAttribute('y', `${y1}`);
        this.screenBackground.setAttribute('width', `${width1}`);
        this.screenBackground.setAttribute('height', `${height1}`);
        this.screenBackground.setAttribute('class', 'fill-blue-100');
        this.screenBars.appendChild(this.screenBackground);
    }
    drawIndicators() {
        if (!this.screenBox) return;
        if (!this.screenBars) return;
        let { x: x1, y: y1, width: width1, height: height1 } = this.screenBox;
        this.beatIndicator = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
        this.beatIndicator.setAttribute('x', `${x1}`);
        this.beatIndicator.setAttribute('y', `${y1}`);
        this.beatIndicator.setAttribute('width', `${width1}`);
        this.beatIndicator.setAttribute('height', `${height1}`);
        this.beatIndicator.setAttribute('class', 'fill-green-300');
        this.screenBars.appendChild(this.beatIndicator);
        const barHeight1 = height1 / 4;
        this.barIndicator = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
        this.barIndicator.setAttribute('x', `${x1}`);
        this.barIndicator.setAttribute('y', `${y1 + height1 - barHeight1}`);
        this.barIndicator.setAttribute('width', `${width1}`);
        this.barIndicator.setAttribute('height', `${barHeight1}`);
        this.barIndicator.setAttribute('class', 'fill-green-500');
        this.screenBars.appendChild(this.barIndicator);
        this.label = document.createElementNS('http://www.w3.org/2000/svg', 'text');
        this.label.setAttribute('x', `${x1 + width1 / 2}`);
        this.label.setAttribute('y', `${y1 + height1 / 2.8}`);
        this.label.setAttribute('dominant-baseline', 'middle');
        this.label.setAttribute('text-anchor', 'middle');
        this.label.setAttribute('style', 'font-size: 45px; font-family: sans-serif; font-weight: 500');
        this.screenBars.appendChild(this.label);
        this.subLabel = document.createElementNS('http://www.w3.org/2000/svg', 'text');
        this.subLabel.setAttribute('x', `${x1 + width1 / 2}`);
        this.subLabel.setAttribute('y', `${y1 + height1 / 1.8}`);
        this.subLabel.setAttribute('dominant-baseline', 'middle');
        this.subLabel.setAttribute('text-anchor', 'middle');
        this.subLabel.setAttribute('style', 'font-size: 30px; font-family: sans-serif; font-weight: 500');
        this.screenBars.appendChild(this.subLabel);
    }
    getColors(type1: TrackType): [bgColor: string, beatColor: string, barColor: string, textColor: string] {
        switch(type1){
            case TrackType.MAIN:
                return [
                    'fill-green-100',
                    'fill-green-300',
                    'fill-green-500',
                    'fill-black'
                ];
            case TrackType.FILL:
                return [
                    'fill-yellow-100',
                    'fill-yellow-300',
                    'fill-yellow-500',
                    'fill-black'
                ];
            case TrackType.TRANSITION:
                return [
                    'fill-gray-100',
                    'fill-gray-300',
                    'fill-gray-500',
                    'fill-black'
                ];
            case TrackType.PAUSED:
                return [
                    'fill-black',
                    'fill-gray-200',
                    'fill-gray-200',
                    'fill-white'
                ];
            default:
                return [
                    'fill-red-100',
                    'fill-red-300',
                    'fill-red-500',
                    'fill-black'
                ];
        }
    }
    get type() {
        return this.pedalService.currentTrackType ?? 0;
    }
    get track() {
        return this.pedalService.currentTrack;
    }
    get beat() {
        return this.pedalService.beat;
    }
    get bar() {
        return this.pedalService.bar;
    }
    modify(virtualPedalEl1: SVGElement) {
        const { track: track1, type: type1, beat: beat1, bar: bar1 } = this;
        if (this.virtualPedalEl !== virtualPedalEl1) {
            this.setupScreen(virtualPedalEl1);
        }
        if (track1 && !this.beatIndicator) {
            this.drawIndicators();
        }
        if (track1 && this.beatIndicator && this.barIndicator && this.label) {
            this.label.textContent = this.pedalService.label;
            this.subLabel!.textContent = this.pedalService.subLabel;
            const [bgColor1, beatColor1, barColor1, textColor1] = this.getColors(type1);
            let { x: x1, width: width1 } = this.screenBox!;
            this.screenBackground?.setAttribute('class', bgColor1);
            this.label?.setAttribute('class', textColor1);
            this.subLabel?.setAttribute('class', textColor1);
            const beatWidth1 = width1 / track1.timeSignatureNumerator;
            this.beatIndicator.setAttribute('x', `${x1 + beat1 * beatWidth1}`);
            this.beatIndicator.setAttribute('width', `${beatWidth1}`);
            this.beatIndicator.setAttribute('class', beatColor1);
            if (track1.barCount > 1 && type1 !== TrackType.PAUSED) {
                const barWidth1 = width1 / track1.barCount;
                this.barIndicator.setAttribute('x', `${x1 + bar1 * barWidth1}`);
                this.barIndicator.setAttribute('width', `${barWidth1}`);
                this.barIndicator.setAttribute('class', barColor1);
            } else {
                this.barIndicator.setAttribute('x', '0');
                this.barIndicator.setAttribute('width', '0');
            }
        }
        if (!track1) {
            this.screenBackground?.setAttribute('class', 'fill-blue-100');
            this.beatIndicator?.setAttribute('x', '0');
            this.beatIndicator?.setAttribute('width', '0');
            this.beatIndicator?.setAttribute('class', 'fill-blue-100');
            this.barIndicator?.setAttribute('x', '0');
            this.barIndicator?.setAttribute('width', '0');
            this.barIndicator?.setAttribute('class', 'fill-blue-100');
            if (this.label) {
                this.label?.setAttribute('class', 'fill-black');
                this.label.textContent = '';
                this.subLabel?.setAttribute('class', 'fill-black');
                this.subLabel!.textContent = '';
            }
        }
    }
};
interface VirtualPedalSignature {
    Element: HTMLDivElement;
    Args: {
        songFile?: SongFile;
    };
    Blocks: {
    };
}
export default class VirtualPedalComponent extends Component<VirtualPedalSignature> {
    @service('virtual-pedal')
    pedalService: VirtualPedalService;
    @service
    projectManager: ProjectManagerService;
    constructor(owner1: unknown, args1: VirtualPedalSignature['Args']){
        super(owner1, args1);
    }
    get title() {
        return this.projectManager.currentSong?.name;
    }
    @action
    async triggerPlay() {
        this.pedalService.start();
    }
    @action
    triggerFill() {
        this.pedalService.triggerFill();
    }
    @action
    startTransition(partNumber1?: number) {
        this.pedalService.triggerStartTransition(partNumber1 ?? 127);
    }
    @action
    endTransition() {
        this.pedalService.triggerEndTransition();
    }
    @action
    triggerOutro() {
        // You can't trigger an outro if you're already in a transition
        if (this.pedalService.currentTrackType === TrackType.TRANSITION) {
            return;
        }
        this.pedalService.trackQueue.clear();
        this.pedalService.triggerOutro();
    }
    @action
    stop() {
        this.pedalService.stop();
    }
    isStarting = false;
    isEnding = false;
    inTransition = false;
    pedalTimer?: number;
    @action
    mouseDown(ev1: MouseEvent) {
        this.mainPedalDown(ev1.detail);
        document.addEventListener('mouseup', this.mouseUp);
    }
    mainPedalDown(pressCount1: number) {
        if (pressCount1 >= 3) {
            clearTimeout(this.pedalTimer);
            this.pedalTimer = undefined;
            this.stop();
            return;
        }
        if (pressCount1 === 2) {
            clearTimeout(this.pedalTimer);
            this.pedalTimer = undefined;
            this.isEnding = true;
            this.triggerOutro();
            return;
        }
        if (!this.pedalService.isPlaying) {
            this.isStarting = true;
            this.triggerPlay();
            return;
        }
        if (this.pedalService.isPlaying) {
            if (this.pedalService.currentTrackType === TrackType.INTRO) {
                this.triggerOutro();
                return;
            }
            clearTimeout(this.pedalTimer);
            this.pedalTimer = setTimeout(()=>{
                this.inTransition = true;
                this.startTransition();
                this.pedalTimer = undefined;
            }, 500);
        }
    }
    @action
    mouseUp() {
        this.mainPedalUp();
        document.removeEventListener('mouseup', this.mouseUp);
    }
    mainPedalUp() {
        // clear window selection
        window.getSelection()?.removeAllRanges();
        if (this.isStarting) {
            this.isStarting = false;
            return;
        }
        if (this.isEnding) {
            this.isEnding = false;
            return;
        }
        if (this.pedalTimer) {
            clearTimeout(this.pedalTimer);
            this.pedalTimer = undefined;
            this.triggerFill();
            return;
        }
        if (this.inTransition) {
            this.inTransition = false;
            this.endTransition();
        }
    }
    @action
    footSwitchLeft() {
        this.pedalService.triggerFootSwitchLeft();
    }
    @action
    footSwitchRight() {
        this.pedalService.triggerFootSwitchRight();
    }
    keyboardTimer?: number;
    pedalState: 'up' | 'down' = 'up';
    pressCount = 0;
    @action
    keyboardPedal(ev1: KeyboardEvent) {
        if (ev1.type === 'keydown') {
            if (this.pedalState === 'down') return;
            this.pedalState = 'down';
            this.pressCount++;
            this.mainPedalDown(this.pressCount);
        }
        if (ev1.type === 'keyup') {
            if (this.pedalState === 'up') return;
            this.keyboardTimer && clearTimeout(this.keyboardTimer);
            this.pedalState = 'up';
            this.mainPedalUp();
            this.keyboardTimer = setTimeout(()=>{
                this.pressCount = 0;
            }, 300);
        }
    }
    static{
        template(`
    <div
      class="virtual-pedal"
      ...attributes
      {{onKey "f" this.triggerFill}}
      {{onKey "t" (fn this.startTransition 127)}}
      {{onKey "1" (fn this.startTransition 1)}}
      {{onKey "2" (fn this.startTransition 2)}}
      {{onKey "3" (fn this.startTransition 3)}}
      {{onKey "4" (fn this.startTransition 4)}}
      {{onKey "5" (fn this.startTransition 5)}}
      {{onKey "6" (fn this.startTransition 6)}}
      {{onKey "7" (fn this.startTransition 7)}}
      {{onKey "8" (fn this.startTransition 8)}}
      {{onKey "9" (fn this.startTransition 9)}}
      {{onKeyUp "t" this.endTransition}}
      {{onKeyUp "1" this.endTransition}}
      {{onKeyUp "2" this.endTransition}}
      {{onKeyUp "3" this.endTransition}}
      {{onKeyUp "4" this.endTransition}}
      {{onKeyUp "5" this.endTransition}}
      {{onKeyUp "6" this.endTransition}}
      {{onKeyUp "7" this.endTransition}}
      {{onKeyUp "8" this.endTransition}}
      {{onKeyUp "9" this.endTransition}}
      {{onKey "o" this.triggerOutro}}
      {{onKey "s" this.stop}}
      {{onKey "a" this.footSwitchLeft}}
      {{onKey "l" this.footSwitchLeft}}
      {{onKey "p" this.footSwitchRight}}
      {{onKey "r" this.footSwitchRight}}
      {{onKey "Space" this.keyboardPedal altKey=true}}
      {{onKey "Space" this.keyboardPedal cmdKey=true}}
      {{onKeyUp "Space" this.keyboardPedal altKey=true}}
      {{onKeyUp "Space" this.keyboardPedal cmdKey=true}}
    >
      <div class="flex flex-col items-center p-4 pt-0">
        <h1
          class="max-w-full text-ellipsis overflow-hidden text-nowrap dark:text-white"
          title={{this.title}}
        >{{this.title}}</h1>
        {{! template-lint-disable no-forbidden-elements }}
        {{! template-lint-disable no-invalid-interactive }}
        {{! template-lint-disable no-pointer-down-event-binding }}
        <svg
          id="virtual_pedal"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 531 1024.06"
          class="w-32"
          {{VirtualPedalModifier}}
        >
          <defs>
            <style>
              .cls-1,.cls-2,.cls-3,.cls-4{stroke:#5856d6;stroke-miterlimit:10;}
              .cls-1,.cls-3{fill:none;} .cls-1,.cls-4{stroke-width:6px;}
              .cls-2{fill:#fff;stroke-width:4px;} .cls-3{stroke-width:10px;}
              .cls-4{fill:#5856d6;}</style>
          </defs>
          <g id="screen-bars">
          </g>
          <path
            class="cls-3"
            d="m36.61,5h457.23c16.56,0,30,13.44,30,30v670.3c0,38.63-31.37,70-70,70H76.61c-38.63,0-70-31.37-70-70V35c0-16.56,13.44-30,30-30Z"
          />
          <circle id="tempo" class="cls-1" cx="267" cy="320.7" r="43" />
          <circle id="drumset" class="cls-1" cx="416" cy="320.7" r="43" />
          <circle id="volume" class="cls-1" cx="118" cy="320.7" r="43" />
          <rect
            id="screen"
            class="cls-1"
            x="44"
            y="54.7"
            width="221"
            height="176"
            rx="12"
            ry="12"
          />
          <rect
            id="centre_switch"
            class="cls-1"
            x="387"
            y="122.7"
            width="51"
            height="51"
            rx="12"
            ry="12"
          />
          <path
            id="left_switch"
            class="cls-1"
            d="m330.67,144.8l29.77-27.29c3.19-2.92,8.33-.7,8.38,3.63l.63,53.87c.05,4.32-5.04,6.67-8.29,3.82l-30.4-26.58c-2.24-1.96-2.28-5.44-.09-7.45Z"
          />
          <path
            id="right_switch"
            class="cls-1"
            d="m493.43,150.69l-29.77,27.29c-3.19,2.92-8.33.7-8.38-3.63l-.63-53.87c-.05-4.32,5.04-6.67,8.29-3.82l30.4,26.58c2.24,1.96,2.28,5.44.09,7.45Z"
          />
          <path
            id="up_switch"
            class="cls-1"
            d="m414.99,66.37l27.29,29.77c2.92,3.19.7,8.33-3.63,8.38l-53.87.63c-4.32.05-6.67-5.04-3.82-8.29l26.58-30.4c1.96-2.24,5.44-2.28,7.45-.09Z"
          />
          <path
            id="down_switch"
            class="cls-1"
            d="m409.11,229.12l-27.29-29.77c-2.92-3.19-.7-8.33,3.63-8.38l53.87-.63c4.32-.05,6.67,5.04,3.82,8.29l-26.58,30.4c-1.96,2.24-5.44,2.28-7.45.09Z"
          />
          <path
            id="main_pedal"
            class="cls-4 cursor-pointer"
            d="m36,470.7h457c6.62,0,12,5.38,12,12v216c0,30.36-24.64,55-55,55H79c-30.36,0-55-24.64-55-55v-216c0-6.62,5.38-12,12-12Z"
            {{pedalClickHandler this.mouseDown this.mouseUp}}
          />
          <line class="cls-1" x1="7" y1="414.7" x2="524" y2="414.7" />
          {{! <path
            class="cls-2 pointer-events-none"
            d="m69.85,527.27h43.27c13.67,0,24.02,1.06,31.06,3.19,7.04,2.13,12.72,6.43,17.05,12.92,4.33,6.49,6.49,16.94,6.49,31.35,0,9.75-1.53,16.54-4.58,20.39-3.06,3.84-9.08,6.79-18.08,8.85,10.03,2.27,16.83,6.03,20.4,11.29,3.57,5.26,5.36,13.32,5.36,24.17v15.46c0,11.27-1.29,19.62-3.86,25.05-2.58,5.43-6.68,9.14-12.31,11.13-5.63,1.99-17.17,2.99-34.62,2.99h-50.17v-166.8Zm43.37,28.54v37.09c1.85-.07,3.3-.1,4.33-.1,4.26,0,6.97-1.05,8.14-3.14,1.17-2.09,1.75-8.09,1.75-17.98,0-5.22-.48-8.88-1.44-10.97-.96-2.09-2.22-3.42-3.76-3.97-1.55-.55-4.55-.86-9.02-.93Zm0,63.05v46.67c6.11-.21,10.01-1.17,11.69-2.88,1.68-1.72,2.52-5.94,2.52-12.67v-15.56c0-7.14-.76-11.47-2.27-12.98-1.51-1.51-5.5-2.37-11.95-2.58Z"
          />
          <path
            class="cls-2 pointer-events-none"
            d="m186.38,527.27h43.27c13.67,0,24.02,1.06,31.06,3.19,7.04,2.13,12.72,6.43,17.05,12.92,4.33,6.49,6.49,16.94,6.49,31.35,0,9.75-1.53,16.54-4.58,20.39-3.06,3.84-9.08,6.79-18.08,8.85,10.03,2.27,16.83,6.03,20.4,11.29,3.57,5.26,5.36,13.32,5.36,24.17v15.46c0,11.27-1.29,19.62-3.86,25.05-2.58,5.43-6.68,9.14-12.31,11.13-5.63,1.99-17.17,2.99-34.62,2.99h-50.17v-166.8Zm43.37,28.54v37.09c1.85-.07,3.3-.1,4.33-.1,4.26,0,6.97-1.05,8.14-3.14,1.17-2.09,1.75-8.09,1.75-17.98,0-5.22-.48-8.88-1.44-10.97-.96-2.09-2.22-3.42-3.76-3.97-1.55-.55-4.55-.86-9.02-.93Zm0,63.05v46.67c6.11-.21,10.01-1.17,11.69-2.88,1.68-1.72,2.52-5.94,2.52-12.67v-15.56c0-7.14-.76-11.47-2.27-12.98-1.51-1.51-5.5-2.37-11.95-2.58Z"
          />
          <path
            class="cls-2 pointer-events-none"
            d="m302.9,527.27h73.46v33.38h-30.08v31.63h26.79v31.73h-26.79v70.06h-43.37v-166.8Z"
          />
          <path
            class="cls-2 pointer-events-none"
            d="m386.87,527.27h73.46v33.38h-30.08v31.63h26.79v31.73h-26.79v70.06h-43.37v-166.8Z"
          /> }}
          <rect
            class="cls-3 pointer-events-none"
            x="5"
            y="795.06"
            width="521"
            height="224"
            rx="32"
            ry="32"
          />
          <circle
            id="foot_left"
            class="cls-1 cursor-pointer !fill-transparent"
            cx="120.33"
            cy="907.06"
            r="59.51"
            {{on "mousedown" this.footSwitchLeft}}
          />
          <circle
            id="foot_right"
            class="cls-1 cursor-pointer !fill-transparent"
            cx="409.65"
            cy="907.06"
            r="59.51"
            {{on "mousedown" this.footSwitchRight}}
          />
        </svg>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        VirtualPedal: typeof VirtualPedalComponent;
    }
}
