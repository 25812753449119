import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type ProjectManagerService from 'editor/services/project-manager';
import { isDevelopingApp, macroCondition } from '@embroider/macros';

export default class ApplicationRoute extends Route {
  @service declare projectManager: ProjectManagerService;
  @service declare router: RouterService;

  async beforeModel() {
    if (macroCondition(isDevelopingApp())) {
      const savedProject = await navigator.storage.getDirectory();
      try {
        await savedProject.getDirectoryHandle('SONGS');
        await this.projectManager.loadProject(savedProject);
      } catch (e) {
        // no-op
      }
    }
  }
}
