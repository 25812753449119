import { modifier } from 'ember-modifier';
import { later, next } from '@ember/runloop';

interface ClickOutsideSignature {
  Element: HTMLElement;
  Args: {
    Named: {
      timeout?: number;
      wormholeEl?: HTMLElement | string;
    };
    Positional: [() => void];
  };
}

function onClickOutside(
  element: HTMLElement,
  [callback]: [() => void],
  {
    timeout: timeout = 0,
    wormholeEl: wormholeEl = undefined,
  }: { timeout?: number; wormholeEl?: HTMLElement | string },
) {
  function handleClick(event: TouchEvent | MouseEvent) {
    let $wormhole;
    if (typeof wormholeEl === 'string') {
      $wormhole = document.querySelector(wormholeEl);
    } else {
      $wormhole = wormholeEl;
    }
    if (
      !element.contains(event.target as Node) &&
      (!$wormhole || !$wormhole.contains(event.target as Node))
    ) {
      later(() => callback(), timeout);
    }
  }
  // This modifier is activated during a click event.
  // If we dond’t delay attaching to document, then the click event fires on the activating click because of event bubbling
  // I’m using capture phase so it doesn’t fire if the DOM is changed as a result of the click
  // eslint-disable-next-line ember/no-runloop
  next(() => {
    document.addEventListener('click', handleClick, { capture: true });
    document.addEventListener('touchstart', handleClick, { capture: true });
  });

  return () => {
    document.removeEventListener('touchstart', handleClick, {
      capture: true,
    });
    document.removeEventListener('click', handleClick, { capture: true });
  };
}

export default modifier<ClickOutsideSignature>(onClickOutside);

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'on-click-outside': typeof onClickOutside;
  }
}
