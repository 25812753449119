export default async function promiseBatch(
  items: Array<any>,
  limit: number,
  fn: (item: any) => Promise<any>,
): Promise<any> {
  let results: PromiseSettledResult<any>[] = [];
  for (let start = 0; start < items.length; start += limit) {
    const end = start + limit > items.length ? items.length : start + limit;
    const slicedResults = await Promise.allSettled(
      items.slice(start, end).map(fn),
    );
    results = [...results, ...slicedResults];
  }
  return results.map((result) => {
    if (result.status === 'rejected') {
      throw result.reason;
    } else {
      return result.value;
    }
  });
}
