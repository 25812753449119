import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type { DrumsetFile } from 'editor/services/project-manager';
import type ProjectManagerService from 'editor/services/project-manager';
import type SongPlayerService from 'editor/services/song-player';
import type StatusService from 'editor/services/status';

export default class RestrictedDrumsetsDrumsetRoute extends Route {
  @service declare projectManager: ProjectManagerService;
  @service declare songPlayer: SongPlayerService;
  @service declare status: StatusService;
  @service declare router: RouterService;

  model({ drumset }: { drumset: string }) {
    const drumsetFile = this.projectManager.findDrumset(drumset);
    this.projectManager.currentDrumset = drumsetFile;
    return drumsetFile;
  }

  async afterModel(model?: DrumsetFile) {
    if (model) {
      this.projectManager.currentDrumset = model;
      await model.parse();
      this.status.addDrumsetInfo(model);
      model.drumset?.prepareAudio(this.songPlayer.audioContext);
    } else {
      this.router.transitionTo('restricted.drumsets');
    }
  }
}
