import Controller from '@ember/controller';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { next } from '@ember/runloop';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import {
  AddFolderCommand,
  MoveSongCommand,
  SortFoldersCommand,
  SortFoldersSongsAlphabeticallyCommand,
} from 'editor/models/folder-commands';
import { AddNewSongCommand } from 'editor/models/song-commands';
import type ProjectManagerService from 'editor/services/project-manager';
import { SongFile, SongFolder } from 'editor/services/project-manager';
import type UndoManagerService from 'editor/services/undo-manager';

export default class RestrictedSongsController extends Controller {
  @service declare router: RouterService;
  @service declare projectManager: ProjectManagerService;
  @service declare undoManager: UndoManagerService;
  declare model: SongFolder[];

  @tracked filterText = '';

  get folder() {
    return this.projectManager.currentFolder;
  }

  get song() {
    return this.projectManager.currentSong;
  }

  get folders() {
    return this.projectManager.songFolders;
  }

  get sortMethod() {
    return this.projectManager.sortMethod;
  }

  @action
  async updateFilter(event: Event) {
    this.filterText = (event.target as HTMLInputElement).value;
  }

  get filter() {
    if (this.filterText.length >= 2) {
      return this.filterText;
    } else {
      return '';
    }
  }

  @action
  clearFilter() {
    this.filterText = '';
  }

  @action
  selectSong(folder: SongFolder, song: SongFile) {
    this.router.transitionTo(
      'restricted.songs.folder.song',
      folder.path,
      song.path,
    );
  }

  @action
  sortFolders(oldIndex: number, newIndex: number) {
    this.undoManager.executeCommand(
      new SortFoldersCommand(this.projectManager, oldIndex, newIndex),
    );
  }

  @action
  isSelected(folder: SongFolder) {
    return this.folder == folder;
  }

  @action
  async addFolder() {
    const folder = await this.undoManager.executeCommand(
      new AddFolderCommand(this.projectManager),
    );
    this.router.transitionTo('restricted.songs.folder', folder.path);
  }

  @action
  async addSong(folder?: SongFolder) {
    folder = folder ?? this.folder;
    if (!folder) {
      return;
    }
    const command = new AddNewSongCommand(this.projectManager, folder);
    command.on('execute', (song) => {
      this.router.transitionTo('restricted.songs.folder.song', song.path);
    });
    await this.undoManager.executeCommand(command);
  }

  @action
  async importSong(folder?: SongFolder) {
    folder = folder ?? this.folder;
    if (!folder) {
      return;
    }
    const commands = await this.projectManager.importSong(folder);
    if (commands) {
      commands.on('execute', async (results) => {
        const [song] = results;
        if (song instanceof SongFile) {
          this.router.transitionTo(
            'restricted.songs.folder.song',
            song.folder.path,
            song.path,
          );
        }
      });
      commands.on('reverse', () => {
        this.projectManager.currentFolder = undefined;
        this.router.transitionTo('restricted.songs.index');
      });

      await this.undoManager.executeCommand(commands);
    }
  }

  @action
  moveSong(
    fromElement: HTMLElement,
    fromIndex: number,
    toElement: HTMLElement,
    toIndex: number,
  ) {
    // eslint-disable-next-line ember/no-runloop
    next(() => {
      const folderFromIndex = parseInt(fromElement.dataset['folderIndex']!);
      const folderToIndex = parseInt(toElement.dataset['folderIndex']!);
      const fromFolder = this.folders[folderFromIndex]!;
      const toFolder = this.folders[folderToIndex]!;
      this.undoManager.executeCommand(
        new MoveSongCommand(fromFolder, fromIndex, toFolder, toIndex),
      );
    });
  }

  @action
  sortFoldersSongsAlphabetically() {
    switch (this.sortMethod) {
      case 'none':
        this.projectManager.sortMethod = 'AtoZ';
        break;
      case 'AtoZ':
        this.projectManager.sortMethod = 'ZtoA';
        break;
      case 'ZtoA':
        this.projectManager.sortMethod = 'AtoZ';
        break;
      default:
        this.projectManager.sortMethod = 'none';
        break;
    }
    const command = new SortFoldersSongsAlphabeticallyCommand(
      this.projectManager,
    );
    const song = this.projectManager.currentSong;
    command.on('execute', () => {
      if (song) {
        this.router.transitionTo(
          'restricted.songs.folder.song',
          song.folder.path,
          song.path,
        );
      }
    });
    this.undoManager.executeCommand(command);
  }
}
