import ProjectManagerService, {
  SongFile,
  SongReference,
  type Playlist,
} from 'editor/services/project-manager';
import Command from './command';

export class AddPlaylistCommand extends Command<Playlist> {
  project: ProjectManagerService;
  playlist?: Playlist;

  constructor(project: ProjectManagerService) {
    super('Add Playlist');
    this.project = project;
  }

  markDirty(): void {
    this.playlist?.markDirty();
    this.project.playlistsDirty = true;
  }

  execute() {
    this.playlist = this.project.addPlaylist();
    return this.playlist;
  }

  reverse() {
    if (!this.playlist) {
      throw new Error('Playlist not found');
    }
    this.project.removePlaylist(this.playlist);
    return this.playlist;
  }
}

export class RenamePlaylistCommand extends Command<Playlist> {
  playlist: Playlist;
  fromName: string;
  toName: string;

  constructor(playlist: Playlist, name: string) {
    super('Rename Playlist');
    this.playlist = playlist;
    this.fromName = playlist.name;
    this.toName = name;
  }

  markDirty(): void {
    this.playlist.markDirty();
  }

  execute() {
    this.playlist.name = this.toName;
    return this.playlist;
  }

  reverse() {
    this.playlist.name = this.fromName;
    return this.playlist;
  }
}

export class AddSongToPlaylistCommand extends Command<SongReference> {
  playlist: Playlist;
  song: SongFile;
  toIndex?: number;
  songReference?: SongReference;

  constructor(playlist: Playlist, song: SongFile, toIndex?: number) {
    super('Add Song to Playlist');
    this.playlist = playlist;
    this.song = song;
    this.toIndex = toIndex;
  }

  markDirty(): void {
    this.playlist.markDirty();
  }

  execute() {
    this.songReference = this.playlist.addSong(this.song, this.toIndex);
    return this.songReference;
  }

  reverse() {
    if (!this.songReference) {
      throw new Error('Song reference not found');
    }
    this.playlist.removeSong(this.songReference);
    return this.songReference;
  }
}

export class RemoveSongFromPlaylistCommand extends Command<SongReference> {
  playlist: Playlist;
  song: SongReference;
  index?: number;

  constructor(playlist: Playlist, song: SongReference) {
    super('Remove Song from Playlist');
    this.playlist = playlist;
    this.song = song;
  }

  markDirty(): void {
    this.playlist.markDirty();
  }

  execute() {
    this.index = this.playlist.removeSong(this.song);
    return this.song;
  }

  reverse() {
    if (!this.index) {
      throw new Error('Song reference not found');
    }
    this.playlist.songs = this.playlist.songs.toSpliced(
      this.index,
      0,
      this.song,
    );
    return this.song;
  }
}

export class SortPlaylistCommand extends Command<Playlist> {
  playlist: Playlist;
  oldIndex: number;
  newIndex: number;

  constructor(playlist: Playlist, oldIndex: number, newIndex: number) {
    super('Sort Playlist');
    this.playlist = playlist;
    this.oldIndex = oldIndex;
    this.newIndex = newIndex;
  }

  markDirty(): void {
    this.playlist.markDirty();
  }

  execute() {
    const song = this.playlist.songs[this.oldIndex];
    const arr = [...this.playlist.songs];
    if (song) {
      // remove song from oldIndex and insert it at newIndex
      arr.splice(this.oldIndex, 1);
      arr.splice(this.newIndex, 0, song);
    }
    this.playlist.songs = arr;
    return this.playlist;
  }

  reverse() {
    const song = this.playlist.songs[this.newIndex];
    const arr = [...this.playlist.songs];
    if (song) {
      // remove song from newIndex and insert it at oldIndex
      arr.splice(this.newIndex, 1);
      arr.splice(this.oldIndex, 0, song);
    }
    this.playlist.songs = arr;
    return this.playlist;
  }
}

export class SortPlaylistsCommand extends Command<void> {
  project: ProjectManagerService;
  oldIndex: number;
  newIndex: number;

  constructor(
    project: ProjectManagerService,
    oldIndex: number,
    newIndex: number,
  ) {
    super('Sort Playlists');
    this.project = project;
    this.oldIndex = oldIndex;
    this.newIndex = newIndex;
  }

  markDirty(): void {
    this.project.playlistsDirty = true;
  }

  execute() {
    const playlist = this.project.playlists[this.oldIndex];
    const arr = [...this.project.playlists];
    if (playlist) {
      // remove playlist from oldIndex and insert it at newIndex
      const newIndexPath = this.project.playlists[this.newIndex].path;
      // the below is so the BB shows the playlists in the same order as the BBMO.
      // the BB bases it on config.csv, and goes in the order of 1.csv, 2.csv, ...
      // this is based on path, so we need to make sure the path matches the index (+ 1)
      if (this.oldIndex < this.newIndex) { // if we're moving the playlist up, we need to move the path of everything else down
        for (let i = this.newIndex; i > this.oldIndex; i--) {
          this.project.playlists[i].path = this.project.playlists[i - 1].path;
          this.project.playlists[i].markDirty();
        }
      } else if (this.oldIndex > this.newIndex) { // if we're moving the playlist down, we need to move the path of everything else up
        for (let i = this.newIndex; i < this.oldIndex; i++) {
          this.project.playlists[i].path = this.project.playlists[i + 1].path;
          this.project.playlists[i].markDirty();
        }
      }
      playlist.path = newIndexPath;
      playlist.markDirty();
      arr.splice(this.oldIndex, 1);
      arr.splice(this.newIndex, 0, playlist);
    }
    this.project.playlists = arr;
  }

  reverse() {
    const playlist = this.project.playlists[this.newIndex];
    const arr = [...this.project.playlists];
    if (playlist) {
      // remove playlist from newIndex and insert it at oldIndex
      const newIndexPath = this.project.playlists[this.oldIndex].path;
      // the below is so the BB shows the playlists in the same order as the BBMO.
      // the BB bases it on config.csv, and goes in the order of 1.csv, 2.csv, ...
      // this is based on path, so we need to make sure the path matches the index (+ 1)
      if (this.newIndex < this.oldIndex) { // if we're moving the playlist up, we need to move the path of everything else down
        for (let i = this.oldIndex; i > this.newIndex; i--) {
          this.project.playlists[i].path = this.project.playlists[i - 1].path;
          this.project.playlists[i].markDirty();
        }
      } else if (this.newIndex > this.oldIndex) { // if we're moving the playlist down, we need to move the path of everything else up
        for (let i = this.oldIndex; i < this.newIndex; i++) {
          this.project.playlists[i].path = this.project.playlists[i + 1].path;
          this.project.playlists[i].markDirty();
        }
      }
      playlist.path = newIndexPath;
      playlist.markDirty();
      arr.splice(this.newIndex, 1);
      arr.splice(this.oldIndex, 0, playlist);
    }
    this.project.playlists = arr;
  }
}

export class DeletePlaylistCommand extends Command<Playlist> {
  projectManager: ProjectManagerService;
  playlist: Playlist;
  index: number;

  constructor(projectManager: ProjectManagerService, playlist: Playlist) {
    super('Delete Playlist');
    this.projectManager = projectManager;
    this.index = projectManager.playlists.findIndex((d) => d === playlist);
    this.playlist = playlist;
  }

  markDirty(): void {
    this.projectManager.playlistsDirty = true;
  }

  async execute() {
    this.projectManager.playlists = this.projectManager.playlists.filter(
      (p) => p !== this.playlist,
    );
    return this.playlist;
  }

  async reverse() {
    this.projectManager.playlists = this.projectManager.playlists.toSpliced(
      this.index,
      0,
      this.playlist,
    );
    return this.playlist;
  }
}

export class DuplicatePlaylistCommand extends Command<Playlist> {
  projectManager: ProjectManagerService;
  playlist: Playlist;
  newPlaylist?: Playlist;

  constructor(projectManager: ProjectManagerService, playlist: Playlist) {
    super('Duplicate Playlist');
    this.projectManager = projectManager;
    this.playlist = playlist;
  }

  markDirty(): void {
    this.projectManager.playlistsDirty = true;
    this.newPlaylist?.markDirty();
  }

  async execute() {
    if (this.newPlaylist) {
      this.projectManager.playlists = [
        ...this.projectManager.playlists,
        this.newPlaylist,
      ];
    } else {
      const newPlaylist = this.projectManager.addPlaylist(this.playlist.name);
      this.newPlaylist = newPlaylist;
      this.newPlaylist.songs = [...this.playlist.songs];
    }
    return this.newPlaylist;
  }

  async reverse() {
    this.projectManager.playlists = this.projectManager.playlists.filter(
      (d) => d !== this.newPlaylist,
    );
    return this.playlist;
  }
}
