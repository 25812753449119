import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import { SongFolder } from 'editor/services/project-manager';
import ProjectManagerService from 'editor/services/project-manager';

export default class RestrictedSongsFolderRoute extends Route {
  @service declare projectManager: ProjectManagerService;
  @service declare router: RouterService;

  constructor(properties?: object) {
    super(properties);
    this.projectManager.on('removeFolder', (folder) => {
      const model = this.modelFor(this.routeName);
      if (model === folder) {
        this.router.transitionTo('restricted.songs');
      }
    });
  }

  beforeModel(transition: Transition) {
    if (this.projectManager.drumsets.length === 0) {
      this.router.transitionTo('restricted.songs');
    }
  }

  model({ folder: folderId }: { folder: string }) {
    const folder = this.projectManager.findFolder(folderId);
    this.projectManager.currentFolder = folder;
    this.projectManager.currentSong = undefined;
    return folder;
  }

  afterModel(model: SongFolder) {
    if (!model) {
      this.router.transitionTo('restricted.songs');
    }
  }
}
