export default function notEq(first: any, second: any) {
  return first != second;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    notEq: typeof notEq;
    'not-eq': typeof notEq;
  }
}
