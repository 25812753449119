import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type { Playlist } from 'editor/services/project-manager';
import type ProjectManagerService from 'editor/services/project-manager';

export default class RestrictedPlaylistsPlaylistRoute extends Route {
  @service declare projectManager: ProjectManagerService;
  @service declare router: RouterService;

  model({ playlist: playlistId }: { playlist: string }) {
    const playlist = this.projectManager.playlists.find(
      (p) => p.id === playlistId,
    );
    this.projectManager.currentPlaylist = playlist;
    return playlist;
  }

  afterModel(model: Playlist) {
    if (!model) {
      this.router.transitionTo('restricted.playlists');
    }
  }
}
