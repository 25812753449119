import { action } from '@ember/object';
import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type Drumset from 'editor/models/drumset';
import type { Instrument } from 'editor/models/drumset';
import type { SongTrack } from 'editor/models/song';
import constrainValue from 'editor/utils/constrain-value';
import List from 'editor/utils/list';
import MusicUtils from 'editor/utils/music-utils';
import type ProjectManagerService from './project-manager';
import type Song from 'editor/models/song';
import playInstrument from 'editor/utils/play-instrument';
import playAccent from 'editor/utils/play-accent';
import type SongPlayerService from './song-player';

export class ChokeGroup {
  notes = new List<AudioBufferSourceNode>();

  choke(when: number) {
    // Sets the note to stop and then removes the note so the next choke doesn’t update this note’s stop time
    this.notes.dropWhile((note) => {
      note.stop(when);
      return true;
    });
  }

  add(note: AudioBufferSourceNode) {
    this.notes.push(note);
  }
}

interface PlayOpts {
  loop?: boolean;
  playFrom?: any;
  startOffset?: any;
  followedBy?: any;
  nextBar?: boolean;
}

type QueuedTrack = {
  type: TrackType;
  track: SongTrack;
  loop: boolean;
  playFrom?: number;
  startOffset?: number;
  followedBy?: QueuedTrack;
};

export enum TrackType {
  INTRO,
  OUTRO,
  MAIN,
  FILL,
  TRANSITION,
  PAUSED,
}

export default class VirtualPedalService extends Service {
  @service declare projectManager: ProjectManagerService;
  @service declare songPlayer: SongPlayerService;

  audioContext: AudioContext;
  drumsetVolume: GainNode;
  @tracked isPlaying = false;
  @tracked currentTrack?: SongTrack;
  @tracked currentTrackType?: TrackType;
  @tracked beat = 0;
  @tracked bar = 0;
  @tracked _currentSong?: Song;
  @tracked _currentDrumset?: Drumset;
  trackQueue = new List<QueuedTrack>();

  constructor(properties?: object) {
    super(properties);
    this.audioContext = new AudioContext({
      latencyHint: 'interactive',
      sampleRate: 44100,
    });
    this.drumsetVolume = new GainNode(this.audioContext, { gain: 1 });
    this.drumsetVolume.connect(this.audioContext.destination);
    this.projectManager.on('songChange', (songFile) => {
      songFile.parse().then((song) => {
        if (song) this.song = song;
      });
    });
    this.projectManager.currentSong?.parse().then((song) => {
      if (song) this.song = song;
    });
  }

  set song(song: Song) {
    this.stop();
    this._currentSong = song;
    this.setDrumset(song.drumset);
    song.on('changeDrumset', (drumsetName) => {
      this.setDrumset(drumsetName);
    });
    this.audioContext.suspend();
  }

  get song() {
    return this._currentSong!;
  }

  setSong(song: Song) {
    this._currentSong = song;
  }

  setDrumset(name: string) {
    this.projectManager
      .findDrumset(name)
      .parse()
      .then((drumset) => {
        if (drumset) {
          drumset.prepareAudio(this.audioContext);
          this._currentDrumset = drumset;
          const drumsetGain = drumset.volume / 100;
          this.drumsetVolume.gain.setValueAtTime(
            drumsetGain,
            this.audioContext.currentTime,
          );
        }
      });
  }

  get drumset() {
    return this._currentDrumset;
  }

  get label() {
    switch (this.currentTrackType) {
      case TrackType.INTRO:
        return 'Intro fill';
      case TrackType.MAIN:
      case TrackType.TRANSITION:
      case TrackType.PAUSED:
        return `Part ${this.currentPartIndex + 1}/${this.song.parts.length}`;
      case TrackType.FILL:
        return `Fill ${this.currentFillIndex + 1}/${this.currentPart!.fills.length}`;
      case TrackType.OUTRO:
        return 'Outro fill';
      default:
        return '';
    }
  }

  get subLabel() {
    switch (this.currentTrackType) {
      case TrackType.INTRO:
        return '';
      case TrackType.MAIN:
        return ``;
      case TrackType.FILL:
        return ``;
      case TrackType.TRANSITION:
        return 'Transition';
      case TrackType.PAUSED:
        return 'Paused';
      case TrackType.OUTRO:
        return '';
      default:
        return '';
    }
  }

  get ticksPerSecond() {
    const track = this.currentTrack ?? this.song.tracks[0]!;
    return (track.tickToQuarter / 60) * this.song.tempo;
  }

  get secondsPerBeat() {
    if (!this.currentTrack) return 0;

    return (
      this.currentTrack.tickToQuarter /
      (this.currentTrack.timeSignatureDenominator / 4) /
      this.ticksPerSecond
    );
  }

  get secondsPerSixteenth() {
    if (!this.currentTrack) return 0;
    const ticksToSixteenth = this.currentTrack?.tickToQuarter / 4;
    return ticksToSixteenth / this.ticksPerSecond;
  }

  #chokeGroups = new Array(15).fill(0).map(() => new ChokeGroup());
  getChokeGroup(group: number): ChokeGroup {
    if (group < 0 || group > 15) throw new Error('Invalid choke group');
    return this.#chokeGroups[group]!;
  }

  addToChokeGroup(group: number, note: AudioBufferSourceNode) {
    this.getChokeGroup(group).add(note);
  }

  #fillChokeGroups = new Array(15).fill(0).map(() => new Array<number>());
  getFillChokeGroup(group: number): number[] {
    if (group < 0 || group > 15) throw new Error('Invalid choke group');
    return this.#fillChokeGroups[group]!;
  }

  addToFillChokeGroup(group: number, onWhen: number) {
    this.getFillChokeGroup(group).push(onWhen);
  }

  clearFillChokeGroups() {
    this.#fillChokeGroups = new Array(15)
      .fill(0)
      .map(() => new Array<number>());
  }

  start(partIndex = 0) {
    if (this.isPlaying) return;
    this.currentTrackType = TrackType.INTRO;

    clearTimeout(this.loopTimer);
    this.clearQueue();

    this.isPlaying = true;
    this.songPlayer.stop();

    if (this.audioContext.state === 'suspended') {
      this.audioContext.resume();
    }

    if (this.song.intro && this.song.intro.main) {
      this.queue(TrackType.INTRO, this.song.intro.main, {
        loop: false,
        playFrom: 0,
        startOffset: this.song.intro.main.trigPos,
        followedBy: {
          type: TrackType.MAIN,
          track: this.song.parts[partIndex]!.main!,
          loop: true,
        },
      });
    } else {
      this.playTrack(this.song.parts[0]!.main!, TrackType.MAIN, true);
    }

    this.tick();
  }

  stop() {
    this.isPlaying = false;
    this.clearQueue();
    if (this.tickTimer) {
      cancelAnimationFrame(this.tickTimer);
    }
    if (this.loopTimer) {
      clearTimeout(this.loopTimer);
    }
    if (this.followUpTimer) {
      clearTimeout(this.followUpTimer);
    }
    if (this.trackMarkerTimer) {
      clearTimeout(this.trackMarkerTimer);
    }
    this.currentTrackType = undefined;
    this.currentTrack = undefined;
    this.currentFillIndex = -1;
    this.currentPartIndex = 0;
    this.transitionToPart = 0;
    this.playingEvents.forEach(([noteOn, note]) => {
      if (noteOn >= this.audioContext.currentTime) {
        note.stop();
      }
    });
    this.playingEvents.clear();
  }

  /**
   * Adds a track to play after the current track
   */
  queue(type: TrackType, track: SongTrack, playOpts: PlayOpts) {
    const loop = playOpts.loop ?? false;
    const playFrom =
      playOpts.playFrom ?? (playOpts.nextBar === true ? -1 : undefined);
    const startOffset = playOpts.startOffset;
    const followedBy = playOpts.followedBy;
    this.trackQueue.clear();
    this.trackQueue.push({
      type,
      track,
      loop,
      playFrom,
      startOffset,
      followedBy,
    });
  }

  clearQueue() {
    this.trackQueue.clear();
  }

  tickTimer?: number;
  followUpTimer?: number;
  @action
  tick() {
    if (!this.isPlaying) return;

    // Snapshot of current time
    const currentTime = this.audioContext.currentTime;

    // If the outro is complete, then stop
    if (
      this.currentTrackType === TrackType.OUTRO &&
      currentTime >= this.trackEnd
    ) {
      this.stop();
      return;
    }

    // Set up beat information for the UI
    const track = this.currentTrack;
    let barEnd = 0;
    if (track) {
      const elapsedTime = currentTime - this.trackStart;
      const timePerTick = (this.trackEnd - this.trackStart) / track.totalTicks;

      let currentTick = Math.round(elapsedTime / timePerTick);
      if (currentTick === Infinity) {
        currentTick = 0;
      }
      if (currentTick < 0) {
        currentTick = track.totalTicks + currentTick;
      }
      const beatTicks: number = MusicUtils.beatTicks(
        track.tickToQuarter,
        track.timeSignatureDenominator,
      );
      const beat = Math.floor(
        (currentTick / beatTicks) % track.timeSignatureNumerator,
      );
      if (beat != this.beat && !isNaN(beat)) this.beat = beat;
      const bar = Math.floor(currentTick / track.barLength);
      if (bar != this.bar && !isNaN(bar)) this.bar = bar;
      barEnd =
        this.trackStart + ((bar + 1) * track.barLength) / this.ticksPerSecond;
    }

    // If we have a queued track and we’re within the last half beat of the current track
    if (this.trackQueue.first) {
      if (
        // The next track is queued to play from the end of the curren track
        (!this.trackQueue.first.playFrom &&
          this.trackEnd - currentTime <= this.secondsPerBeat / 2) ||
        // The next track is queued to play from a specific time
        (typeof this.trackQueue.first.playFrom !== 'undefined' &&
          this.trackQueue.first.playFrom - this.secondsPerBeat / 2 <=
            currentTime)
      ) {
        // Grab the first track from the queue
        let { type, track, loop, playFrom, startOffset, followedBy } =
          this.trackQueue.shift()!;
        playFrom = playFrom ?? this.trackEnd;
        if (playFrom === -1) {
          playFrom = barEnd;
        }

        // Clear the loop timer so it doesn’t continue to buffer the currently playing track
        // Clear other timers to avoid issues like https://github.com/SingularSound/BBMO/issues/39
        clearTimeout(this.loopTimer);
        clearTimeout(this.trackMarkerTimer);
        clearTimeout(this.followUpTimer);

        // If the incoming track is not a main track, then we need to stop any notes that are scheduled to play in the buffer
        if (type !== TrackType.MAIN) {
          // Stop all notes scheduled after this track is scheduled to start
          const dropped = this.playingEvents.dropAfter(
            ([noteOn]) => noteOn < playFrom!,
          );
          dropped.forEach(([, note]) => note.stop());
        }

        // Set and play the new track
        this.playTrack(track, type, loop, playFrom, startOffset ?? 0);
        // A bit of a hack to make sure the above track is buffered first
        clearTimeout(this.followUpTimer);
        this.followUpTimer = setTimeout(() => {
          if (followedBy) {
            this.queue(followedBy.type, followedBy.track, {
              loop: followedBy.loop,
            });
          }
        }, 100);
      }
    }
    this.tickTimer = requestAnimationFrame(this.tick);
  }

  trackStart = 0;
  trackEnd = 0;
  playTrack(
    track: SongTrack,
    type: TrackType,
    loop: boolean = false,
    playFrom?: number,
    startOffset?: number,
  ) {
    playFrom = playFrom ?? 0;
    startOffset = startOffset ?? 0;
    this.scheduleEvents(track, type, loop, playFrom, startOffset);
  }

  queueFillTypeTrack(
    type: TrackType,
    track: SongTrack,
    loop: boolean,
    playImmediately?: boolean,
    followedBy?: QueuedTrack,
  ) {
    if (!this.currentTrack) return;

    const currentTime = this.audioContext.currentTime;

    const currentTrack = this.currentTrack;
    let startOffset = type === TrackType.MAIN ? 0 : track.trigPos;

    const timePerBar =
      (this.trackEnd - this.trackStart) / currentTrack.barCount;
    const elapsedTime = (currentTime - this.trackStart) % timePerBar;
    const currentTick = Math.round(
      currentTrack.barLength - (timePerBar - elapsedTime) * this.ticksPerSecond,
    );

    let ticksToStartOffset = startOffset - currentTick;
    if (ticksToStartOffset < 0) {
      startOffset = startOffset - ticksToStartOffset;
    }
    let playFrom =
      currentTime + (startOffset - currentTick) / this.ticksPerSecond;

    if (playImmediately) {
      playFrom = 0;
      startOffset = 0;
    }

    this.queue(type, track, { loop, playFrom, startOffset, followedBy });
  }

  @tracked currentPartIndex = 0;
  @tracked currentFillIndex = -1;
  @action
  triggerFill() {
    if (this.currentTrackType === TrackType.TRANSITION) {
      this.transitionToPart = this.transitionFromPart;
      return;
    }
    if (this.currentTrackType === TrackType.INTRO) return;
    if (this.currentTrackType === TrackType.FILL) return;
    if (this.currentTrackType === TrackType.OUTRO) return;

    const wasPaused = this.currentTrackType === TrackType.PAUSED;
    this.unpause();

    // Reset any pending transition
    this.transitionToPart = this.currentPartIndex;

    const part = this.currentPart;
    if (part) {
      let fillIndex = this.currentFillIndex;
      if (part.shuffleFills) {
        fillIndex = Math.floor(Math.random() * part.fills.length);
      } else {
        fillIndex++;
        if (fillIndex >= part.fills.length) {
          fillIndex = 0;
        }
      }

      const fill = part.fills[fillIndex];
      if (fill) {
        this.playFill(fill, wasPaused);
      }
      this.currentFillIndex = fillIndex;
    }
  }

  /**
   * Queue fill settings:
   * - Immediately (last half beat == next measure)
   * - Next half beat
   * - Next Beat
   * - Next Measure
   */
  playFill(fillTrack: SongTrack, immediately = false) {
    if (!this.currentTrack) return;
    this.currentTrackType = TrackType.FILL;

    this.queueFillTypeTrack(TrackType.FILL, fillTrack, false, immediately, {
      type: TrackType.MAIN,
      track: this.currentPart!.main!,
      loop: true,
    });
  }

  transitionFromPart = 0;
  transitionToPart = 0;
  transitionTrackPlaying = false;
  @action
  triggerStartTransition(partNumber: number) {
    if (this.currentTrackType === TrackType.TRANSITION) return;
    if (this.currentTrackType === TrackType.PAUSED) {
      this.unpause();
      this.stop();
      return;
    }

    let partIndex = 0;
    // Next part
    if (partNumber == 127) {
      partIndex = this.currentPartIndex + 1;
      if (partIndex >= this.song.parts.length) {
        partIndex = 0;
      }
      // Previous part
    } else if (partNumber == 126) {
      partIndex = this.currentPartIndex - 1;
      if (partIndex < 0) {
        partIndex = this.song.parts.length - 1;
      }
    } else {
      partIndex = partNumber - 1;
    }

    this.transitionFromPart = this.currentPartIndex;
    this.transitionToPart = constrainValue(
      partIndex,
      0,
      this.song.parts.length - 1,
    );
    const part = this.currentPart;
    this.currentTrackType = TrackType.TRANSITION;
    if (part?.transition) {
      this.transitionTrackPlaying = true;
      this.#startTransition(part.transition);
    }
  }

  #startTransition(transitionTrack: SongTrack) {
    this.queueFillTypeTrack(TrackType.TRANSITION, transitionTrack, true);
  }

  @action
  triggerEndTransition() {
    if (!this.isPlaying) {
      this.start(this.transitionToPart);
    }
    if (!this.currentTrack) return;

    const nextPart = this.song.parts.at(this.transitionToPart);
    if (nextPart?.main) {
      this.currentFillIndex = -1;
      this.queue(TrackType.MAIN, nextPart.main, {
        loop: true,
        nextBar: true,
      });
    }
    this.transitionTrackPlaying = false;
  }

  private get currentPart() {
    return this.song.parts.at(this.currentPartIndex);
  }

  triggerOutro() {
    if (this.song.outro && this.song.outro.main) {
      this.unpause();
      this.playOutro(this.song.outro.main);
    } else {
      this.stop();
    }
  }

  playOutro(outroTrack: SongTrack) {
    this.currentTrackType = TrackType.OUTRO;
    this.queueFillTypeTrack(TrackType.OUTRO, outroTrack, false);
  }

  triggerFootSwitchLeft() {
    const part = this.currentPart;
    if (part && part.accentFileName) {
      const effect = this.projectManager.findEffect(part.accentFileName);
      if (effect) {
        playAccent(
          this.audioContext,
          this.drumsetVolume,
          effect,
          part.accentVolume,
        );
      }
    }
  }

  triggerFootSwitchRight() {
    clearTimeout(this.loopTimer);
    if (this.currentTrackType === TrackType.PAUSED) {
      this.unpause();
      this.playTrack(this.currentTrack!, TrackType.MAIN, true);
    } else {
      this.pause();
    }
  }

  pause() {
    if (this.currentTrack && this.currentTrackType !== TrackType.PAUSED) {
      this.playingEvents.forEach(([, note]) => note.stop());
      this.drumsetVolume.gain.setValueAtTime(0, this.audioContext.currentTime);
      this.currentTrackType = TrackType.PAUSED;
      this.playTrack(this.currentTrack!, TrackType.PAUSED, true);
    }
  }

  unpause() {
    if (this.currentTrackType === TrackType.PAUSED) {
      this.playingEvents.forEach(([, note]) => note.stop());
      const drumsetGain = (this.drumset?.volume ?? 100) / 100;
      this.drumsetVolume.gain.setValueAtTime(
        drumsetGain,
        this.audioContext.currentTime,
      );
      this.currentTrackType = TrackType.MAIN;
    }
  }

  playingEvents = new List<[number, AudioBufferSourceNode]>();
  loopTimer?: number;
  trackMarkerTimer?: number;
  scheduleEvents(
    track: SongTrack,
    type: TrackType,
    loop: boolean,
    playStart = 0,
    playFromTick = 0,
    playToTick = -1,
    eventStartIndex = -1,
  ) {
    if (!track) {
      throw new Error('No track to play');
    }
    if (!this.isPlaying) return;

    // Store this argument because we want to pass it through unmodified when buffering the next part of the track
    const playToTickArg = playToTick;

    const events = track.events;
    const time = this.audioContext.currentTime;

    if (playStart === 0) {
      // If there is no offset specified, then we start immediately at the current time
      playStart = time;
    }

    // How many MIDI events to buffer
    const eventsBuffer = track.barLength * 1;

    // What tick will we pay to in this buffer
    playToTick = Math.min(
      track.totalTicks,
      playToTick == -1 ? playFromTick + eventsBuffer : playToTick,
    );

    // How long is the buffer (before we allocate extra events if needed)
    const duration = (playToTick - playFromTick) / this.ticksPerSecond;

    // If we are playing to the end of the track, then we need to add the extra events (for a fill)
    if (playToTick == track.totalTicks) {
      playToTick += track.extraTicks;
    }

    // We’ve started the track from the beginning
    if (this.currentTrack !== track || playFromTick === 0) {
      // Reset track markers
      this.trackMarkerTimer = setTimeout(
        () => {
          if (type === TrackType.MAIN) {
            // Set the main part
            this.currentPartIndex = this.transitionToPart;
          }
          this.currentTrack = track;
          this.currentTrackType = type;
          // We need to calculate track start from track end because the track might start mid-way through
          this.trackEnd =
            playStart + (track.totalTicks - playFromTick) / this.ticksPerSecond;
          this.trackStart =
            this.trackEnd - track.totalTicks / this.ticksPerSecond;
        },
        (playStart - time) * 1000,
      );
    }

    // Determine the range of events to play
    let eventEndIndex = events.length;
    for (
      let i = Math.max(eventStartIndex, 0), ii = events.length;
      i < ii;
      i++
    ) {
      const trigPos = type === TrackType.MAIN ? 0 : track.trigPos;
      const tick = trigPos + events[i]!.tick;
      if (eventStartIndex < 0 && tick >= playFromTick) {
        eventStartIndex = i;
      }
      if (tick >= playToTick) {
        eventEndIndex = i;
        break;
      }
    }

    // If we have no notes to play, then we’re done
    if (eventStartIndex < 0) {
      eventStartIndex = eventEndIndex;
    }

    // Set a timer to revisit this function when the buffer is done
    this.loopTimer = setTimeout(
      () => {
        playFromTick = playFromTick + eventsBuffer;
        if (playFromTick > track.tickCount) {
          playFromTick = 0;
          eventEndIndex = 0;
        }

        let shouldLoop = loop;
        // We don’t want to loop if there is a queued track about to play
        if (this.trackQueue.first) {
          shouldLoop = false;
        }

        // playFromTick is greater than 0 if we’re buffering the next part of the track
        if (playFromTick > 0 || shouldLoop) {
          this.scheduleEvents(
            track,
            type,
            loop,
            playStart + duration,
            playFromTick,
            playToTickArg,
            eventEndIndex,
          );
        }
      },
      // Set the timeout to buffer the next part of the track in the last buffered beat
      (playStart + duration - time - this.secondsPerBeat) * 1000,
    );

    // If we’re buffering beyond the first part of track, then we don’t need the fill choke groups any more
    if (eventStartIndex > 0) {
      this.clearFillChokeGroups();
    }

    let event, onWhen, offWhen, instrument, note;
    // Loop through the events to be played
    for (let i = eventStartIndex; i < eventEndIndex; i++) {
      event = events[i]!;
      const trigPos = type === TrackType.MAIN ? 0 : track.trigPos;
      const tick = trigPos + event.tick;
      // Determine when to play and stop the note
      onWhen = playStart + (tick - playFromTick) / this.ticksPerSecond;
      // Will be used for non-percussion instruments
      offWhen = onWhen + event.length / this.ticksPerSecond;

      instrument = this.drumset?.instruments[event.note];
      if (instrument) {
        // Stop all instruments in the same choke group
        if (instrument.chokeGroup > 0) {
          const chokeGroup = this.getChokeGroup(instrument.chokeGroup);
          chokeGroup.choke(onWhen);
        }

        // Check if this note should be excluded because of a fill choke group
        if (!this.shouldNoteBeExcluded(instrument, onWhen)) {
          note = playInstrument(
            this.audioContext,
            this.drumsetVolume,
            instrument,
            onWhen,
            offWhen,
            event.velocity,
          );
        }

        if (note) {
          // Add the note to the choke group
          if (instrument.chokeGroup > 0) {
            this.addToChokeGroup(instrument.chokeGroup, note);
          }
          // Store the note so we can stop it when needed
          this.playingEvents.push([onWhen, note]);
        }

        // If we’re dealing with extra ticks for a fill, add them to a fill choke group
        if (tick >= track.totalTicks && instrument.fillChokeGroup > 0) {
          this.addToFillChokeGroup(instrument.fillChokeGroup, onWhen);
        }
      }
    }

    // Quick cleanup
    this.playingEvents.dropWhile(
      ([noteOn]) => noteOn < this.audioContext.currentTime,
    );
  }

  shouldNoteBeExcluded(instrument: Instrument, onWhen: number) {
    if (instrument.fillChokeGroup === 0) return false;

    const fillChokeGroup = this.getFillChokeGroup(instrument.fillChokeGroup);
    let delaySeconds = this.secondsPerSixteenth;
    if (instrument.fillChokeDelay === 0) {
      // 1/4
      delaySeconds = this.secondsPerSixteenth * 4;
    } else if (instrument.fillChokeDelay === 1) {
      // 1/8
      delaySeconds = this.secondsPerSixteenth * 2;
    } else {
      // 1/16
    }
    return fillChokeGroup.some((when) => {
      return onWhen >= when - delaySeconds && onWhen < when + delaySeconds;
    });
  }
}

declare module '@ember/service' {
  interface Registry {
    'virtual-pedal': VirtualPedalService;
  }
}
